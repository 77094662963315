import React, { useEffect, useState } from "react";
import { getRandomNumber } from "../../../utils/function";
import StartFreeTrialBtn from "./StartFreeTrialBtn";

const SubHeaderVideo = (props) => {
  const [video, setVideo] = useState("p54D4H6VKdk");
  useEffect(() => {
    const youtubeVideo = ['p54D4H6VKdk', '9ZdomhAEi2Y', 'H9iOfdGGBiY', 'IhWwaKkgl8k'];
    const i = getRandomNumber(3, 0);
    if (i => 0 && i < 4) {
      setVideo("https://www.youtube-nocookie.com/embed/" + youtubeVideo[i] + "?controls=0&rel=0");
    } else {
      setVideo("https://www.youtube-nocookie.com/embed/" + youtubeVideo[0] + "?controls=0&rel=0");
    }
  }, []);

  return (
    <div className="star-parent">
      {/* <img className="frame-child6" alt="" src={star_7} />
      <img className="frame-child7" alt="" src={star_9} /> */}
      <div className="group-carousel-parent1">
        <div className="carousel-content-parent">
          <div className="heading-and-body-text position-relative z-index-1">
            <div className="generate-incredible-parent">
              <div className="generate-incredible carousal-heading">
                {`Generate `}
                {/*<span className="incredible cursor-pointer">effective,</span>*/}
              </div>
              <div className="effective-ads-video carousal-heading">
                effective AI Ads
              </div>
              <div className="in-seconds carousal-heading">in seconds!</div>
            </div>
            {/* <div className="carousal-heading carousel-desc">
              Use AI to generate amazing, powerful ads, ad ideas and captions specifically for your business in seconds!
            </div> */}
            <StartFreeTrialBtn></StartFreeTrialBtn>
          </div>
        </div>
        <div className="carousel-gallary-parent">
          {/* <iframe
            width="100%"
            height="350"
            src="https://www.youtube.com/embed/p54D4H6VKdk"
            title=""
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe> */}
          <iframe
            width="90%"
            height="350"
            src={video}
            title="Adspire"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default SubHeaderVideo;

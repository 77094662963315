import React from "react";
import { getWebsiteTitle } from "../../../utils/function";
import AdflowNewSubscribe50offSubpage from "./AdflowNewSubscribe50offSubpage";
import ConceptBackButton from "../../common/Button/ConceptBackButton";

const AdflowSubscribe50OffPage = () => {
  document.title = getWebsiteTitle() + " Subscribe Plans";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking new-subscribe-page">
                <div className="clearfix"></div>
                <div className="flex align-items-center text-white">
                  <ConceptBackButton
                    className={"mr-20 cursor-pointer subscribe-backbtn"}
                    alt="Back"
                    redirectPage="select-ads-type"
                  />
                  <h2 className="mb-20 free-trail-welcome-to">Subscribe</h2>
                </div>
                <div className="divider-border-2"></div>
                <AdflowNewSubscribe50offSubpage />
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdflowSubscribe50OffPage;

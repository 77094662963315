
import { userService } from "../../../services/user.service";
import StripeCheckoutRedirect from "../../../utils/StripeCheckoutRedirect";

export default function CheckoutYearlyPro() {
  async function handleCheckout() {
    userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_YEARLY_PRO_PRICE_ID);
    userService.setKeyValueLocalstorage("clientReferenceId", process.env.REACT_APP_PAYPAL_YEARLY_PRO_PLAN_NAME + "||" + userService.getUserId());

    if (!userService.showLogoutBtn()) {
      const myBtn = document.getElementById("ploginModal");
      myBtn.click();
    } else {
      StripeCheckoutRedirect();
    }
  }

  return <><button className="subs-btn" onClick={handleCheckout}>
    <span className="create-new-ads">Subscribe</span>
  </button></>;
}
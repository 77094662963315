import React from 'react'
import Formgroup from './formgroup'
import StyledButton1 from '../common/Button/StyledButton1'

const Modal = ({ formData, handleSubmitGenerateQuickAds, ShowPopup, handleChange, errorMessage }) => {

    return (
        <div   >
            <div
                className={formData.length > 2 ? "top5 modal-dialog top10 modal-dialog-centered text-open-sans" : "modal-dialog top10 modal-dialog-centered text-open-sans"}
                role="document"
            >
                <div className="modal-content modal-gradient-new font-family-vietnam-pro quick-ad-flow-modal ">
                    <div className={formData.length > 2 ? "quest-modal form-group" : "modal-body text-center  form-group"}>
                        <Formgroup fields={formData} handleChange={handleChange} />
                        {formData.length > 2 ? (
                            <StyledButton1
                                onClick={handleSubmitGenerateQuickAds}
                                name={"Generate Ads"}
                                btnTheme={"light"}
                                btnHeight={"45px"}
                            />
                        ) : formData?.some((field) => !field.value) ? (
                            ""
                        ) : (
                            <StyledButton1 onClick={ShowPopup} name={"Next"} btnTheme={"light"} btnHeight={"45px"} />
                        )}

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Modal

import React, { useState, useEffect } from "react";
import { userService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import Paypal from "./common/Paypal";
import PlanMonthly from "./PlanMonthly";
import PlanYearly from "./PlanYearly";
import { getWebsiteTitle } from "../utils/function";

const Subscribe = () => {

  const [planType, setPlanType] = useState("Yearly");
  const [planSwitchToggle, setPlanSwitchToggle] = useState(true);

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      // navigate("/login");
    }
  }, []);

  const redirectTo = () => {
    navigate("/what-type-business-do-have");
  };
  const userPlanType = () => {
    if (planType === 'Yearly') {
      setPlanType('Monthly')
    } else if (planType === 'Monthly') {
      setPlanType('Yearly')
    }
  };

  if (window.location.pathname === '/subscribe') {
    document.title = getWebsiteTitle() + "Subscribe";
  }


  return (
    <>
      <div className="col-12 col-md-12 col-lg-12">
        <div className="start-profiting-big-with-adspi-wrapper mt-20 mb-20">
          <b className="text-weight-900">{`Start profiting `}</b>
          <span className="big">BIG</span>
          <b className="text-weight-900"> with Adspire® today!</b>
        </div>
        <div className="col-12 col-md-12 col-lg-12">
          {/* <div className="slidecontainer"> */}
          {/* <button className="btn btn-primary" onClick={() => userPlanType("Yearly")}>{planType}</button> */}
          {/* </div> */}

          <div className="onoffswitch" onChange={() => setPlanSwitchToggle(prev => !prev)}>
            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" defaultChecked={planSwitchToggle} />
            <label className="onoffswitch-label" htmlFor="myonoffswitch">

              <span className="onoffswitch-switch"></span>
              <span className="onoffswitch-inner"></span>
            </label>
          </div>

          {planSwitchToggle === true && (<PlanYearly></PlanYearly>)}
          {planSwitchToggle === false && (<PlanMonthly></PlanMonthly>)}

        </div>
      </div>

    </>
  );
};
export default Subscribe;

import { isIOSDevice } from "ios-detector";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../services/user.service";
import { db } from "../../utils/Firebase";
import { getWebsiteTitle } from "../../utils/function";

import "./css/Landing-page.css";

import IOSMessageModal from "../common/popup/IOSMessageModal";
import FooterLanding from "./common/FooterLanding";
import Marquee from "./common/Marquee";
import Roas from "./common/Roas";
import SubFooter from "./common/SubFooter";
import SubHeader from "./common/SubHeader";
import SubHeaderVideo from "./common/SubHeaderVideo";
import WarningUsageWebsite from "./common/WarningUsageWebsite";

const LandingPageVideo = () => {
  document.title = getWebsiteTitle() + "Generative AI Ads";
  return (
    <>
    
      {/* <span
        id="pIOSMessageModal"
        data-toggle="modal"
        data-target="#iosMessageModalId"
      ></span> */}
      <div className="confirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
        <div className="hero-sections-parent">
          <div className="hero-sections">
            <SubHeader></SubHeader>
            <SubHeaderVideo />
          </div>
          <Marquee></Marquee>
          <Roas></Roas>
          <SubFooter></SubFooter>

         

          <FooterLanding></FooterLanding>
        </div>
      </div>
    </>
  );
};

export default LandingPageVideo;

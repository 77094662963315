import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import circleCheck from "../../../images/icons/circle-check.svg";
import { userService } from "../../../services/user.service";
import { fetchStyles } from "../../../utils/Firebase.collection.helper";
import { getWebsiteTitle } from "../../../utils/function";
import BackButton from "../../common/Button/BackButton";
import GenerateImageAdsButton from "../../common/Button/GenerateImageAdsButton";
import ConceptStep from "../../common/ConceptStep";
import SpinnerLoader from "../../common/SpinnerLoader";

const ConceptImageStyles = () => {
  const [adStyles, setStyles] = useState("");
  const [active, setActive] = useState(null);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [loading, setLoader] = useState(false);
  // Accessing the history instance created by React
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      //navigate("/login");
    }
  }, []);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
  const fetchData = async () => {
    try {
      if(isMounted){
      const snapshot = await fetchStyles(setStyles,setLoader)
      // Do additional logic if needed with the snapshot
      userService.clearQuestionFlowData();
    }
    } catch (error) {
      console.error('Error fetching ad categories:', error);
      // Handle error
    }
  };

  fetchData();
  return () => {
    isMounted = false;
  };

  }, []);


  const setStyleValue = (StyleData) => {
    userService.setKeyValueLocalstorage("style", StyleData.data.name);
    userService.setKeyValueLocalstorage("styleId", StyleData.id);
    setButtonStatus(false);
    setClassvalue("generateAdGradient");
    setActive(StyleData.data.name);
  };

  document.title = getWebsiteTitle() + "Image Style";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className={`col-sm-12 col-xs-12 flex content-center`}>
                  <ConceptStep step="5"></ConceptStep>
                </div>
                <h2 className="gradient-head-text text-center text-size-32 p-30">
                  What style do you want your image ad in?
                </h2>
                <div className="clearfix"></div>
                <div className="col-sm-6 col-sm-offset-3">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (
                    <div className="row mb-20 mt-20">
                      {adStyles &&
                        adStyles.map((projectsItems) => (
                          <div
                            className={`col-sm-6 col-xs-12`}
                            key={projectsItems.data.backgroundColor + "trnp"}
                            onClick={() => setStyleValue(projectsItems)}
                          >
                            <div
                              title={projectsItems.data.name}
                              className={`thumbnailBG text-size-16 ${
                                active === projectsItems.data.name &&
                                "box-active"
                              }`}
                              style={{
                                backgroundColor:
                                  projectsItems.data.backgroundColor,
                              }}
                            >
                              <div className="select-boxes-for-img">
                                <img
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="img-thumbnail"
                                  width={60}
                                  alt={projectsItems.data.name}
                                  src={projectsItems.data.imageURL}
                                />
                                <span className="ad-title">
                                  {" "}
                                  {projectsItems.data.name}
                                </span>

                                {active === projectsItems.data.name ? (
                                  <img
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    alt={projectsItems.data.name}
                                    width={20}
                                    src={circleCheck}
                                    className="w-20 float-r"
                                  />
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {loading && (
                    <>
                      <GenerateImageAdsButton
                        buttonStatus={buttonStatus}
                        classvalue={classvalue}
                        pagename="concept-image-ads"
                      ></GenerateImageAdsButton>
                      <BackButton pagename="description"></BackButton>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ConceptImageStyles;

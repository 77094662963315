import React from "react";
import ImageSlider1 from "./ImageSlider1";
import StyledButton1 from "../../common/Button/StyledButton1";
import { useState } from "react";
import { userService } from "../../../services/user.service";
import {
  QuickAdFlowUserPrompt,
  getFreeAdsCount,
  setFreeAdsCount,
  upperCase,
} from "../../../utils/function";
import API_FIREBASE from "../../../config/apifirebase";
import constants from "../../../utils/constants";
import { getStyleMaps } from "../../../utils/functionStyleMaps";
import AdFlowListingFree from "../adFlow/AdFlowListingFree";
import LoadingScreen from "../adFlow/Loading";
import { useNavigate } from "react-router-dom";
import svg64 from "svg64"; // or use window.svg64.default

const SubHeaderfreeAdsCarousel = () => {
  const navigate = useNavigate();
  const [loading, setLoader] = useState(false);
  const [userProductName, setUserProductName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showListingPage, setShowListingPage] = useState(false);
  const [copyImages, setCopyImages] = useState([]);
  const [quickAdResponse, setQuickAdResponse] = useState("");
  let copyItems = [];
  let freeAdsCount = getFreeAdsCount() || 0;

  // ************************ Hooks ********************************

  const handleChange = (event) => {
    const { value } = event.target;
    setUserProductName(value);
  };

  const handleSubmitGenerateFreeAds = async () => {
    if (!userProductName) {
      setErrorMessage("Please enter your product name!");
      setTimeout(() => {
        setErrorMessage("");
      }, 3000);
      return;
    }

    if (getFreeAdsCount() > 5) {
      setErrorMessage("Please subscribe to generate more Free Ads!");
      setTimeout(() => {
        navigate(userService.getUserRedirectPage());
      }, 1500);
      return;
    }

    userService.setKeyValueLocalstorage("uploadInputLoading", userProductName);
    setLoader(true);
    setShowListingPage(false);

    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const type = userService.getValueFromLocalstorage("typeValue");
    const userId = userService.getUserId();
    const objGenerateImage = {
      input: userProductName,
      userId: "pass",
      type: type ? type : "business",
      prompt: QuickAdFlowUserPrompt(userProductName),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };

    API_FIREBASE.post("requestQuickImageIdeaNew", objGenerateImage, {
      headers: {
        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
      },
    })
      .then(async (res) => {
        setQuickAdResponse(res);
        userService.setKeyValueLocalstorage("quickAdResponse", res);
        await getQuickAds(res);
      }, [])
      .catch((e) => {
        setErrorMessage("Something went wrong! Please try different input.");
        setTimeout(() => {
          setErrorMessage("");
        }, 3000);
        setLoader(false);
      })
      .finally(() => {
        setLoader(false);
      });
  };

  const getQuickAds = async (responsePrompt) => {
    const userId = userService.getUserId();

    if (userService.getValueFromLocalstorage("quickAdResponse")) {
      const dataToFilterResponse =
        userService.getValueFromLocalstorage("quickAdResponse").data;
      // Extracting the bullet points using regular expressions
      // Split the response into lines
      const linesIdeas = dataToFilterResponse.split("\n");
      // Initialize variables to store data
      let idea = "";
      // Iterate through the lines and assign values to variables
      linesIdeas.forEach((line) => {
        if (line.startsWith("Image Ad Idea")) {
          idea = line.replace("Image Ad Idea: ", "").trim();
        }
      });

      const objGenerateImage = {
        maxImages: constants.APPLICATION_INFO.MAX_IMAGE,
        userId,
        size: constants.APPLICATION_INFO.SIZE,
        prompt: idea,
        aiEngine: "StabilityAi",
      };

      await API_FIREBASE.post("requestFreeAdImagesV2 ", objGenerateImage, {
        headers: {
          authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
        },
      })
        .then(async (res) => {
          // Split the input data by lines
          if (userService.getValueFromLocalstorage("quickAdResponse")) {
            const dataToFilter =
              userService.getValueFromLocalstorage("quickAdResponse").data;
            // Extracting the bullet points using regular expressions
            // Split the response into lines
            const lines = dataToFilter.split("\n");

            // Initialize variables to store data
            let idea = "";
            let headline = "";
            let description = "";
            let cta = "";
            let reviewMessage = "";
            let reviewName = "";
            let question = "";
            let lightColor = "";
            let darkColor = "";
            let bulletPoints = [];
            const bulletPointsRegex = /^• (.+)$/;
            // Iterate through the lines and assign values to variables
            lines.forEach((line) => {
              if (line.startsWith("Image Ad Idea")) {
                idea = line.replace("Image Ad Idea: ", "").trim();
              } else if (line.startsWith("Headline")) {
                headline = upperCase(line.replace("Headline: ", "").trim());
              } else if (line.startsWith("Description")) {
                description = line.replace("Description: ", "").trim();
              } else if (line.startsWith("CTA")) {
                cta = line.replace("CTA: ", "").trim();
              } else if (line.startsWith("Review Message")) {
                reviewMessage = line.replace("Review Message: ", "").trim();
              } else if (line.startsWith("Review Name")) {
                reviewName = line.replace("Review Name: ", "").trim();
              } else if (line.startsWith("Question")) {
                question = line.replace("Question: ", "").trim();
              } else if (line.startsWith("Color 1 (Dark)")) {
                darkColor = line.replace("Color 1 (Dark): ", "").trim();
              } else if (line.startsWith("Color 2 (Light)")) {
                lightColor = line.replace("Color 2 (Light): ", "").trim();
              } else if (line.startsWith("Bullet Points")) {
                // Start collecting bullet points until the next title using regex
                let index = lines.indexOf(line) + 1;
                while (
                  index < lines.length &&
                  bulletPointsRegex.test(lines[index])
                ) {
                  const match = lines[index].match(bulletPointsRegex);
                  if (match) {
                    bulletPoints.push(match[1].trim());
                  }
                  index++;
                }
              }
            });

            let i = 0;
            if (Array.isArray(res.data.imagesBase64)) {
              for (const item of res.data.imagesBase64) {
                // for testing open below code
                const imagesBase64 = item;

                const styleMaps = await getStyleMaps(i, lightColor, darkColor);

                userService.setKeyValueLocalstorage(
                  "bulletpoints",
                  bulletPoints
                );
                userService.setKeyValueLocalstorage(
                  "creativeAIheadline",
                  headline
                );
                userService.setKeyValueLocalstorage(
                  "creativeAIdescription",
                  description
                );
                userService.setKeyValueLocalstorage("creativeAIcta", cta);
                const objGenerateSecondImage = {
                  title: headline,
                  description: description,
                  cta: cta,
                  height: 1024,
                  width: 1024,
                  bulletpoints: bulletPoints,
                  style: styleMaps,
                  question: question,
                  reviewMessage: reviewMessage,
                  reviewName: reviewName,
                };

                const secondRes = await API_FIREBASE.post(
                  "requestCaptionImageAdOverlayV1",
                  objGenerateSecondImage,
                  {
                    headers: {
                      authkey:
                        constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                    },
                  }
                );

                // Call mergeImages with await
                await mergeImages(imagesBase64, i, copyItems, secondRes.data);
                // Use the mergedImageBase64 as needed
                // since i started from 0
                i++;
              }
            }
          } else {
          }
        }, [])
        .catch((e) => {
          setErrorMessage("Something went wrong!");
          setTimeout(() => {
            setErrorMessage("");
          }, 3000);
          setLoader(false);
        })
        .finally(() => {
          setLoader(false);
        });
    }
  };

  const mergeImages = async (
    imagesBase64,
    i,
    copyItems,
    base64OverLayImage
  ) => {
    try {
      if (imagesBase64) {
        const remoteImage1 = "data:image/png;base64," + imagesBase64;
        const remoteImageBlob = await (await fetch(remoteImage1)).blob();
        let base64Image = "";
        try {
          base64Image = svg64(base64OverLayImage);
        } catch (error) {
          console.log(error);
        }
        const base64Blob = await (await fetch(base64Image)).blob();

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const remoteImage = new Image();
        remoteImage.onload = async () => {
          canvas.width = remoteImage.width;
          canvas.height = remoteImage.height;
          context.drawImage(remoteImage, 0, 0);

          const base64ImageObj = new Image();
          base64ImageObj.onload = async () => {
            context.drawImage(base64ImageObj, 0, 0);

            const mergedImageDataUrl = canvas.toDataURL();

            copyItems.push(mergedImageDataUrl);
            // since i started from 0
            if (i == constants.APPLICATION_INFO.MAX_IMAGE - 1) {
              setLoader(false);
              setShowListingPage(true);
              setCopyImages([...copyImages, ...copyItems]);
              setFreeAdsCount(freeAdsCount + 1);
            }
          };
          base64ImageObj.src = URL.createObjectURL(base64Blob);
        };
        remoteImage.src = URL.createObjectURL(remoteImageBlob);
      }
    } catch (error) {
      console.error("Error in mergeImages:", error);
    } finally {
    }
  };

  return (
    <>
      {showListingPage && (
        <div className="free-ads-generation-parent">
          <AdFlowListingFree
            handleUpload={handleSubmitGenerateFreeAds}
            setShowListingPage={setShowListingPage}
            showListingPage={showListingPage}
            copyImages={copyImages}
          ></AdFlowListingFree>
        </div>
      )}

      {loading && !showListingPage && <LoadingScreen />}

      {!loading && !showListingPage && (
        <div className="star-parent">
          <div className="group-carousel-parent1">
            <div className="carousel-content-parent">
              <div className="heading-and-body-text position-relative z-index-10">
                <div className="generate-incredible-parent">
                  <div className="gradiend-title-text text-left mb-20 text-size-50 m-50-mobile">
                    Generate 10 Free Ads!
                  </div>
                </div>
              </div>
              <div className="text-center form-group width-80-percent font-family-sans-serif">
                <div className="text-left text-size-32 text-weight-600 mb-20">
                  What do you sell?
                </div>
                <div>
                  {errorMessage && (
                    <div
                      className="text-left error-message"
                      style={{
                        color: "#e8e8d9",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <input
                    type="text"
                    value={userProductName}
                    className="user-product-name mb-10 mt-10"
                    onChange={handleChange}
                    placeholder="Ex: brown leather jacket"
                  />
                </div>
                <div className="">
                  <StyledButton1
                    name={"Generate"}
                    btnTheme={"light"}
                    btnHeight={"45px"}
                    onClick={handleSubmitGenerateFreeAds}
                  />
                </div>
              </div>
            </div>
            <div className="carousel-gallary-parent">
              <ImageSlider1></ImageSlider1>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default SubHeaderfreeAdsCarousel;

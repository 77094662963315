import React from "react";

import ellipse133_grayish_blue from "../../../images/landing_page_assests/ellipse133_grayish_blue.svg";
import ellipse134_orange from "../../../images/landing_page_assests/ellipse134_orange.svg";
import lines_dots from "../../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../../images/landing_page_assests/star-1.svg";
import union from "../../../images/landing_page_assests/union.svg";

import HeaderLanding from "./HeaderLanding";

const SubHeader = (props) => {
  return (
    <>
      <img className="hero-sections-child" alt="" src={star_1} />
      <img className="lines-dots-icon" alt="" src={lines_dots} />
      <img className="line-dots-icon" alt="" src={lines_dots} />
      <img className="color-bg-orange-icon" alt="" src={ellipse134_orange} />
     {/* <img
        className="color-bg-grayinsh-blue-icon"
        alt=""
        src={ellipse133_grayish_blue}
  />*/}
      <div className="header">
        <HeaderLanding></HeaderLanding>
      </div>

      <div
        className="union-wrapper"
        onClick={() => {
          window.scrollTo({ top: 99999, left: 0, behavior: "smooth" });
        }}
      >
        <img className="union-icon" alt="" src={union} />
      </div>
    </>
  );
};
export default SubHeader;

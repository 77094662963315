import { BehaviorSubject } from "rxjs";
import { db } from "../utils/Firebase";


const userSubject = new BehaviorSubject();
// JSON.parse(secureLocalStorage.getItem("user"))

export const utmService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  saveUTMData,
};

 async function saveUTMData(dataToSave) {

 
    try{
        await db.collection("webUTMLogs").doc().set(dataToSave);
    } catch (error) {
        console.error(error);
      }
}

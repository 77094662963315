import React from "react";
import chat from "../../../../images/icons/chat.png";
import history from "../../../../images/icons/history.png";
import iconcheck_circle from "../../../../images/icons/iconcheck_circle.svg";
import iconcheck_circle2 from "../../../../images/icons/iconcheck_circle2.svg";
import iconcheck_circle3 from "../../../../images/icons/iconcheck_circle3.svg";
import ideas from "../../../../images/icons/ideas.png";
import imagead from "../../../../images/icons/imagead.png";
import incredibly from "../../../../images/icons/incridibly.png";
import variations from "../../../../images/icons/variations.png";

const PlanDetails = () => {

  return (

    <tbody className="parent-t-body">
      <tr className="">
        <td className="text-left pl-15">
          <img src={incredibly} alt="" className="img-list-margin" />  <span className="incredibly"> Incredibly</span> Powerful AI!
        </td>
        <td className="">
          <img src={iconcheck_circle} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle2} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left pl-15">
          <img src={ideas} alt="" className="img-list-margin" /> <span className="incredibly">Unlimited</span> Ad Ideas!
          <br />
          <span className="second-line-padding"> (6 ad Idea types!)</span>
        </td>
        <td className="">
          <img src={iconcheck_circle} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle2} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left pl-15"><img src={history} alt="" className="img-list-margin" /> Ad Ideas History</td>
        <td className="">
          <img src={iconcheck_circle} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle2} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left table-list-line-height pl-15">
          <img src={imagead} alt="" className="img-list-margin" />  <span className="incredibly">Unlimited</span> Image Ads!
          <br />
          <span className="second-line-padding">   (9 image ad styles!)</span>
        </td>
        <td></td>
        <td className="">
          <img src={iconcheck_circle2} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left pl-15"> <img src={imagead} alt="" className="img-list-margin" /> Image Ads History</td>
        <td></td>
        <td className="">
          <img src={iconcheck_circle2} alt="" width={40} />
        </td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left pl-15">
          <img src={chat} alt="" className="img-list-margin" />  <span className="incredibly">Unlimited</span> Captions!
        </td>
        <td></td>
        <td></td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left table-list-line-height pl-15">
          <img src={variations} alt="" className="img-list-margin" /> Image Ad <span className="incredibly">Variations</span>
        </td>
        <td></td>
        <td></td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
      <tr>
        <td className="text-left table-list-line-height pl-15"> <img src={history} alt="" className="img-list-margin" /> Captions History</td>
        <td></td>
        <td></td>
        <td className="">
          <img src={iconcheck_circle3} alt="" width={40} />
        </td>
      </tr>
    </tbody>

  );
};
export default PlanDetails;

import React, { useEffect, useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import FooterLanding from "./FooterLanding";
import HeaderLanding from "./HeaderLanding";
import ellipse134_orange from "../../../images/landing_page_assests/ellipse134_orange.svg";
import lines_dots from "../../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../../images/landing_page_assests/star-1.svg";
import { getWebsiteName } from "../../../utils/function";
import "../css/new-Landing-page.css";
import VerifyAccountPaymentPlan from "./VerifyAccountPaymentPlan";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import Newpopup from "../../Newpopup";

const VerifyAccountPayment = () => {

  const [showExitTabModal, setShowExitTabModal] = useState(false);
  const [permanentClosePopup, setPermanentClosePopup] = useState(true);
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  document.title = " Free AI Ads using " + getWebsiteName() + " AI Trial";
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.getUserId()) {
      const pagename = userService.getSubscribePageName();
      navigate("/signup");
    }
  }, []);

  useEffect(() => {
    // Show the popup after a delay
    const timeoutId = setTimeout(() => {
      if (permanentClosePopup) {
        setShowExitTabModal(true);
      }
    }, 15000); // 15 seconds delay
    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  // Use useEffect to automatically hide the popup after it's shown
  useEffect(() => {
    if (showExitTabModal) {
      // Automatically hide the popup after 5 seconds
      const timeoutId = setTimeout(() => {
        //showExitTabModal(false);
      }, 5000);

      // Clear the timeout when the component is unmounted
      return () => clearTimeout(timeoutId);
    }
  }, [showExitTabModal]);

  const closePopup = () => {
    setShowExitTabModal(false);
    setPermanentClosePopup(false);
  };

  return (
    <Elements stripe={stripePromise}>
      <div className="confirmed-main-landing-page">
        <div className="hero-sections-parent">
          <div className="hero-sections">
            <div className="free-trail-parebt-div">
              <img className="hero-sections-child" alt="" src={star_1} />
              <img className="lines-dots-icon" alt="" src={lines_dots} />
              <img className="line-dots-icon" alt="" src={lines_dots} />
              <img
                className="color-bg-orange-icon"
                alt=""
                src={ellipse134_orange}
              />
              <div className="header">
                <HeaderLanding></HeaderLanding>
              </div>
              <VerifyAccountPaymentPlan />
              <FooterLanding></FooterLanding>
            </div>
          </div>
        </div>

        {showExitTabModal &&
          userService.getValueFromLocalstorage("remoteConfig")
            .autoAdLeavePopup && <Newpopup closePopup={closePopup} />}
      </div>
    </Elements>
  );
};

export default VerifyAccountPayment;

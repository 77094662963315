import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import frame_1261153255 from "../../../images/free_trail_page_assests/frame-1261153255.svg";
import iconai from "../../../images/free_trail_page_assests/iconai.svg";
import iconchat from "../../../images/free_trail_page_assests/iconchat.svg";
import iconidea from "../../../images/free_trail_page_assests/iconidea.svg";
import iconimage from "../../../images/free_trail_page_assests/iconimage.svg";
import iconimageads1 from "../../../images/free_trail_page_assests/iconimageads1.svg";
import iconstyle_image from "../../../images/free_trail_page_assests/iconstyle-image.svg";
import { userService } from "../../../services/user.service";
import { PAYMENT_SUCCESS_MSG } from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import PlanFreeTierUltra from "../../common/PaymentGateway/PaypalSubscription/PlanFreeTierUltra";
import SpinnerLoader from "../../common/SpinnerLoader";
import CheckoutFreetrialYearlyUltra from "../../common/StripePayment/CheckoutFreetrialYearlyUltra";
import { fbEventsService } from "../../../services/fbevents.service";
import { LIVE_PAYMENT } from "../../../utils/constants";
import { PAYPAL_MONTHLY_TRIAL_PLAN_NAME, FREE_TRIAL, PAYPAL_MONTHLY_TRIAL_PRICE } from "../../../utils/constants";

const WelcomeFreeTrailLanding = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {

    fbEventsService.sendFbEvents(FREE_TRIAL, process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME, PAYPAL_MONTHLY_TRIAL_PRICE);
  }, []);

  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };

  //document.title = getWebsiteTitle() + " Free Trial";
  return (


    <div className="group-free-trail-parent" >
      <div className="frame-free-trail-parent">
        <div className="free-trail-frame-group">
          <div className="free-trail-welcome-to-adspire-wrapper">
            <div className="free-trail-welcome-to-adspire-container">
              {/*<span className="free-trail-welcome-to">
                    <span>Welcome to</span>
                    <span className="span">{` `}</span>
                  </span>
                  <span className="span">
                    <b className="free-trail-adspire2">Adspire®</b>
                  </span>*/}
              {paymentProccessStart && (
                <div>
                  <SpinnerLoader></SpinnerLoader>
                  <p className="text-center-normal-mar30">
                    <b>{PAYMENT_SUCCESS_MSG}</b>
                  </p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="free-trail-frame-container">
          <div className="free-trail-frame-wrapper1">
            <div className="free-trail-frame-wrapper2">
              <div className="free-trail-frame-parent1">
                <button className="free-trail-one-time-offer-wrapper">
                  <div className="limited-time-offer-text">
                    LIMITED TIME OFFER!
                  </div>
                </button>
                <b className="try-out-the-container">
                  <span className="try-out-parent">
                    Try out the{" "}
                    <span className="full-power">Full Power</span>
                    <span> of </span>
                  </span>
                  <br />
                  <span className="adspire-free-trail-3">ADSPIRE</span>
                  <span className="adspire-for"> for </span>
                  <span className="adspire-free underline"> FREE!</span>
                </b>

                {LIVE_PAYMENT.STRIPE && (<CheckoutFreetrialYearlyUltra
                  setPaymentProccessStart={setPaymentProccessStart}
                ></CheckoutFreetrialYearlyUltra>
                )}

                {LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="free-trail-paypal-top-div">
                      <PlanFreeTierUltra
                        setPaymentProccessStart={setPaymentProccessStart}
                      ></PlanFreeTierUltra>
                    </div>
                    <div className="free-trail-next-wrapper">
                      <button className="continue-btn-free-trail">
                        Continue
                      </button>
                    </div>{" "}
                  </>
                )}
                <div className="free-trail-content">
                  <div className="free-trail-iconidea-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconidea}
                    />
                    <div className="free-trail-creative-ad-ideas-container">
                      <span className="free-trail-creative">Creative</span>
                      <span className="free-trail-ad-ideas">{` Ad Ideas `}</span>
                      <span className="free-trail-creative">
                        Generation!
                      </span>
                    </div>
                  </div>
                  <div className="free-trail-iconidea-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconimage}
                    />
                    <div className="welcome-to-adspire-container">
                      <span className="free-trail-creative">Effective</span>
                      <span className="free-trail-ad-ideas">
                        {" "}
                        Image Ads
                      </span>
                      <span className="free-trail-creative">
                        {" "}
                        Generation!
                      </span>
                    </div>
                  </div>
                  <div className="free-trail-iconidea-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconchat}
                    />
                    <div className="creative-ad-ideas-container">
                      <span className="free-trail-creative">Powerful</span>
                      <span className="free-trail-ad-ideas"> Captions</span>
                      <span className="free-trail-creative">
                        {" "}
                        Generation!
                      </span>
                    </div>
                  </div>
                  <div className="free-trail-iconai-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconai}
                    />
                    <div className="creative-ad-ideas-container">
                      <span className="free-trail-ad-ideas">
                        Incredibly
                      </span>
                      <span className="free-trail-creative">
                        {" "}
                        Powerful AI!
                      </span>
                    </div>
                  </div>
                  <div className="free-trail-iconai-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconimageads1}
                    />
                    <div className="free-trail-choice-of-6">
                      Choice of 6 ad idea types
                    </div>
                  </div>
                  <div className="free-trail-iconai-parent">
                    <img
                      className="free-trail-iconidea"
                      alt=""
                      src={iconstyle_image}
                    />
                    <div className="free-trail-choice-of-6">
                      Choice of 8 image ad styles
                    </div>
                  </div>
                </div>
                <div className="try-3-days-for-free-parent">
                  <b className="try-3-days-container">
                    {`Try 3 days for `}
                    <span className="free-trail-free1 underline">
                      FREE!
                    </span>
                  </b>
                  <div className="then-34999year">
                    Then $349.99/Year

                  </div>
                </div>
                <div className="free-trail-instance-parent">

                  {LIVE_PAYMENT.STRIPE && (<CheckoutFreetrialYearlyUltra
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutFreetrialYearlyUltra>
                  )}
                  {LIVE_PAYMENT.PAYPAL && (
                    <>

                      <div className="free-trail-paypal-div">
                        <PlanFreeTierUltra
                          setPaymentProccessStart={setPaymentProccessStart}
                        ></PlanFreeTierUltra>
                      </div>
                      <div className="free-trail-next-wrapper">
                        <button className="continue-btn-free-trail">
                          Continue
                        </button>


                      </div> </>)}
                  <div className="no-obligation-to-continue-jus-parent">
                    <div className="no-obligation-to-container">
                      <p className="no-obligation-to">{`No obligation to continue, just cancel `}</p>
                      <p className="no-obligation-to">
                        before the trial ends.
                      </p>
                    </div>
                    <div className="terms-conditions-parent">
                      <div
                        className="welcome-to-adspire-container pointerclass"
                        onClick={() => redirectTo("legal/tos")}
                      >{`Terms & Conditions`}</div>
                      <div
                        className="welcome-to-adspire-container pointerclass"
                        onClick={() => redirectTo("legal/privacy")}
                      >
                        Privacy Policy
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <img
            className="free-trail-frame-child-second-img"
            alt=""
            src={frame_1261153255}
          />
        </div>
      </div>
    </div >
  );
};

export default WelcomeFreeTrailLanding;

import {
  Elements
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import VerifyAccountPaymentPlan from '../landingpage/common/VerifyAccountPaymentPlan';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);
const StripeForm = () => (
  <Elements stripe={stripePromise}>
    <VerifyAccountPaymentPlan />
  </Elements>
);
export default StripeForm;



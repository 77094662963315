import React, { Fragment, useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import addnew from "../../../images/icons/add-new1.svg";
import cross from "../../../images/icons/cross.png";
import download_btn from "../../../images/icons/download-new.svg";
import edit_btn from "../../../images/icons/edit-new.svg";
import download from "../../../images/icons/social_icons/download-btn-for-carousel.svg";
import edit from "../../../images/icons/social_icons/edit-btn-for-carousel.svg";
import {
  userService,
  updateDownloadCountRedux,
} from "../../../services/user.service";
import {
  getFixedChar,
  getWebsiteTitle,
  truncateString,
} from "../../../utils/function";
import AdFlowListingButton from "../../common/Button/AdFlowListingButton";
import DownloadunwatermarkedButton from "../../common/Button/DownloadunwatermarkedButton";
import StyledButton1 from "../../common/Button/StyledButton1";
import LoginWaterMarkedModal from "../../common/popup/LoginWaterMarkedModal";

import constants from "../../../utils/constants";
import ConceptStep from "../../common/ConceptStep";
import CheckoutYearlyUltra from "../../common/StripePayment/CheckoutYearlyUltra";
import TextFlowDescriptionModal from "../../common/popup/TextFlowDescriptionModal";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import useWindowSize from "../../../_helper/ScreenUtil";
import copy_icon from "../../../images/icons/social_icons/copy_icon.svg";
import ilogo from "../../../images/icons/social_icons/ilogo.png";
import insta_tabs_switcher from "../../../images/icons/social_icons/insta_tabs_switcher.svg";
import logo from "../../../images/icons/social_icons/logo.png";
import meta_tabs_switcher from "../../../images/icons/social_icons/meta_tabs_switcher.svg";
import other_tabs_switcher from "../../../images/icons/social_icons/other_tabs_switcher.svg";
import share from "../../../images/icons/social_icons/share.png";
import { useDispatch } from "react-redux";
import ButtonVariantGradient from "../../common/Button/ButtonVariantGradient";
import SpinnerLoaderVariations from "../../common/SpinnerLoaderVariations";

const DownloadEditCard = ({
  imageSrc,
  handleSingleDownloadClick,
  redirectTo,
  redirectTosubscibepage,
  openQuestionBox,
  getVariations,
  index,
  pagename
}) => {
  const [loading, setLoader] = useState(false);
  const [btnWidth, setBtnWidth] = useState(100);

  const { width } = useWindowSize()
  useEffect(() => {
    if (pagename == "store") {
      setBtnWidth(100);
    } else if (index != "doNotShow") {
      setBtnWidth(50);
    }
  }, [index]);

  const getVariationsWithLoader = (index) => {

    setLoader(true);
    // getVariation called from line 280
    getVariations(index).then((responseData) => {

      setLoader(false);
    }).catch((error) => {
      console.error('The promise was rejected with the following error:', error);
    });
  };



  return (
    <>
      {userService.isPaidUser() && (
        <>
          <div className="modal-footer text-center">
            {/* <div className="cursor-pointer paiduser px-4">
                <img
                  src={download}
                  alt="download"
                  style={{ pointerEvents: "unset", cursor: "pointer", width: "50px" }}
                  width={50}
                  onClick={() => handleSingleDownloadClick(imageSrc)}
                />
              </div> */}
            <div className="cursor-pointer paiduser">
              {loading && <SpinnerLoaderVariations title={"Image loading..."} width={70}></SpinnerLoaderVariations>}
              <div className="modal-footer text-center">
                <div className="row flex content-evenly align-items-center">
                  <div className="cursor-pointer paiduser pt-4">
                    <img
                      src={download}
                      alt="download"
                      style={{ pointerEvents: "unset", cursor: "pointer", width: btnWidth + "px" }}
                      width={btnWidth}
                      onClick={() => handleSingleDownloadClick(imageSrc)}
                    />
                  </div>
                  {pagename != "store" && index != "doNotShow" && (
                    <div className="cursor-pointer paiduser">
                      <ButtonVariantGradient
                        title="Variations"
                        btnstyle={{ marginRight: "0px" }}
                        btnstylebyclass="m-left-right-auto"
                        onClick={() => getVariationsWithLoader(index)}
                      />
                    </div>
                  )}

                  <div className="cursor-pointer paiduser">
                    <img src={edit} alt="Edit your Ad" width={50} style={{ pointerEvents: "unset", cursor: "pointer", width: btnWidth + "px" }} onClick={() => openQuestionBox()} />
                  </div>
                </div>
              </div>{" "}
            </div>
          </div>
        </>
      )}
      {!userService.isPaidUser() && (
        <div className="modal-footer b text-center">
          <div className="row">
            <div className="col-sm-4 col-xs-6 text-center not_paiduser">
              <img src={download} alt="download" width={100} onClick={() => redirectTosubscibepage()} />
            </div>
            <div className="col-sm-4 col-xs-6 text-center cursor-pointer not_paiduser">
              <img src={edit} width={100} alt="Edit your Ad" onClick={() => redirectTosubscibepage()} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const PreviewAdsListing = (props) => {
  // *************************** Hooks ****************************************************************
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const isPad = width <= 1024;
  const navigate = useNavigate();
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(props.copyImages);
  const [isModalOpen, setModalOpen] = useState(false);
  const [remoteImages, setRemoteImages] = useState([]);
  const [isImageDetailModalOpen, setIsImageDetailModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(
    constants.APPLICATION_INFO.ADFLOW_ACTIVE_TAB
  );
  const [instaLoader, setInstaLoader] = useState(false);
  const [countLoader, setCountLoader] = useState("");
  const [images, setImages] = useState([]);
  const [primaryTexts, setPrimaryTexts] = useState([]);
  const [headlines, setHeadlines] = useState([]);
  const [instaCaptions, setInstaCaptions] = useState([]);
  const [variationNumber, setVariationNumber] = useState(0);
  const [imageForVariation, setImageForVariation] = useState(0);
  const [pagename, setPagename] = useState(props.pageName);
  const [instaIndex, setInstaIndex] = useState(0)

  useEffect(() => {
    if (props.pageName) {
      setPagename(props.pageName);
    }
  }, [props.pageName]);

  useEffect(() => {
    if (props.isVariationCompleted) {
      props.setIsVariationCompleted(false);
    }
  }, [props.isVariationCompleted]);

  useEffect(() => {
    const listingContents = props.listingContent;

    if (listingContents) {
      // Extracting FacebookAds data
      const facebookPrimaryTexts = props.listingContent?.FacebookAds?.PrimaryTexts || [];
      const facebookHeadlines = props.listingContent?.FacebookAds?.Headlines || [];
      // Extracting InstagramAds data
      const instagramCaptions = props.listingContent?.InstagramAds?.Captions || [];
      // Setting values for images
      // setImages(facebookPrimaryTexts);
      // Setting values for primaryTexts
      const primaryTextsData = [
        ...facebookPrimaryTexts,
        ...facebookPrimaryTexts,
        ...facebookPrimaryTexts,
        ...facebookPrimaryTexts
      ];

      setPrimaryTexts(primaryTextsData);

      // Setting values for headlines
      const headlinesData = [
        ...facebookHeadlines,
        ...facebookHeadlines,
        ...facebookHeadlines,
        ...facebookHeadlines
      ];

      setHeadlines(headlinesData);

      // Setting values for instaCaptions
      const instaCaptionsData = [
        ...instagramCaptions,
        ...instagramCaptions,
        ...instagramCaptions,
        ...instagramCaptions
      ];

      setInstaCaptions(instaCaptionsData);
    }

  }, [props.listingContent]);

  // *************************** Functions ****************************************************************
  useEffect(() => {

    if (constants.APPLICATION_INFO.IS_INSPECTING_DISABLE) {
      const disableContextMenuAndShortcuts = (e) => {
        e.preventDefault(); // Disable right-click context menu
      };

      document.addEventListener('contextmenu', disableContextMenuAndShortcuts);
      // document.addEventListener('keydown', disableContextMenuAndShortcuts);

      return () => {
        document.removeEventListener('contextmenu', disableContextMenuAndShortcuts);
        //document.removeEventListener('keydown', disableContextMenuAndShortcuts);
      };
    }
  }, []);


  const handleTabClick = (tabNumber) => {
    setInstaIndex(0)
    setActiveTab(tabNumber);
  };
  useEffect(() => {
    setRemoteImages([...remoteImages, ...props.copyImages]);
  }, [props.copyImages]);

  const redirectTo = (pagename) => {
    if (pagename === "ad-ideas") {
      userService.setKeyValueLocalstorage("showLocalAdIdeas", "yes");
    } else if (pagename === "home") {
      window.location.href = "/home";
    } else {
      navigate("/" + pagename);
    }
  };

  const redirectTosubscibepage = () => {
    window.open("/pricing", "_blank");
  };

  const selectImage = (items) => {
    setActive(items);
    setPopupImage(items);

    if (userService.downloadLeftImage() === 0) {
      window.open("/pricing", "_blank"); // open the subscription page on new tab
    } else {
      setIsImageDetailModalOpen(true);
    }
  };

  const openQuestionBox = () => {
    const myBtn = document.getElementById("ptextFlowModal");
    myBtn.click();
  };

  /*const getVariations = (index) => {
    setInstaIndex(index)
    return props.updateVariations(index, 0);
  };*/
  const getVariations = async (index) => {
    try {
      setInstaIndex(index);
      await props.updateVariations(index, 0);

      return true; // Return true if updateVariations succeeds
    } catch (error) {
      console.error("Error occurred during variations update:", error);
      return false; // Return false if updateVariations fails
    }
  };

  const handleDownloadClick = async () => {
    let downloadCount = props.copyImages.length;
    if (userService.isPaidUser() && userService.fullAccesslUser() != "no") {
      for (let index = 0; index < downloadCount; index++) {
        const imageUrl = props.copyImages[index];
        if (userService.downloadLeftImage() > 0) {
          userService.downloadImageCountDeduct();
          await downloadBase64Image(imageUrl, index);
        }
      }
    } else if (userService.getValueFromLocalstorage("isTrialPeriod") == "yes") {
      window.open("/pricing", "_blank");
      // below code open the popup for subscribing by user.
      // setModalOpen(true);
    }
  };
  const dispatch = useDispatch();

  const handleSingleDownloadClick = async (userSelectedImage = "") => {
    if (userService.downloadLeftImage() > 0) {
      if (userSelectedImage) {
        await downloadBase64Image(userSelectedImage, "1");
      } else {
        await downloadBase64Image(popupImage, "1");
      }
      const downloadCount = userService.downloadImageCountDeduct();
      dispatch(updateDownloadCountRedux(downloadCount));
    } else if (userService.getValueFromLocalstorage("isTrialPeriod") == "yes") {
      window.open("/pricing", "_blank");
      // below code open the popup for subscribing by user.
      // setModalOpen(true);
    }
  };

  async function downloadBase64Image(base64Data, fileName) {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = base64Data;
    fileName = getFixedChar(
      userService.getValueFromLocalstorage("uploadInput"),
      15
    );
    // Set the download attribute to specify the filename
    link.download = fileName;

    // Simulate a click event to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  const getFormValueFromAI = async (datas) => {
    if (props.pageName === "home") {
      props.handleUpload("", "edit"); // We are sending this function from Quick Ad
    } else if (props.pageName === "store") {
      props.handleUpload("generateMore", "edit"); // We are sending this function from Quick Ad
    }
  };

  const hideTheImage = async (datas) => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "quick-ad"
    ) {
      props.setShowListingPage(false);
    } else {
      props.setShowListingPage(false);
    }
  };

  const handleStoreUpload = () => {
    if (props.pageName === "store") {
      props.handleUpload("generateMore");
    } else if (props.pageName === "home") {
      props.handleUpload();
    } else {
      props.setResetPreviousPage(true);
      props.setShowListingPage(false);
    }
  };

  document.title = getWebsiteTitle() + "Ad Listing";
  const copyImages = props.copyImages;
  //  const listingContents = props.listingContent;

  const Card = ({ imageSrc, headline, onClick, isActive, primaryText, index, getVariations }) => {

    const { width } = useWindowSize();
    const isMobile = width <= 767;
    const isPad = width <= 1024;


    const copyToprimaryText = (e) => {
      const textToCopy = primaryText;
      navigator.clipboard.writeText(textToCopy);
    };
    const copyToheadline = (e) => {
      const textToCopy = headline; // Use headline instead of primaryText
      navigator.clipboard.writeText(textToCopy);
    };

    const getText = (text) => {
      const textData = text
        ? text
        : userService.getValueFromLocalstorage("creativeAIheadline");
      return truncateString(textData);
    };

    const getVariationsWithLoader = (index) => {
      setCountLoader(index);
      setInstaLoader(true);

      getVariations(index).then((responseData) => {
        setInstaLoader(false);
      }).catch((error) => {
        console.error('The promise was rejected with the following error:', error);
      });
    };

    return (
      <div
        className={`col-sm-11 col-xs-12 px-20 width-100-percent`}
        title={getText(headline)}
        onClick={onClick}
      >
        <div
          className={`thumbnailListBG adspire-watermark-container cursor-pointer preview-card-facebook ${isActive && "box-active"
            }`}
          style={{ minHeight: 500 }}
        >
          <img
            onClick={copyToprimaryText}
            src={copy_icon}
            alt="copy_icon"
            className="copy-icon-fb-top"
            style={
              isMobile
                ? { width: "30px", pointerEvents: "unset" }
                : { width: "50px", pointerEvents: "unset" }
            }
          />
          <div data-toggle="modal">
            <div className="top-stl">

              <div className="flex content-between align-items-center">
                <img className="ilogo-stl" src={ilogo} style={{ width: "140px" }} />
                {pagename != "store" && (<ButtonVariantGradient
                  title="Variations"
                  btnstyle={{ marginRight: "0px" }}
                  btnstylebyclass="m-left-right-auto"
                  onClick={() => getVariationsWithLoader(index)}
                />)}
              </div>

              <div className="text-stl ellipsis-finsta">
                {getText(primaryText)}
              </div>
            </div>
            {userService.isTrialPeriodForPaidUser() && (
              <>
                <img data-target="#imageDetailModal"
                  className="img-responsive rounded-0"
                  src={imageSrc}
                  style={{ pointerEvents: "none" }}
                  alt={getText(headline)}
                />
                <p>
                  <b>
                    <span className="watermark-text-adspire-for-images">
                      ADSPIRE®
                    </span>
                  </b>
                </p>
              </>
            )}

            {!userService.isTrialPeriodForPaidUser() && (
              <img
                data-toggle="modal"
                data-target="#myModal"
                className="img-responsive generated-img-arrays rounded-0"
                src={imageSrc}
                alt=""
              />
            )}
            {instaLoader && index === countLoader && (<SpinnerLoaderVariations title={"Insta loading..."} width={300}></SpinnerLoaderVariations>)}
            <div className="justify-content-text text-left mt-10">
              <div className="headline-style col-2"> {getText(headline)}</div>
              <div className="ord-styl">
                {userService.getValueFromLocalstorage("ctaPreview")
                  ? userService.getValueFromLocalstorage("ctaPreview")
                  : "Order Now"}
              </div>
            </div>
          </div>

          {/* Your existing JSX */}
          <img
            src={copy_icon}
            onClick={copyToheadline}
            alt="copy_icon"
            className="copy-icon-fb-bottom"
            style={
              isMobile
                ? { width: "30px", pointerEvents: "unset" }
                : { width: "50px", pointerEvents: "unset" }
            }
          />
        </div>
      </div>
    );
  };

  const FacebookCardList = ({
    images,
    headlines,
    onSelect,
    activeImage,
    primaryTexts,
    handleSingleDownloadClick,
    redirectTo,
    redirectTosubscibepage,
  }) => {
    const { width } = useWindowSize();
    const isSmMobile = width <= 400;

    const isMobile = width <= 767;
    const isPad = width <= 1024;
    const isMenuExist = width >1120
    // Check if images array is empty or null
    if (!images || images.length === 0) {
      // Return null or an error message component
      return <div>No images available</div>;
    }

    return (
      <div style={{
        width:isMenuExist ? width-230 :width - 40
      }}  className="facebook-card-list  ">
        <Carousel
        
          infiniteLoop={true}
          selectedItem={instaIndex}
          interval={2000}
          showThumbs={false}
          autoPlay={false}
          centerMode
          centerSlidePercentage={ isSmMobile ? 120:isMobile ? 100 : isPad ? 60 : 42}
        >
          {images?.map((imageSrc, index) => (
            <div  key={`facebook-ad-listing-${index}`}>
              <Card
                key={`ad-listing-${index}`}
                primaryText={primaryTexts[index]}
                imageSrc={imageSrc}
                headline={headlines[index]}
                onClick={() => onSelect(imageSrc)}
                isActive={activeImage === imageSrc}
                getVariations={getVariations}
                index={index}
              />
              <DownloadEditCard
                imageSrc={imageSrc}
                handleSingleDownloadClick={handleSingleDownloadClick}
                redirectTo={redirectTo}
                redirectTosubscibepage={redirectTosubscibepage}
                openQuestionBox={openQuestionBox}
                index={"doNotShow"}
              ></DownloadEditCard>
            </div>
          ))}
        </Carousel>
      </div>
    );
  };

  const InstaCard = ({ imageSrc, onClick, isActive, instaCaption, getVariations, index }) => {
    const { width } = useWindowSize();
    const isMobile = width <= 767;
    const isPad = width <= 1024;
    
    const copyToCaptionText = (e) => {
      const textToCopy = instaCaption;
      navigator.clipboard.writeText(textToCopy);
    };

    const getCaption = (caption) => {
      const instaCaptionData = caption
        ? caption
        : userService.getValueFromLocalstorage("creativeAIheadline");

      return truncateString(instaCaptionData);
    };

    const getVariationsWithLoader = (index) => {
      setCountLoader(index);
      setInstaLoader(true);
      getVariations(index).then((responseData) => {
        setInstaLoader(false);
      }).catch((error) => {
        console.error('The promise was rejected with the following error:', error);
      });
    };

    return (
      <div className={`col-sm-11 col-xs-12`} title={getCaption(instaCaption)} onClick={onClick}>
        <div className={`thumbnailListBG adspire-watermark-container cursor-pointer preview-card ${isActive && "box-active"}`} style={{ minHeight: 500 }}>
          <div data-toggle="modal">
            <div className="top-stl">
              <div className="flex content-between align-items-center">
                <img className="ilogo-stl" src={ilogo} style={{ width: "140px" }} />
                {pagename != "store" && (<ButtonVariantGradient title="Variations" btnstyle={{ marginRight: "0px" }} btnstylebyclass="m-left-right-auto" onClick={() => getVariationsWithLoader(index)} />)}
              </div>
            </div>
            {userService.isTrialPeriodForPaidUser() && (
              <>
                {" "}
                <img data-target="#imageDetailModal" className="img-responsive rounded-0" src={imageSrc} alt={getCaption(instaCaption)} style={{ pointerEvents: "none" }} />
                <p className="margin-0">
                  <b>
                    <span className="watermark-text-adspire-for-images">ADSPIRE®</span>
                  </b>
                </p>
              </>
            )}

            {!userService.isTrialPeriodForPaidUser() && (
              <>
                <img data-toggle="modal" data-target="#myModal" className="img-responsive generated-img-arrays rounded-0" src={imageSrc} alt="" />
              </>
            )}

            {instaLoader && index === countLoader && (<SpinnerLoaderVariations title={"Insta loading..."} width={300}></SpinnerLoaderVariations>)}

            <img className="ilogo-stl rounded-0" src={share} />

            <div className="justify-content-text text-left">
              <div className="d-inline-block max-three-line-truncate">{instaCaption}</div>
            </div>
          </div>
          <img
            onClick={copyToCaptionText}
            src={copy_icon}
            alt="copy_icon"
            className="copy-icon-fb-bottom"
            style={isMobile ? { width: "30px", pointerEvents: "unset" } : { width: "50px", pointerEvents: "unset" }}
          />
        </div>
      </div>
    );
  };

  const InstaCardList = ({
    images,
    onSelect,
    activeImage,
    InstaCaptions,
    handleSingleDownloadClick,
    redirectTo,
    redirectTosubscibepage
  }) => {
    const { width } = useWindowSize();
    const isMobile = width <= 767;
    const isPad = width <= 1024;
    const isSmMobile = width <= 400;

const isMenuExist = width >1120
    return (
      <div style={{
        width:isMenuExist ? width-230 :width - 40
      }}  className="facebook-card-list ">
        <Carousel
          infiniteLoop={true}
          selectedItem={instaIndex}
          interval={2000}
          showThumbs={false}
          autoPlay={false}
          centerMode
          centerSlidePercentage={ isSmMobile ? 100:isMobile ? 100 : isPad ? 60 : 42}
        >
          {images.map((imageSrc, index) => (
            <Fragment key={`ad-listing-${index}`}>
              <InstaCard
                key={`instaAd-listing-${index}`}
                imageSrc={imageSrc}
                instaCaption={InstaCaptions[index]}
                onClick={() => onSelect(imageSrc)}
                isActive={activeImage === imageSrc}
                getVariations={getVariations}
                index={index}
              />

              <DownloadEditCard
                imageSrc={imageSrc}
                handleSingleDownloadClick={handleSingleDownloadClick}
                redirectTo={redirectTo}
                redirectTosubscibepage={redirectTosubscibepage}
                openQuestionBox={openQuestionBox}
                index={"doNotShow"}
              ></DownloadEditCard>
            </Fragment>
          ))}
        </Carousel>
      </div>
    );
  };

  return (
    <React.Fragment>
      {props.copyImages && (
        <div className="container-fluid">
          <div className="row">
            <div className=" ">
              {userService.getValueFromLocalstorage(
                "userSelectedAdTypePage"
              ) != "upload-ad" && (
                  <div className={`col-sm-12  col-xs-12 flex content-center`}>
                    <ConceptStep step="7"></ConceptStep>
                  </div>
                )}
              <div className="top-contentDIv">
                <div   className="top-ranckking ">
                  <div  className="tabs-styling     image-tab-switcher">
                    <div className="t-center ">
                      <div className="tab-header">
                        <div
                          className={`tab-item  ${activeTab === 1 ? "active" : ""
                            }`}
                          onClick={() => handleTabClick(1)}
                        >
                          <img alt="insta" src={meta_tabs_switcher} />
                        </div>
                        <div
                          className={`tab-item ${activeTab === 2 ? "active" : ""
                            }`}
                          onClick={() => handleTabClick(2)}
                        >
                          <img alt="insta" src={insta_tabs_switcher} />
                        </div>
                        <div
                          className={`tab-item ${activeTab === 3 ? "active" : ""
                            }`}
                          onClick={() => handleTabClick(3)}
                        >
                          <img alt="insta" src={other_tabs_switcher} />
                        </div>
                      </div>
                    </div>
                    <div className="tab-content ">
                      {activeTab === 1 && (
                        <div className="mt-40  ">
                          <FacebookCardList
                            images={copyImages}
                            headlines={headlines}
                            onSelect={(selectedImage) =>
                              selectImage(selectedImage)
                            }
                            activeImage={active}
                            primaryTexts={primaryTexts}
                            handleSingleDownloadClick={
                              handleSingleDownloadClick
                            }
                            redirectTo={redirectTo}
                            redirectTosubscibepage={redirectTosubscibepage}
                            pageName={props.pageName}
                          />
                        </div>
                      )}
                      {activeTab === 2 && (
                        <div className=" mt-40 ">
                          <InstaCardList
                            images={copyImages}
                            onSelect={(selectedImage) =>
                              selectImage(selectedImage)
                            }
                            activeImage={active}
                            InstaCaptions={instaCaptions}
                            handleSingleDownloadClick={
                              handleSingleDownloadClick
                            }
                            redirectTo={redirectTo}
                            redirectTosubscibepage={redirectTosubscibepage}
                            pageName={props.pageName}
                          />
                        </div>
                      )}
                      {activeTab === 3 && (
                        <div className=" mt-40">
                          {props.copyImages &&
                            props.copyImages.map((items, index) => (
                              <div
                                className={
                                  isMobile ? `mt-10 mb-20 ` : `col-sm-6 col-md-4 px-20 col-lg-3 col-xs-12 mt-20`
                                }
                                key={`ad-listing-${index}`}
                                title={userService.getValueFromLocalstorage(
                                  "uploadInput"
                                )}
                                onClick={() => selectImage(items)}
                              >
                                <div
                                  className={`thumbnailListBG adspire-watermark-container cursor-pointer ${active == items && "box-active"
                                    }`}
                                  data-toggle="modal"
                                  data-target="#imageDetailModal"
                                >
                                  {userService.isTrialPeriodForPaidUser() && (
                                    <>
                                      <img
                                        className="img-responsive user-imgDiv pointerEvents"
                                        src={items}
                                        alt={userService.getValueFromLocalstorage(
                                          "uploadInput"
                                        )}
                                      />
                                      <p>
                                        <b>
                                          <span className="watermark-text-adspire-for-images">
                                            ADSPIRE®
                                          </span>
                                        </b>
                                      </p>
                                    </>
                                  )}

                                  {!userService.isTrialPeriodForPaidUser() && (
                                    <>
                                      <img
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        className="img-responsive user-imgDiv generated-img-arrays"
                                        src={items}
                                        alt=""
                                      />
                                    </>
                                  )}
                                </div>

                                <DownloadEditCard
                                  imageSrc={items}
                                  handleSingleDownloadClick={
                                    handleSingleDownloadClick
                                  }
                                  redirectTo={redirectTo}
                                  redirectTosubscibepage={
                                    redirectTosubscibepage
                                  }
                                  openQuestionBox={openQuestionBox}
                                  getVariations={getVariations}
                                  index={index}
                                  pagename={pagename}
                                ></DownloadEditCard>
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-xs-12 col-lg-12 mt-20 justifyCenter   ">
                    {/* <h1 className="top-ranckking-h1">
                      <img
                        onClick={() => BackButtonNew()}
                        alt="backImage"
                        className="mar-right-20 cursor-pointer"
                        src={back}
                      />{" "}
                      {!userService.isTrialPeriodForPaidUser() &&
                        "Here's your ads!"}
                      {userService.isTrialPeriodForPaidUser() &&
                        userService.downloadLeftImage() + " Ad Downloads Left"}
                    </h1>

                    <p className="text-size-24">Click an ad to edit</p> */}
                    {/* <div className="clearfix"></div> */}

                    <div className=" col-xs-12 col-sm-7 col-md-4 col-lg-3  justifyCenter   ">
                      <div className=" col-xs-11   ">
                        {userService.isPurchaser() && (
                          <AdFlowListingButton
                            handleUpload={props.handleUpload}
                            generatedImage={props.generatedImage}
                            hideTheImage={hideTheImage}
                            handleDownloadClick={handleDownloadClick}
                            pagename={pagename}
                          ></AdFlowListingButton>
                        )}

                        {!userService.isPurchaser() && (
                          <DownloadunwatermarkedButton pagename="captions"></DownloadunwatermarkedButton>
                        )}
                      </div>

                      {userService.isContentAllowed() && (
                        <>
                          {/* image popup */}

                          <div
                            className={`modal fade`}
                            id="imageDetailModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">
                                      <img src={cross} alt="" />
                                    </span>
                                  </button>
                                  <h1 className="top-ranckking-h1 text-center">
                                    {!userService.isTrialPeriodForPaidUser() &&
                                      "Here's your ads!"}

                                    {userService.isTrialPeriodForPaidUser() &&
                                      userService.downloadLeftImage() +
                                      " Ad Downloads Left"}
                                  </h1>
                                </div>
                                <div className="modal-body text-center user-imgDiv">
                                  {userService.isTrialPeriodForPaidUser() && (
                                    <p>
                                      <b>
                                        <span className="adspire-logo-for-modal watermark-text-adspire-for-images">
                                          ADSPIRE®
                                        </span>
                                      </b>
                                    </p>
                                  )}
                                  <img
                                    width={408}
                                    height={408}
                                    src={popupImage}
                                    className="pointerEvents object-fit-cover modal-opened-img"
                                    alt="popupImage"
                                  />
                                </div>
                                {userService.isPaidUser() && (
                                  <div className="modal-footer text-center">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-6 text-center cursor-pointer">
                                        {!userService.isPurchaser() && (
                                          <img
                                            src={edit_btn}
                                            data-dismiss="modal"
                                            alt="Edit your Ad"
                                            onClick={() => redirectTo("home")}
                                          />
                                        )}
                                        {userService.isPurchaser() && (
                                          <img
                                            src={edit_btn}
                                            data-dismiss="modal"
                                            alt="Edit your Ad"
                                            onClick={() => openQuestionBox()}
                                          />
                                        )}
                                      </div>
                                      <div className="col-sm-4 col-xs-6 text-center">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSingleDownloadClick()
                                          }
                                        >
                                          <img src={download_btn} alt="" />
                                        </a>
                                      </div>
                                      <div
                                        className="col-sm-4 col-xs-6 text-center cursor-pointer"
                                        title="New Ad"
                                        data-dismiss="modal"
                                        onClick={() => redirectTo("home")}
                                      >
                                        <img
                                          src={addnew}
                                          data-dismiss="modal"
                                          alt="New Ad"
                                        />
                                      </div>
                                    </div>

                                    {/* <AdButton></AdButton> */}
                                    <div className=" m-top  btn-rarius mb-5">
                                      <div className="col-sm-10 col-sm-offset-1  col-xs-12 text-center">
                                        <StyledButton1
                                          btnTheme={"light"}
                                          type="button"
                                          name={"Generate more"}
                                          data-dismiss="modal"
                                          btnHeight="64px"
                                          onClick={handleStoreUpload}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {!userService.isPaidUser() && (
                                  <div className="modal-footer text-center">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-6  text-center cursor-pointer">
                                        <img
                                          src={edit}
                                          data-dismiss="modal"
                                          alt="Edit your Ad"
                                          onClick={() =>
                                            redirectTosubscibepage()
                                          }
                                        />
                                      </div>
                                      <div className="col-sm-4 col-xs-6 text-center">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            redirectTosubscibepage()
                                          }
                                        >
                                          <img src={download} alt="" />
                                        </a>
                                      </div>

                                      <div
                                        className="col-sm-4 col-xs-6 text-center  cursor-pointer"
                                        title="New Ad"
                                        data-dismiss="modal"
                                        onClick={() => redirectTosubscibepage()}
                                      >
                                        <img
                                          src={addnew}
                                          data-dismiss="modal"
                                          alt="New Ad"
                                        />
                                      </div>
                                    </div>

                                    {/* <AdButton></AdButton> */}
                                    <div className=" m-top btn-rarius mb-5">
                                      <div className="col-sm-10 bcol-sm-offset-1 col-xs-12 text-center">
                                        <StyledButton1
                                          btnTheme={"light"}
                                          type="button"
                                          name={"Generate more"}
                                          data-dismiss="modal"
                                          btnHeight="64px"
                                          onClick={redirectTosubscibepage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* image popup*/}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
      <p id="ploginModal" data-toggle="modal" data-target="#loginModal"></p>
      <LoginWaterMarkedModal></LoginWaterMarkedModal>

      <TextFlowDescriptionModal
        getFormValueFromAI={getFormValueFromAI}
      ></TextFlowDescriptionModal>
      <span
        id="ptextFlowModal"
        data-toggle="modal"
        data-target="#textFlowDescriptionModalId"
      ></span>

      <div
        className={`modal ${isModalOpen ? "show" : ""}`}
        style={{
          display: isModalOpen ? "block" : "none",
          background: "#0000006e",
        }}
      >
        <div
          className="modal-dialog top40 modal-dialog-centered text-open-sans"
          role="document"
        >
          <div className="modal-content modal-gradient-new">
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setModalOpen();
                }}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="mt-10">
                <div className="text-white font-20-regular text-weight-600 marging-30">
                  Only paying subscribers can download UNLIMITED ads.
                </div>
                <CheckoutYearlyUltra>Subscribe</CheckoutYearlyUltra>
                {/*<CheckoutMonthlyUltra>Subscribe Monthly</CheckoutMonthlyUltra>*/}
              </div>
            </div>
            <div className="modal-footer text-center"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PreviewAdsListing;
import React, { useEffect, useState } from "react";
import circleCheck from "../../../images/icons/circle-check.svg";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import SpinnerLoader from "../../common/SpinnerLoader";

const LandingProductTypeSecondPage = (props) => {
  const [adOptions, setOptions] = useState("");
  const [active, setActive] = useState(null)
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [moreOptions, setMoreOptions] = useState(false);
  const [classvalue, setClassvalue] = useState("generateAd");
  

  
  useEffect(() => {
    //setOptions(adOptions)
  }, [moreOptions]);

  useEffect(() => {
    db.collection("adIdeas")
      .doc("quickIdeaGenerator")
      .collection("adGeneration")
      .doc("productTypes")
      .collection("types")
      .doc(userService.getValueFromLocalstorage("typeIDOfOptionValue"))
      .collection("options")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setOptions(dataReceived);
        setLoader(true);
      });
  }, []);

  //document.title = getWebsiteTitle() + "Ad Type";

  const setOptionValue = (typeObj) => {
    setButtonStatus(false)
    userService.setKeyValueLocalstorage("typeIDOfOptionValue", typeObj.id);
    userService.setKeyValueLocalstorage("typeOptionValue", typeObj.data.name);
    setClassvalue("generateAd")
    setActive(typeObj.data.name);
    if(userService.showLogoutBtn()){
      window.location.href="/quick-free-trial";
    } 
  };

  const getClassForShowHide=(i)=>{
    if(i>5 && !moreOptions){
      return "col-sm-6 col-xs-12 clshide";
    }else{
      return "col-sm-6 col-xs-12";
    }
  }

  const showmoreProduct=()=>{

    setMoreOptions(true);
  }

  const colors = [
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
  ];
  

  return (
    <React.Fragment>
    
                {/*<h1 className="text-center">What type of ad do you want?</h1>*/}

                <div className="what-do-you-want-an-ad-for-wrapper">
                  <b className="what-do-you">What type of {userService.getValueFromLocalstorage("typeOptionValue")} do you sell?</b>
                </div>
                <div className="clearfix"></div>
                <div className="col-sm-6">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (

                    <div className="row mt-20 ">
                      {adOptions &&
                        adOptions.map((projectsItems,index) => (
                          <div
                          data-toggle="modal"
                          data-target={!userService.showLogoutBtn()?"#loginModal":""} 
                            className={getClassForShowHide(index)}
                            key={projectsItems.data.name}
                            title={projectsItems.data.name}
                            onClick={() => setOptionValue(projectsItems)}
                          >
                            <div
                              className={`thumbnailBG text-size-16 h-60 ${active === projectsItems.data.name && 'box-active'}`}
                              style={{
                                backgroundColor: colors[index],
                              }}
                            >
                              <div className="select-boxes btn-text-left">
                                <span className="ad-title">
                                 {projectsItems.data.name}
                                </span>
                                {active === projectsItems.data.name ?
                                  < img
                                  
                                    alt={projectsItems.data.name}
                                    width={20}
                                    src={circleCheck}
                                    className="w-20 float-r"
                                  />
                                  :
                                  ""}
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {
                    loading &&
                    (
                      <>
                   {!moreOptions && (   <div className="form-group text-center" title="Generate Ad Ideas">
      <button
        type="submit"
        title="See more"
        className={`btn btn-primary ${classvalue}`}
        onClick={() => showmoreProduct()}
      >
        See more
      </button>
      
    </div>)}

                        
                       
                      </>
                    )
                  }
                </div>
    </React.Fragment>
  );
};
export default LandingProductTypeSecondPage;
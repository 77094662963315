import { useEffect, useState } from "react";
import VerifyEmail from "../components/landingpage/VerifyEmail";
import {
  handleVerifyEmail,
  verifyPasswordResetCode,
} from "../utils/Firebase.helper";
import SubHeaderSmall from "./landingpage/common/SubHeaderSmall";
import FooterLanding from "./landingpage/common/FooterLanding";
import SpinnerLoader from "./common/SpinnerLoader";
import ResetPasswordPage from "./ResetPasswordPage";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user.service";

const Useraction = () => {
  const [verifyMessage, setVerifyMessage] = useState("");
  const [resetPasswordPage, setResetPasswordPage] = useState("");
  const urlParams = new URLSearchParams(window.location.search);
  const [loading, setLoader] = useState(false);
  const navigate = useNavigate();
  const userVerifyEmail = {
    oobCode: urlParams.get("oobCode"),
    apiKey: urlParams.get("apiKey"),
    lang: urlParams.get("lang"),
    mode: urlParams.get("mode"),
  };

  useEffect(() => {
    if (urlParams.get("oobCode") && urlParams.get("mode") != "resetPassword") {
      handleVerifyEmail(urlParams.get("oobCode"), "", "").then(function (
        result
      ) {
        setVerifyMessage(result);
        setLoader(true);
        setResetPasswordPage("verifyemail");
        /* handle a successful result */
      });
    } else if (
      urlParams.get("oobCode") &&
      urlParams.get("mode") == "resetPassword"
    ) {
      setLoader(true);
      setResetPasswordPage("resetPasswordPage");
    }
  }, [urlParams.get("oobCode")]);

  useEffect(() => {
    if (urlParams.get("mode") != "resetPassword") {
      setTimeout(() => {
        userService.setKeyValueLocalstorage("pageName", "Login");
        navigate("/signup");
      }, 3000);
    }
  }, [urlParams.get("oobCode")]);

  return (
    <>
      {resetPasswordPage == "resetPasswordPage" && (
        <ResetPasswordPage></ResetPasswordPage>
      )}

      {resetPasswordPage == "verifyemail" && (
        <div className="confirmed-main-landing-page">
          {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
          <div className="hero-sections-parent">
            <div className="hero-sections">
              <SubHeaderSmall></SubHeaderSmall>
              {!loading && (
                <SpinnerLoader message="Validating Email id..."></SpinnerLoader>
              )}
            </div>

            {loading && (
              <div className="col-sm-8 col-sm-offset-2">
                <div className="frame-parent32">
                  {verifyMessage.status == "ok" && (
                    <div className="verfiy-email verfiy-email-box" role="alert">
                      <p>{verifyMessage.message}</p>
                    </div>
                  )}
                  {verifyMessage.status == "fail" && (
                    <div
                      className="verfiy-email  verfiy-email-box"
                      role="alert"
                    >
                      <p>
                        {verifyMessage.message === "Your code expired."
                          ? " Your Reset Password code expired, Please reset password again!"
                          : "Your code expired."}
                      </p>
                    </div>
                  )}
                </div>{" "}
              </div>
            )}

            <FooterLanding></FooterLanding>
          </div>
        </div>
      )}
    </>
  );
};

export default Useraction;

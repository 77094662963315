import React from "react";
import idea_1 from "../../../images/landing_page_assests/idea-1.svg";

const UnleaseAdIdea = (props) => {
  return (
    <>
       <div className="idea-1-parent">
            <img className="idea-1-icon" alt="" src={idea_1} />
            <div className="group-item" />
           {/* <div className="generate-creative-container landing-pg-title-margin">*/}
           <div className="generate-creative-container">
              <span className="unlimited-captions-txt-container">
                <p className="x-higher-roas-on-average-using"> 

                  <span>
                    {`Unleash the innovative capabilities `}
                    <span className="incredible cursor-pointer">of AI Marketing to generate Creative and</span>
                  </span>
                </p>
                <p className="x-higher-roas-on-average-using">
                  <span>
                   
                    <span className="incredible cursor-pointer"> Inspiring Ad Ideas</span>
                    {` `}
                  </span>
                  <span className="ad-ideas">{`Ad Ideas `}</span>
                </p>
              </span>
            </div>
            <div className="ad-ideas-wrapper">
              <div className="image-ads">Ad Ideas</div>
            </div>
          </div>
    </>
  );
};
export default UnleaseAdIdea;

import React from "react";
import { Link } from "react-router-dom";
import { userService } from "../services/user.service";
import constants from "../utils/constants";

const Settings = () => {
  return (
    <div className="container-fluid">
      <div className="row settings nav-margin-top">
        <span className="span">
          <b className="gradient-head-text text-size-30">Settings </b>
        </span>
        <div className="col-md-12">
          <div className="card-container">
            <div className="card-body">
              <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                <span className="gradient-head-text text-size-28">
                  {userService.downloadLeftImage()}
                </span>{" "}
                Ad Downloads Left
              </h3>
              <p className="text-light-gray-200 font-family-sans-serif">
                Trial user can download / test up to{" "}
                {constants.APPLICATION_INFO.DOWNLOAD_IMAGE_TRIAL_USER_COUNT} ads
                unwatermareked
              </p>
            </div>
            <div className="card-body">
              <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                Adspire® App
              </h3>
              <p className="text-light-gray-200 font-family-sans-serif">
                Adspire® is available for free on the App Store{" "}
                <a
                  href="https://apps.apple.com/us/app/adspire/id1667132875"
                  target="_blank"
                  className="underline"
                >
                  here
                </a>
              </p>
            </div>
            <div className="card-body">
              <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                Contact
              </h3>
              <p className="text-light-gray-200 font-family-sans-serif">
                Questions, concerns or suggestions? Contact us at{" "}
                <a
                  href="mailto:support@adspireai.com"
                  className="text-light-gray-200"
                >
                  <span className="underline">support@adspireai.com</span>
                </a>
              </p>
            </div>
            <div className="card-body">
              <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                Legal
              </h3>
              <p className="text-light-gray-200 font-family-sans-serif">
                <Link to="/legal/tos">
                  <div className="underline text-light-gray-200">
                    Terms of Services
                  </div>
                </Link>
                <br />
                <Link to="/legal/privacy">
                  <div className="underline text-light-gray-200">
                    Privacy policy
                  </div>
                </Link>
              </p>
            </div>

            <div className="card-body">
              <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                Subscription Settings
              </h3>
              <p className="text-light-gray-200 font-family-sans-serif">
                <Link to="/subscription-details">
                  <div className="underline text-light-gray-200">
                    Subscription Settings
                  </div>
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Settings;

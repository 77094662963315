import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWebsiteTitle } from "../../../utils/function";
import AdFlowFreeTrialSubpage from "./AdFlowFreeTrialSubpage";

const AdFlowFreeTrial = () => {
 // document.title = getWebsiteTitle() + "Ad Goal";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className="clearfix"></div>
                <AdFlowFreeTrialSubpage></AdFlowFreeTrialSubpage>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdFlowFreeTrial;

import React from "react";
import _x30_1 from "../../images/ad_goal_assets/_x30_1.svg";
import product_box from "../../images/ad_goal_assets/product-box.svg";
import product_active from "../../images/ad_goal_assets/product_active.svg";
import Layer_3 from "../../images/ad_goal_assets/Layer_3.svg";
import ad_type_gradient_outline from "../../images/ad_goal_assets/adType-gradient-outline.svg";
import ad_type_outline from "../../images/ad_goal_assets/adType-outline.svg";
import Icon from "../../images/ad_goal_assets/Icon.svg";
import outline from "../../images/ad_goal_assets/outline.svg";
import ad_idea_gradient_outline from "../../images/ad_goal_assets/ad-idea-gradient-outline.svg";
import ad_idea_outline from "../../images/ad_goal_assets/ad-idea-outline.svg";
import Group from "../../images/ad_goal_assets/Group.svg";
import fi_4947095 from "../../images/ad_goal_assets/fi_4947095.svg";
import copy_two_paper_sheets from "../../images/ad_goal_assets/copy-two-paper-sheets.svg";
import { userService } from "../../services/user.service";
import { useNavigate } from "react-router-dom";



const ThemeStep = (props) => {
    const navigate = useNavigate();
    const redirectTo = (pagename) => {
        let isRedirect = false;
        if (pagename === "home") {
            userService.removeKeyLocalstorage("ideas");
            window.location.href = "/home";
        } else if (pagename === "product-options") {
            userService.removeKeyLocalstorage("ideas");
            userService.setKeyValueLocalstorage("product-options", "true");
            pagename = "home"
            isRedirect = true;
        } else if (pagename === "product-ad-type") {
            isRedirect = true;
        } else if (pagename === "product-ad-ideas") {
            isRedirect = true;
        } else if (
            pagename === "image-ads" &&
            userService.getValueFromLocalstorage("ideas").substring(0, 20)
        ) {
            isRedirect = true;
        }
        if (isRedirect) {
            navigate("/" + pagename);
        }
    };

    return (
        <>
            <div className="parent-component-stepper  imgTextCenter  ">
                <ul className="">
                    <li onClick={() => redirectTo("home")}>
                        <div className={"stepper-active one"}>
                            <img src={_x30_1} alt="img-1" />
                        </div>
                        <p className="text-active">Niche</p>
                    </li>

                    <li
                        onClick={() =>
                            props.step == 3 ||
                                props.step == 4 || 
                                props.step == 5 ||
                                props.step == 6 ||
                                props.step == 7 ||
                                props.step == 8
                                ? redirectTo("home")
                                : ""
                        }
                    >
                        <div
                            className={
                                props.step == 2 ||
                                    props.step == 3 ||
                                    props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "stepper-active two"
                                    : "stepper two"
                            }
                        >
                            <img
                                src={
                                    props.step == 2 ||
                                        props.step == 3 ||
                                        props.step == 4 ||
                                        props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? product_active
                                        : product_box
                                }
                                alt="img-2"
                                width={20}
                            />
                        </div>
                        <p
                            className={
                                props.step == 2 ||
                                    props.step == 3 ||
                                    props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "text-active"
                                    : "text"
                            }
                        >
                            Product
                        </p>
                    </li>
                    <li
                        onClick={() =>
                            props.step == 4 ||
                                props.step == 5 ||
                                props.step == 6 ||
                                props.step == 7 ||
                                props.step == 8
                                ? redirectTo("home")
                                : ""
                        }
                    >
                        <div
                            className={
                                props.step == 3 ||
                                    props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "stepper-active three"
                                    : "stepper three"
                            }
                        >
                            <img
                                className={
                                    props.step == 3 ||
                                        props.step == 4 ||
                                        props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? "mix-blend-mode-color-dodge"
                                        : ""
                                }
                                src={
                                    props.step == 3 ||
                                        props.step == 4 ||
                                        props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? ad_type_outline
                                        : ad_type_outline
                                }
                                alt="img-3"
                                width={20}
                            />
                        </div>
                        <p
                            className={
                                props.step == 3 ||
                                    props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "text-active"
                                    : "text"
                            }
                        >
                            Theme
                        </p>
                    </li>
                    <li
                        onClick={() =>
                            props.step == 5 ||
                                props.step == 6 ||
                                props.step == 7 ||
                                props.step == 8
                                ? redirectTo("product-ad-ideas")
                                : ""
                        }
                    >
                        <div
                            className={
                                props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "stepper-active four"
                                    : "stepper four"
                            }
                        >
                            <img
                                className={
                                    props.step == 4 ||
                                        props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? "mix-blend-mode-color-dodge"
                                        : ""
                                }
                                src={
                                    props.step == 4 ||
                                        props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? ad_idea_outline
                                        : ad_idea_outline
                                }
                                alt="img-4"
                            />
                        </div>
                        <p
                            className={
                                props.step == 4 ||
                                    props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "text-active"
                                    : "text"
                            }
                        >
                            Background
                        </p>
                    </li>
                    <li>
                        <div
                            className={
                                props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "stepper-active five"
                                    : "stepper five"
                            }
                        >
                            <img
                                className={
                                    props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? "mix-blend-mode-color-dodge"
                                        : ""
                                }
                                src={
                                    props.step == 5 ||
                                        props.step == 6 ||
                                        props.step == 7 ||
                                        props.step == 8
                                        ? outline
                                        : outline
                                }
                                alt="img-5"
                            />
                        </div>
                        <p
                            className={
                                props.step == 5 ||
                                    props.step == 6 ||
                                    props.step == 7 ||
                                    props.step == 8
                                    ? "text-active"
                                    : "text"
                            }
                        >
                            Image Ads
                        </p>
                    </li>

                </ul>
            </div>
        </>
    );
};
export default ThemeStep;

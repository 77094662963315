import React, { useEffect, useState } from "react";
import circleCheck from "../../../images/icons/circle-check.svg";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import SpinnerLoader from "../../common/SpinnerLoader";
import adSpireWatermark from "../../../images/icons/Adspire_watermark.svg";
import StyledButton2 from "../../common/Button/StyledButton2";



const LandingAdFlow = (props) => {
  const [adOptions, setOptions] = useState("");
  const [active, setActive] = useState(null);
  const [loading, setLoader] = useState(false);
  const [childProduct, setChildProduct] = useState("");
  const [childProductStatus, setChildProductStatus] = useState(false);
  const [moreOptions, setMoreOptions] = useState(false);
  const [classvalue, setClassvalue] = useState("generateAd");

  useEffect(() => {
    //setOptions(adOptions)
  }, [moreOptions]);

  useEffect(() => {
    // Cleanup function for Firestore listener
    let unsubscribe;

    if (!childProductStatus) {
      unsubscribe = db.collection("productPresets").where("order", "!=", 3)
        .orderBy("order", "desc")
        .onSnapshot((snapshot) => {
          const dataReceived = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
          setOptions(dataReceived);
          userService.setKeyValueLocalstorage("productData", dataReceived);
          setLoader(true);
        });
    }

    // Cleanup function
    return () => {
      if (unsubscribe) {
        unsubscribe(); // Unsubscribe from the Firestore listener when the component unmounts
      }
    };
  }, [childProductStatus]);

  //document.title = getWebsiteTitle() + "Ad Type";

  const setOptionValue = (subProduct) => {
    props.currentPage("ads-ready");
    setChildProductStatus(true);
    setChildProduct(subProduct);
    userService.setKeyValueLocalstorage("uploadInput", subProduct.input)
  };

  const getClassForShowHide = (i) => {
    if (i > 5 && !moreOptions) {
      return "col-sm-3 col-xs-12 clshide";
    } else {
      return "col-sm-4 col-xs-12";
    }
  };

  const redirectTo = () => {
    userService.setKeyValueLocalstorage('adflow', 'yes')
    
    if (userService.showLogoutBtn()) {
      window.location.href = "/store";
    } else {
      window.location.href = "/signup";
    }
  };

  return (
    <React.Fragment>
      {childProductStatus && (
        <h1 className="top-ranckking-h1 top-ranckking-h1-font-height text-center">Your ads are ready!</h1>
      )}



      {childProductStatus && (
        <div className="row on-mobile-visible">
          <div className="col-sm-12 col-xs-12 mt-20 text-center btn-radius ">
            {/* <TryWithYourProduct></TryWithYourProduct> */}
            <div className="col-sm-12 col-xs-12 col-md-8 mt-20 text-center w-350" onClick={redirectTo}>
              <StyledButton2 btnHeight="78px" zIndexValue="0" btnTextStyle="font-25" name={"Try with Your Product"} type="button" disabled={loading} />
            </div>
          </div>
        </div>
      )}



      {!childProductStatus && <div
        className="what-do-you-want-an-ad-for-wrapper landingadflonew text-left-home"
        title="What type of product do you sell?"
      >
        <b className="what-do-you press-product">Press a product:</b>
      </div>}
      <div className="clearfix"></div>
      <div className="">
        {!loading && (
          <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
        )}
        {loading && (
          <>
            <div className="row flex place-content-center press-product-container">
              {!childProductStatus &&
                adOptions &&
                adOptions.map((projectsItems, index) => (
                  <div
                    className="col-sm-3 col-xs-12 mt-20 "
                    key={projectsItems.data.input}
                    onClick={() => setOptionValue(projectsItems.data)}
                    title={projectsItems.data.input}
                  >
                    <div
                      className={`text-size-16 cursor-pointer  ${active == projectsItems.data.input && "box-active"
                        }`}
                    >
                      <div className="btn-text-left min-width-new-landing-page">
                        <img
                          data-toggle="modal"
                          data-target="#myModal"
                          className="img-thumbnail pointerEvents img-radius"
                          alt={projectsItems.data.input}
                          src={projectsItems.data.originalImageUrl}
                        />
                        {/* <img
                          src={adSpireWatermark}
                          alt=""
                          className="watermarkImg-main-ad-flow"
                        /> */}


                        {active == projectsItems.data.input ? (
                          <img
                            data-toggle="modal"
                            data-target="#myModal"
                            alt={projectsItems.data.input}
                            width={20}
                            src={circleCheck}
                            className="w-20 float-r"
                          />
                        ) : (
                          ""
                        )}
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                ))}

              {childProductStatus &&
                childProduct.adImageURLs &&
                childProduct.adImageURLs.map((projectsItems, index) => (
                  <div
                    className="col-sm-3 col-xs-10 mt-20 offset-mobile-10"
                    key={projectsItems}
                  // title={projectsItems}
                  >
                    <div
                      className={`text-size-16 ${active == projectsItems && "box-active"
                        }`}
                    >
                      <div className="btn-text-left child-product-status-min-width-new-landing-page">
                        <img
                          data-toggle="modal"
                          data-target="#myModal"
                          className="img-thumbnail pointerEvents img-radius"
                          alt="test"
                          src={projectsItems}
                        />
                        {/* <img
                          src={adSpireWatermark}
                          alt=""
                          className="watermarkImg-main-ad-flow-child"
                        /> */}
                      </div>

                      <div className="clearfix"></div>
                    </div>
                  </div>
                ))}
            </div>

            {childProductStatus && (
              <div className="row on-mobile-hidden">
                <div className="col-sm-12 col-xs-12 mt-20 text-center btn-radius ">
                  {/* <TryWithYourProduct></TryWithYourProduct> */}
                  <div className="col-sm-12 col-xs-12 col-md-8 mt-20 text-center" onClick={redirectTo}>
                    <StyledButton2 btnHeight="78px" zIndexValue="0" btnTextStyle="font-25" name={"Try with Your Product"} type="button" disabled={loading} />
                  </div>
                </div>
              </div>
            )}
          </>
        )}
      </div>
    </React.Fragment>
  );
};
export default LandingAdFlow;

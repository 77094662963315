import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../common/SpinnerLoader";
import SignupPageText from "./SignupPageText";

import { handleResetPassword } from "../../../utils/Firebase.helper";

import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { userService } from "../../../services/user.service";
import { getWebsiteTitle } from "../../../utils/function";
import StyledButtonWhite from "../../common/Button/StyledButtonWhite";

const ResetPage = () => {
  document.title = getWebsiteTitle() + " ResetPage";
  const [loginTitle, setLoginTitle] = useState("Login to see your ads!"); // Local signed-in state.
  const [errorMessage, setErrorMessage] = useState(""); // Local signed-in state.
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);
  const [loading, setLoader] = useState(false);
  const [pageMessage, setPageMessage] = useState("");
  const [showPassword, setShowPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const urlParams = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  const initFormValues = {
    confirmPassword: "",
    password: "",
  };
  const [formData, setFormData] = useState(initFormValues);
  useEffect(() => {
    // fbEventsService.sendFbEvents(FREE_TRIAL, process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME, PAYPAL_MONTHLY_TRIAL_PRICE);
  }, []);

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("Please enter valid password")
      .min(6, "Password must be 6 or more character long")
      .max(16, "Password maximum size 16 character long"),
    confirmPassword: Yup.string()
      .required("Please enter valid confirm Password")
      .oneOf(
        [Yup.ref("password"), null],
        "Password & Confirm Password must match"
      ),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    setErrorMessage("")
    setLoader(true)
    try {
      const log = await handleResetPassword(data.password, urlParams.get("oobCode"), "", "");
      if (log.message === "EXPIRED_INVALID_CODE") {
        setErrorMessage("Your reset password code expired.")
        setLoader(false)
      } else if (log.message === "PASSWORD_RESET_SUCCESSFULLY") {
        setPageMessage("Your password resetted successfully.")
        setLoader(false)
        const timeout = setTimeout(() => {
          setErrorMessage("");
          setPageMessage("");
          userService.setKeyValueLocalstorage("pageName", "Login");
          window.location.href = '/signup';
          //navigate("/signup");
        }, 5000);
        return () => {
          timeout && clearTimeout(timeout);
        };

      }
      setLoader(false)
      reset();
      // User successfully logged in, you can redirect or perform other actions here
    } catch (error) {
      console.error(error);
      setErrorMessage("Your reset password code expired.")
      setLoader(false)
    } finally {
      setLoader(false)
    }
  };

  const CustomPageName = () => {
    userService.setKeyValueLocalstorage("pageName", "Login");
    navigate("/signup");
  }

  return (
    <div className="group-free-trail-parent">
      <div className="frame-free-trail-parent">
        <div className="free-trail-frame-group">
          <div className="free-trail-welcome-to-adspire-wrapper">
            <div className="free-trail-welcome-to-adspire-container">

            </div>
          </div>
        </div>



        <div className="new-trail-frame-container-by-2">
          <div className="new-trail-frame-wrapper2">
            <div className="free-trail-frame-wrapper">
              {loading && (<div className="text-center"><SpinnerLoader></SpinnerLoader>
                <p className="text-center-normal-mar30">
                  <b>{pageMessage}</b>
                </p>
              </div>)}

              {pageMessage && (<div className="text-center">
                <p className="text-center-normal-mar30">
                  <b>{pageMessage}</b>
                </p>
              </div>)}
              {errorMessage && (<b className="error-message text-center"> {errorMessage}</b>)}

              <div className="free-trail-frame-parent1">





                <><span className="adspire-free-trail-3 fullwidth font-35 text-center">
                  Reset Password
                </span>

                  <div className="try-3-days-for-free-parent">

                    <div className="then-34999year">
                      <div className="text-left">
                        <div className="row">
                          <div className="max-width-form">
                            <form onSubmit={handleSubmit(onSubmit)}>

                              <div className="form-row">
                                <div className="form-group col">
                                  <div className="password-container">
                                    <input
                                      name="password"
                                      placeholder="New Password"
                                      type={showPassword ? "text" : "password"}
                                      {...register("password")}
                                      className={`custom-input custom-input-radius  modal-gradient- ${errors.password ? "is-invalid" : ""
                                        }`}
                                    />
                                    <span className="eye-icon" onClick={() => { setShowPassword(prev => !prev) }}>
                                      {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                    </span>
                                  </div>

                                  <div className="invalid-feedback">
                                    {errors.password?.message}
                                  </div>
                                </div>
                              </div>

                              <div className="form-row">
                                <div className="form-group col">
                                  <div className="password-container">
                                    <input
                                      name="confirmPassword"
                                      placeholder="Confirm Password"
                                      type={showConfirmPassword ? "text" : "password"}
                                      {...register("confirmPassword")}
                                      className={`custom-input custom-input-radius  modal-gradient- ${errors.confirmPassword ? "is-invalid" : ""
                                        }`}
                                    />
                                    <span className="eye-icon" onClick={() => { setShowConfirmPassword(prev => !prev) }}>
                                      {showConfirmPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                    </span>
                                  </div>

                                  <div className="invalid-feedback">
                                    {errors.confirmPassword?.message}
                                  </div>
                                </div>
                              </div>


                              {!loading && (<div className="form-row submit-signup-button">

                                <StyledButtonWhite
                                  type="submit"
                                  name={"Reset My Password"}
                                />
                              </div>)}
                            </form>

                          </div>
                        </div>
                      </div>
                      <p className="account-login">Remember your password? <span onClick={() => CustomPageName()}>Login</span></p>
                    </div>
                  </div></>




              </div>
            </div>

            <SignupPageText></SignupPageText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPage;

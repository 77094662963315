import React from "react";
const EffectiveAds = (props) => {

  return (
    <div className="heading-and-body-text position-relative z-index-1">
        <div className="generate-incredible-parent">
          <div className="generate-incredible">
            {props.msg.msg1}
            <span className="incredible cursor-pointer"> {props.msg.msg2} </span>
          </div>
          <div className="effective-ads-product-flow"> {props.msg.msg3}</div>
          <div className="in-seconds-product-flow"> {props.msg.msg4}</div>
          
        </div>
        <div className="the-worlds-most-powerful-ai-t-parent">
          <div className="the-worlds-most">{`The world's most powerful AI that inspires & generates ads for your businesses in seconds!`}</div>
          <div className="next-wrapper2">
            <b className="what-do-you">Get started</b>
          </div>
        </div>
      </div>
  );
};
export default EffectiveAds;

 
 
 
 
 
 
 
 
 
 
 
 
 
 
 

import React from "react";
import { useNavigate } from "react-router-dom";
const BackButton = (props) => {
  const navigate = useNavigate();
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };
  return (
    <div title="Back" className="form-group text-center pointerclass" onClick={() => redirectTo(props.pagename)}>
      Back
    </div>
  );
};
export default BackButton;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API_FIREBASE from "../../../config/apifirebase";
import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import { userService } from "../../../services/user.service";
import constants from "../../../utils/constants";
import LoadingScreen from "../../landingpage/adFlow/Loading";
import svg64 from "svg64"; // or use window.svg64.default
import {
  ConceptAdImagePrompt,
  getWebsiteTitle,
  isEligibleForFreeAdsAPI,
  updateNewSignupUserStatus,
  upperCase,
} from "../../../utils/function";
import { getStyleMaps } from "../../../utils/functionStyleMaps";
import ConceptStep from "../../common/ConceptStep";
import AdFlowListingFree from "../adFlow/AdFlowListingFree";

const ConceptImageFreeAds = () => {
  const [generatedImage, setGeneratedImage] = useState([]);
  const [showSubscribe, setShowSubscribe] = useState(false);
  const [loading, setLoader] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showListingPage, setShowListingPage] = useState(false);
  const [copyImages, setCopyImages] = useState([]);
  let copyItems = [];
  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    generateAdImages();
  }, []);

  const generateAdImages = async () => {
    if (isEligibleForFreeAdsAPI()) {
      setLoader(true);
      console.log("generating image here now 1");
      const userId = userService.getUserId();
      const styleId = userService.getValueFromLocalstorage("styleId");
      const style = userService.getValueFromLocalstorage("style");
      const idea = userService.getValueFromLocalstorage("ideas");
      const input = userService.getValueFromLocalstorage(
        "conceptAdFormattedInput"
      );
      const ad_type = userService.getValueFromLocalstorage(
        "userSelectedConceptAdTypeObj"
      ).data.name;
      const prompt = ConceptAdImagePrompt(ad_type, input, idea);

      const userIdeaHistoryId =
        userService.getValueFromLocalstorage("userIdeaHistoryId");
      const maxImages = 10; //userService.getValueFromLocalstorage("maxImages");
      const size = userService.getValueFromLocalstorage("size");
      let imageNameInfo = {};
      const objGenerateImage = {
        userId,
        prompt,
        userIdeaHistoryId,
        maxImages,
        size,
        idea,
        aiEngine: "StabilityAi",
      };

      API_FIREBASE.post("requestAdImagesWithCaptionsFreeV2", objGenerateImage, {
        headers: {
          authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
        },
      })
        .then(async (res) => {
          // setLoader(true);
          //setGeneratedImage(res.data);
          // setGeneratedImage([...generatedImage, ...res.data.imageURLs]);
          // CODE START FOR MERGING THE CODE WITH OVERLAY IMAGES
          if (res.data && res.data.captions.headline) {
            // Initialize variables to store extracted information
            let idea = "";
            let headline = upperCase(res.data.captions.headline);
            let description = res.data.captions.description;
            let cta = res.data.captions.cta;
            const bulletPoints = res.data.captions.bulletPoints;
            const lightColor = res.data.captions.lightColor;
            const darkColor = res.data.captions.darkColor;
            const reviewName = res.data.captions.reviewName;
            const reviewMessage = res.data.captions.reviewMessage;
            const question = res.data.captions.question;
            // Iterate through the lines and extract the information
            let i = 0;
            // for testing open below code
            if (Array.isArray(res.data.imagesBase64)) {
              for (const item of res.data.imagesBase64) {
                // for testing open below code
                const imagesBase64 = item;

                const styleMaps = await getStyleMaps(i, lightColor, darkColor);
                userService.setKeyValueLocalstorage(
                  "bulletpoints",
                  bulletPoints
                );
                userService.setKeyValueLocalstorage(
                  "creativeAIheadline",
                  headline
                );
                userService.setKeyValueLocalstorage(
                  "creativeAIdescription",
                  description
                );
                userService.setKeyValueLocalstorage("creativeAIcta", cta);
                const objGenerateSecondImage = {
                  title: headline,
                  description: description,
                  cta: cta,
                  height: 1024,
                  width: 1024,
                  bulletpoints: bulletPoints,
                  style: styleMaps,
                  reviewName: reviewName,
                  reviewMessage: reviewMessage,
                  question: question,
                };
                const secondRes = await API_FIREBASE.post(
                  "requestCaptionImageAdOverlayV1",
                  objGenerateSecondImage,
                  {
                    headers: {
                      authkey:
                        constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                    },
                  }
                );
                await mergeImages(imagesBase64, i, copyItems, secondRes.data);
                i++;
              }
            }
          }
          // CODE END FOR MERGING THE CODE WITH OVERLAY IMAGES
        }, [])
        .catch((e) => {
          setErrorMessage(
            "Limit to access this feature has been reached. Try again at another time."
          );
          setLoader(false);
        })
        .finally(() => {
          // setErrorMessage("Limit to access this feature has been reached. Try again at another time.")
          setLoader(false);
        });
    }
  };

  const mergeImages = async (
    imagesBase64,
    i,
    copyItems,
    base64OverLayImage
  ) => {
    try {
      if (imagesBase64) {
        const remoteImage1 = "data:image/png;base64," + imagesBase64;
        const remoteImageBlob = await (await fetch(remoteImage1)).blob();
        let base64Image = "";
        try {
          base64Image = svg64(base64OverLayImage);
        } catch (error) {

        }
        const base64Blob = await (await fetch(base64Image)).blob();

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const remoteImage = new Image();
        remoteImage.onload = async () => {
          canvas.width = remoteImage.width;
          canvas.height = remoteImage.height;
          context.drawImage(remoteImage, 0, 0);

          const base64ImageObj = new Image();
          base64ImageObj.onload = async () => {
            context.drawImage(base64ImageObj, 0, 0);

            const mergedImageDataUrl = canvas.toDataURL();

            copyItems.push(mergedImageDataUrl);
            // since i started from 0
            if (i == constants.APPLICATION_INFO.MAX_IMAGE - 1) {
              setLoader(false);
              setShowListingPage(true);
              setCopyImages([...copyImages, ...copyItems]);
              if (
                userService.getValueFromLocalstorage("generatedAdsNumber") == 0
              ) {
                await updateNewSignupUserStatus();
              }
            }
          };
          base64ImageObj.src = URL.createObjectURL(base64Blob);
        };
        remoteImage.src = URL.createObjectURL(remoteImageBlob);
      }
    } catch (error) {
      console.error("Error in mergeImages:", error);
    } finally {
    }
  };

  const hideTheImage = () => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "quick-ad"
    ) {
      navigate("/quick-ad");
    } else if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "concept-ad"
    ) {
      navigate("/concept-ad");
    }
  };

  document.title = getWebsiteTitle() + "Image Ads";

  return (
    <>
      <span
        id="psubscribeLiteModal"
        data-toggle="modal"
        data-target="#subscribeLiteModal"
      ></span>
      <span
        id="psubscribeModal"
        data-toggle="modal"
        data-target="#subscribeModal"
      ></span>

      {!showListingPage && <LoadingScreen />}

      {showListingPage && (
        <AdFlowListingFree
          handleUpload={generateAdImages}
          setShowListingPage={setShowListingPage}
          showListingPage={showListingPage}
          copyImages={copyImages}
        ></AdFlowListingFree>
      )}

      {!showSubscribe && userService.checkCredits() && !generatedImage && (
        <React.Fragment>
          <div className="container-fluid">
            <div className="row">
              <div className="">
                {/* <div className="col-sm-6 col-sm-offset-3"> */}
                <div className="top-contentDIv">
                  <div className="top-ranckking">
                    <ConceptStep step="5"></ConceptStep>
                    <h1 className="text-center">&nbsp;</h1>
                    {errorMessage && (
                      <p className="error-message cls-center">{errorMessage}</p>
                    )}
                    <div className="clearfix"></div>
                    {!errorMessage && (
                      <div className="row">
                        <div className=" top-ranckking text-center">
                          <img
                            width={100}
                            className=""
                            src={Rolling_circle_loader}
                            alt="Generating Image Ads..."
                            title="Generating Image Ads..."
                          />
                          <div className="clearfix"></div>
                          <p>{generatedImage}</p>
                          <div className=" text-center">
                            <h2 className="gradient-head-text text-center text-size-32 mb-40">
                              Generating Image Ads...
                            </h2>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </React.Fragment>
      )}
    </>
  );
};
export default ConceptImageFreeAds;

import { isIOSDevice } from "ios-detector";
import React, { useEffect } from "react";
import { getWebsiteTitle } from "../../utils/function";
import { metaDesc } from "../../utils/landingConstants";
import FooterLanding from "./common/FooterLanding";
import IOSMessageModal from "../common/popup/IOSMessageModal";
import SubHeaderSmall from "./common/SubHeaderSmall";
import SubHeaderfreeAdsTwo from "./common/SubHeaderfreeAdsTwo";
import "./css/Landing-page.css";

const LandingPageFreeAds2 = () => {

  useEffect(() => {
    document.querySelector("meta[name='description']").setAttribute("content", metaDesc.AIADS);
  }, []);

  document.title = getWebsiteTitle() + "AI Ads Generator";


  return (
    <>
   
      <div className="container-fluid">
        <div className="confirmed-main-landing-page">
          <div className="hero-sections-parent">
            <div className="hero-sections landing-free-ads-two">
              <SubHeaderSmall></SubHeaderSmall>
              <SubHeaderfreeAdsTwo></SubHeaderfreeAdsTwo>
            </div>
            <FooterLanding></FooterLanding>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPageFreeAds2;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../services/user.service";


import PlanMonthlyLite from "../components/common/PaymentGateway/PaypalSubscription/PlanMonthlyLite";
import PlanMonthlyPro from "../components/common/PaymentGateway/PaypalSubscription/PlanMonthlyPro";
import PlanMonthlyUltra from "../components/common/PaymentGateway/PaypalSubscription/PlanMonthlyUltra";
import PlanDetails from "./common/PaymentGateway/PaypalSubscription/PlanDetails";
import SpinnerLoader from "./common/SpinnerLoader";
import CheckoutMonthlyLite from "./common/StripePayment/CheckoutMonthlyLite";
import CheckoutMonthlyPro from "./common/StripePayment/CheckoutMonthlyPro";
import CheckoutMonthlyUltra from "./common/StripePayment/CheckoutMonthlyUltra";

import constants from "../utils/constants";
import BestSeller from "./common/BestSeller";

const PlanMonthly = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);
  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      //navigate("/login");
    }
  }, []);

  const redirectTo = () => {
    navigate("/business-type");
  };

  return (
    <>
      {paymentProccessStart && (
        <div><SpinnerLoader></SpinnerLoader>
          <p className="text-center-normal-mar30">
            <b>{constants.PAYMENT_SUCCESS_MSG}</b></p>
        </div>)}
      <div className="tabel-parent">
        <table className="tabel-parent-subscribe">
          <thead className="">
            <tr className="">
              <th className=""></th>
              <th className="">
                <div className="subscribe2" >
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyLite
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyLite>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>

                      <div className="paypal-div">
                        <PlanMonthlyLite setPaymentProccessStart={setPaymentProccessStart} />
                      </div>
                      <button className="next">
                        <span className="extracss" >Subscribe</span>
                      </button> </>)}
                </div>
              </th>

              <th className="">
                <div className="subscribe3">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyPro
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyPro>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div">
                        <PlanMonthlyPro setPaymentProccessStart={setPaymentProccessStart} />
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads" >Subscribe</span>
                      </button></>)}
                </div>
              </th>
              <th className="">
                <div className="subscribe4">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyUltra
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyUltra>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div">
                        <PlanMonthlyUltra setPaymentProccessStart={setPaymentProccessStart} />
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button></>)}
                </div>
              </th>
            </tr>
            <tr>
              <th className="th-parent">
                <div className="get-up-to">
                  <span>Get up to</span>
                  <b className="font-18-regular"> 55% off</b>
                  <span >
                    with a Yearly subscription!
                  </span>
                </div>
              </th>
              <th className="th-parent">
                <div className="lite-parent p-20">
                  <div className="lite">LITE</div>
                  <div className="only-2499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$24.99/mo</p>
                  </div>
                </div>
              </th>
              <th className="th-parent">
                <div className="pro-parent p-20 ">
                  <div className="pro">PRO</div>
                  <div className="only-2499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$49.99/mo</p>
                  </div>
                </div>
              </th>
              <th className="th-parent">
                <BestSeller></BestSeller>
                <div className="ultra-parent p-20 ">
                  <div className="pro">ULTRA</div>
                  <div className="only-6499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$64.99/mo</p>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <PlanDetails></PlanDetails>
          <tfoot className="">
            <tr>
              <th className=""></th>
              <th className="bottom-subscribe-buttons">
                <div className="subscribe2" >
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyLite
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyLite>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div-bottom">
                        <PlanMonthlyLite
                          setPaymentProccessStart={setPaymentProccessStart}
                        />
                      </div>
                      <button className="next">
                        <span className="extracss" >Subscribe</span>
                      </button> </>)}
                </div>
              </th>

              <th className="bottom-subscribe-buttons">
                <div className="subscribe3">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyPro
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyPro>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div-bottom">
                        <PlanMonthlyPro setPaymentProccessStart={setPaymentProccessStart} />
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads" >Subscribe</span>
                      </button> </>)}
                </div>
              </th>
              <th className="bottom-subscribe-buttons">
                <div className="subscribe4">

                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutMonthlyUltra
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutMonthlyUltra>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div-bottom">
                        <PlanMonthlyUltra setPaymentProccessStart={setPaymentProccessStart} />
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button></>)}
                </div>
              </th>
            </tr>
          </tfoot>
        </table>
        {/* ***********************For Mobile View************************************ */}
        <div className="subscribe-for-mobile">
          <div className="get-up-to-container">
            <span className="get-up-to-container1">
              <span className="get-up-to">Get up to</span>
              <b className="font-18-regular"> 55% off</b>
              <span className="get-up-to">
                {" "}
                with a Yearly subscription!
              </span>
            </span>
          </div>
          <div>
            <div className="lite-parent mb-30">
              <div className="lite">LITE</div>
              <div className="only-2499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$24.99/mo</p>
              </div>
              <div className="subscribe2" >

                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutMonthlyLite
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutMonthlyLite>
                )}
                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>

                    <div className="paypal-div">
                      <PlanMonthlyLite setPaymentProccessStart={setPaymentProccessStart} />
                    </div>
                    <button className="next">
                      <span className="extracss" >Subscribe</span>
                    </button> </>)}
              </div>
            </div>
          </div>
          <div>
            <div className="pro-parent">
              <div className="pro">PRO</div>
              <div className="only-2499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$49.99/mo</p>
              </div>
              <div className="subscribe3">
                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutMonthlyPro
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutMonthlyPro>
                )}
                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="paypal-div">
                      <PlanMonthlyPro setPaymentProccessStart={setPaymentProccessStart} />
                    </div>
                    <button className="subs-btn">
                      <span className="create-new-ads" >Subscribe</span>
                    </button></>)}
              </div>
            </div>
          </div>
          <div className="margin-tb35-lr-0">
            <BestSeller></BestSeller>
            <div className="ultra-parent">
              <div className="pro">ULTRA</div>
              <div className="only-6499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$64.99/mo</p>
              </div>
              <div className="subscribe4">
                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutMonthlyUltra
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutMonthlyUltra>
                )}
                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="paypal-div">
                      <PlanMonthlyUltra setPaymentProccessStart={setPaymentProccessStart} />
                    </div>
                    <button className="subs-btn">
                      <span className="create-new-ads">Subscribe</span>
                    </button></>)}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlanMonthly;

import { isIOSDevice } from "ios-detector";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../services/user.service";
import { db } from "../../utils/Firebase";
import { getWebsiteTitle } from "../../utils/function";

import ellipse_143 from "../../images/landing_page_assests/ellipse-143.svg";
import group_1261152240 from "../../images/landing_page_assests/group-1261152240.svg";
import vector from "../../images/landing_page_assests/vector.svg";

import "./css/Landing-page.css";

import LandingProductType from "./questionsProductFlow/LandingProductType";

import LandingProductTypeSecondPage from "./questionsProductFlow/LandingProductTypeSecondPage";

import IOSMessageModal from "../common/popup/IOSMessageModal";
import WarningUsageWebsite from "./common/WarningUsageWebsite";
import EffectiveAdsProductFlow from "./common/EffectiveAdsProductFlow";
import FooterLanding from "./common/FooterLanding";
import Marquee from "./common/Marquee";
import Roas from "./common/Roas";
import SubFooter from "./common/SubFooter";
import SubHeader from "./common/SubHeader";
import VerifyEmail from "./VerifyEmail";
import { metaDesc } from "../../utils/landingConstants";

const LandingPageProductFlow = () => {
  const [adCategory, setAdCategory] = useState("");
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null);
  const [currentPage, setCurrentPage] = useState("prdocutFlowFirstPage");
  const navigate = useNavigate();

  useEffect(() => {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", metaDesc.LandingPageDescription);
  }, []);

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("adCategories")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setAdCategory(dataReceived);
        setLoader(true);
      });
    userService.clearQuestionFlowData();
  }, []);

  const setBusinessValue = (adCategory) => {
    setButtonStatus(false);
    setActive(adCategory.data.name);
    setClassvalue("next-btn-enable");
    userService.setKeyValueLocalstorage(
      "adCategoryValue",
      adCategory.data.value
    );
    userService.setKeyValueLocalstorage("categoryName", adCategory.data.name);
    userService.setKeyValueLocalstorage("adCategoryID", adCategory.id);
    userService.setKeyValueLocalstorage(
      "adCategoryQuestion",
      adCategory.data.question
    );
    setCurrentPage("business-type");
  };

  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };
  const getClass = () => {
    if (currentPage == "generate-image") {
      return "ellipse-parent-space-reduce";
    }
  };
  const msg = {
    msg1: "Use Generative AI",
    msg2: "",
    msg3: "TO CREATE, EFFECTIVE ADS",
    msg4: "within seconds!",
  };
  document.title =
    getWebsiteTitle() +
    "AI Ad Generator";
  return (
    <>


      {/* <span
        id="pIOSMessageModal"
        data-toggle="modal"
        data-target="#iosMessageModalId"
      ></span> */}
      <div className="confirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
        <div className="hero-sections-parent">
          <div className="hero-sections">
            <SubHeader></SubHeader>

            <EffectiveAdsProductFlow msg={msg}></EffectiveAdsProductFlow>
            <img className="vector-icon" alt="" src={vector} />
            <div className="ellipse-box">
              <div className={`ellipse-parent-product-flow ${getClass()}`}>
                <img className="frame-child" alt="" src={ellipse_143} />
                <b className="adspire1">Adspire®</b>
                <div
                  className={`what-do-you-want-an-ad-for-productflow ${currentPage}`}
                >
                  {currentPage == "prdocutFlowFirstPage" && loading && (
                    <LandingProductType
                      currentPage={setCurrentPage}
                    ></LandingProductType>
                  )}

                  {currentPage == "prdocutFlowSecondPage" && loading && (
                    <LandingProductTypeSecondPage
                      currentPage={setCurrentPage}
                    ></LandingProductTypeSecondPage>
                  )}

                  <div className="component-2">
                    <div className="next6">Start</div>
                  </div>
                </div>
              </div>
              <div className="mockup">
                <img className="mockup-child" alt="" src={group_1261152240} />
              </div>
            </div>
          </div>
          <Marquee></Marquee>
          <Roas></Roas>

          <SubFooter unleas={true} ideas={true}></SubFooter>



          <FooterLanding></FooterLanding>
        </div>
      </div>
    </>
  );
};

export default LandingPageProductFlow;

import React from "react";
import setting_modal from "../../../images/modal_assests/setting_modal.svg";
import quick_yellow from "../../../images/modal_assests/Frame_24.svg";
import { useState } from "react";
import { userService } from "../../../services/user.service";
import { useNavigate } from "react-router-dom";


const SubHeaderfreeAdsTwo = () => {

  // ************************ Hooks ********************************
  const [errorMessage, setErrorMessage] = useState("");


  // ********************** Functions ***********************************
  const navigate = useNavigate();

  const redirectTo = (pagename) => {
    if (!userService.isUserFreeAdsStatus()) {
      pagename = userService.getSubscribePageName();
      window.open("/" + pagename, "_blank");
    } else {
      userService.setKeyValueLocalstorage("userSelectedAdTypePage", pagename);
      navigate("/" + pagename);
    }
  };



  return (
    <div className="star-parent">
      <div className="flex content-center ">
        <div className="carousel-content-parent">
          <div className="heading-and-body-text position-relative z-index-10">
            <div className="generate-incredible-parent">
              <div className="gradiend-title-text mb-20 text-size-50 p-50-tab">Generate 10 Free Ads!</div>
            </div>
          </div>
          <div className="modal-gradient-new free-ads-2-container">
            <div className="text-center">
              <div className="mt-4">
                <div className="row popup-product">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-5 cursor-pointer">
                    <div
                      onClick={() => redirectTo("concept-ad")}
                    >
                      <img src={setting_modal} alt="product type" width={130} />
                      <p className="p-title font-18-regular font-family-sans-serif">Custom Ad</p>

                    </div>
                  </div>
                  <div
                    className="col-sm-5 cursor-pointer"
                    onClick={() => redirectTo("free-ads")}
                  >
                    <div>
                      <img src={quick_yellow} alt="product type" width={130} />
                      <p className="p-title font-18-regular font-family-sans-serif">Quick Ad</p>
                      {/* <p className="sub-title">Ad ideas + concept ads</p> */}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};


export default SubHeaderfreeAdsTwo;

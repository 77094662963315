import React from "react";
import { useNavigate } from "react-router-dom";


const NextButton = (props) => {

  // ******************** Hooks ***************************************
  const navigate = useNavigate();

  // ******************** Functions ***************************************
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };


  return (
    <div className="form-group text-center" title="Next">
      <button type="btn" disabled={props.buttonStatus} className={`btn btn-primary ${props.classvalue}`} onClick={() => redirectTo(props.pagename)}> Next </button>
    </div>
  );
};
export default NextButton;

import { useState } from "react";
import insta_tabs_switcher from "../../images/icons/social_icons/insta_tabs_switcher.svg";
import meta_tabs_switcher from "../../images/icons/social_icons/meta_tabs_switcher.svg";
import other_tabs_switcher from "../../images/icons/social_icons/other_tabs_switcher.svg";
import sampler from "../../images/icons/social_icons/sampler.svg";

export const ImagesTabsSwitch = () => {
    const [activeTab, setActiveTab] = useState(1);

    const handleTabClick = (value) => {
        setActiveTab(value)
    };

    return (
        <>
            <div className="tabs-styling image-tab-switcher">
                <div className="tab-header">
                    <div
                        className={`tab-item ${activeTab === 1 ? 'active' : ''}`}
                        onClick={() => handleTabClick(1)}
                    >
                        <img alt="insta" src={insta_tabs_switcher} />
                    </div>
                    <div
                        className={`tab-item ${activeTab === 2 ? 'active' : ''}`}
                        onClick={() => handleTabClick(2)}
                    >
                        <img alt="insta" src={meta_tabs_switcher} />
                    </div>
                    <div
                        className={`tab-item ${activeTab === 3 ? 'active' : ''}`}
                        onClick={() => handleTabClick(3)}
                    >
                        <img alt="insta" src={other_tabs_switcher} />
                    </div>
                </div>

                <div className="tab-content">
                    {activeTab === 1 && <div className="content-style mt-40"><h4 className="text-white text-center">Sampler 1</h4><img alt="insta" src={sampler} /></div>}
                    {activeTab === 2 && <div className="content-style mt-40"><h4 className="text-white text-center">Sampler 2</h4><img alt="insta" src={sampler} /></div>}
                    {activeTab === 3 && <div className="content-style mt-40"><h4 className="text-white text-center">Sampler 3</h4><img alt="insta" src={sampler} /></div>}
                </div>
            </div>
        </>
    )
};
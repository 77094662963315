import React, { useState, useEffect } from "react";
import { PAYMENT_SUCCESS_MSG } from "../../../utils/constants";
import SpinnerLoader from "../../common/SpinnerLoader";
import { userService } from "../../../services/user.service";
import { fbEventsService } from "../../../services/fbevents.service";
import {
  PAYPAL_MONTHLY_TRIAL_PRICE,
  QUICK_FREE_TRIAL,
  DEFAULT_USER_MONTHLY_PAYMENT_PLAN,
  DEFAULT_USER_YEARLY_PAYMENT_PLAN, MONTHLY_DISCOUNTED_PRICE, YEARLY_DISCOUNTED_PRICE
} from "../../../utils/constants";
import NewSubscriberCarousal from "../common/NewSubscriberCarousal";
import CheckoutFreetrialQuickMonthlyUltraDiscounted from "../../common/StripePayment/CheckoutFreetrialQuickMonthlyUltraDiscounted";


const AdflowNewSubscribe50offSubpage = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);
  const [planSwitchToggle, setPlanSwitchToggle] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    plan: "Yearly",
    name: "Year",
    month: MONTHLY_DISCOUNTED_PRICE,
    year: YEARLY_DISCOUNTED_PRICE,
  });
  useEffect(() => {
    if (!planSwitchToggle) {
      setSelectedPlan({
        plan: "Yearly",
        name: "Year",
        month: MONTHLY_DISCOUNTED_PRICE,
        year: YEARLY_DISCOUNTED_PRICE,
      });
      userService.setKeyValueLocalstorage(
        "defaultUserSelectedPlan",
        DEFAULT_USER_YEARLY_PAYMENT_PLAN
      );
    } else {
      setSelectedPlan({
        plan: "Monthly",
        name: "Month",
        month: MONTHLY_DISCOUNTED_PRICE,
        year: YEARLY_DISCOUNTED_PRICE,
      });

      userService.setKeyValueLocalstorage(
        "defaultUserSelectedPlan",
        DEFAULT_USER_MONTHLY_PAYMENT_PLAN
      );
    }
  }, [planSwitchToggle]);

  useEffect(() => {
    userService.setKeyValueLocalstorage(
      "defaultUserSelectedPlan",
      DEFAULT_USER_YEARLY_PAYMENT_PLAN
    );
    fbEventsService.sendFbEvents(
      QUICK_FREE_TRIAL,
      process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME,
      PAYPAL_MONTHLY_TRIAL_PRICE
    );
    userService.removeKeyLocalstorage("quickFreeTrial");
  }, []);

  //document.title = getWebsiteTitle() + " Free Trial";
  return (
    <div className="">
      <div className="col-md-12 col-sm-offset-0">
        <div className="group-free-trail-parent">
          <div className="frame-free-trail-parent">
            <div className="free-trail-frame-group">
              <div className="free-trail-welcome-to-adspire-wrapper">
                <div className="free-trail-welcome-to-adspire-container">
                  <span className="free-trail-welcome-to- sub-to-gen">
                    <span>Subscribe to Generate</span>
                    {/* <span className="span">{`Generate`}</span> */}
                  </span>
                  <div>
                    <span className="unlmtd-pow-ads">
                      UNLIMITED Powerful Ads!
                    </span>
                  </div>
                  {/* <span>Generate <span className="unlmtd-pow-ads">UNLIMITED</span> ads that actually convert!</span> */}

                  {/* <span className="span">
                    <b className="free-trail-adspire2 cursor-pointer" onClick={() => redirectTo('')}>Adspire®</b>
                  </span> */}
                  {paymentProccessStart && (
                    <div>
                      <SpinnerLoader></SpinnerLoader>
                      <p className="text-center-normal-mar30">
                        <b>{PAYMENT_SUCCESS_MSG}</b>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="buttons-container flex flex-column align-items-center position-relative z-index-1">
              <CheckoutFreetrialQuickMonthlyUltraDiscounted
                btnTheme="light"
                buttonName={MONTHLY_DISCOUNTED_PRICE}
                componentName="StyledButtonMonth"
                buttonType="button"
                setPaymentProccessStart={setPaymentProccessStart}
              ></CheckoutFreetrialQuickMonthlyUltraDiscounted>

              <div className="new-sub-second-btn-con">
                <CheckoutFreetrialQuickMonthlyUltraDiscounted
                  btnHeight={"70px"}
                  buttonName={YEARLY_DISCOUNTED_PRICE}
                  btnTextStyle={"gradient-text font-family-sans-serif"}
                  componentName="StyledButtonYear"
                  buttonType="button"
                  setPaymentProccessStart={setPaymentProccessStart}
                ></CheckoutFreetrialQuickMonthlyUltraDiscounted>
              </div>
            </div>

            <div className="new-subscriber-carousal-container">
              <NewSubscriberCarousal />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdflowNewSubscribe50offSubpage;

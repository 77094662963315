import React,{useState} from 'react'

const Formgroup = ({ fields }) => {
    const initialVisibleFields = 3;

    const [visibleFields, setVisibleFields] = useState(initialVisibleFields);
    const handleShowMore = () => {
        setVisibleFields((prevVisibleFields) => prevVisibleFields + 2); // Increase by 2 when "Show More" is clicked
      };
    
    return (
        <>
            {fields?.slice(0, visibleFields).map((field, index) => (
                <div  key={index}>
                    <div className={fields.length > 2 ? "quest-modal text":"text-left font-20-regular text-weight-600"}>{field.constant} 
                   {fields.length > 2 &&  <span>  (optional)</span>} 
                    </div>
                    {field.errorMessage && (
                        <div
                            className="text-left error-message"
                            style={{
                                color: "#e8e8d9",
                                marginBottom: "10px",
                                fontSize: "16px",
                            }}
                        >
                            {field.errorMessage}
                        </div>
                    )}
                    <input
                        type="text"
                        value={field.value}
                        
                        className="user-product-name"
                        onChange={(e) => field.handleChange(e, index)}
                        placeholder={`${field.placeholder}`}
                    /> 
                </div>
                
            ))}
             {visibleFields < fields.length && (
                <div className='text-center'> <button className='btn btn-secondary specimore cursor-pointer' onClick={handleShowMore}>Specify More</button></div>   
                  )}
        </>
    )
}

export default Formgroup

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { colors } from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import ConceptBackButton from "../../common/Button/ConceptBackButton";
import ConceptInput from "../../common/Button/ConceptInput";
import ConceptStep from "../../common/ConceptStep";
import SpinnerLoader from "../../common/SpinnerLoader";
import ProductCaptionModal from "../../common/popup/ProductCaptionModal";

const ConceptAdOptions = (props) => {
  const [conceptAdOptions, setConceptAdOptions] = useState("");
  const [conceptAdOption, setConceptAdOption] = useState(null);
  const [loading, setLoader] = useState(false);
  const [modaltextVariable, setModaltextVariable] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");

  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("quickIdeaGenerator")
      .collection("adGeneration")
      .doc("productTypes")
      .collection("types")
      .doc(userService.getValueFromLocalstorage("conceptAdProductObj").id)
      .collection("options")
      .orderBy("order")

      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setConceptAdOptions(dataReceived);
        setLoader(true);
      });
  }, []);

  document.title = getWebsiteTitle() + "Concept Ad Options";

  const setUserSelectedConceptAdOptions = (optionsObj) => {
    setButtonStatus(false);
    userService.setKeyValueLocalstorage("concetAdOptionsObj", optionsObj);
    setClassvalue("generateAd");
    setConceptAdOption(optionsObj.data.name);
  };

  const handleChange = (e) => {
    userService.setKeyValueLocalstorage("customeAdOptionInputClicked", "yes");
    userService.setKeyValueLocalstorage("conceptAdInput", e.target.value);
  };

  const getBackPageName = () => {
    return "concept-ad";
  };

  const handleGoto = () => {
    navigate("/concept-ad-type");
  };
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className={`col-sm-12 col-xs-12 flex content-center`}>
                  {userService.getValueFromLocalstorage("questionFlow") !=
                    "yes" && <ConceptStep step="2"></ConceptStep>}
                </div>
                <div className="flex content-center align-items-center text-white p-30">
                  <ConceptBackButton
                    className={"mr-20 cursor-pointer"}
                    alt="Back"
                    redirectPage={getBackPageName()}
                  />

                  <h1 className="gradiend-title-text m-0">
                    What{" "}
                    {
                      userService.getValueFromLocalstorage(
                        "conceptAdProductObj"
                      )?.data?.name
                    }{" "}
                    do you sell?
                  </h1>
                </div>
                <div className="clearfix"></div>
                <div className={`col-sm-12 flex content-center`}>
                  <div className="col-sm-3">
                    <ConceptInput className="form-group col-5"></ConceptInput>
                  </div>
                </div>
                <div className="col-sm-12 flex content-center">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (
                    <div className="col-sm-6 mb-20 mt-20">
                      {conceptAdOptions &&
                        conceptAdOptions.map((projectsItems, index) => (
                          <div
                            className={`col-sm-6 p-5 col-xs-12`}
                            key={projectsItems.data.name}
                            title={projectsItems.data.name}
                            onClick={() => {
                              setUserSelectedConceptAdOptions(projectsItems);
                              setModaltextVariable(projectsItems.data.name);
                              setShowModal((prev) => !prev);
                            }}
                          >
                            <div
                              className={`thumbnail-category text-size-16 h-60 ${
                                conceptAdOption == projectsItems.data.name &&
                                "box-active"
                              }`}
                              style={{
                                background: colors[index],
                              }}
                            >
                              <div className="select-boxes">
                                <span className="ad-title">
                                  {" "}
                                  {projectsItems.data.name}
                                </span>
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {loading && (
                    <>
                      {/* NOT REQUIRED, PLS DO NOT OPEN KISHORE <NoSpecificTypeButton></NoSpecificTypeButton>*/}
                      {/* <GenerateAdIdeasButton
                        classvalue={classvalue}
                        buttonStatus={buttonStatus}
                        pagename="ad-ideas"
                      ></GenerateAdIdeasButton> */}
                      {/*<BackButton pagename="description"></BackButton> */}
                    </>
                  )}
                </div>
              </div>
              {/*<div className="form-group text-center">
                <p> <button type="btn" className="btn btn-primary white-border" onClick={() => unsetUserSelectedConceptAdOptions()}>No specific type</button></p>
              </div>*/}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      {showModal && (
        <ProductCaptionModal
          handleChange={handleChange}
          showModal={showModal}
          setShowModal={setShowModal}
          handleSubmit={handleGoto}
          showedText={modaltextVariable}
        />
      )}
    </React.Fragment>
  );
};
export default ConceptAdOptions;

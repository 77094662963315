import React, { useEffect, useState } from "react";
import {
  default as free_plan,
  default as lite_plan,
  default as pro_plan,
  default as ultra_plan,
} from "../../../../images/free_trail_page_assests/lite_plan.svg";
import download from "../../../../images/icons/download-black.png";
import { userService } from "../../../../services/user.service";
import { db } from "../../../../utils/Firebase";
import { getWebsiteTitle, isTrialPeriodEnd } from "../../../../utils/function";

import { CSVLink } from "react-csv";
import {
  formatDate,
  getAmountInfo,
  getPlans,
  getPlanName,
} from "../../../../utils/function";

const PaymentHistory = () => {
  // ******************** Other Methods ***************************************s
  document.title = getWebsiteTitle() + " Payment Success";
  const headers = [
    { label: "Order Id", key: "order_id" },
    { label: "Amount", key: "amount" },
    { label: "Plan", key: "plan" },
    { label: "Purchase Date", key: "purchase_date" },
    { label: "Status", key: "status" },
  ];

  // ******************** Hooks ***************************************
  const [planName, setPlanName] = useState("");
  const [paymentHistoryData, setPaymentHistory] = useState("");

  // ******************** First Time Rendrer ***************************************
  useEffect(() => {
    if (userService.getValueFromLocalstorage("PaymentInfo").plan === "Lite") {
      setPlanName(lite_plan);
    } else if (
      userService.getValueFromLocalstorage("PaymentInfo").plan === "Pro"
    ) {
      setPlanName(pro_plan);
    } else if (
      userService.getValueFromLocalstorage("PaymentInfo").plan === "Ultra"
    ) {
      setPlanName(ultra_plan);
    } else if (
      userService.getValueFromLocalstorage("PaymentInfo").plan ===
      "Free Trial Yearly"
    ) {
      setPlanName(free_plan);
    }
  }, [userService.getValueFromLocalstorage("PaymentInfo").plan]);

  useEffect(() => {
    const userId = userService.getUserId();
    if (userId) {
      db.collection("purchases")
        .where("userId", "==", userId)
        .where("type", "==", "SUBSCRIPTION")
        .orderBy("purchaseDate", "desc")
        .onSnapshot((snapshot) => {
          const datas = snapshot.docs.map((doc) => ({
            id: doc.id,
            product_id: doc.data().productId,
            amount: "$ " + doc.data().amount,
            planName: getPlans(doc.data().priceId, doc.data()),
            order_id: doc.data().orderId,
            purchase_date: formatDate(doc.data()?.purchaseDate),
            expiryDate: formatDate(doc.data()?.expiryDate),
            status: doc.data().status,
            isTrialPeriodEnd: isTrialPeriodEnd(doc.data()?.purchaseDate, doc.data()?.expiryDate)
          }));
          setPaymentHistory(datas);
        });
    }
  }, [userService.getUserId()]);

  return (
    <div className="row nav-margin-top">
      <span className="span">
        <b className="payment-history-adspire2">Transactions </b>
      </span>
      <div className="col-md-12 px-30 ">
        <div className="group-free-trail-parent ">
          <div className="frame-free-trail-parent ">
            <div className="row">
              {" "}
              <div className="col-sm-6  col-xs-10 ">
                {" "}
                <div className="payemt_history">Payment History</div>
                <p className="subTitle">
                  See history of your payment plan invoice
                </p>
              </div>
              <div className="col-sm-6  col-xs-12 mb-20 text-right">
                <button className="btn  btn-primary generateAd autowidth">
                  <CSVLink
                    filename={"adspire_subscription_data.csv"}
                    data={paymentHistoryData}
                    headers={headers}
                    className="cls_blk"
                  >
                    <img src={download} alt=""></img> Download All
                  </CSVLink>
                </button>
              </div>
            </div>
            <div className="payment-history-frame-container text-left ">
              <div className="clearfix"></div>
              <table className="table table-payment-history ">
                <thead>
                  <tr className="payment-history-head-tr ">
                    <th scope="col">Order Id</th>
                    <th scope="col">Amount </th>
                    <th scope="col">Plan</th>
                    <th scope="col">Date</th>
                    <th scope="col">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {paymentHistoryData.length > 0 &&
                    paymentHistoryData &&
                    paymentHistoryData.map((paymentHistoryItem) => (
                      <tr key={paymentHistoryItem.order_id}>
                        <th scope="row">{paymentHistoryItem.order_id}</th>
                        <td>{paymentHistoryItem.isTrialPeriodEnd === "no" && ("$ 0.00")}

                          {paymentHistoryItem.isTrialPeriodEnd === "yes" && (paymentHistoryItem.amount)}</td>
                        <td>{paymentHistoryItem.planName}</td>
                        <td>{paymentHistoryItem.purchase_date}</td>

                        <td>{paymentHistoryItem.status}</td>
                        {/*<td><a href="#" className="download-btn"> <img src={downloadWhite} alt=""></img></a></td>*/}
                      </tr>
                    ))}
                  {paymentHistoryData.length === 0 && (
                    <tr>
                      <td colSpan={5}>
                        <div className="alert alert-danger" role="alert">
                          There is no record found for Your Payment History.
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentHistory;

import React from "react";
import new_add_circle_plus from "../../../../images/icons/add-new.svg";
import payment_success from "../../../../images/payment_success_assests/payment_success.svg";
import { userService } from "../../../../services/user.service";
import "../PaypalSubscription/PaymentSuccess.css";


const PaymentSuccessNew = () => {


  return (
    <div className="row">
      <div className="payment-success">
        <div className="free-trail-frame-container">
          <div className="col-sm-12 col-xs-12">
            <div className="mt-70 text-center">

              <b className="try-out-the-container">
                <span className="payment-success-welcome-to white-text mar-20 font-60"> Welcome to  </span> <span className="payment-success-adspire-text font-60">Adspire® {userService.getValueFromLocalstorage("PaymentInfo").plan}! </span>
                <p className="pclass text-center font-21-regular"> You can now start creating your <span className="text-weight-600"><i>stunning, powerful ads!</i></span></p>
              </b>
              <div className="margin-tb15-lr-10" title="New Ad">
                <button
                  type="button"
                  className="btn btn-primary generateAd w-200 h-65">
                  <img src={new_add_circle_plus} alt="" className="" /> <span className="home-modal-btn-text">New Ad</span>
                </button>
              </div>
              <div className="text-center col-sm-12 mt-70"><img alt="" src={payment_success} /></div>
            </div>
          </div>
        </div>

      </div>
    </div>

  );
};

export default PaymentSuccessNew;

import React, { useState, useEffect } from "react";
import {
  PAYPAL_MONTHLY_TRIAL_PRICE,
  QUICK_FREE_TRIAL,
  MONTHLY_TRIAL_PRICE,
  DEFAULT_USER_MONTHLY_PAYMENT_PLAN,
  YEARLY_TRIAL_PRICE,
  DEFAULT_USER_YEARLY_PAYMENT_PLAN,
} from "../../utils/constants";
import { userService } from "../../services/user.service";
import { fbEventsService } from "../../services/fbevents.service";
import NewSubscriberCarousal from "../landingpage/common/NewSubscriberCarousal";
import ButtonGradient from "../common/Button/ButtonGradient";
import useWindowSize from "../../_helper/ScreenUtil";
import { planCardsExtraObj, planCardsObj } from "./SubscribeConstants";
import { useNavigate } from "react-router-dom";
import StripeCheckoutRedirect from "../../utils/StripeCheckoutRedirect";

const NewSubscribe = () => {
  const navigate = useNavigate();
  // **************************** Hooks *************************************
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const isTab = width <= 1500
  const [planSwitchToggle, setPlanSwitchToggle] = useState(false);
  const [isModalOpen, setModalOpen] = useState(false);
  const [paymentwarning, setPaymentwarning] = useState();
  const [paymentStatus, setPaymentStatus] = useState("PAID");

  const [selectedPlan, setSelectedPlan] = useState({
    plan: "Yearly",
    name: "Year",
    month: MONTHLY_TRIAL_PRICE,
    year: YEARLY_TRIAL_PRICE,
  });

  // **************************** First time Rederer *************************************
  useEffect(() => {
    if (!planSwitchToggle) {
      setSelectedPlan({
        plan: "Yearly",
        name: "Year",
        month: MONTHLY_TRIAL_PRICE,
        year: YEARLY_TRIAL_PRICE,
      });
      userService.setKeyValueLocalstorage(
        "defaultUserSelectedPlan",
        DEFAULT_USER_YEARLY_PAYMENT_PLAN
      );
    } else {
      setSelectedPlan({
        plan: "Monthly",
        name: "Month",
        month: MONTHLY_TRIAL_PRICE,
        year: YEARLY_TRIAL_PRICE,
      });

      userService.setKeyValueLocalstorage(
        "defaultUserSelectedPlan",
        DEFAULT_USER_MONTHLY_PAYMENT_PLAN
      );
    }
  }, [planSwitchToggle]);

  useEffect(() => {
    setPaymentwarning(userService.downloadLeftImage());
  }, [userService.getValueFromLocalstorage("userSettings")]);

  useEffect(() => {
    if (userService.getValueFromLocalstorage("paymentStatus") === "EXPIRED") {
      setPaymentStatus("EXPIRED");
    }
  }, [userService.getValueFromLocalstorage("paymentStatus")]);

  useEffect(() => {
    setModalOpen(true);
    userService.setKeyValueLocalstorage(
      "defaultUserSelectedPlan",
      DEFAULT_USER_YEARLY_PAYMENT_PLAN
    );
    fbEventsService.sendFbEvents(
      QUICK_FREE_TRIAL,
      process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME,
      PAYPAL_MONTHLY_TRIAL_PRICE
    );
    userService.removeKeyLocalstorage("quickFreeTrial");
  }, []);

  async function handleCheckout(planInfos) {
    userService.setKeyValueLocalstorage("userSelectedPlan", planInfos.priceId);
    userService.setKeyValueLocalstorage(
      "clientReferenceId",
      planInfos.planName + "||" + userService.getUserId()
    );
    if (!userService.showLogoutBtn()) {
      navigate("/signup");
    } else {
      StripeCheckoutRedirect();
    }
  }

  return (
    <>
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 col-lg-12 col-12">
            <h2 className="text-size-50 text-white text-center font-family-vietnam-pro">
              {planCardsExtraObj?.title}
            </h2>
            <div className={isMobile ? "" : "flex content-center"}>
              <ButtonGradient
                title={planCardsExtraObj?.monthly}
                btnstyle={
                  planSwitchToggle
                    ? {
                      background: "white",
                      color: "black",
                      boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.50)",
                    }
                    : {
                      background: "black",
                      color: "white",
                      boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.50)",
                    }
                }
                btnstylebyclass={
                  isMobile
                    ? `width-100-percent p-5 m-15`
                    : isTab ? 'width-30-percent p-5 m-15' : `width-15-percent p-5 m-15`
                }
                stylebyclass="text-size-20 p-8 text-weight-600"
                onClick={() => {
                  setPlanSwitchToggle(false);
                }}
              />
              <ButtonGradient
                title={planCardsExtraObj?.yearly}
                btnstyle={
                  planSwitchToggle
                    ? {
                      background: "black",
                      color: "white",
                      boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.50)",
                    }
                    : {
                      background: "#A4FFE4",
                      color: "#025f04",
                      boxShadow: "0px 0px 25px 0px rgba(0, 0, 0, 0.50)",
                    }
                }
                btnstylebyclass={
                  isMobile
                    ? `width-100-percent p-5 m-15`
                    : isTab ? 'width-30-percent p-5 m-15' : `width-15-percent p-5 m-15`
                }
                stylebyclass="text-size-20 p-8 text-weight-600"
                onClick={() => {
                  setPlanSwitchToggle(true);
                }}
              />
            </div>

            <div
              className={
                isMobile
                  ? "mt-40"
                  : "flex content-center align-items-center mt-40"
              }
            >
              {!planSwitchToggle &&
                planCardsObj?.monthly?.map((monthlyPlanInfo, index) => {
                  return (
                    <div className="text-center" key={"monthly " + index}>
                      <div
                        className="subscribe-monthly-card m-15"
                        key={index}
                        style={monthlyPlanInfo?.cardStyle}
                      >
                        <h4
                          className={`text-weight-700 text-size-28 ${index === 0
                            ? "gradient-text-purple"
                            : index === 2
                              ? "gradient-text-sky-blue"
                              : ""
                            }`}
                        >
                          {monthlyPlanInfo?.title}
                        </h4>
                        <h6
                          className={`text-size-20 ${index === 0
                            ? "gradient-text-purple"
                            : index === 2
                              ? "gradient-text-sky-blue"
                              : ""
                            }`}
                        >
                          {monthlyPlanInfo?.plan}
                        </h6>
                        <ButtonGradient
                          title="Continue"
                          btnstyle={monthlyPlanInfo?.btnStyle}
                          btnstylebyclass="width-90-percent p-5 m-10"
                          stylebyclass={monthlyPlanInfo?.stylebyclass}
                          onClick={() => {
                            handleCheckout(monthlyPlanInfo);
                          }}
                        />
                      </div>
                      <p style={monthlyPlanInfo?.planTypeStyle}>
                        <i>{monthlyPlanInfo?.planType}</i>
                      </p>
                    </div>
                  );
                })}

              {planSwitchToggle &&
                planCardsObj?.yearly?.map((yearlyPlanInfo, index) => {
                  return (
                    <div className="text-center" key={"Year " + index}>
                      <div
                        className="subscribe-monthly-card m-15"
                        key={index}
                        style={yearlyPlanInfo?.cardStyle}
                      >
                        <h4
                          className={`text-weight-700 text-size-28 ${index === 0
                            ? "gradient-text-purple"
                            : index === 2
                              ? "gradient-text-sky-blue"
                              : ""
                            }`}
                        >
                          {yearlyPlanInfo?.title}
                        </h4>
                        <h6
                          className={`text-size-20 ${index === 0
                            ? "gradient-text-purple"
                            : index === 2
                              ? "gradient-text-sky-blue"
                              : ""
                            }`}
                        >
                          {yearlyPlanInfo?.plan}
                        </h6>
                        <ButtonGradient
                          title="Continue"
                          btnstyle={yearlyPlanInfo?.btnStyle}
                          btnstylebyclass="width-90-percent p-5 m-10"
                          stylebyclass={yearlyPlanInfo?.stylebyclass}
                          onClick={() => {
                            handleCheckout(yearlyPlanInfo);
                          }}
                        />
                      </div>
                      <p style={yearlyPlanInfo?.planTypeStyle}>
                        <i>{yearlyPlanInfo?.planType}</i>
                      </p>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="new-subscriber-carousal-container">
            <NewSubscriberCarousal />
          </div>
        </div>
      </div>

      {/* ************************ Modal **************************************** */}
      {paymentwarning === 0 && (
        <div
          className={`modal modalwithmenu overflows-auto bg-dark-opacity-10 ${isModalOpen ? "show" : ""
            }`}
          style={{
            display: isModalOpen ? "block" : "none",
            marginLeft: isMobile ? "" : "180px",
          }}
        >
          <div
            className="modal-dialog modal-600 modal-350-mobile modal-dialog-centered font-family-vietnam-pro top-20-percent"
            role="document"
          >
            <div className="modal-content modal-gradient-new">
              {/* <div className="modal-header mr-20">
              <button
                type="button"
                onClick={() => redirectTo("quick-ad")}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div> */}
              <div className="modal-body text-center p-60">
                <div className="">
                  <div className="">
                    <h1 className="text-white text-size-30 m-0">
                      <span className="text-weight-600">Upgrade</span> to
                      download more ads!
                    </h1>
                  </div>
                  <div className="text-center mt-20">
                    <ButtonGradient
                      title="Dismiss"
                      stylebyclass="gradient-head-text text-size-24 text-black"
                      btnstyle={{
                        background: "white",
                        height: "40px",
                        padding: "3px",
                      }}
                      btnstylebyclass="width-80-percent m-left-right-auto mt-10 mb-10"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    />
                  </div>
                  <p>
                    {userService.getValueFromLocalstorage(
                      "planInfoPerDuration"
                    )}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {paymentStatus === "EXPIRED" && (
        <div
          className={`modal  overflows-auto ${isModalOpen ? "show" : ""
            }`}
          style={{
            display: isModalOpen ? "block" : "none",
            marginLeft: isMobile ? "" : "180px",
          }}
        >
          <div
            className="modal-dialog  modal-600 modal-350-mobile modal-dialog-centered font-family-vietnam-pro top-20-percent"
            role="document"
          >
            <div className="modal-content modal-gradient-new">
              <div className="modal-body text-center p-60">
                <div className="">
                  <div className="">
                    <h1 className="text-white text-size-30 m-0">
                      <span className="text-weight-600 ">Subscribe</span>
                      <span> to continue generating profitable ads with Adspire® AI!
                      </span>
                    </h1>
                  </div>
                  <div className="text-center mt-20">
                    <ButtonGradient
                      title="Okay"
                      stylebyclass="gradient-head-text text-size-24 text-black"
                      btnstyle={{
                        background: "white",
                        height: "40px",
                        padding: "3px",
                      }}
                      btnstylebyclass="width-80-percent m-left-right-auto mt-10 mb-10"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    />
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      )}


    </>
  );
};

export default NewSubscribe;

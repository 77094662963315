import { userService } from "../../../services/user.service";
import StripeCheckoutRedirect from "../../../utils/StripeCheckoutRedirect";

export default function CheckoutMonthlyUltra() {
  async function handleCheckout() {
    userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_PRICE_ID);
    userService.setKeyValueLocalstorage("clientReferenceId", process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_PLAN_NAME + "||" + userService.getUserId());
    StripeCheckoutRedirect();
  }

  return <>
    <button className="white-button-for-gradient" onClick={handleCheckout}>
      <span className="button-inner-text-gradient">Subscribe</span>
    </button>
  </>;
}
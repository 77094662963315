import React from "react";
import { userService } from "../../../../services/user.service";
import {
  PAYPAL_ENVIRONMENT,
  PAYPAL_PAYMENT_STATUS,
  PAYPAL_YEARLY_TRIAL_PLAN_ID, PAYPAL_YEARLY_TRIAL_PLAN_NAME,
  PAYPAL_YEARLY_TRIAL_PRICE,
  PLAN_TYPE_FREE_TRIAL,
} from "../../../../utils/constants";
import PayPalBtn from "../../../common/PaymentGateway/PaypalBtnSilver";

const PlanFreeTierUltra = (props) => {

  const paypalSubscribeUltra = (data, actions) => {
    props.setPaymentProccessStart(true);
    return actions.subscription.create({
      plan_id: PAYPAL_YEARLY_TRIAL_PLAN_ID,
    });
  };


  const paypalOnError = async (err) => {
    props.setPaymentProccessStart(false);
  };

  const paypalOnApproveUltra = async (data, detail) => {
    // call the backend api to store transaction details

    const userId = userService.getUserId();
    const dataToSave = {
      user_id: userId,
      environment: PAYPAL_ENVIRONMENT,
      product_id: PAYPAL_YEARLY_TRIAL_PLAN_NAME,
      subscription_id: data.subscriptionID,
      order_id: data.orderID,
      purchase_date: new Date(),
      status: PAYPAL_PAYMENT_STATUS,
      amount: PAYPAL_YEARLY_TRIAL_PRICE,
      plan: PLAN_TYPE_FREE_TRIAL
    };

  };
  return (
    <PayPalBtn
      amount="1"
      currency="USD"
      createSubscription={paypalSubscribeUltra}
      onApprove={paypalOnApproveUltra}
      catchError={paypalOnError}
      onError={paypalOnError}
      onCancel={paypalOnError}
    />
  );
};
export default PlanFreeTierUltra;

export const message={
  "captions1":"“Make a statement on the street - buy your own custom skateboard today!”",
  "captions2":"“Come to our restaurant for a truly unique burger experience!”",
  "captions3":"“Luxury and style come together with our designer wallets.”",
  "ad_ideas1":"An image of a person unzipping a luxurious leather wallet and revealing a pile of hundred dollar bills inside",
  
  "ad_ideas2":"A person holding a luxurious wallet with a sunset in the background, looking out over a city skyline.",
  
  "ad_ideas3":"A person with a luxurious wallet in each hand, standing in front of an open door with a bright light shining behind them.",
}

export const metaDesc={
  LandingPageDescription:"Supercharge your marketing efforts with AI Ads. Harness the potential of generative AI for impactful campaigns. Explore endless ad ideas and conquer the world of AI marketing.",
  AIADS:"Discover the power of AI Ads and generative AI in revolutionizing marketing. Unleash innovative ad ideas and boost your campaigns with AI-driven strategies."
}
import React, { useEffect, useState } from "react";
import circleCheck from "../../../images/icons/circle-check.svg";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { getWebsiteTitle } from "../../../utils/function";
import SpinnerLoader from "../../common/SpinnerLoader";


const LandingAdType = (props) => {
  const [adStyles, setStyles] = useState("");
  const [active, setActive] = useState(null)
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("types")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setStyles(dataReceived);
        setLoader(true);
      });

    // Getting prompt value from here 
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage("prompt", snapshot.data().prompt);
        userService.setKeyValueLocalstorage("maxIdeas", snapshot.data().maxIdeas);
        userService.setKeyValueLocalstorage("model", snapshot.data().model);
        userService.setKeyValueLocalstorage("temperature", snapshot.data().temperature);
        userService.setKeyValueLocalstorage("maxTokens", snapshot.data().maxTokens);
      });

    // Getting prompt value for image  
    db.collection("adIdeas")
      .doc("imageGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage("promptImage", snapshot.data().prompt);
        userService.setKeyValueLocalstorage("size", snapshot.data().size);
        userService.setKeyValueLocalstorage("maxImages", snapshot.data().maxImages);
      });
  }, []);

  document.title = getWebsiteTitle() + "Ad Type";

  const setTypeOfAdValue = (typeObj) => {
    setButtonStatus(false)
    userService.setKeyValueLocalstorage("typeIDOfAdValue", typeObj.id);
    userService.setKeyValueLocalstorage("typeValue", typeObj.data.name);
    setClassvalue("generateAd")
    setActive(typeObj.data.name)
    // redirectTo();
  };

  return (
    <React.Fragment>
    
                {/*<h1 className="text-center">What type of ad do you want?</h1>*/}

                <div className="what-do-you-want-an-ad-for-wrapper">
                  <b className="what-do-you">What type of ad do you want?</b>
                </div>
                <div className="clearfix"></div>
                <div className="col-sm-8">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (

                    <div className="row mb-20 mt-20 ">
                      {adStyles &&
                        adStyles.map((projectsItems) => (
                          <div
                            className={`col-sm-6 col-xs-12`}
                            key={projectsItems.data.name}
                            onClick={() => setTypeOfAdValue(projectsItems)}
                          >
                            <div
                              className={`thumbnailBG text-size-16 h-60 ${active == projectsItems.data.name && 'box-active'}`}
                              style={{
                                backgroundColor: projectsItems.data.backgroundColor,
                              }}
                            >
                              <div className="select-boxes btn-text-left">
                                <img
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="img-thumbnail"
                                  width={60}
                                  alt={projectsItems.data.name}
                                  src={projectsItems.data.imageURL}
                                />
                                <span className="ad-title">
                                  {" "}
                                 {projectsItems.data.name}
                                </span>
                                {active == projectsItems.data.name ?
                                  < img
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    alt={projectsItems.data.name}
                                    width={20}
                                    src={circleCheck}
                                    className="w-20 float-r"
                                  />
                                  :
                                  ""}
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {
                    loading &&
                    (
                      <>
                      <div className="form-group text-center" title="Generate Ad Ideas">
      <button
        type="submit"
        disabled={buttonStatus}
        className={`btn btn-primary ${classvalue}`}
        onClick={() => props.currentPage('ad-ideas')}
      >
        Generate Ad Ideas
      </button>
      
    </div>

                        
                       
                      </>
                    )
                  }
                </div>
    </React.Fragment>
  );
};
export default LandingAdType;

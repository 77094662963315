import React, { useState, useEffect } from "react";
const EffectiveAds = (props) => {
  return (
    <div className="heading-and-body-text position-relative z-index-1">
        <div className="generate-incredible-parent">
          <div className="generate-incredible">
            {`Generate `}
            <span className="incredible cursor-pointer">incredible,</span>
          </div>
          <div className="effective-ads">effective ads</div>
          <div className="in-seconds">in seconds!</div>
        </div>
        <div className="the-worlds-most-powerful-ai-t-parent">
          <div className="the-worlds-most">{`The world's most powerful AI that inspires & generates ads for your businesses in seconds!`}</div>
          <div className="next-wrapper2">
            <b className="what-do-you">Get started</b>
          </div>
        </div>
      </div>
  );
};
export default EffectiveAds;
 
 
 
 
 
 
 
 
 
 
 
 
 
 
 

import { userService } from "../../../services/user.service";
import StripeCheckoutRedirect from "../../../utils/StripeCheckoutRedirect";

export default function CheckoutFreetrialYearlyUltra() {
  async function handleCheckout() {  
    userService.setKeyValueLocalstorage("userSelectedPlan",process.env.REACT_APP_STRIPE_YEARLY_ULTRA_TRIAL_PRICE_ID);
    userService.setKeyValueLocalstorage("clientReferenceId",process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_TRIAL_PLAN_NAME+"||"+userService.getUserId());

    if(!userService.showLogoutBtn()){
      window.location.href="/signup"
    }else{
      StripeCheckoutRedirect();
  }
}

  return <><p className="p0" id="ploginModal" data-toggle="modal" data-target="#loginModal"></p><div className="free-trail-next-wrapper" onClick={handleCheckout}>
                    <button className="continue-btn-free-trail">Continue</button>
                  </div></>;
}
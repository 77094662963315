import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import NavbarPrivate from "./components/common/NavbarPrivate";
import AdButtonModal from "./components/common/popup/AdButtonModal";
import GeneratedImageAdModal from "./components/common/popup/GeneratedImageAdModal";
import HomeButtonModal from "./components/common/popup/HomeButtonModal";
import SubscribeButtonModal from "./components/common/popup/SubscribeButtonModal";
import SubscribeLiteButtonModal from "./components/common/popup/SubscribeLiteButtonModal";
import SubscribeProButtonModal from "./components/common/popup/SubscribeProButtonModal";
import { updateDownloadCountRedux, userService } from "./services/user.service";
import { utmService } from "./services/utm.service";
import { auth } from "./utils/Firebase";
import { isIOSDevice } from "ios-detector";
import ReactPixel from "react-facebook-pixel";
import { paymentService } from "./services/payment.service";
import { PublicRoutes, PrivateRoutes } from "./components/routingServices";
import { addAffliateLog, fetchRemoteConfig } from "./utils/Firebase.collection.helper";
import { useDispatch, useSelector } from "react-redux";
import { containsAffiliate, getUserDocInfo } from "./utils/function";
import WarningUsageWebsite from "./components/landingpage/common/WarningUsageWebsite";
import AdsCountModalButton from "./components/common/popup/AdsCountModalButton";

const App = () => {
  const [pathname, setPathName] = useState("");
  const [utmParamsFlag, setUTMParamsFlag] = useState(false);
  const [showSettingMenu, setShowSettingMenu] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [newPathName, setNewPathName] = useState("");
  const [downloadLeft, setDownloadLeft] = useState("");
  const reduxDownloadLeft = useSelector((state) => state.downloadLeft);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    ReactPixel.init("549936907253864", {}, { debug: false, autoConfig: true });
    ReactPixel.pageView();
    ReactPixel.fbq("track", "PageView");
  }, []);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        if (isMounted) {
          await fetchRemoteConfig();
        }
      } catch (error) {
        console.error("Error fetching fetchRemoteConfig", error);
        // Handle error
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const location = useLocation();


  useEffect(() => {
    setDownloadLeft(reduxDownloadLeft);
  }, [reduxDownloadLeft]);


  const logOut = async () => {
    await auth.signOut();
    await userService.logout();
    window.location.href = "/";
  };

  useEffect(() => {
    const userId = userService.getUserId();
    if (!utmParamsFlag && window.location.search) {
      setUTMParamsFlag(true);
      const dataToSave = {
        user_id: userId,
        utmValue: window.location.search,
        utmAddedDate: new Date(),
      };
      utmService.saveUTMData(dataToSave);
    }
  }, []);

  async function processUserInformation() {
    const userId = userService.getUserId();
    const email = userService.getEmailId();

    if (userId && email) {
      try {
        const userInformation = await getUserDocInfo(email, userId);
        const response = await paymentService.getSubscriptionInfoRegistration(userInformation);

        if (response === "home" && userService.isLoggedInUser()) {
          const downloadCount = userService.downloadLeftImage();

          dispatch(updateDownloadCountRedux(downloadCount));
        } else if (userService.isLoggedInUser()) {

        }
      } catch (error) {
        console.error("Error occurred:", error);
      }
    }
  }

  // HERE WE ARE CHECKING IF USER IS PAID OR NOT
  useEffect(() => {
    processUserInformation();
  }, []);

  useEffect(() => {
    // WHEN USER ON SUCCESS PAGE, AFTER 3 SECOND CALL THE WHOLE PROCESS TO UPDATE IT.
    const affiliateCode = containsAffiliate(location.pathname);
    if (affiliateCode) {
      userService.setKeyValueLocalstorage("affliateCode", affiliateCode);
      addAffliateLog(affiliateCode)
    }

    if (location.pathname == "/success") {
      const timeoutId = setTimeout(() => {
        processUserInformation()
      }, 5000);
      // Cleanup function to clear the timeout
      return () => clearTimeout(timeoutId);
    }
    // const userId = userService.getUserId();
    setNewPathName(location.pathname);
    setPathName(location.pathname);
    const pageName = userService.checkLoggedInUserAndRedirect();
    if (pageName) {
      navigate(pageName);
    }
  }, [location]);

  useEffect(() => {
    if (isIOSDevice()) {
      // navigate("/mobile/download1");
      //setIsModalOpen(true);
    }
  }, [isIOSDevice()]);

  // WE are restricting user to not enter in private url
  // only Subscribed user may access
  // private URL
  const privateURL = [
    "/payment-success",
    "/ad-goal",
    "/business-type",
    "/description",
    "/ad-type",
    "/ad-ideas",
    "/image-style",
    "/image-ads",
    "/captions",
    "/my-adlisting",
    "/upload-product",
    "/adflow-listing",
    "/loading",
    "/store",
    "/stripe",
    "/quick-ad", //* New Quick ad flow updated private route
  ];
  if (
    (privateURL.includes(pathname) && !userService.checkCredits()) ||
    (privateURL.includes(pathname) && !userService.showLogoutBtn())
  ) {
    window.location.href = "/free-trial-subscription";
  }
  // code end here nx1@yopmail.com

  if (
    pathname.trim() === "/" ||
    pathname === "/login" ||
    pathname === "/saveimagesocialmedia" ||
    pathname === "/useraction" ||
    pathname === "/verify-email" ||
    pathname === "/free-trial-subscriptions" ||
    pathname === "/signup" ||
    pathname === "/quick-free-trial" ||
    pathname === "/aboutus" ||
    pathname === "/mobile/download1" ||
    pathname === "/legal/privacy" ||
    pathname === "/legal/tos" ||
    pathname === "/product-flow-landing" ||
    pathname === "/CheckoutYearlyUltra" ||
    pathname === "/landing/ai-ads-video" ||
    pathname === "/ai-ads" ||
    pathname === "/free-ads" ||
    pathname === "/free-ads2" ||
    pathname === "/video" ||
    pathname === "/ad-text" ||
    pathname === "/landing/ai-ads-carousal" ||
    pathname === "/landing/product-flow" ||
    pathname === "/product-flow" ||
    pathname === "/verify-account" ||
    pathname === "/ad-carousel" ||
    pathname === "/product-flow-updated"
  ) {
    return (
      <>
        <PublicRoutes />
        {/* <WarningUsageWebsite isOpen={isModalOpen} setIsModalOpen={setIsModalOpen} />*/}
      </>
    )
  } else {
    return (
      <>
        <div className="wrapper">
          <nav id="sidebar">
            <div className="cursor-pointer">
              <NavbarPrivate></NavbarPrivate>
              <div className="logout">
                {showSettingMenu && (
                  <>
                    <Link Link to="/settings">
                      <p className="text-size-18 border-bottom-1-white m-15 p-5">
                        Account Settings
                      </p>
                    </Link>
                    <p
                      className="text-center text-size-18 text-dark-red"
                      onClick={logOut}
                      title="Logout"
                    >
                      Logout
                    </p>
                  </>
                )}

                {userService.showLogoutBtn() && (<div
                  className="flex align-items-baseline ml-15 user-email-bg"
                  onClick={() => setShowSettingMenu((prev) => !prev)}
                >
                  <p
                    className="text-truncate m-0"
                    style={{ maxWidth: "120px" }}
                    title={userService.getValueFromLocalstorage("email")}
                  >
                    {userService.getValueFromLocalstorage("email")
                      ? userService.getValueFromLocalstorage("email")
                      : userService.getValueFromLocalstorage("email")}
                  </p>
                  <i
                    className={
                      showSettingMenu
                        ? `ml-2 fa fa-caret-up`
                        : `ml-2 fa fa-caret-right`
                    }
                    aria-hidden="true"
                  ></i>
                </div>
                )}

              </div>
            </div>

            {/*userService.showLogoutBtn() && (
              <ul className="text-center">
               
                {userService.getValueFromLocalstorage("name")  && (
                  <li
                    className="flex"
                    title={userService
                      .getValueFromLocalstorage("name")
                      .slice(0, 6)}
                  >
                    <div className="">
                      <h4 className="user-nav-name">kk
                        {
                          Array.from(
                            userService.getValueFromLocalstorage("name")
                          )[0]
                        }
                      </h4>
                    </div>
                    <h4>
                      {userService.getValueFromLocalstorage("name").slice(0, 6)}
                    </h4>
                  </li>
                )}
               
              </ul>
            )*/}
          </nav>
         
          <div
            id="content"
            className={`fullwidth   ${newPathName?.toLocaleLowerCase() === "/subscriptions" ||
              newPathName?.toLocaleLowerCase() === "/subscriptions-50-off" ||
              newPathName?.toLocaleLowerCase() === "/free-trial-subscription" ||
              newPathName?.toLocaleLowerCase() === "/pricing"
              ? "new-subscriber-page-background"
              : "new-landing-page-background"
              }`}

          >
             {newPathName?.toLocaleLowerCase() !== "/free-trial-subscription" &&
            newPathName?.toLocaleLowerCase() !== "/subscriptions" &&
            newPathName?.toLocaleLowerCase() !== "/subscribe" && (
              <>
                <div className="pull-right  mainRightIcon">
                  {/* <SubscribeButtonModal></SubscribeButtonModal> */}
                  <SubscribeLiteButtonModal></SubscribeLiteButtonModal>
                  <SubscribeProButtonModal></SubscribeProButtonModal>
                  <AdsCountModalButton adsCounts={downloadLeft}></AdsCountModalButton>
                  <AdButtonModal></AdButtonModal>
                  <HomeButtonModal></HomeButtonModal>
                  <GeneratedImageAdModal></GeneratedImageAdModal>
                </div>
                {/* <div className="downloads-banner-counter">
                    <p className="m-0 p-0">{downloadLeft} downloads left</p>
                  </div> */}
              </>
            )}
            <button
              type="button"
              id="sidebarCollapse"
              className="btn navbar-btn bg-primary toglebtn"
            >
              <i className="glyphicon  glyphicon-align-left"></i>
            </button>

<div className="">
<PrivateRoutes />

</div>
          </div>
        </div>
      </>
    );
  }
};
export default App;

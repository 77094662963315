export default function ButtonGradient(props) {
  return (
    <>
      <button
        key={props?.title}
        className={`${props?.btnstylebyclass} button-gradient-with-whitebottom-border position-relative z-index-1`}
        style={props?.btnstyle}
        onClick={props?.onClick}
      >
        <p className={props?.stylebyclass} style={props?.titlestyle}>
          {props?.title}
        </p>
      </button>
    </>
  );
}

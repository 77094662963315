import React from "react";
import { useNavigate } from "react-router-dom";
import create_ad from "../../../images/icons/add-new.svg";


const NewAdImg = () => {

  // ******************** Hooks ***************************************
  const navigate = useNavigate();

  // ******************** Functions ***************************************
  const redirectTo = (page) => {
    navigate("/" + page);
  };


  return (
    <>
      <img
        onClick={() => {
          redirectTo("store");
        }}
        width={120}
        className=" pointerclass"
        src={create_ad}
        alt="New Ad"
        title="New Ad"
      />
    </>
  );
};
export default NewAdImg;

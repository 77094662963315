import React, { useEffect, useState } from "react";
import Cologne_Layout from "../../../images/free_trail_page_assests/Cologne_Layout21.svg";
import HeroImage from "../../../images/free_trail_page_assests/Frame-1261153205.svg";
import CustomiseAds from "../../../images/free_trail_page_assests/IMG_39991.svg";
import imageToShare from "../../../images/free_trail_page_assests/imageToShare192.svg";
import { fbEventsService } from "../../../services/fbevents.service";
import { userService } from "../../../services/user.service";
import { MONTHLY_TRIAL_PRICE, PAYMENT_SUCCESS_MSG, PAYPAL_MONTHLY_TRIAL_PRICE, QUICK_FREE_TRIAL, YEARLY_TRIAL_PRICE } from "../../../utils/constants";
import SpinnerLoader from "../../common/SpinnerLoader";
import CheckoutFreetrialQuickMonthlyUltra from "../../common/StripePayment/CheckoutFreetrialQuickMonthlyUltra";


const AdFlowFreeTrialSubpage = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);
  const [planSwitchToggle, setPlanSwitchToggle] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState({
    plan: "Yearly",
    name: "Year",
    month: MONTHLY_TRIAL_PRICE,
    year: YEARLY_TRIAL_PRICE,
  });
  useEffect(() => {
    if (!planSwitchToggle) {
      setSelectedPlan({
        plan: "Yearly",
        name: "Year",
        month: MONTHLY_TRIAL_PRICE,
        year: YEARLY_TRIAL_PRICE,
      })
      userService.setKeyValueLocalstorage("defaultUserSelectedPlan", process.env.REACT_APP_STRIPE_YEARLY_ULTRA_TRIAL_PRICE_ID);

    } else {
      setSelectedPlan({
        plan: "Monthly",
        name: "Month",
        month: MONTHLY_TRIAL_PRICE,
        year: YEARLY_TRIAL_PRICE,
      })

      userService.setKeyValueLocalstorage("defaultUserSelectedPlan", process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID);
    }
  }, [planSwitchToggle])

  useEffect(() => {
    userService.setKeyValueLocalstorage("defaultUserSelectedPlan", process.env.REACT_APP_STRIPE_YEARLY_ULTRA_TRIAL_PRICE_ID);
    fbEventsService.sendFbEvents(QUICK_FREE_TRIAL, process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME, PAYPAL_MONTHLY_TRIAL_PRICE);
    userService.removeKeyLocalstorage("quickFreeTrial");
  }, []);

  const redirectTo = (pageName) => {
    window.location.href = ("/" + pageName);
  };

  //document.title = getWebsiteTitle() + " Free Trial";
  return (


    <div className="">
      <div className="col-md-12 col-sm-offset-0">
        <div className="group-free-trail-parent">
          <div className="frame-free-trail-parent">
            <div className="free-trail-frame-group">
              <div className="free-trail-welcome-to-adspire-wrapper">
                <div className="free-trail-welcome-to-adspire-container" >
                  {/*<span className="free-trail-welcome-to">
                    <span>Welcome to</span>
                    <span className="span">{` `}</span>
                  </span>
                  <span className="span">
                    <b className="free-trail-adspire2 cursor-pointer" onClick={() => redirectTo('')}>Adspire®</b>
  </span>*/}
                  {paymentProccessStart && (<div><SpinnerLoader></SpinnerLoader>
                    <p className="text-center-normal-mar30"><b>{PAYMENT_SUCCESS_MSG}</b></p>
                  </div>)}
                </div>
              </div>
            </div>
            <div className="ad-flow-free-trialnew text-open-sans">
              <div className="product-flow-landing-wrapper1 box-width text-center">
                <div className="product-flow-landing-wrapper2">
                  <div className="free-trail-frame-parent1 text-center">
                    <div className="text-center">

                      <img alt="" className="imgwidth" src={HeroImage} />

                      <p> <button className="limited-time-text text-uppercase font-italic">
                        Limited Offer
                      </button></p>
                      <p>
                        <b className="try-out-the-container text-center new-ads-test">
                          Try <span className="adspire-free-trail-3 logo-text">ADSPIRE</span><span className="adspire-for"> for </span><span className="adspire-free underline"> FREE!</span>
                        </b></p>
                    </div>
                    <div className="col-md-12 col-xs-12 col-sm-12 j-center">
                      <div className="col-md-10 col-xs-12 col-sm-12">
                        <CheckoutFreetrialQuickMonthlyUltra componentName="StyledButton1" buttonType="button" buttonName="Continue" setPaymentProccessStart={setPaymentProccessStart}></CheckoutFreetrialQuickMonthlyUltra>
                        <p className="h5 cursor-pointer" data-toggle="modal" data-target="#imageDetailModal">Cancel anytime!</p>
                      </div>
                    </div>

                    <div className="free-trail-content free-trail-content-ad-flow-free-trial">
                      <div className="free-trail-iconidea-parent">
                        <ul className="step-icondiv   ad-flow-free-trial-new  ad-flow-free-trial-new-in-responsive">
                          <li>   <img className="free-trail-iconidea-ad-flow-free-trial" alt="" src={CustomiseAds} /><br></br>Customise Ads! </li>
                          <li>   <img className="free-trail-iconidea-ad-flow-free-trial" alt="" src={imageToShare} /><br></br>Concept Ads!</li>
                          <li>   <img className="free-trail-iconidea-ad-flow-free-trial" alt="" src={Cologne_Layout} /><br></br>Multiple Ad Layouts!</li>
                        </ul>
                      </div>
                    </div>

                    <div className="try-3-days-for-free-parent">
                      <b className="try-3-days-container">
                        {`Try 3 days for `}
                        <span className="free-trail-free1">FREE!</span>
                      </b>
                      <div className="then-34999year">
                        Then ${
                          planSwitchToggle ? `${selectedPlan?.month}/${selectedPlan?.name}` : `${selectedPlan?.year}/${selectedPlan?.name}`
                        }
                      </div>

                    </div>
                    <div className="free-trail-instance-parent">


                      <div className="no-obligation-to-continue-jus-parent">
                        <div className="no-obligation-to-container">
                          <p className="no-obligation-to font-15-regular">{`No obligation to continue, just cancel `}</p>
                          <p className="no-obligation-to font-15-regular">
                            before the trial ends.
                          </p>
                        </div>
                        <div className="adflow-new-switch">
                          <div className="onoffswitch" onChange={() => setPlanSwitchToggle(prev => !prev)}>
                            <input type="checkbox" name="onoffswitch" className="onoffswitch-checkbox" id="myonoffswitch" defaultChecked={planSwitchToggle} />
                            <label className="onoffswitch-label" htmlFor="myonoffswitch">

                              <span className="onoffswitch-switch"></span>
                              <span className="onoffswitch-inner"></span>
                            </label>
                          </div>
                        </div>
                        {/* <div className="text-center"> <img alt="" className="imgwidth" src={yearly} /></div> */}
                        <div className="terms-conditions-parent">
                          <div className="welcome-to-adspire-container pointerclass font-15-regular" onClick={() => redirectTo('legal/tos')}>{`Terms & Conditions`}</div>
                          <div className="welcome-to-adspire-container pointerclass font-15-regular" onClick={() => redirectTo('legal/privacy')}>
                            Privacy Policy
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >

  );

};

export default AdFlowFreeTrialSubpage;
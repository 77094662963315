import React from "react";
import grediant1 from "../../../images/landing_page_assests/grediant1.svg";
import arrow_images from "../../../images/incredible-ads/arrow_images.svg";
import stunning_ads_girl from "../../../images/landing_page_assests/stunning_kpi_boy.png";
import { useNavigate } from "react-router-dom";
import ButtonGradient from "../../common/Button/ButtonGradient";
import useWindowSize from "../../../_helper/ScreenUtil";

const IncredibleAds = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <div className="roas incredible-ads-my ">
      <div className="rd-section-bg overlay-image " />
      <div className="incredible-ads-container">
        <div className="incredible-ads-container-first">
          <div className="adspire-your-roas-container incredible-ads-container-first-text-left text-white mt-70 mb-60">
            <span className="unlimited-caption-txt-container">
              <span className="adspire-your-new-roas">Stunning Ads</span>
              <span>{` `}</span>
              <br />
              <span className="adspire-stunning-kpis-text span">
                Even More Stunning KPIs.
              </span>
            </span>
          </div>
          <div className="green-stunning-parent">
            <div className="incredible-ads-box-item">
              <div className="green-stunning-card">
                <div className="text-left font-family-sans-serif">
                  <h4 className="text-size-30 text-black text-weight-600">Profits</h4>
                  <h1 className="text-size-50 text-white text-weight-600">$47,891</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="blue-stunning-parent">
            <div className="incredible-ads-box-item">
              <div className="blue-stunning-card">
                <div className="text-left font-family-sans-serif">
                  <h4 className="text-size-30 text-black text-weight-600">CTR</h4>
                  <h1 className="text-size-50 text-white text-weight-600">5.82%</h1>
                </div>
              </div>
            </div>
          </div>
          <div className="red-stunning-parent">
            <div className="incredible-ads-box-item">
              <div className="red-stunning-card">
                <div className="text-left font-family-sans-serif">
                  <h4 className="text-size-30 text-black text-weight-600">ROAS</h4>
                  <h1 className="text-size-50 text-white text-weight-600">316%</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="incredible-ads-stunning-arrows">
          <img src={arrow_images} alt="vector incredible ads" />
        </div>
        <div className="incredible-ads-container-first incredible-ads-align-items-end">
          <div className="incredible-ads-item-box-container">
            <img className="incredible-ads-item-image" src={stunning_ads_girl} alt="ai ads" />
          </div>
        </div>
      </div>
      <div className="col-md-12 position-relative z-index-10" style={isMobile ? { paddingBottom: "110px" } : { marginTop: "-50px", marginBottom: "100px" }}>
        <div className="text-center">
          <ButtonGradient
            title="Generate Ads"
            btnstyle={{
              textShadow: "5px 5px 20px white"
            }}
            stylebyclass="text-size-30 text-weight-600 text-white"
            btnstylebyclass="m-left-right-auto"
            onClick={() => navigate("/signup")}
          />
          <p className="font-family-sans-serif text-size-16 text-light-gray mt-10">Try 100% free for 3 days. <span className="text-weight-600 gradient-head-text">cancel anytime</span></p>
        </div>
      </div>
      <img className="grediant-icon1" alt="" src={grediant1} />
    </div>
  );
};
export default IncredibleAds;



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import add from "../images/icons/add.png";
import cross from "../images/icons/cross.png";
import download from "../images/icons/download.png";
import grid_btn_icon from "../images/icons/grid-btn-icon.svg";
import list_btn_icon from "../images/icons/list_btn_icon.svg";
import { userService } from "../services/user.service";
import { db } from "../utils/Firebase";
import { getWebsiteTitle } from "../utils/function";
import SpinnerLoader from "./common/SpinnerLoader";

import { saveAs } from "file-saver";
import MyAdIdeas from "./MyAdIdeas";
import Subscribe from "./Subscribe";
import AdFlowFreeTrial from "./landingpage/adFlow/AdFlowFreeTrial";
const MyAdListing = (props) => {
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);

  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn-enable");
  const [myListing, setMyListing] = useState(false);
  const [loading, setLoader] = useState(false);
  const [tabsChangeer, setTabsChangeer] = useState(true);

  const navigate = useNavigate();
  const backStylesPage = () => {
    // props.setGeneratedImage("");
    navigate("/image-style");
  };
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };

  const handleDownloadClick = (url) => {
    saveAs(url, "img");
  };

  useEffect(() => {
    // create the userRef
    if (userService.getUserId()) {
      const userRef = db.collection("users").doc(userService.getUserId());

      db.collection("userImagesHistory")
        .where("userRef", "==", userRef)
        .orderBy("createdAt", "desc")
        .limit(12)
        .onSnapshot((snapshot) => {
          const dataReceived = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));

          setMyListing(dataReceived);
          setLoader(true);
        });
    }
  }, []);

  const selectImage = (items) => {
    setActive(items);
    setPopupImage(items);
  };
  const colors = [
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
  ];
  document.title = getWebsiteTitle() + "Home";

  return (
    <React.Fragment>
      {!userService.isSubscribed() && <AdFlowFreeTrial></AdFlowFreeTrial>}
      {userService.isSubscribed() && (
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  {/* <Step step="7"></Step> */}
                  <div className="divider-horizontal"></div>
                  <div className="col-sm-10 col-sm-offset-1 mt-sm-80">
                    {/*  <p className="text-center mar-top-20"> 
                
                <img onClick={() => { redirectTo("ad-goal") }}
                          width={60} className=" pointerclass"
                          src={create_ad}
                          alt="New Ad"
                          title="New Ad"
                        />

<img onClick={() => { redirectTo("subscribe") }}
                          width={60} className="pointerclass"
                         
                          src={upgrade}
                          alt="Upgrade"
                          title="Upgrade"
                        /></p> */}
                    <div className="flex content-between w-sm-100">
                      <div className="flex mt-10">
                        <h2
                          onClick={() => {
                            setTabsChangeer(true);
                          }}
                          className={`text-left ${
                            tabsChangeer ? "text-tabs-active" : "text-tabs"
                          }`}
                        >
                          Image Ads
                        </h2>

                        <h2
                          onClick={() => {
                            setTabsChangeer(false);
                          }}
                          className={`text-left ${
                            tabsChangeer ? "text-tabs" : "text-tabs-active"
                          }`}
                        >
                          Ad Ideas
                        </h2>
                      </div>
                      <div className="flex mt-10">
                        <button className="grid-icon-button">
                          <img src={grid_btn_icon} alt="" />
                        </button>
                        <button className="list-icon-button">
                          <img src={list_btn_icon} alt="" />
                        </button>
                      </div>
                    </div>

                    {tabsChangeer && (
                      <>
                        <div className="flex content-between">
                          <h2 className="text-left text-size-24 mb-40">
                            My Ads
                          </h2>
                          <h2 className="text-left text-size-24 mb-40 cursor-pointer">
                            See all
                          </h2>
                        </div>
                        <p>{userService.getValueFromLocalstorage("ideas")}</p>
                        <div className="clearfix"></div>

                        <div className="row mb-20 mt-20">
                          {!loading && (
                            <div className="text-center">
                              <SpinnerLoader message="Captions Loading..."></SpinnerLoader>
                            </div>
                          )}
                          <div className="grid-container formobilegrid">
                            {myListing &&
                              myListing.map((items, index) => (
                                <div
                                  className="grid-img-show"
                                  key={`parent-images-${index}`}
                                >
                                  <div
                                    className="grid"
                                    key={`parent-images-${index}`}
                                  >
                                    {items.data.imageURLs &&
                                      items.data.imageURLs.map(
                                        (imageURL, index) => (
                                          <React.Fragment
                                            key={`child-images-${index}`}
                                          >
                                            <img
                                              src={imageURL}
                                              alt=""
                                              onClick={() =>
                                                selectImage(imageURL)
                                              }
                                              data-toggle="modal"
                                              data-target="#imageDetailModal"
                                            />
                                          </React.Fragment>
                                        )
                                      )}
                                  </div>
                                  <h5 className="ml-10">
                                    {items.data.formattedPrompt}
                                  </h5>
                                </div>
                              ))}
                          </div>
                        </div>
                      </>
                    )}

                    {!tabsChangeer && <MyAdIdeas></MyAdIdeas>}

                    <div className="form-group text-center">
                      {/* <GenerateMoreButton
                      buttonStatus={buttonStatus}
                      classvalue={classvalue}
                      pagename="ad-goal"
                    ></GenerateMoreButton>
                    <div className="form-group text-center">
                      <button
                        type="btn"
                        data-toggle="modal"
                        data-target="#captionModal"
                        className="btn btn-primary generateAd "
                      >
                        Generate Captions{" "}
                      </button>
                    </div> 
                    <div
                      className="form-group text-center pointerclass"
                      onClick={() => backStylesPage()}
                    >
                      Back
                    </div>*/}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}

      {/*CAPTION POPUP START HERE */}
      <div
        className="modal fade"
        id="captionModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="captionModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={cross} alt="" className="" />{" "}
                </span>
              </button>
            </div>
            <div className="modal-body p50">
              <div className=" text-center m-top">
                <p>
                  {" "}
                  You may use these images as inspiration, a base/part of your
                  ad, or even the final ad, 100% commercially.
                </p>

                <button
                  data-dismiss="modal"
                  type="button"
                  className="btn btn-primary generateAd btn-block "
                  id="greatbtnModal"
                  onClick={() => redirectTo("captions")}
                >
                  Great!
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/*CAPTION POPUP END HERE */}

      {/* image popup */}

      <div
        className={`modal fade`}
        id="imageDetailModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contained-modal-title-vcenter"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={cross} alt="" className="" />{" "}
                </span>
              </button>
            </div>
            <div className="modal-body text-center user-imgDiv">
              <img width={408} height={408} src={popupImage} />
            </div>
            <div className="modal-footer text-center">
              <div className="row">
                <a
                  href={`https://adspireai.com/getdownloadimg.php?img=${popupImage}`}
                >
                  <div className="col-sm-6 col-xs-6 text-center">
                    <button
                      type="button"
                      className="btn btn-secondary circlediv"
                    >
                      <img src={download} alt="" className="icon-absolute" />{" "}
                    </button>
                    <p className="greytext">Download</p>
                  </div>
                </a>

                <div
                  className="col-sm-6 col-xs-6 text-center"
                  data-toggle="modal"
                  data-target="#exampleModal"
                  onClick={() => redirectTo("ad-goal")}
                >
                  <button
                    type="button"
                    className="btn btn-secondary dashCircle"
                  >
                    <img src={add} alt="" className="icon-absolute" />{" "}
                  </button>
                  <p className="greytext">New Ad</p>
                </div>
              </div>

              {/* <AdButton></AdButton> */}
              <div className=" m-top">
                {/*<div className="col-sm-4 col-xs-4 text-center">
                                <button type="button" className="btn btn-primary white-border btn-block" data-dismiss="modal" data-toggle="modal"
                                  data-target="#captionModal">Generate Captions</button>

                              
                              </div>*/}
                <div className="col-sm-4 col-xs-4 text-center">
                  {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                </div>
                <div className="col-sm-4 col-xs-4 text-center">
                  <a
                    href=""
                    className="white-textdiv"
                    data-dismiss="modal"
                    onClick={() => redirectTo("ad-ideas")}
                  >
                    See Ad Ideas
                  </a>
                  {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* image popup*/}
    </React.Fragment>
  );
};
export default MyAdListing;

import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { RWebShare } from "react-web-share";
import SignInScreen from "../components/SignInScreen";
import SomeSubAdIdeas from "../components/SomeSubAdIdeas";
import LoginWaterMarkedModal from "../components/common/popup/LoginWaterMarkedModal";
import adSpireWatermark from "../images/icons/Adspire_watermark.svg";
import add from "../images/icons/add.png";
import cross from "../images/icons/cross.png";
import download from "../images/icons/download.png";
import share from "../images/icons/share.png";
import { userService } from "../services/user.service";
import { getWebsiteTitle } from "../utils/function";
import GenerateCaptionsButton from "./common/Button/GenerateCaptionsButton";
import Logo from "./common/Logo";


const NewUserAdListing = (props) => {
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  const [displayBlock, setDisplayBlock] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn-enable");

  const navigate = useNavigate();




  useEffect(() => {
    if (!userService.checkCredits()) {
      const myBtn = document.getElementById("psubscribeModal");
      myBtn.click();  navigate("/subscribe");
    }
  }, []);



  const backStylesPage = () => {
    props.setGeneratedImage("");
    navigate("/image-style");
  };

  const redirectTo = (pagename) => {
    if (pagename === "ad-ideas") {
      userService.setKeyValueLocalstorage("showLocalAdIdeas", "yes");
    }
    navigate("/" + pagename);
  };

  const selectImage = (items) => {
    setActive(items);
    if (userService.isContentAllowed()) {
      setPopupImage(items);
    } else {
      openLoginPopup();
    }
  };

  const openLoginPopup = () => {
    const myBtn = document.getElementById("ploginModal");
    myBtn.click();
  };

  const handleDownloadClick = (url) => {
    saveAs(url, "img");
  };

  const generateAdIdeasProps = (e) => {
    props.setGeneratedImage(false);
    props.setLoader(true);
    props.generateAdIdeas();
  };

  const getWaterMarkingCls=()=>{
    if(window.location.pathname=="/image-ads"){
      return "watermarkImg-main";
    }
    return "watermarkImg";
  }

  

  const colors = ["#4fcfbe", "#ff9e83", "#fc94cf"];

  // localStorage.setItem("loginMethod", "anonymous");

  document.title = getWebsiteTitle() + "Image Ads";
  return (
    <React.Fragment>
      {props.generatedImage && (
        <div className="container-fluid">
          <div className="text-center">
          { window.location.pathname!="/image-ads" && (
            <Logo path="/home"></Logo>)}
            {/* <h2 className="gradient-logo-text text-size-32">ADSPIRE</h2> */}
          </div>
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  <div className="col-sm-10 col-sm-offset-1">
                    <h2 className="text-left gradient-head-text text-size-32 mb-40">
                      Here are some image Ads I made using the idea:
                    </h2>
                    <p>{userService.getValueFromLocalstorage("ideas")}</p>
                    <div className="clearfix"></div>

                    <div className="row mb-20 mt-20">
                      {props.generatedImage &&
                        props.generatedImage.map((items, index) => (
                          <div
                            className={`col-sm-3 col-xs-12`}
                            key={`gen-ad-img-${index}`} 
                            onClick={() => selectImage(items)}
                            data-toggle="modal"
                            data-target="#imageDetailModal"
                          >
                            <div
                              className={`thumbnailListBG mb-20 ${active == items && "box-active"
                                }`}
                            >
                              <img
                                data-toggle="modal"
                                data-target="#myModal"
                                className="img-responsive user-imgDiv"
                                src={items}
                              />
                              <img src={adSpireWatermark} alt="" className={getWaterMarkingCls()} />
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-sm-offset-3 col-sm-6 text-center">
                      <div className="form-group text-center">
                        {!userService.isContentAllowed() && (
                          <button
                            type="btn"
                            title="Generate Captions"
                            data-toggle="modal"
                            data-target="#captionModal"
                            className="btn btn-primary generateAd  "
                            onClick={() => openLoginPopup()}
                          >
                            Generate Captions
                          </button>
                        )}

                        {userService.isContentAllowed() && (
                          <GenerateCaptionsButton pagename="captions"></GenerateCaptionsButton>
                        )}
                      </div>

                      {/* <div
                          className="form-group text-center pointerclass"
                          onClick={() => backStylesPage()}
                        >
                          Back
                        </div>*/}

                      {!userService.isContentAllowed() && (
                        <>
                          <div
                            className="form-group text-center"
                            title="Generate more "
                          >
                            Sign in to download your image ads <span className="underline">unwatermarked:</span>
                          </div>
                          <SignInScreen></SignInScreen>


                          {/*<div
                            className="form-group text-center"
                            title="Generate more "
                          >
                            <button
                              type="btn"
                              className={`btn btn-primary next-btn-enable btn-block`}
                            >
                              Signup with Facebook
                            </button>
                          </div> */}
                          <h2 className="gradient-head-text text-size-32">Here are your amazing ad ideas:</h2>
                          <div className="row mb-20">
                            {colors &&
                              colors.map((color, index) => (
                                <div
                                  className={`col-sm-12 col-xs-12`}
                                  key={color}
                                >
                                  <div
                                    className={`some-ad-idea-thumbnailBG blur text-size-16`}
                                    style={{
                                      backgroundColor: color,
                                    }}
                                  ></div>

                                  {index == 0 && (

                                    <div className="blur-text">Sign-in to see<br></br> your ad ideas</div>)}

                                  <div className="clearfix"></div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                      {userService.isContentAllowed() && (
                        <SomeSubAdIdeas></SomeSubAdIdeas>
                      )}

                      {userService.isContentAllowed() && (
                        <>
                          {/*CAPTION POPUP START HERE */}
                          <div
                            className="modal fade"
                            id="captionModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="captionModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog top40" role="document">
                              <div className="modal-content text-center">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true"><img src={cross} alt='' /></span>
                                  </button>
                                </div>
                                <div className="modal-body p50">
                                  <div className=" text-center m-top">
                                    <p>
                                      {" "}
                                      You may use these images as inspiration, a
                                      base/part of your ad, or even the final
                                      ad, 100% commercially .
                                    </p>

                                    <button
                                      data-dismiss="modal"
                                      type="button"
                                      className="btn btn-primary generateAd btn-block "
                                      id="greatbtnModal"
                                      onClick={() => redirectTo("captions")}
                                    >
                                      Great!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*CAPTION POPUP END HERE */}

                          {/* image popup */}

                          <div
                            className={`modal fade`}
                            id="imageDetailModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true"><img src={cross} alt='' /></span>
                                  </button>
                                </div>
                                <div className="modal-body text-center user-imgDiv">
                                  <img
                                    width={408}
                                    height={408}
                                    src={popupImage}
                                  />
                                </div>
                                <div className="modal-footer text-center">
                                  <div className="row">
                                    <div
                                      className="col-sm-4 col-xs-4 text-center"
                                      onClick={() =>
                                        handleDownloadClick(popupImage)
                                      }
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-secondary circlediv"
                                        data-dismiss="modal"
                                      >
                                        <img src={download} alt='' className="icon-absolute" />
                                      </button>
                                      <p className="greytext">Download</p>
                                    </div>

                                    <RWebShare
                                      data={{
                                        text: userService.getValueFromLocalstorage(
                                          "ideas"
                                        ),
                                        url: popupImage,
                                        title:
                                          userService.getValueFromLocalstorage(
                                            "input"
                                          ),
                                      }}
                                     
                                    >
                                      <div className="col-sm-4 col-xs-4 text-center">
                                        <button
                                          type="button"
                                          className="btn btn-secondary circlediv"
                                        >
                                          <img src={share} alt='' className="icon-absolute" />
                                        </button>
                                        <p className="greytext">Share</p>
                                      </div>
                                    </RWebShare>

                                    <div
                                      title="New Ad" 
                                      className="col-sm-4 col-xs-4 text-center"
                                      data-toggle="modal"
                                      data-target="#exampleModal"
                                      onClick={() => redirectTo("ad-goal")}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-secondary dashCircle"

                                      >
                                        <img src={add} className="icon-absolute" alt='' />

                                      </button>
                                      <p className="greytext">New Ad</p>
                                    </div>
                                  </div>

                                  {/* <AdButton></AdButton> */}
                                  <div className=" m-top">
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      <button
                                        type="button"
                                        title="Generate Captions"
                                        className="btn btn-primary white-border btn-block"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#captionModal"
                                      >
                                        Generate Captions
                                      </button>

                                      {/*<button type="button" className="btn btn-primary generateAd btn-block ">Generate Variations</button>*/}
                                      {/* <GenerateAdIdeasButton></GenerateAdIdeasButton> */}
                                    </div>
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                                    </div>
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      <a
                                        href=""
                                        className="white-textdiv"
                                        data-dismiss="modal"
                                        onClick={() => redirectTo("ad-ideas")}
                                      >
                                        See Ad Ideas
                                      </a>
                                      {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* image popup*/}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
      <p id="ploginModal" data-toggle="modal"
        data-target="#loginModal">
      </p>
      <LoginWaterMarkedModal></LoginWaterMarkedModal>
    </React.Fragment>
  );
};
export default NewUserAdListing;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../services/user.service";
import { getWebsiteTitle } from "../../utils/function";
import SignInScreen from "../SignInScreen";
import NextButton from "../common/Button/NextButton";
import SpinnerLoader from "../common/SpinnerLoader";
import StepText from "../common/StepText";

const AdText = () => {
  const [adCategory, setAdCategory] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null)
  const [loading, setLoader] = useState(false);
  const [userCredit, setUserCredit] = useState(0);

  // Accessing the history instance created by React
  const navigate = useNavigate();


  const redirectTo = () => {
    navigate("/business-type");
  };

  const setBusinessValue = (adCategory) => {
    setButtonStatus(false)
    setActive(adCategory.data.name);
    setClassvalue("next-btn-enable")
    userService.setKeyValueLocalstorage(
      "adCategoryValue",
      adCategory.data.value
    );
    userService.setKeyValueLocalstorage(
      "categoryName",
      adCategory.data.name
    );
    userService.setKeyValueLocalstorage("adCategoryID", adCategory.id);
    userService.setKeyValueLocalstorage(
      "adCategoryQuestion",
      adCategory.data.question
    );
  };

  document.title = getWebsiteTitle() + "Ad Goal";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <StepText step="1"></StepText>



                <div className="clearfix"></div>
                <div className="col-sm-6 col-sm-offset-3">

                  {!userService.showLogoutBtn() && (<>
                    <SignInScreen></SignInScreen>
                  </>)}

                  {userService.showLogoutBtn() && !loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}

                  <form>
                    <div className="form-group">
                      <label for="formGroupExampleInput">Example label</label>
                      <input type="text" className="form-control" id="formGroupExampleInput" placeholder="Example input" />
                    </div>
                    <div className="form-group">
                      <label for="formGroupExampleInput2">Another label</label>
                      <input type="text" className="form-control" id="formGroupExampleInput2" placeholder="Another input" />
                    </div>
                  </form>

                  <div className="form-group text-center">
                    {userService.showLogoutBtn() && loading && (
                      <NextButton classvalue={classvalue} buttonStatus={buttonStatus} pagename="business-type"></NextButton>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdText;

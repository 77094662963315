import { isIOSDevice } from "ios-detector";
import React, { useEffect, useState } from "react";
import { getWebsiteTitle } from "../../utils/function";
import { useNavigate } from "react-router-dom";
import FooterLanding from "./common/FooterLanding";
import SubHeaderSmall from "./common/SubHeaderSmall";

import "./css/Landing-page.css";
import IOSMessageModal from "../common/popup/IOSMessageModal";

const VerifyEmail = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      window.location.href = "/free-trial-subscriptions";
    }, 3000);
  }, []);

  const [pathname, setPathName] = useState(window.location.pathname);

  document.title = getWebsiteTitle() + " Verify Email";
  if (pathname == "/verify-email") {
    return (
      <>
       
        {/* <span
          id="pIOSMessageModal"
          data-toggle="modal"
          data-target="#iosMessageModalId"
        ></span> */}
        <div className="confirmed-main-landing-page">
          {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
          <div className="hero-sections-parent">
            <div className="hero-sections">
              <SubHeaderSmall></SubHeaderSmall>
            </div>
            <div className="col-sm-8 col-sm-offset-2">
              <div className="frame-parent32">
                <div className="verfiy-email verfiy-email-box" role="alert">
                  <p>
                    <b>Verification Email Sent!</b>
                  </p>
                  <p>
                    Click the link in the email to generate your FREE ad!
                    <br />
                    <span className="itlsmall">
                      (You may need to check your junk/span folder)
                    </span>
                  </p>
                </div>
              </div>
            </div>

            <FooterLanding></FooterLanding>
          </div>
        </div>
      </>
    );
  }

  return <></>;
};

export default VerifyEmail;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import lite_plan from "../../../../images/payment_success_assests/Lite.svg";
import pro_plan from "../../../../images/payment_success_assests/Pro.svg";
import { default as free_plan, default as ultra_plan } from "../../../../images/payment_success_assests/Ultra.svg";
import { userService } from "../../../../services/user.service";
import { getWebsiteTitle } from "../../../../utils/function";
import SignInScreen from "../../../SignInScreen";
import NewAdImg from "../../Button/NewAdImg";
import "../PaypalSubscription/PaymentSuccess.css";
const PaymentSuccess = () => {

  const navigate = useNavigate();
  const [planName, setPlanName] = useState("");

  useEffect(() => {

    if (userService.showLogoutBtn()) {
      setTimeout(() => {

        if (userService.getValueFromLocalstorage("redirectPage") && userService.getValueFromLocalstorage("adCategoryValue") != "") {
          const redirectPage = userService.getValueFromLocalstorage("redirectPage");
          userService.removeKeyLocalstorage("redirectPage");
          navigate('/' + redirectPage);
        } else {
          navigate('/ad-goal');
        }
      }, 3000)
    }
  }, [])

  useEffect(() => {

    if (userService.getValueFromLocalstorage("PaymentInfo").plan.includes("lite")) {
      setPlanName(lite_plan)
    } else if (userService.getValueFromLocalstorage("PaymentInfo").plan.includes("pro")) {
      setPlanName(pro_plan)
    } else if (userService.getValueFromLocalstorage("PaymentInfo").plan.includes("ultra")) {
      setPlanName(ultra_plan)
    } else if (userService.getValueFromLocalstorage("PaymentInfo").plan.includes("trial")) {
      setPlanName(free_plan)
    }
  }, [userService.getValueFromLocalstorage("PaymentInfo").plan]);


  document.title = getWebsiteTitle() + " Payment Success";
  return (
    <div className="container">
      <div className="row payment-success">
        <div className="free-trail-frame-container">
          <div className="col-sm-6 col-xs-12">
            <div className="payment-success-frame-parent1">

              <b className="try-out-the-container">
                <span className="payment-success-welcome-to white-text mar-20"> Welcome to  </span> <span className="payment-success-adspire-text">Adspire® {userService.getValueFromLocalstorage("PaymentInfo").plan}! </span>
                {userService.getValueFromLocalstorage("PaymentInfo").subscription_id && (<>
                  <br></br>
                  <p className="pclass"> Congratulations on a successful payment! Now you can start creating your powerful ads!</p>


                  <p className="font-22 pclass">Your Transaction Id:- {userService.getValueFromLocalstorage("PaymentInfo").subscription_id}</p>
                  {!userService.showLogoutBtn() && (<h2>Please login before proceeding...</h2>)}
                </>)}
                {!userService.getValueFromLocalstorage("PaymentInfo").subscription_id && (
                  <>
                    <p className="font-22 error-message">There is no transaction found. Please contact admin.</p>
                  </>)}
              </b>
              {!userService.showLogoutBtn() && (<>
                <SignInScreen></SignInScreen>
              </>)}
              <div className="text-center col-sm-12"><NewAdImg></NewAdImg></div>
            </div>
          </div>

          <div className="col-sm-6 col-xs-12">
            {/* <div className="payment-success-gradient-bg-1 mar-20"> */}
            <img className="grediant-dummy" alt="" src={planName} />
            {/* </div> */}
          </div>
        </div>

      </div>
    </div>

  );
};

export default PaymentSuccess;

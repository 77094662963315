const StyledButton4 = ({ zIndexValue, name, amount, type = "button", disabled = false, onClick, btnTextStyle, btnHeight, btnTheme, ...restProps }) => {

    // ******************** Functions ***************************************
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };


    return (
        <>
            {
                btnTheme === "light"
                    ? (
                        <div className="styled-button-3-container" >
                            <button
                                className="styled-button-3-sub-container"
                                type={type}
                                disabled={disabled}
                                onClick={handleClick}
                            >
                                <div className="styled-button-3-border">
                                    <p
                                        className="styled-button-3-text"
                                    >
                                        ${name}/Month
                                    </p>
                                </div>
                            </button>
                        </div>
                    )
                    :
                    (
                        <div className="styled-button-1-container"
                            onClick={handleClick}
                        >
                            <div className="free-trail-next-wrapper next-wrapper-btn btn-width wht-bdr3"
                                style={
                                    btnHeight ? { height: `${btnHeight}` } : { height: "60px" }
                                }
                            >
                                <button
                                    style={
                                        zIndexValue ? { zIndex: `${zIndexValue}` } : { zIndex: 10 }
                                    }
                                    className="continue-btn-free-trail cursor-pointer font-24"
                                    type={type}
                                    data-dismiss="modal"
                                    disabled={disabled}
                                >
                                    <span className={
                                        ` ${btnTextStyle}`
                                    }>
                                        {name ? name : <span style={{
                                            fontWeight: "700"
                                        }}>${amount}/Year <b >{`(55% Off!)`}</b></span>}
                                    </span>
                                </button>
                            </div>
                        </div >
                    )
            }
        </>
    )
}


export default StyledButton4
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import { configureStore  } from 'redux';
// import { Provider } from 'react-redux';
// import allReducers from "./reducers";
import reportWebVitals from './reportWebVitals';
import './fonts/glyphicons-halflings-regular.eot';
import './fonts/glyphicons-halflings-regular.eot?#iefix';
import './fonts/glyphicons-halflings-regular.woff2';
import './fonts/glyphicons-halflings-regular.woff';
import './fonts/glyphicons-halflings-regular.ttf';
import './fonts/druk-wide-bold.ttf';
import './fonts/glyphicons-halflings-regular.svg#glyphicons_halflingsregular';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './redux/store'

ReactDOM.render(
  <Provider store={store}>
  <BrowserRouter>
    <App />
  </BrowserRouter>
  </Provider>
  , document.getElementById('root')
);
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

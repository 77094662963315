import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import arrow_down_vector from "../../../images/icons/arrow-down-vector.svg";
import arrow_up_vector from "../../../images/icons/arrow-up-vector.svg";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { getWebsiteTitle } from "../../../utils/function";
import SpinnerLoader from "../../common/SpinnerLoader";

const LandingBusinessType = (props) => {
  const [categoryType, setCategoryType] = useState("");
  const [filterCat, setFilterCat] = useState("");
  const [isSearch, setIsSearch] = useState(false);
  const [loading, setLoader] = useState(false);
  const [isListShowHide, setIsListShowHide] = useState(true);
  const [selectedDropDownValue, setSelectedDropDownValue] = useState(userService.getValueFromLocalstorage("adCategoryValue"));


  // Accessing the history instance created by React
  const navigate = useNavigate();

  const formatData = (datas) => {
    const catArr = [];
    datas.forEach((element) => {
      catArr.push({ key: element.id, name: element.data.name });
    });
    setCategoryType(catArr);
    setFilterCat(catArr);
  };

  const getBusinessType = () => {
    const adCategoryID = userService.getValueFromLocalstorage("adCategoryID");
    if (adCategoryID) {
      db.collection("adIdeas")
        .doc("ideasGenerator")
        .collection("adCategories")
        .doc(adCategoryID)
        .collection("options")
        .orderBy("order")
        .onSnapshot((snapshot) => {
          const dataReceived = snapshot.docs.map((doc) => ({
            id: doc.id,
            data: doc.data(),
          }));
          formatData(dataReceived);
          setLoader(true);
        });
    }
  };

  useEffect(() => {
    getBusinessType();
  }, [!categoryType]);

  useEffect(() => {
    setCategoryType(categoryType);
  }, [categoryType]);


  const onDropdownSelected = (e, value, index) => {
    e.preventDefault();
    setIsListShowHide(false)
    userService.setKeyValueLocalstorage("categoryTypeValue", value);
    setSelectedDropDownValue(value);
    userService.setKeyValueLocalstorage("redirectPage", "description");
    if (userService.isPurchaser()) {
      props.currentPage("briefly-describe-business")
    } else {
      window.location.href = "/free-trial"
    }
  };

  const sortBusinessType = (orderType) => {
    setIsSearch(false);
    userService.setKeyValueLocalstorage("orderType", orderType);
    getBusinessType();
    // setFilterCat(categoryType);
  };

  const searchBusinessType = () => {
    setIsSearch(true);
  };

  const onSearch = (searchInput) => {
    if (searchInput) {
      const filteredData = categoryType.filter((item) => {
        return item.name.toLowerCase().includes(searchInput.toLowerCase());
      });
      setFilterCat(filteredData);
    } else {
      setFilterCat(categoryType);
    }
  };

  document.title = getWebsiteTitle() + "Business Type";

  return (
    <React.Fragment>

      {/*  <h2 className="gradient-head-text text-center text-size-32 mb-40">
                  
                </h2>*/}

      <div className="what-do-you-want-an-ad-for-wrapper">
        <b className="what-do-you">{userService.getValueFromLocalstorage("adCategoryQuestion")}</b>
      </div>

      <div className="clearfix"></div>
      <div className="col-sm-6">

        {!loading && (
          <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
        )}
        {loading && (

          <div className="col-sm-12 col-sm-offset-1 mt-3 pb-3">

            <div className="list-group">
              {isSearch && (
                <input
                  type="text"
                  className="form-control msgbox"
                  name="search"
                  onChange={(e) => onSearch(e.target.value)}
                ></input>
              )}

              <button
                type="button"
                className="list-group-item list-group-item-action capfirst list-top list-parent active text-size-24"
              >
                {selectedDropDownValue}
                {isListShowHide && (<img src={arrow_up_vector} alt="" className="float-r mr-10 mt-5" onClick={() => setIsListShowHide(false)} />)}
                {!isListShowHide && (<img src={arrow_down_vector} alt="" className="float-r mr-10 mt-5" onClick={() => setIsListShowHide(true)} />)}

                {/* <img src={dropdown_icon_search} alt="" className="float-r mr-10" onClick={()=>setIsListShowHide(true)}/>*/}
              </button>
              {isListShowHide && (<div className="for-scollable-content">
                {filterCat &&
                  filterCat.map((v, index) => (
                    <React.Fragment key={index}>
                      <button
                        type="button"
                        title={v.name}
                        key={v.name}
                        onClick={(e) => onDropdownSelected(e, v.name, index)}
                        className="list-group-item list-group-item-action list-childs"
                      >
                        {v.name}
                      </button>
                    </React.Fragment>
                  ))}
              </div>)}
            </div>
          </div>)}

      </div>

    </React.Fragment>
  );
};
export default LandingBusinessType;

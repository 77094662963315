import React from "react";
import star_1 from "../../../images/landing_page_assests/star-1.svg";
import union from "../../../images/landing_page_assests/union.svg";
import HeaderLanding from "./HeaderLanding";

const SubHeader2NewLandingPage = (props) => {
  return (
    <>
      <img className="hero-sections-child new-oct-landing-start-on-top-left" alt="" src={star_1} />
      <div className="header header-position-top-fixed">
        <HeaderLanding></HeaderLanding>
      </div>

      <div
        className="union-wrapper"
        onClick={() => {
          window.scrollTo({ top: 99999, left: 0, behavior: "smooth" });
        }}
      >
        <img className="union-icon" alt="" src={union} />
      </div>
    </>
  );
};
export default SubHeader2NewLandingPage;

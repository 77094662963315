import React from "react";
import star_2 from "../../../images/landing_page_assests/star-2.svg";
const MarqueeLight = (props) => {
  return (
    <div className="marquee-wrapper line-new-landing-page" style={{ background: "#fff" }}>
      <div className="container-">
        <div className="new-marquee-block">
          <main className="new-marquee-container">
            <p className="first-slide slide__slides">
              <span>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b><img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
              </span>
            </p>
            <p className="words words--first slide__slides">
              <span>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b><img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
              </span>
            </p>
            <p className="words words--second slide__slides">
              <span>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b><img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire">Adspire®</b>
              </span>
            </p>
          </main>
        </div>
      </div >
    </div >
  );
};
export default MarqueeLight;
















import React from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";


const StyledButtonWhite2 = () => {

    // ******************** Functions ***************************************
    const navigate = useNavigate();

    // ******************** Functions ***************************************
    const checkUserStatusAndRedirect = () => {
        navigate(userService.getUserRedirectPage());
    };


    return (
        <div className="try-for-free-btn-container">
            <button className="next-wrapper1 carousal-btn try-for-free-btn" onClick={() => checkUserStatusAndRedirect()}>
                <div className="try-for-free-btn-inner-style ">
                    <b className="what-do-you try-for-free-text adspire">
                        Try for FREE!
                    </b>
                </div>
            </button>
        </div>
    );
};
export default StyledButtonWhite2;



import React from "react";
import { useNavigate } from "react-router-dom";
import megaphone from "../../../images/icons/megaphone.svg"
// import home_black_lght from "../../../images/icons/home.svg"
import { userService } from "../../../services/user.service";
const HomeButtonModal = (props) => {
  const redirectTo = () => {
    userService.clearDataAfterReferesh()
    window.location.href = "/pricing";
  };
  return (
    <>
      {/* <div className="pull-right margin-tb15-lr-10" title="Home">
        <button
          type="button"  className="btn btn-primary generateAd autowidth w-56 h-56"
          onClick={() => redirectTo()}
        >
          <img src={home_black_lght} alt="" />
        </button>
      </div>*/}

      <div className="pull-right margin-tb15-lr-10" title="Pricing">
        <button
          type="button" className="btn btn-primary generateAd autowidth w-56 h-56 homeround"
          onClick={() => redirectTo()}
        >
          <img src={megaphone} alt="" />
        </button>
      </div>
    </>
  );
};
export default HomeButtonModal;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  getWebsiteTitle,
  isEligibleForFreeAdsAPI,
} from "../../utils/function";
import { userService } from "../../services/user.service";
import { db } from "../../utils/Firebase";
import { colors } from "../../utils/constants";
import ConceptBackButton from "../common/Button/ConceptBackButton";
import ConceptStep from "../common/ConceptStep";
import SpinnerLoader from "../common/SpinnerLoader";

const PreviewAdType = () => {
  const [conceptAdType, setConceptAdType] = useState("");
  const [active, setActive] = useState(null);
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");

  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("types")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setConceptAdType(dataReceived);
        setLoader(true);
      });

    // Getting prompt value from here
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage("prompt", snapshot.data().prompt);
        userService.setKeyValueLocalstorage(
          "maxIdeas",
          snapshot.data().maxIdeas
        );
        userService.setKeyValueLocalstorage("model", snapshot.data().model);
        userService.setKeyValueLocalstorage(
          "temperature",
          snapshot.data().temperature
        );
        userService.setKeyValueLocalstorage(
          "maxTokens",
          snapshot.data().maxTokens
        );
      });

    // Getting prompt value for image
    db.collection("adIdeas")
      .doc("imageGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage(
          "promptImage",
          snapshot.data().prompt
        );
        userService.setKeyValueLocalstorage("size", snapshot.data().size);
        userService.setKeyValueLocalstorage(
          "maxImages",
          snapshot.data().maxImages
        );
      });
    userService.removeKeyLocalstorage("customeGenerateClicked");
    userService.removeKeyLocalstorage("text");
  }, []);

  document.title = getWebsiteTitle() + "Ad Type";

  const setUserSelectedConceptAdType = (conceptAdTypeObj) => {
    setButtonStatus(false);
    userService.setKeyValueLocalstorage(
      "userSelectedConceptAdTypeObj",
      conceptAdTypeObj
    );
    setClassvalue("generateAd");
    setActive(conceptAdTypeObj.data.name);
    navigate("/product-ad-ideas");
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className={`col-sm-12  col-xs-12  justifyCenter`}>
                  <ConceptStep step="3"></ConceptStep>
                </div>
                <div className="text-white justifyCenter  py-20 col-xs-12 ">
                  
                <div className="col-xs-12 col-sm-10 justifyCenter   col-md-8 col-lg-6  flex">
                 <div className="flex ">
                 <ConceptBackButton
                    className={"mr-10 cursor-pointer"}
                    alt="Back"
                    redirectPage="concept-ad-options"
                  />
                  <h1 className="gradiend-title-text1 ">What type of ad do you want?</h1>
                
                 </div>
                
                  </div>
                
                </div>
                <div className="clearfix"></div>
                <div className="col-xs-12 ">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}

                  {loading && (
                    <div className="justifyCenter col-xs-12 mt-10 ">

<div className="row    col-md-8 col-lg-6  col-xs-12 col-sm-10   ">
                      {conceptAdType &&
                        conceptAdType.map((projectsItems, index) => (
                          <div
                          className={`col-sm-6 px-20  col-xs-12`}
                            key={projectsItems.data.name}
                            title={projectsItems.data.name}
                            onClick={() =>
                              setUserSelectedConceptAdType(projectsItems)
                            }
                          >
                            <div
                              className={`thumbnail-category text-size-16 h-60 ${active == projectsItems.data.name &&
                                "box-active"
                                }`}
                              style={{
                                background: colors[index],
                              }}
                            >
                              <div className="select-boxes">
                                <img
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="img-thumbnail"
                                  width={60}
                                  alt={projectsItems.data.name}
                                  src={projectsItems.data.imageURL}
                                />
                                <span className="ad-title">
                                  {" "}
                                  {projectsItems.data.name}
                                </span>
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                    </div>
                  )}
                  {loading && (
                    <>
                      {/* NOT REQUIRED, PLS DO NOT OPEN KISHORE <NoSpecificTypeButton></NoSpecificTypeButton>*/}
                      {/* <GenerateAdIdeasButton
                        classvalue={classvalue}
                        buttonStatus={buttonStatus}
                        pagename="ad-ideas"
                      ></GenerateAdIdeasButton> */}
                      {/*<BackButton pagename="description"></BackButton> */}
                    </>
                  )}
                </div>
              </div>

              {/*<div className="form-group text-center">
                <p> <button type="btn" className="btn btn-primary white-border" onClick={() => unsetUserSelectedConceptAdType()}>No specific type</button></p>
              </div>*/}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default PreviewAdType;

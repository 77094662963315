import React, { useEffect, useState } from "react";
import ConceptBackButton from "../../common/Button/ConceptBackButton";
import simple from "../../../images/simple.svg";
import StyledButton1 from "../../common/Button/StyledButton1";
import {
  fetchBackground,
  fetchBackgroundTheme,
} from "../../../utils/Firebase.collection.helper";
import SpinnerLoader from "../../common/SpinnerLoader";
import { userService } from "../../../services/user.service";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ThemeStep from "../../common/ThemeStep";

const ChooseTheme = () => {
  const navigate = useNavigate();
  const [chooseTheme, setChooseTheme] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showAll, setShowAll] = useState(false);
  const [themeStepCount, setThemeStepCount] = useState(3);

  const [chooseThemeList, setChooseThemeList] = useState([]);
  const [chooseBackgroundImage, setChooseBackgroundImage] = useState([]);
  const [selectedTheme, setSelectedTheme] = useState("");
  const numToShow = showAll ? chooseBackgroundImage.length : 6;

  const handleGetTheme = async () => {
    setLoading(true);
    await fetchBackgroundTheme().then((res) => {
      setChooseThemeList(res);
      setLoading(false);
    });
  };

  const handleGetBackgroundImage = async (themeObj) => {
    setThemeStepCount(4);
    userService.setKeyValueLocalstorage("selectedTheme", themeObj.data.value);
    setLoading(true);
    await fetchBackground(themeObj.id).then((res) => {
      setChooseBackgroundImage(res);
      setChooseTheme(false);
      setLoading(false);
    });
  };

  const loadThemeBasedImage = async (backgroundObj) => {
    console.log("is handleGetBackgroundImage called themeName ", backgroundObj.data.backgroundIdea)
    userService.setKeyValueLocalstorage("selectedBackground", backgroundObj.data.backgroundIdea);
    navigate("/theme-ads");
  };

  const chooseAiBackground = () =>{
    const backgroundList = chooseBackgroundImage.slice(0, numToShow)
    const randomIndex = Math.floor(Math.random() * backgroundList.length);
    const aiSelectedBackground = backgroundList[randomIndex];
    loadThemeBasedImage(aiSelectedBackground)

  }

  useEffect(() => {
    handleGetTheme();
  }, []);
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="">
          <div className="top-contentDIv">
            <div className="top-ranckking   flex-column">
              <div className={`col-sm-12 col-xs-12 flex  imgTextCenter `}>
                <ThemeStep step={themeStepCount} />
              </div>
              <div className="flex content-center  p-20 align-items-center text-white ">
                <ConceptBackButton
                  className={"mr-20 cursor-pointer"}
                  alt="Back"
                  redirectPage="home"
                />
                <h1  className="text-center ">
                  {chooseTheme ? "Choose a theme" : "Choose a background style:"}
                </h1>
              </div>
              <div className="clearfix "></div>
              {loading && (
                <SpinnerLoader message="Theme data being loading..."></SpinnerLoader>
              )}
              <>
                {chooseTheme ? (
                  <div className="col-xs-12    imgTextCenter">
                    <div className="col-xs-12  col-sm-11 col-md-9 col-lg-8">
                      <div className={`col-xs-12  container  `}>
                        <div className="row ">
                          {chooseThemeList?.map((item) => {
                            return (
                              <div
                                key={item?.id}

                                className={` mb-20  align-items-center flex-column col-xs-6 col-sm-3 col-md-3 col-lg-3 cursor-pointer`}
                                onClick={() => {
                                  setSelectedTheme(item?.id);
                                  handleGetBackgroundImage(item);
                                }}
                              >
                                <div>

                                <div style={{
                               
                                }}  className="imgTextCenter   align-items-center ">

                                <div
                                  className={
                                    selectedTheme === `${item?.id}`
                                      ? " border-img-1 border-img"
                                      : "border-img-1  "
                                  }
                                >
                                  <div style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    justifyContent: 'center'
                                  }} className="imgSection ">
                                    <img
                                      alt="circle-img"
                                      width={64}
                                      src={item?.data?.imageURL}
                                    />
                                  </div>
                                </div>
                              </div>

                                <div
                                  className="product-category-text"
                                  style={{ width: "100%" }}
                                >
                                  {item?.data?.name}
                                </div>
                                </div>
                                </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="col-xs-12   imgTextCenter ">
                    <div className="col-xs-12   col-sm-6   col-lg-6 col-xl-6 col-md-8 flex  align-items-center">

                      <div className="  flex flex-column ">
                        <div className={`col-sm-12 col-xs-12 container   `}>
                          <div className="row ">
                            {chooseBackgroundImage.slice(0, numToShow)?.map((item, index) => (
                              <div
                                className={` mb-20 flex justify-content-center align-items-center flex-column col-xs-6 col-sm-6  col-md-4  cursor-pointer`}
                                onClick={() => { }}
                                key={index + "ct"}
                              >
                                <div className="exampleUrl imageUrl" onClick={() => {
                                  loadThemeBasedImage(item);
                                }}>
                                  <img
                                    alt="circle-img"
                                    src={item?.data?.exampleUrl}
                                    className="imgSection1"
                                  />
                                  <div className="product-category-text text-center">
                                    {item?.data?.name}
                                  </div>
                                </div>
                              </div>
                            ))}

                          </div>

                        </div>
                        {chooseTheme !== true && (
                          <div className=" mt-10 ">
                            <div
                              className="styled-button-1-container"
                              onClick={() => { chooseAiBackground()}}
                              style={{ width: 290 }}
                            >
                              <div className="free-trail-next-wrapper next-wrapper-btn btn-width wht-bdr3">
                                <button
                                  style={{ zIndex: 10 }}
                                  className="continue-btn-free-trail cursor-pointer  font-18-regular"
                                  data-dismiss="modal"
                                >
                                  <span>Use AI</span>
                                </button>
                              </div>
                            </div>
                            <div style={{ width: 290, margin: "auto", marginTop: 20 }}>


                              {!showAll && chooseBackgroundImage.length > 6 && (

                                <StyledButton1
                                  onClick={() => setShowAll(true)}
                                  name={"See all"}
                                  btnTheme={"light"}
                                  btnHeight={"45px"}
                                />
                              )}

                            </div>
                          </div>
                        )}
                      </div>

                    </div>

                  </div>

                )}
              </>

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseTheme;

import React from "react";
import { Link } from "react-router-dom";
import "../../components/landingpage/css/Landing-page.css";
import frame_1261153743_aboutUs_pg from "../../images/aboutUs_page_assests/frame_1261153743_aboutUs_pg.svg";
import frame_1261153744_about_pg from "../../images/aboutUs_page_assests/frame_1261153744_about_pg.svg";
import grediant from "../../images/aboutUs_page_assests/grediant.svg";
import rectangle_4358_about_pg from "../../images/aboutUs_page_assests/rectangle_4358_about_pg.svg";
import rectangle_4359_about_pg from "../../images/aboutUs_page_assests/rectangle_4359_about_pg.svg";
import rectangle_4360_about_pg from "../../images/aboutUs_page_assests/rectangle_4360_about_pg.svg";
import star_1_about_pg from "../../images/aboutUs_page_assests/star_1_about_pg.svg";
import color_bg from "../../images/landing_page_assests/color-bg.svg";
import lines_dots from "../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../images/landing_page_assests/star-1.svg";
import { getWebsiteName } from "../../utils/function";
import FooterLanding from "../landingpage/common/FooterLanding";
import HeaderLanding from "../landingpage/common/HeaderLanding";

const About = () => {
  document.title = " Generative AI Ads - About " + getWebsiteName();
  return (
    <div className="sconfirmed-main-landing-page">
      {/* <img className="noise-icon" alt="" src={noise_2x} /> */}

      <div className="hero-sections-parent">
        <div className="heros-sections">
          <img className="hero-sections-child" alt="" src={star_1} />
          <img className="lines-dots-icon" alt="" src={lines_dots} />
          <img className="line-dots-icon" alt="" src={lines_dots} />
          <img className="dual-color-bg-icon" alt="" src={color_bg} />
          <div className="header">
            <HeaderLanding></HeaderLanding>
          </div>

          <div className="title-page-name">
            <h2 className="gradient-logo-text">About Us</h2>
          </div>

          <div className="how-section1">
            <div className="row">
              <div className="how-section-child">
                <div className="">
                  <img src={rectangle_4358_about_pg} className="rounded-circle img-fluid" alt="" />
                </div>
                <div className="">
                  <p className="text-img-child">We’ve been starting and running businesses for over 20 years. And, as you probably realized yourself, no matter how good your product or business is, if you don’t have good ads, it probably won’t sell or succeed.</p>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="how-section-child">
                <div className="">
                  <p className="text-img-child">After paying literally millions of dollars to designers for ads over our 2 decade business run, and testing thousands of ads for all types of businesses and products, we longed for the day that generating effective ads easily and automatically would be possible.</p>
                </div>
                <div className="">
                  <img src={rectangle_4359_about_pg} className="rounded-circle img-fluid" alt="" />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="how-section-child">
                <div className="">
                  <img src={rectangle_4360_about_pg} className="rounded-circle img-fluid" alt="" />
                </div>
                <div className="">
                  <p className="text-img-child get-up-to">But finally, with the recent astronomical advancements of AI, we have finally been able to make this ineffable dream a reality! And we can now proudly introduce...</p>
                </div>
              </div>
            </div>
          </div>

          <div className="how-section2">
            <div className="about_container" >
              <div className="adspire-name">
                <b className="adspire8">Adspire®</b>
                <p className="about_container_child">What remarkable ads will you generate? Try it out for  <Link to={"/"}><span className="free-here-grediant">FREE <span className="about_underline" ><u>here.</u></span></span></Link></p>
              </div>
            </div>
            <img src={frame_1261153744_about_pg} alt="" className="half-circle1" />
            <img src={frame_1261153743_aboutUs_pg} alt="" className="half-circle2" />
            <img src={star_1_about_pg} alt="" className="star-1" />
            <img src={grediant} alt="" className="grediant-1" />
          </div>
          <FooterLanding></FooterLanding>
        </div>
      </div>
    </div>
  );
};

export default About;

import React, { useEffect, useState } from "react";
import AdFlowListing from "../landingpage/adFlow/AdFlowListing";
import LoadingScreen from "../landingpage/adFlow/Loading";
import Category from "../landingpage/conceptAd/Category";
import API_FIREBASE from "./../../config/apifirebase";
import { userService } from "./../../services/user.service";
import constants from "./../../utils/constants";
import {
  ImageGeneratePrompt,
  NewHomeScreenPrompt,
  getWebsiteTitle,
  updateNewSignupUserStatus,
  upperCase,
} from "./../../utils/function";
import { getStyleMaps } from "./../../utils/functionStyleMaps";
import Modal from "./Modal";
import ButtonGradient from "../common/Button/ButtonGradient";
import useWindowSize from "../../_helper/ScreenUtil";
import svg64 from "svg64"; // or use window.svg64.default
import Store from "../landingpage/adFlow/Store";

const NewHomeScreen = () => {
  const [loading, setLoader] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userProductName, setUserProductName] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [showListingPage, setShowListingPage] = useState(false);
  const [resetPreviousPage, setResetPreviousPage] = useState(false);
  const [copyImages, setCopyImages] = useState([]);
  const [quickAdResponse, setQuickAdResponse] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [formData1, setFormData1] = useState([]);
  const [isModalOpen, setModalOpen] = useState(false);
  const [paymentwarning, setPaymentwarning] = useState();
  const [sellModal, setSellModal] = useState(true);
  const [showStore, setStore] = useState(true);

  useEffect(() => {
    setPaymentwarning(userService.downloadLeftImage());
  }, [userService.getValueFromLocalstorage("userSettings")]);

  useEffect(() => {
    if (!userService.isPurchaser()) {
      window.open("/pricing", "_blank");
    } else {
      console.log("purchase true");
    }
  }, []);

  let copyItems = [];
  // Accessing the history instance created by React
  document.title = getWebsiteTitle() + "Home ";
  useEffect(() => {
    userService.setUserBackPage("new-home-screen");
  }, []);
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  // Below code reset this page to initial state
  useEffect(() => {
    if (resetPreviousPage) {
      setShowListingPage(false);
      copyItems.length = 0;
      copyItems = [];
      setButtonStatus(true);
      setResetPreviousPage(false);
      setSellModal(true);
      setStore(true);
    }
  }, [resetPreviousPage]);

  useEffect(() => {
    setTimeout(function () {
      setErrorMessage("");
    }, 4000);
  }, [errorMessage]);

  const updateButtonStatus = (newStatus) => {
    setButtonStatus(newStatus);
  };

  const handleErrors = (error) => {
    // setResetPreviousPage(false);
    setErrorMessage("Something went wrong! Please try again");
    setResetPreviousPage(true);
    setTimeout(() => {
      setErrorMessage("");
    }, 3000);
    setLoader(false);
  };

  const GenerateQuestion = async (conceptAdProductItems) => {
    userService.setKeyValueLocalstorage("uploadInputLoading", userProductName);
    const searchQuery = userProductName
      ? userProductName
      : conceptAdProductItems;
    userService.setKeyValueLocalstorage("searchQuery", searchQuery);
    setSellModal(false);
    setLoader(true);
    setShowListingPage(false);
    setShowPopup(false);
    setStore(false);
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const type = userService.getValueFromLocalstorage("typeValue");
    const userId = userService.getUserId();
    const objGenerateImage = {
      input: searchQuery,
      userId,
      type: type ? type : "business",
      prompt: NewHomeScreenPrompt(searchQuery),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };
    try {
      const res = await API_FIREBASE.post(
        "requestQuickImageIdeaNew",
        objGenerateImage,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );
      if (res.status === 200) {
        setQuickAdResponse(res);
        userService.setKeyValueLocalstorage("quickAdResponse", res);
        try {
          const userDataString = res?.data;
          let questions = userDataString.match(/Question \d+: ([^\n]+)/g);
          let exampleAnswers = userDataString.match(
            /Example Answer: i\.e\. ([^\n]+)/g
          );
          if (!questions && !exampleAnswers) {
            questions = userDataString.match(
              /(\d+\)\s.*[?])\s*i\.e\.\s([^\n]+)/g
            );
            exampleAnswers = userDataString.match(/i\.e\.\s([^\n]+)/g);
          }
          if (questions && exampleAnswers) {
            const userData = questions.map((question, index) => ({
              question: question.replace(/Question \d+: (.+)/, "$1").trim(),
              exampleAnswer: exampleAnswers[index]
                .replace(/Example Answer: i\.e\. (.+)/, "$1")
                .trim(),
            }));
            const initialFormData1 = userData.map((item, index) => ({
              constant: `${item.question}`,
              placeholder: `i.e. ${item.exampleAnswer}`,
              errorMessage: "",
              value: "",
              handleChange: (e) => handleFieldChange(e, index),
            }));
            setFormData1(initialFormData1);
            setShowPopup(true);
          } else {
            setErrorMessage("Failed to load please try again");
            setSellModal(true);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      }
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoader(false);
    }
  };

  const handleFieldChange = (e, index) => {
    setFormData1((prevFormData1) => {
      const updatedFormData = [...prevFormData1];
      const newValue = e.target.value;
      if (updatedFormData[index].value !== newValue) {
        updatedFormData[index].value = newValue;
        // Call handleChange function with the updated data
        updatedFormData[index].handleChange(e);
      }
      return updatedFormData;
    });
  };

  const handleSubmitGenerateQuickAds = async (e) => {
    const searchQuery = userService.getValueFromLocalstorage("searchQuery");

    let concatenatedValues = formData1.reduce(
      (acc, item) => acc.concat(item.value),
      []
    );
    concatenatedValues = concatenatedValues ? concatenatedValues : searchQuery;
    const combinedValues = `${userProductName} ${concatenatedValues}`;

    userService.setKeyValueLocalstorage("uploadInputLoading", userProductName);
    setLoader(true);
    setShowListingPage(false);
    setShowPopup(false);
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const type = userService.getValueFromLocalstorage("typeValue");
    const userId = userService.getUserId();
    let userInput = combinedValues.replace(",,,,", "");
    const objGenerateImage = {
      input: userInput,
      userId,
      type: type ? type : "business",
      prompt: ImageGeneratePrompt(userInput),
      maxIdeas: maxIdeas ? maxIdeas : 1,
    };

    try {
      const res = await API_FIREBASE.post(
        "requestQuickImageIdeaNew",
        objGenerateImage,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      );
      setQuickAdResponse(res);
      userService.setKeyValueLocalstorage("quickAdResponse", res);
      await getQuickAds(res);
    } catch (error) {
      handleErrors(error);
    } finally {
      setLoader(false);
    }
  };

  const getQuickAds = async (responsePrompt) => {
    const userId = userService.getUserId();

    if (userService.getValueFromLocalstorage("quickAdResponse")) {
      const dataToFilterResponse =
        userService.getValueFromLocalstorage("quickAdResponse").data;
      // Extracting the bullet points using regular expressions
      // Split the response into lines
      const linesIdeas = dataToFilterResponse.split("\n");
      // Initialize variables to store data
      let idea = "";
      // Iterate through the lines and assign values to variables
      linesIdeas.forEach((line) => {
        if (line.startsWith("Image Ad Idea")) {
          idea = line.replace("Image Ad Idea: ", "").trim();
        }
      });

      const objGenerateImage = {
        maxImages: constants.APPLICATION_INFO.MAX_IMAGE,
        userId,
        size: constants.APPLICATION_INFO.SIZE,
        prompt: idea,
        aiEngine: "StabilityAi",
      };

      try {
        const res = await API_FIREBASE.post(
          "requestQuickAdImagesV2",
          objGenerateImage,
          {
            headers: {
              authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
            },
          }
        );

        // Split the input data by lines
        if (userService.getValueFromLocalstorage("quickAdResponse")) {
          const dataToFilter =
            userService.getValueFromLocalstorage("quickAdResponse").data;
          // Extracting the bullet points using regular expressions
          // Split the response into lines
          const lines = dataToFilter.split("\n");

          // Initialize variables to store data
          let idea = "";
          let headline = "";
          let description = "";
          let cta = "";
          let reviewMessage = "";
          let reviewName = "";
          let question = "";
          let lightColor = "";
          let darkColor = "";
          let bulletPoints = [];
          const bulletPointsRegex = /^• (.+)$/;
          // Iterate through the lines and assign values to variables
          lines.forEach((line) => {
            if (line.startsWith("Image Ad Idea")) {
              idea = line.replace("Image Ad Idea: ", "").trim();
            } else if (line.startsWith("Headline")) {
              headline = upperCase(line.replace("Headline: ", "").trim());
            } else if (line.startsWith("Description")) {
              description = line.replace("Description: ", "").trim();
            } else if (line.startsWith("CTA")) {
              cta = line.replace("CTA: ", "").trim();
            } else if (line.startsWith("Review Message")) {
              reviewMessage = line.replace("Review Message: ", "").trim();
            } else if (line.startsWith("Review Name")) {
              reviewName = line.replace("Review Name: ", "").trim();
            } else if (line.startsWith("Question")) {
              question = line.replace("Question: ", "").trim();
            } else if (line.startsWith("Color 1 (Dark)")) {
              darkColor = line.replace("Color 1 (Dark): ", "").trim();
            } else if (line.startsWith("Color 2 (Light)")) {
              lightColor = line.replace("Color 2 (Light): ", "").trim();
            } else if (line.startsWith("Bullet Points")) {
              // Start collecting bullet points until the next title using regex
              let index = lines.indexOf(line) + 1;
              while (
                index < lines.length &&
                bulletPointsRegex.test(lines[index])
              ) {
                const match = lines[index].match(bulletPointsRegex);
                if (match) {
                  bulletPoints.push(match[1].trim());
                }
                index++;
              }
            }
          });

          let i = 0;
          if (Array.isArray(res.data.imagesBase64)) {
            const totalImageCount = res.data.imagesBase64.length;
            for (const item of res.data.imagesBase64) {
              // for testing open below code
              const imagesBase64 = item;

              const styleMaps = await getStyleMaps(i, lightColor, darkColor);

              userService.setKeyValueLocalstorage("bulletpoints", bulletPoints);
              userService.setKeyValueLocalstorage(
                "creativeAIheadline",
                headline
              );
              userService.setKeyValueLocalstorage(
                "creativeAIdescription",
                description
              );
              userService.setKeyValueLocalstorage("creativeAIcta", cta);
              const objGenerateSecondImage = {
                title: headline,
                description: description,
                cta: cta,
                height: 1024,
                width: 1024,
                bulletpoints: bulletPoints,
                style: styleMaps,
                question: question,
                reviewMessage: reviewMessage,
                reviewName: reviewName,
              };

              const secondRes = await API_FIREBASE.post(
                "requestCaptionImageAdOverlayV1",
                objGenerateSecondImage,
                {
                  headers: {
                    authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
                  },
                }
              );

              // await mergeBase64ImagesimagesBase64, i, copyItems, secondRes.data);
              // Call mergeImages with await
              await mergeImages(
                imagesBase64,
                i,
                copyItems,
                secondRes.data,
                totalImageCount
              );
              // Use the mergedImageBase64 as needed

              // since i started from 0
              i++;
            }
          }
        }
      } catch (error) {
        handleErrors(error);
      } finally {
        setLoader(false);
      }
    }
  };

  const mergeImages = async (
    imagesBase64,
    i,
    copyItems,
    base64OverLayImage,
    totalImageCount
  ) => {
    try {
      if (imagesBase64) {
        const remoteImage1 = "data:image/png;base64," + imagesBase64;
        const remoteImageBlob = await (await fetch(remoteImage1)).blob();
        let base64Image = "";
        try {
          base64Image = svg64(base64OverLayImage);
        } catch (error) { }
        const base64Blob = await (await fetch(base64Image)).blob();

        const canvas = document.createElement("canvas");
        const context = canvas.getContext("2d");

        const remoteImage = new Image();
        remoteImage.onload = async () => {
          canvas.width = remoteImage.width;
          canvas.height = remoteImage.height;
          context.drawImage(remoteImage, 0, 0);

          const base64ImageObj = new Image();
          base64ImageObj.onload = async () => {
            context.drawImage(base64ImageObj, 0, 0);

            const mergedImageDataUrl = canvas.toDataURL();

            copyItems.push(mergedImageDataUrl);
            // since i started from 0
            if (i === totalImageCount - 1) {
              setShowListingPage(true);
              setLoader(false);
              setCopyImages([...copyImages, ...copyItems]);
              if (
                userService.getValueFromLocalstorage("generatedAdsNumber") === 0
              ) {
                await updateNewSignupUserStatus();
              }
            }
          };
          base64ImageObj.src = URL.createObjectURL(base64Blob);
        };
        remoteImage.src = URL.createObjectURL(remoteImageBlob);
      }
    } catch (error) {
      console.error("Error in mergeImages:", error);
    } finally {
    }
  };

  const handleChangename = (e, index) => {
    const newFormData = [...formData];
    newFormData[index].value = e.target.value;
    setUserProductName(newFormData[index].value);
  };
  const [formData, setFormData] = useState([
    {
      constant: "What do you sell?",
      errorMessage: errorMessage,
      value: userProductName,
      handleChange: handleChangename,
      placeholder: "Ex: brown leather jacket",
    },
  ]);

  return (
    <>
      {errorMessage && (
        <div className="text-error alert alert-danger errorPopup">
          {errorMessage}
        </div>
      )}
      {showListingPage && (
        <AdFlowListing
          handleUpload={handleSubmitGenerateQuickAds}
          setShowListingPage={setShowListingPage}
          showListingPage={showListingPage}
          copyImages={copyImages}
          setResetPreviousPage={setResetPreviousPage}
          pageName="home"
        ></AdFlowListing>
      )}

      {loading && !showListingPage && <LoadingScreen />}

      {sellModal && (
        <>
          <div className="font-family-vietnam-pro">
            {buttonStatus && (
              <>
                <Modal
                  formData={formData}
                  errorMessage={errorMessage}
                  ShowPopup={GenerateQuestion}
                />
                <div className="text-white text-center row display-mauto">
                  <h3>Or tap a niche: </h3>
                </div>

                <div className="or-with-horizontal-line">
                  <div className="tecenter">OR</div>
                </div>
              </>
            )}
            <Category
              GenerateQuestion={GenerateQuestion}
              buttonStatus={buttonStatus}
              updateButtonStatus={updateButtonStatus}
              setStore={setStore}
            />
          </div>
        </>
      )}
      {showStore && (
        <div className="new-home-screen-store-section">
          <hr className="divider-horizontal" />
          <Store
            setSellModal={setSellModal}
            setResetPreviousPage={setResetPreviousPage}
          />
        </div>
      )}
      {showPopup && (
        <>
          <div className="font-family-vietnam-pro">
            <Modal
              formData={formData1}
              handleSubmitGenerateQuickAds={handleSubmitGenerateQuickAds}
              handleChange={handleFieldChange}
            />
          </div>
        </>
      )}

      {paymentwarning == 0 && (
        <div
          className={`modal overflows-auto bg-dark-opacity-10 ${isModalOpen ? "show" : ""
            }`}
          style={{
            display: isModalOpen ? "block" : "none",
            marginLeft: isMobile ? "" : "12%",
          }}
        >
          <div
            className="modal-dialog modal-600 modal-350-mobile modal-dialog-centered font-family-vietnam-pro top-20-percent"
            role="document"
          >
            <div className="modal-content modal-gradient-new">
              <div className="modal-body text-center p-60">
                <div className="">
                  <div className="">
                    <h1 className="text-white text-size-30 m-0">
                      <span className="text-weight-600">
                        You have {paymentwarning} ads remaining renew ur plan
                      </span>
                    </h1>
                  </div>
                  <div className="text-center mt-20">
                    <ButtonGradient
                      title="Dismiss"
                      stylebyclass="gradient-head-text text-size-24 text-black"
                      btnstyle={{
                        background: "white",
                        height: "40px",
                        padding: "3px",
                      }}
                      btnstylebyclass="width-80-percent m-left-right-auto mt-10 mb-10"
                      onClick={() => {
                        setModalOpen(false);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default NewHomeScreen;

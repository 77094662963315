import React from "react";
import star_7 from "../../../images/landing_page_assests/star-7.svg";
import star_9 from "../../../images/landing_page_assests/star-9.svg";
import idea_1 from "../../../images/landing_page_assests/idea-1.svg";
import iconidea from "../../../images/landing_page_assests/iconidea.svg";
import iconad_goal from "../../../images/landing_page_assests/iconad-goal.svg";
import ad_ideas_img from "../../../images/landing_page_assests/ad_ideas1.svg";
import iconstyle_image from "../../../images/landing_page_assests/iconstyle-image.svg";

import arrow_08 from "../../../images/landing_page_assests/arrow-08.svg";
import rectangle_4354 from "../../../images/landing_page_assests/rectangle-4354.svg";
import _00113000x3000_4_2x from "../../../images/landing_page_assests/00113000x3000-4@2x.png";
import rectangle_43541 from "../../../images/landing_page_assests/rectangle-43541.svg";
import _00113000x3000_41_2x from "../../../images/landing_page_assests/00113000x3000-41@2x.png";
import rectangle_43542 from "../../../images/landing_page_assests/rectangle-43542.svg";
import _00113000x3000_42_2x from "../../../images/landing_page_assests/00113000x3000-42@2x.png";
import rectangle_43543 from "../../../images/landing_page_assests/rectangle-43543.svg";
import _00113000x3000_43_2x from "../../../images/landing_page_assests/00113000x3000-43@2x.png";
import star_21 from "../../../images/landing_page_assests/star-21.svg";
import star_22 from "../../../images/landing_page_assests/star-22.svg";
import line1 from "../../../images/landing_page_assests/line1.svg";
import grediant from "../../../images/landing_page_assests/grediant.svg";
import rectangle_4359 from "../../../images/landing_page_assests/rectangle-4359.svg";
import career_1 from "../../../images/landing_page_assests/career-1.svg";
import rectangle_43591 from "../../../images/landing_page_assests/rectangle-43591.svg";
import rocket_1_1 from "../../../images/landing_page_assests/rocket-1-1.svg";
import rectangle_43592 from "../../../images/landing_page_assests/rectangle-43592.svg";
import shoppingbag_1 from "../../../images/landing_page_assests/shoppingbag-1.svg";
import travelagency_1_1 from "../../../images/landing_page_assests/travelagency-1-1.svg";
import rectangle_43595 from "../../../images/landing_page_assests/rectangle-43595.svg";
import mybusiness_1 from "../../../images/landing_page_assests/mybusiness-1.svg";
import perspectivegridflat_1 from "../../../images/landing_page_assests/perspectivegridflat-1.svg";
import _3rd_section_bg from "../../../images/landing_page_assests/3rd-section-bg.svg";
import { message } from "../../../utils/landingConstants";
import line from "../../../images/landing_page_assests/line.svg";
import Subscribe from "../../Subscribe";
import ImageSlider from "./ImageSlider";
import UnleaseImageAds from "./UnleaseImageAds";
import UnleaseAdIdea from "./UnleaseAdIdea";
import ImageSlider1 from "./ImageSlider1";
const SubCarouselFooter = (props) => {
  return (
    <>
      {" "}
      <div className="nd-sections">
        <img className="line-icon" alt="" src={line} />
        <UnleaseImageAds></UnleaseImageAds>

        <ImageSlider1 />

      </div>
      <div className="star-parent">
        <img className="frame-child6" alt="" src={star_7} />
        <img className="frame-child7" alt="" src={star_9} />
        <div className="group-parent1">
          <UnleaseAdIdea></UnleaseAdIdea>
          <div className="frame-parent4">
            <div className="group-parent2">
              <img className="frame-child8" alt="" src={ad_ideas_img} />
              <div className="frame-child9" />
              <div className="iconidea-wrapper">
                <img className="iconidea" alt="" src={iconidea} />
              </div>
              <div className="iconad-goal-wrapper">
                <img className="iconad-goal" alt="" src={iconad_goal} />
              </div>
            </div>
            <div className="iconstyle-image-wrapper">
              <img className="iconstyle-image" alt="" src={iconstyle_image} />
            </div>
            <div className="frame-parents5">
              <div className="a-skateboader-standing-stop-a-wrapper">
                <div className="a-skateboader-standing">
                  {message.ad_ideas1}
                </div>
              </div>
              <div className="a-person-wearing-a-stylish-out-wrapper">
                <div className="a-person-wearing">{message.ad_ideas2}</div>
              </div>
              <div className="a-skateboader-standing-stop-a-container">
                <div className="a-skateboader-standing">
                  {message.ad_ideas3}
                </div>
              </div>
              {/* <div className="next-wrapper3">
                  <b className="what-do-you">Show more</b>
                </div>*/}
            </div>
            <img className="arrow-08-icon" alt="" src={arrow_08} />
          </div>
        </div>
      </div>
      <div className="frame-parent6">
        <div className="group-wrapper">
          <div className="generate-powerful-driving-parent">
            <div className="generate-powerful-container">
              <span className="unlimited-captions-txt-container">
                {`Generate `}
                <span className="incredible cursor-pointer">Powerful</span>
                {` & `}
                <span className="incredible cursor-pointer">Driving</span>
              </span>
            </div>
            <div className="captions-wrapper">
              <div className="caption">Captions</div>
            </div>
          </div>
        </div>
        <div className="frame-parents7">
          <div className="group-frame">
            <div className="group-div">
              <div className="group-inner" />
              <img className="group-child1" alt="" src={rectangle_4354} />
              <div className="rectangle-div" />
              <img className="x3000-4-icon" alt="" src={_00113000x3000_4_2x} />
              <div className="make-a-statement">{message.captions1}</div>
            </div>
          </div>
          <div className="group-wrapper1">
            <div className="rectangle-parent5">
              <div className="group-child2" />
              <img className="group-child3" alt="" src={rectangle_43541} />
              <div className="group-child4" />
              <img
                className="x3000-4-icon1"
                alt=""
                src={_00113000x3000_41_2x}
              />
              <div className="come-to-you">{message.captions2}</div>
            </div>
          </div>
          <div className="group-wrapper2">
            <div className="rectangle-parent6">
              <div className="group-child5" />
              <img className="group-child6" alt="" src={rectangle_43542} />
              <div className="group-child7" />
              <img
                className="x3000-4-icon2"
                alt=""
                src={_00113000x3000_42_2x}
              />
              <div className="turn-heads-on">{message.captions3}</div>
            </div>
          </div>
          <div className="group-wrapper3">
            <div className="rectangle-parent7">
              <div className="group-child8" />
              <img className="group-child9" alt="" src={rectangle_43543} />
              <div className="group-child10" />
              <img
                className="x3000-4-icon3"
                alt=""
                src={_00113000x3000_43_2x}
              />
              <div className="unleash-your-individual">
                “Unleash your individual style - shop for your custom skateboard
                now!”
              </div>
            </div>
          </div>
        </div>
        {/*<div className="next-wrapper4">
            <b className="what-do-you">Show more</b>
          </div>*/}
        <img className="frame-child10" alt="" src={star_7} />
        <img className="frame-child11" alt="" src={star_7} />
        <img className="frame-child12" alt="" src={star_21} />
        <img className="frame-child13" alt="" src={star_22} />
      </div>
      <div className="line-parent">
        <img className="line-icon1" alt="" src={line1} />
        <div className="grediant-parent">
          <img className="grediant-icon" alt="" src={grediant} />
          <div className="adspire-is-for">Adspire® is for...</div>
        </div>
        <div className="frame-parent8">
          <div className="group-parent3">
            {/* <div className="vector-group">
                <img
                  className="group-child11"
                  alt=""
                  src={rectangle_4359}
                />
                <div className="career-1-parent cursor-pointer">
                  <img className="career-1-icon" alt="" src={career_1} />
                  <div className="small-business">Small Businesses</div>
                </div>
        </div>*/}

            <div className="vector-group">
              <img className="group-child11" alt="" src={rectangle_4359} />
              <div className="career-1-parent cursor-pointer">
                <img className="career-1-icon" alt="" src={shoppingbag_1} />
                <div className="small-business">E-Commerce</div>
              </div>
            </div>

            <div className="vector-container">
              <img className="group-child12" alt="" src={rectangle_43591} />
              <div className="rocket-1-1-parent cursor-pointer">
                <img className="career-1-icon" alt="" src={rocket_1_1} />
                <div className="small-business">Startups</div>
              </div>
            </div>
            <div className="vector-parent1">
              <img className="group-child13" alt="" src={rectangle_43592} />
              <div className="shopping-bag-1-parent cursor-pointer">
                <img className="career-1-icon" alt="" src={travelagency_1_1} />
                <div className="small-business">Agencies</div>
              </div>
            </div>
          </div>
          <div className="group-parent4">
            {/* <div className="vector-parent2">
                <img
                  className="group-child14"
                  alt=""
                  src={rectangle_43593}
                />
                <div className="travel-agency-1-1-parent cursor-pointer">
                  <img
                    className="career-1-icon"
                    alt=""
                    src={travelagency_1_1}
                  />
                  <div className="small-business">Agencies</div>
                </div>
      </div>
              <div className="vector-parent3">
                <img
                  className="group-child15"
                  alt=""
                  src={rectangle_43594}
                />
                <div className="restaurant-1-parent cursor-pointer">
                  <img
                    className="career-1-icon"
                    alt=""
                    src={restaurant_1}
                  />
                  <div className="small-business">Restaurants</div>
                </div>
              </div>*/}
            <div className="vector-parent4">
              <img className="group-child16" alt="" src={rectangle_43595} />
              <div className="my-business-1-parent cursor-pointer">
                <img className="career-1-icon" alt="" src={mybusiness_1} />
                <div className="small-business">Retail</div>
              </div>
            </div>
            <div className="vector-group">
              <img className="group-child11" alt="" src={rectangle_4359} />
              <div className="career-1-parent-2 cursor-pointer">
                <img className="career-1-icon" alt="" src={career_1} />
                <div className="small-business">Small Businesses</div>
              </div>
            </div>
          </div>
        </div>
        <div className="and-any-business-wrapper">
          <div className="and-any-business">
            and <span className="underline cursor-pointer">literally ANY</span>{" "}
            Business!
          </div>
        </div>
      </div>
      <div className="perspective-grid-flat-1-parent" id="free-trial-subscription">
        <img
          className="perspective-grid-flat-1-icon"
          alt=""
          src={perspectivegridflat_1}
        />
        <img className="rd-section-bg1" alt="" src={_3rd_section_bg} />
        <div className="what-are-you-container">
          <span className="unlimited-caption-txt-container">
            <span>
              {`What are you waiting for?
              Subscribe for as low as `}
            </span>
            <span className="mo">$16/mo!</span>
          </span>
        </div>
        <div className="mo-wrapper">
          <div className="caption">$16/mo!</div>
        </div>

        {/* -------------- SubsCribe----------------------- */}

        <div className="table-subscribe">
          <Subscribe></Subscribe>
        </div>
        <img className="frame-child14" alt="" src={star_7} />
      </div>
    </>
  );
};
export default SubCarouselFooter;

import React, { useEffect, useState } from "react";
import bottom_image from "../../../images/free_trail_page_assests/bottom-image.png";
import iconai from "../../../images/free_trail_page_assests/iconai.svg";
import iconchat from "../../../images/free_trail_page_assests/iconchat.svg";
import iconidea from "../../../images/free_trail_page_assests/iconidea.svg";
import iconimage from "../../../images/free_trail_page_assests/iconimage.svg";
import { fbEventsService } from "../../../services/fbevents.service";
import { userService } from "../../../services/user.service";
import { PAYMENT_SUCCESS_MSG, PAYPAL_MONTHLY_TRIAL_PRICE, QUICK_FREE_TRIAL } from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import SpinnerLoader from "../../common/SpinnerLoader";
import CheckoutFreetrialQuickMonthlyUltra from "../../common/StripePayment/CheckoutFreetrialQuickMonthlyUltra";

const QuickFreeTrial = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);

  useEffect(() => {
    fbEventsService.sendFbEvents(QUICK_FREE_TRIAL, process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME, PAYPAL_MONTHLY_TRIAL_PRICE);
    userService.removeKeyLocalstorage("quickFreeTrial");
  }, []);

  const redirectTo = (pageName) => {
    window.location.href = ("/" + pageName);
  };

  document.title = getWebsiteTitle() + " Free Trial";
  return (
    <div >
      <div className="col-md-12 col-sm-offset-0 mx-auto">
        <div className="group-free-trail-parent">
          <div className="frame-free-trail-parent">
            <div className="free-trail-frame-group">
              <div className="free-trail-welcome-to-adspire-wrapper">
                <div className="free-trail-welcome-to-adspire-container">
                  <span className="free-trail-welcome-to">
                    <span>Welcome to</span>
                    <span className="span">{` `}</span>
                  </span>
                  <span className="span">
                    <b className="free-trail-adspire2 cursor-pointer" onClick={() => redirectTo('')}>

                      Adspire</b>
                  </span>
                  {paymentProccessStart && (<div><SpinnerLoader></SpinnerLoader>
                    <p className="text-center-normal-mar30"><b>{PAYMENT_SUCCESS_MSG}</b></p>
                  </div>)}
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-offset-4">
              <div className="product-flow-landing-wrapper1 box-width text-center">
                <div className="product-flow-landing-wrapper2">
                  <div className="free-trail-frame-parent1 text-center">
                    <div className="text-center col-lg-12">
                      <p>
                        <button className="free-trail-one-time-offer-wrapper">
                          <div className="limited-time-offer-text font-15-regular">LIMITED TIME OFFER !</div>
                        </button>
                      </p>
                      <p>
                        <b className="try-out-the-container text-center">
                          <span className="try-out-parent">Try out the <span className="full-power">Full Power</span><span> of </span></span>

                          <span className="adspire-free-trail-3 logo-text">ADSPIRE</span><span className="adspire-for"> for </span><span className="adspire-free underline"> FREE!</span>
                        </b></p>
                    </div>


                    <div className="free-trail-content">
                      <div className="free-trail-iconidea-parent">
                        <ul className="step-icondiv">
                          <li>   <img className="free-trail-iconidea" alt="" src={iconidea} /><br></br>Genius <br></br>Ad Ideas!</li>
                          <li>   <img className="free-trail-iconidea" alt="" src={iconimage} /><br></br>Powerful<br></br>Image Ads!</li>
                          <li>   <img className="free-trail-iconidea" alt="" src={iconchat} /><br></br>Effective<br></br>Captions!</li>
                          <li>  <img className="free-trail-iconidea" alt="" src={iconai} /><br></br>Incredibly <br></br>Powerful AI!</li>
                        </ul>
                      </div>
                    </div>


                    <CheckoutFreetrialQuickMonthlyUltra setPaymentProccessStart={setPaymentProccessStart}></CheckoutFreetrialQuickMonthlyUltra>


                    <div className="try-3-days-for-free-parent">
                      <b className="try-3-days-container">
                        {`Try 3 days for `}
                        <span className="free-trail-free1 underline">FREE!</span>
                      </b>
                      <div className="then-34999year">

                        Then $64.99/Month
                      </div>

                    </div>
                    <div className="free-trail-instance-parent">


                      <div className="no-obligation-to-continue-jus-parent">
                        <div className="no-obligation-to-container">
                          <p className="no-obligation-to font-15-regular">{`No obligation to continue, just cancel `}</p>
                          <p className="no-obligation-to font-15-regular">
                            before the trial ends.
                          </p>
                        </div>
                        <div className="terms-conditions-parent">
                          <div className="welcome-to-adspire-container pointerclass font-15-regular" onClick={() => redirectTo('legal/tos')}>{`Terms & Conditions`}</div>
                          <div className="welcome-to-adspire-container pointerclass font-15-regular" onClick={() => redirectTo('legal/privacy')}>
                            Privacy Policy
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="img-bg-bottom"> <img className="product-flow-img" alt="" src={bottom_image} /></div>
              </div>
              {/* <img className="free-trail-frame-child-second-img" alt="" src={frame_1261153255} /> */}
            </div>
          </div>
        </div>
      </div>
    </div>

  );

};

export default QuickFreeTrial;
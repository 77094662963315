import React from "react";
import welcome_assests from "../images/welcome_assests.svg";
import golden_quick_ad from "../images/icons/golden_quick_ad.svg";
import green_setting_custom_ad from "../images/icons/green_setting_custom_ad.svg";
import { userService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import ButtonGradient from "./common/Button/ButtonGradient";

const WelcomeScreen = () => {
  const navigate = useNavigate();
  const redirectTo = (pagename) => {
    if (!userService.isUserFreeAdsStatus()) {
      pagename = userService.getSubscribePageName();
      window.open("/" + pagename, "_blank");
    } else {
      userService.setKeyValueLocalstorage("userSelectedAdTypePage", pagename);
      navigate("/" + pagename);
    }
  };

  return (
    <div className="row">
      <div className="free-trail-frame-container">
        <div className={`col-12 col-md-12 col-lg-12 col-lg-offset-1 welcome-trail-responsive`}>
          <div className="mt-40 text-center">
            <b className="try-out-the-container">
              <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro"> Welcome to <span className="payment-success-adspire-text font-60 text-size-44">ADSPIRE !</span> </h3>
            </b>
            <p className="text-white text-center text-size-32 font-family-vietnam-pro m-0">You can generate <span className="text-weight-600 underline">unlimited</span> ads</p>
            <p className="text-white text-center text-size-32 font-family-vietnam-pro m-0">& download 5 during your trial.</p>
            <div className="row col-lg-offset-1 mt-40">
              <div className="col-sm-1"></div>
              <div className="col-sm-8">
                <ButtonGradient
                  title="Generate Ads"
                  btnstyle={{
                    textShadow: "inset 5px 39px 45px -25px",
                    width: "340px",
                    height: "70px",
                    paddingTop: "15px",
                  }}
                  stylebyclass="text-size-30 text-weight-600 text-white"
                  btnstylebyclass="m-left-right-auto"
                  onClick={() => navigate("/home")}
                />
              </div>
              <div className="col-sm-1"></div>
            </div>
          </div>
          <img alt="" src={welcome_assests} width={400} />
        </div>
        {/* <div className="col-12 col-md-12 col-lg-12"> */}
        {/* </div> */}
      </div>
    </div>

  );
};

export default WelcomeScreen;

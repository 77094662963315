import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SpinnerLoader from "../../components/common/SpinnerLoader";
import { userService } from "../../services/user.service";
import { getWebsiteTitle } from "../../utils/function";

import ellipse_143 from "../../images/landing_page_assests/ellipse-143.svg";
import group_1261152240 from "../../images/landing_page_assests/group-1261152240.svg";
import vector from "../../images/landing_page_assests/vector.svg";

import "./css/Landing-page.css";

import LandingAdType from "./questionsFlow/LandingAdType";
import LandingBrieflyDescriptions from "./questionsFlow/LandingBrieflyDescriptions";
import LandingBusinessType from "./questionsFlow/LandingBusinessType";

import { fetchAdCategories } from "../../utils/Firebase.collection.helper";
import EffectiveAds from "./common/EffectiveAds";
import FooterLanding from "./common/FooterLanding";
import Marquee from "./common/Marquee";
import Roas from "./common/Roas";
import SubFooter from "./common/SubFooter";
import SubHeader from "./common/SubHeader";
import WelcomeFreeTrailLanding from "./common/WelcomeFreeTrailLanding";
import LandingAdIdeas from "./questionsFlow/LandingAdIdeas";
import LandingGenerateImage from "./questionsFlow/LandingGenerateImage";
import LandingImageStyle from "./questionsFlow/LandingImageStyle";
import QuestionBox from "./questionsFlow/QuestionBox";
const LandingPageNew = () => {
  const [adCategory, setAdCategory] = useState("");
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null);
  const [currentPage, setCurrentPage] = useState("questionPage");
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        if (isMounted) {
          const snapshot = await fetchAdCategories(setAdCategory, setLoader);
          // Do additional logic if needed with the snapshot
          userService.clearQuestionFlowData();
        }
      } catch (error) {
        console.error('Error fetching ad categories:', error);
        // Handle error
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };

  }, []);

  const setBusinessValue = (adCategory) => {
    setButtonStatus(false);
    setActive(adCategory.data.name);
    setClassvalue("next-btn-enable");
    userService.setKeyValueLocalstorage(
      "adCategoryValue",
      adCategory.data.value
    );
    userService.setKeyValueLocalstorage("categoryName", adCategory.data.name);
    userService.setKeyValueLocalstorage("adCategoryID", adCategory.id);
    userService.setKeyValueLocalstorage(
      "adCategoryQuestion",
      adCategory.data.question
    );
    setCurrentPage("business-type");
  };

  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };
  const getClass = () => {
    if (currentPage === "generate-image") {
      return "ellipse-parent-space-reduce";
    }
  };

  document.title = getWebsiteTitle() + "Home";
  return (
    <>


      <div className="confirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
        <div className="hero-sections-parent">
          <div className="hero-sections">
            <SubHeader />

            <EffectiveAds />

            <img className="vector-icon" alt="" src={vector} />
            <div className="ellipse-box">
              <div className={`ellipse-parent ${getClass()}`}>
                <img className="frame-child" alt="" src={ellipse_143} />
                <b className="adspire1">Adspire®</b>
                <div className={`what-do-you-want-an-ad-for ${currentPage}`}>
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..." />
                  )}
                  {currentPage === "questionPage" && loading && (
                    <QuestionBox currentPage={setCurrentPage} />
                  )}

                  {currentPage === "business-type" && loading && (
                    <LandingBusinessType
                      currentPage={setCurrentPage}
                    />
                  )}
                  {currentPage === "free-trial" && loading && (
                    <WelcomeFreeTrailLanding
                      currentPage={setCurrentPage}
                    />
                  )}

                  {currentPage === "briefly-describe-business" && loading && (
                    <LandingBrieflyDescriptions
                      currentPage={setCurrentPage}
                    />
                  )}

                  {currentPage === "ad-type" && loading && (
                    <LandingAdType currentPage={setCurrentPage} />
                  )}

                  {currentPage === "ad-ideas" && loading && (
                    <LandingAdIdeas
                      currentPage={setCurrentPage}
                    />
                  )}
                  {currentPage === "image-style" && loading && (
                    <LandingImageStyle
                      currentPage={setCurrentPage}
                    />
                  )}
                  {currentPage === "generate-image" && loading && (
                    <LandingGenerateImage
                      currentPage={setCurrentPage}
                    />
                  )}


                  <div className="component-2">
                    <div className="next6">Start</div>
                  </div>
                </div>
              </div>
              <div className="mockup">
                <img className="mockup-child" alt="" src={group_1261152240} />
              </div>
            </div>
          </div>
          <Marquee />
          <Roas />
          <SubFooter />



          <FooterLanding />
        </div>
      </div>
    </>
  );
};
export default LandingPageNew;
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";

import {
  filterIdeas,
  getWebsiteTitle
} from "../../../utils/function";

import SpinnerLoader from "../../../components/common/SpinnerLoader";
import API_FIREBASE from "../../../config/apifirebase";
import PressIdea from "../../../images/icons/PressIdea.png";
import { db } from "../../../utils/Firebase";
import constants from "../../../utils/constants";

const LandingAdIdeas = (props) => {
  const [adStyles, setStyles] = useState("");
  const [ideas, setIdeas] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [loading, setLoader] = useState(false);
  const [completionText, setCompletionText] = useState("");
  const [active, setActive] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");
  const [openInputBox, setOpenInputBox] = useState(false);

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    generateAIChoicesIdeas();

    db.collection("users")
      .where("userId", "===", userService.getUserId())
      .onSnapshot((snapshot) => {
        const userDataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          credits: doc.data().credits,
        }));
      });

    const textValue = userService.getValueFromLocalstorage("text");

    if (
      userService.getValueFromLocalstorage("showLocalAdIdeas") === "yes" &&
      userService.getValueFromLocalstorage("text")
    ) {
      setLoader(true);

      setCompletionText(filterIdeas(textValue));
      userService.removeKeyLocalstorage("showLocalAdIdeas");
    } else {
      // generateAdIdeas();
    }
  }, []);

  useEffect(() => {
    // generateAdIdeas();
  }, [userService.getValueFromLocalstorage("typeValue")]);

  const generateAIChoicesIdeas = async () => {
    setLoader(false);
    const input = userService.getValueFromLocalstorage("input");
    const adCategory = userService.getValueFromLocalstorage("adCategoryValue");

    const objGenerateImage = {
      input,
      adCategory,
      landingPage: true,
    };

    API_FIREBASE.post("requestAIChoiceTypeFromOpenAI", objGenerateImage, {
      headers: {
        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
      },
    })
      .then((res) => {
        userService.setKeyValueLocalstorage("typeValue", res.data); // new implementation
        generateAdIdeas();
      }, [])
      .catch((e) => {
        setLoader(true);
      });
  };

  const generateAdIdeas = async () => {
    setLoader(false);
    const input = userService.getValueFromLocalstorage("input");
    const userId = userService.getUserId();
    const typeId = userService.getValueFromLocalstorage("typeIDOfAdValue");
    const type = userService.getValueFromLocalstorage("typeValue");
    const adCategoryId = userService.getValueFromLocalstorage("adCategoryID");
    const prompt = userService.getValueFromLocalstorage("prompt");
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const model = userService.getValueFromLocalstorage("model");
    const temperature = userService.getValueFromLocalstorage("temperature");
    const maxTokens = userService.getValueFromLocalstorage("maxTokens");
    // const maxTokens = userService.getValueFromLocalstorage("maxTokens");

    const objGenerateImage = {
      input,
      userId,
      typeId,
      type,
      adCategoryId,
      prompt,
      maxIdeas,
      model,
      temperature,
      maxTokens,
      landingPage: true,
    };
    let completionTextArr = [];
    API_FIREBASE.post("requestTextCompletionFromOpenAI", objGenerateImage, {
      headers: {
        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
      },
    })
      .then((res) => {
        setCompletionText(filterIdeas(res.data.completionText));
        userService.setKeyValueLocalstorage("text", res.data.completionText);

        userService.setKeyValueLocalstorage(
          "userIdeaHistoryId",
          res.data.userIdeaHistoryId
        );
        setLoader(true);
      }, [])
      .catch((e) => {
        if (e.response.status === 500) {
          //check the library you use to fetch data for how they responds with error statuses

          // used to open the popup
          const myBtn = document.getElementById("psubscribeModal");
          myBtn.click();
          /* setErrorMessage(
            formatErrorMessage(APPLICATION_ERROR_MESSAGE.CREDIT_REACHED)
          ); */
        }

        setLoader(true);
      });
  };

  const colors = [
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
  ];

  document.title = getWebsiteTitle() + "Ad Ideas";
  const userSelectedIdeas = (ideas) => {
    userService.setKeyValueLocalstorage("ideas", ideas);
    setUserInputValue(ideas);

    setActive(ideas);
    setButtonStatus(false);
    setClassvalue("generateAd");
  };
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };

  const AdduserInsertedIdea = (e) => {
    setUserInputValue(e.target.value);
    userService.setKeyValueLocalstorage("ideas", e.target.value);
  };

  const getText = (userClickedText, realText) => {
    if (userClickedText === realText) {
      return false;
    } else if (
      (userClickedText === realText && !openInputBox) ||
      !openInputBox
    ) {
      return true;
    }
    return false;
  };

  const getInputBtn = (userClickedTextActive, realText) => {
    if (userClickedTextActive === realText) {
      return true;
    }
    return false;
  };

  return (
    <React.Fragment>
      <div className="top-ranckking">
        {/*<h2 className="gradient-head-text text-center text-size-32 mb-40">
                  Here are some{" "}
                  {userService.getValueFromLocalstorage("typeValue")} ad ideas I
                  thought of:
                </h2>*/}

        <div className="what-do-you-want-an-ad-for-wrapper">
          <b className="what-do-you">
            {!loading && (
              <span>
                Generating ad ideas... <br></br>
                Please wait..<br></br>
                This can take up to 1 minute<br></br>
              </span>
            )}

            {loading && completionText && (
              <span>
                Here are some{" "}
                {userService.getValueFromLocalstorage("typeValue")}, I thought
                of
              </span>
            )}
          </b>

          {/*<b className="what-do-you"> Here are some{" "}
            {userService.getValueFromLocalstorage("typeValue")} ad ideas I
            thought of:</b>*/}
        </div>
        {errorMessage && (
          <p className="error-message text-center">{errorMessage}</p>
        )}

        <div className="clearfix"></div>
        <div className="col-sm-6 col-sm-offset-3 mt-10">
          {!loading && (
            <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
          )}
          {loading && (
            <div className="row mb-20">
              {completionText &&
                completionText.map((adcategoryItems, index) => (
                  <div
                    className={`col-sm-12 col-xs-12 ${adcategoryItems === "" ? "hideIt" : "nothideIt"
                      }`}
                    key={index + "ideas"}>
                    <div
                      className={`some-ad-idea-thumbnailBG text-size-16 ${active === adcategoryItems && "box-active"
                        }`}
                      style={{
                        backgroundColor: colors[index],
                      }}
                    >
                      <div className="">
                        <span className=" text-weight-500">
                          {getInputBtn(active, adcategoryItems) && (
                            <input
                              className="form-control"
                              type="text"
                              onChange={(e) => AdduserInsertedIdea(e)}
                              value={userInputValue}
                            ></input>
                          )}

                          {getText(active, adcategoryItems) && (
                            <span
                              className=" text-weight-500"
                              onClick={() => userSelectedIdeas(adcategoryItems)}
                            >
                              {adcategoryItems}
                            </span>
                          )}
                        </span>
                      </div>

                      {getInputBtn(active, adcategoryItems) && (
                        <div className="form-group text-center mtop nobottom-space">
                          <button
                            type="button"
                            className={`btn btn-primary generateAd`}
                            onClick={() => props.currentPage("image-style")}
                            title="Generate Image Ads"
                          >
                            Generate Image Ads
                          </button>
                          {/* <img src={edit_pencil} alt="" className="float-r mt-20" />*/}
                        </div>
                      )}

                      <div className="clearfix"></div>
                    </div>
                  </div>
                ))}
              <img
                src={PressIdea}
                alt=""
                width={160}
                className="tap-to-generate-img"
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default LandingAdIdeas;

import React from "react";
import { Route, Routes } from "react-router-dom";
import AdListing from "./AdListing";
import MyAdListing from "./MyAdListing";
import PaymentHistory from "./common/PaymentGateway/PaypalSubscription/PaymentHistory";
import PaymentStripeSuccess from "./common/PaymentGateway/PaypalSubscription/PaymentStripeSuccess";
import PaymentSuccess from "./common/PaymentGateway/PaypalSubscription/PaymentSuccess";
import LandingPageAdFlow from "./landingpage/LandingPageAdFlow";
import LandingPageAdFlowWithCarousels from "./landingpage/LandingPageAdFlowWithCarousels";
import LandingPageCarousel from "./landingpage/LandingPageCarousel";
import LandingPageNew from "./landingpage/LandingPageNew";
import LandingPageProductFlow from "./landingpage/LandingPageProductFlow";
import LandingPageProductFlowUpdated from "./landingpage/LandingPageProductFlowUpdated";
import LandingPageVideo from "./landingpage/LandingPageVideo";
import AdFlowListing from "./landingpage/adFlow/AdFlowListing";
import QuickFreeTrial from "./landingpage/common/QuickFreeTrial";
import WelcomeNewSignup from "./landingpage/common/WelcomeNewSignup";
import About from "./staticpage/About";
import Privacy from "./staticpage/Privacy";
import Riskdisclosure from "./staticpage/Riskdisclosure";
import TermsCondition from "./staticpage/TermsCondition";
import QuickAdFlowUpdated from "./QuickAdFlowUpdated";
import SelectAdsType from "./SelectAdsType";
import Settings from "./Settings";
import SubscriptionDetails from "./SubscriptionDetails";
import AdText from "./TextFlowPages/AdText";
import Useraction from "./Useraction";
import WelcomeScreen from "./WelcomeScreen";
import PaymentSuccessNew from "./common/PaymentGateway/PaypalSubscription/PaymentSuccessNew";
import LandingFreeTrial from "./landingpage/LandingFreeTrial";
import LandingPageAIAds from "./landingpage/LandingPageAIAds";
import LandingPageAdFlowWithoutCarousels from "./landingpage/LandingPageAdFlowWithoutCarousels";
import LandingPageFreeAds from "./landingpage/LandingPageFreeAds";
import LandingPageFreeAds2 from "./landingpage/LandingPageFreeAds2";
import LandingPageStripe from "./landingpage/LandingPageStripe";
import LandingPageVideoNew from "./landingpage/LandingPageVideoNew";
import VerifyEmail from "./landingpage/VerifyEmail";
import AdflowNewSubscribePage from "./landingpage/adFlow/AdflowNewSubscribePage";
import AdflowSubscribe50OffPage from "./landingpage/adFlow/AdflowSubscribe50OffPage";
import LoadingScreen from "./landingpage/adFlow/Loading";
import Store from "./landingpage/adFlow/Store";
import UploadProduct from "./landingpage/adFlow/UploadProduct";
import VerifyAccountPayment from "./landingpage/common/VerifyAccountPayment";
import ConceptAd from "./landingpage/conceptAd/ConceptAd";
import ConceptAdIdeas from "./landingpage/conceptAd/ConceptAdIdeas";
import ConceptAdOptions from "./landingpage/conceptAd/ConceptAdOptions";
import ConceptFreeAdIdeas from "./landingpage/conceptAd/ConceptFreeAdIdeas";
import ConceptImageAds from "./landingpage/conceptAd/ConceptImageAds";
import ConceptImageFreeAds from "./landingpage/conceptAd/ConceptImageFreeAds";
import ConceptImageStyles from "./landingpage/conceptAd/ConceptImageStyles";
import ConcetpAdType from "./landingpage/conceptAd/ConcetpAdType";
import NewHomeScreen from "./newhomescreen/NewHomeScreen";
import MobilePage from "./staticpage/MobilePage";
import SubscribeContainer from "./subscribe/SubscribeContainer";
import Newpopup from "./Newpopup";
import PreviewPage from "./preview-feature/PreviewPage";
import WelcomeScreenWithName from "./WelcomeScreenWithName";
import PreviewAdType from "./preview-feature/PreviewAdType";
import PreviewAdIdeas from "./preview-feature/PreviewAdIdeas";
import ChooseTheme from "./landingpage/backgroundThem/ChooseTheme";
import ThemeImageAds from "./landingpage/adFlow/ThemeImageAds";

const PublicRoutes = () => (
  <Routes>
    <Route exact path="/useraction" element={<Useraction></Useraction>}></Route>

    <Route
      exact
      path="/verify-account"
      element={<VerifyAccountPayment></VerifyAccountPayment>}
    ></Route>

    <Route
      exact
      path="/free-ads"
      element={<LandingPageFreeAds></LandingPageFreeAds>}
    ></Route>
    <Route
      exact
      path="/free-ads2"
      element={<LandingPageFreeAds2></LandingPageFreeAds2>}
    ></Route>
    <Route
      exact
      path="/video"
      element={<LandingPageVideoNew></LandingPageVideoNew>}
    ></Route>

    <Route
      exact
      path="/stripe"
      element={<LandingPageStripe></LandingPageStripe>}
    ></Route>

    <Route
      exact
      path="/product-flow-landing"
      element={<LandingPageProductFlow></LandingPageProductFlow>}
    ></Route>

    <Route
      exact
      path="/quick-free-trial"
      element={<QuickFreeTrial></QuickFreeTrial>}
    ></Route>

    <Route
      exact
      path="/landing/ai-ads-carousal"
      element={<LandingPageCarousel></LandingPageCarousel>}
    ></Route>

    <Route
      exact
      path="/ai-ads"
      element={<LandingPageAIAds></LandingPageAIAds>}
    ></Route>

    <Route
      exact
      path="/landing/ai-ads-video"
      element={<LandingPageVideo></LandingPageVideo>}
    ></Route>

    <Route
      exact
      path="/free-trial-subscriptions"
      element={<LandingFreeTrial></LandingFreeTrial>}
    ></Route>
    <Route
      exact
      path="/signup"
      element={<WelcomeNewSignup></WelcomeNewSignup>}
    ></Route>
    <Route
      exact
      path="/verify-email"
      element={<VerifyEmail></VerifyEmail>}
    ></Route>
    <Route exact path="/legal/privacy" element={<Privacy></Privacy>}></Route>
    <Route
      exact
      path="/legal/tos"
      element={<TermsCondition></TermsCondition>}
    ></Route>
    <Route
      exact
      path="/riskdisclosure"
      element={<Riskdisclosure></Riskdisclosure>}
    ></Route>
    <Route exact path="/ad-text" element={<AdText></AdText>}></Route>

    <Route
      exact
      path="/mobile/download1"
      element={<MobilePage></MobilePage>}
    ></Route>

    {/* Old landing page Product Flow
      What type of product do you sell? July 2023
      */}
    <Route
      path="/product-flow"
      element={<LandingPageProductFlow></LandingPageProductFlow>}
    />

    {/* Old landing page Product Flow
      What type of product do you sell? updated august 20 2023
      */}

    <Route
      path="/product-flow-updated"
      element={<LandingPageProductFlowUpdated></LandingPageProductFlowUpdated>}
    />

    <Route
      exact
      path="/presets"
      element={<LandingPageAdFlow></LandingPageAdFlow>}
    ></Route>

    <Route
      exact
      path="/v1"
      element={
        <LandingPageAdFlowWithCarousels></LandingPageAdFlowWithCarousels>
      }
    ></Route>

    <Route
      exact
      path="/ad-carousel"
      element={
        <LandingPageAdFlowWithCarousels></LandingPageAdFlowWithCarousels>
      }
    ></Route>
    <Route exact path="/aboutus" element={<About></About>}></Route>
    <Route
      exact
      path="/"
      element={
        <LandingPageAdFlowWithoutCarousels></LandingPageAdFlowWithoutCarousels>
      }
    ></Route>

    <Route
      path="*"
      element={
        <LandingPageAdFlowWithCarousels></LandingPageAdFlowWithCarousels>
      }
    />
    <Route
      exact
      path="/subscriptions-50-off"
      element={<AdflowSubscribe50OffPage />}
    ></Route>
  </Routes>
);
const PrivateRoutes = () => (
  <>
    <Routes>
      <Route exact path="/" element={<LandingPageNew></LandingPageNew>}></Route>
      <Route
        exact
        path="/subscriptions-50-off"
        element={<AdflowSubscribe50OffPage />}
      ></Route>
      <Route
        exact
        path="/stripe"
        element={<LandingPageStripe></LandingPageStripe>}
      ></Route>

      <Route
        exact
        path="/carousel"
        element={<LandingPageCarousel></LandingPageCarousel>}
      ></Route>

      <Route
        exact
        path="/select-ads-type"
        element={<SelectAdsType></SelectAdsType>}
      ></Route>

      {/* //* Quick ad flow updated Route */}
      <Route exact path="/quick-ad" element={<QuickAdFlowUpdated />}></Route>

      <Route
        exact
        path="/free-trial-subscription"
        element={<AdflowNewSubscribePage></AdflowNewSubscribePage>}
      ></Route>

      <Route exact path="/pricing" element={<SubscribeContainer />}></Route>

      <Route
        exact
        path="/subscriptions"
        element={<AdflowNewSubscribePage />}
      ></Route>

      <Route
        exact
        path="/adflow-listing"
        element={<AdFlowListing></AdFlowListing>}
      ></Route>

      <Route exact path="/ad-text" element={<AdText></AdText>}></Route>

      <Route path="/concept-ad" element={<ConceptAd></ConceptAd>} />

      <Route path="/concept-ad" element={<ConceptAd></ConceptAd>} />
      <Route
        path="/concept-ad-options"
        element={<ConceptAdOptions></ConceptAdOptions>}
      />
      <Route
        path="/concept-ad-type"
        element={<ConcetpAdType></ConcetpAdType>}
      />
      <Route
        path="/concept-ad-ideas"
        element={<ConceptAdIdeas></ConceptAdIdeas>}
      />

      <Route
        path="/product-ad-type"
        element={<PreviewAdType></PreviewAdType>}
      />
      <Route
        path="/product-ad-ideas"
        element={<PreviewAdIdeas></PreviewAdIdeas>}
      />



      <Route
        path="/concept-image-style"
        element={<ConceptImageStyles></ConceptImageStyles>}
      />

      <Route
        exact
        path="/concept-image-ads"
        element={<ConceptImageAds></ConceptImageAds>}
      ></Route>

      <Route
        exact
        path="/concept-free-image-ads"
        element={<ConceptImageFreeAds></ConceptImageFreeAds>}
      ></Route>

      <Route
        exact
        path="/concept-free-ad-ideas"
        element={<ConceptFreeAdIdeas></ConceptFreeAdIdeas>}
      ></Route>
      <Route
        exact
        path="/choose-theme"
        element={<ChooseTheme />}
      />
      <Route
        exact
        path="/theme-ads"
        element={<ThemeImageAds />}
      />


      <Route path="/upload-product" element={<UploadProduct></UploadProduct>} />
      <Route path="/loading" element={<LoadingScreen></LoadingScreen>} />
      <Route path="/store" element={<Store></Store>} />

      <Route
        path="/success"
        element={<PaymentStripeSuccess></PaymentStripeSuccess>}
      />

      <Route
        path="/payment-success"
        element={<PaymentSuccessNew></PaymentSuccessNew>}
      />

      <Route path="/welcome-trail" element={<WelcomeScreen></WelcomeScreen>} />

      <Route path="/welcome" element={<WelcomeScreenWithName></WelcomeScreenWithName>} />

      <Route
        exact
        path="/payment-success"
        element={<PaymentSuccess></PaymentSuccess>}
      ></Route>
      <Route
        exact
        path="/payment-history"
        element={<PaymentHistory></PaymentHistory>}
      ></Route>
      <Route exact path="/settings" element={<Settings></Settings>}></Route>
      <Route
        exact
        path="/subscription-details"
        element={<SubscriptionDetails></SubscriptionDetails>}
      ></Route>

      <Route
        exact
        path="/my-adlisting"
        element={<MyAdListing></MyAdListing>}
      ></Route>
      <Route
        exact
        path="/subscribe"
        element={<AdflowNewSubscribePage></AdflowNewSubscribePage>}
      ></Route>

      <Route
        exact
        path="/product-flow-landing"
        element={<LandingPageProductFlow></LandingPageProductFlow>}
      ></Route>

      <Route exact path="/ad-listing" element={<AdListing></AdListing>}></Route>

      <Route exact path="/privacy-policy" element={<Privacy></Privacy>}></Route>
      <Route
        exact
        path="/termsconditions"
        element={<TermsCondition></TermsCondition>}
      ></Route>
      <Route
        exact
        path="/riskdisclosure"
        element={<Riskdisclosure></Riskdisclosure>}
      ></Route>
      <Route
        path="*"
        element={
          <LandingPageAdFlowWithoutCarousels></LandingPageAdFlowWithoutCarousels>
        }
      />
      <Route
        exact
        path="/home1"
        element={<NewHomeScreen></NewHomeScreen>}
      ></Route>
      <Route exact path="/popup" element={<Newpopup></Newpopup>}></Route>
      <Route exact path="/home" element={<PreviewPage />}></Route>
    </Routes>
  </>
);

export { PrivateRoutes, PublicRoutes };

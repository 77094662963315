export const planCardsObj = {
  monthlyTrial: [
    {
      title: "10 ads/mo",
      allowedDownloadImage: 10,
      plan: "$19.99/mo",
      cardStyle: { background: "white", color: "black" },
      btnStyle: { boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)" },
      stylebyclass: "text-size-20 p-8 text-weight-600",
      planType: "Startup Plan",
      cardStyle: { background: "white", color: "black" },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_TRIAL_STARTUP_19_PRICE_ID,
      planName: process.env.REACT_APP_PAYPAL_MONTHLY_LITE_PLAN_NAME,
    },
    {
      title: "50 ads/mo",
      allowedDownloadImage: 50,
      plan: "$49.99/mo",
      btnStyle: {
        background: "white",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "gradient-head-text text-size-20 p-8 text-weight-800",
      planType: "Pro Plan",
      cardStyle: {
        background: "linear-gradient(289deg, #32D1E9 -7.86%, #C63CF9 98.56%)",
        color: "white",
      },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_TRIAL_49_PRO_PRICE_ID,
      planName: process.env.REACT_APP_STRIPE_MONTHLY_49_PRO_PLAN,
    },
    {
      title: "500 ads/mo",
      allowedDownloadImage: 500,
      plan: "$199.99/mo",
      btnStyle: {
        background: "white",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "text-black text-size-20 p-8 text-weight-800",
      planType: "Agency Plan",
      cardStyle: { background: "black", color: "white" },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_TRIAL_199_AGENCY_PRICE_ID,
      planName:
        process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID_DISCOUNTED,
    },
  ],
  monthly: [
    {
      title: "10 ads/mo",
      allowedDownloadImage: 10,
      plan: "$19.99/mo",
      priceTitle: "$19.99/month",
      cardStyle: { background: "white", color: "black" },
      btnStyle: {
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
        background: "linear-gradient(90deg, #B43EE9 0%, #4C056C 100%)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-600",
      planType: "Startup Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "26px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_STARTUP_19_PRICE_ID,
      planName: process.env.REACT_APP_PAYPAL_MONTHLY_LITE_PLAN_NAME,
    },
    {
      title: "50 ads/mo",
      allowedDownloadImage: 50,
      plan: "$49.99/mo",
      priceTitle: "$49.99/month",
      cardStyle: {
        background: "white",
        color: "black",
        width: "330px",
        padding: "35px",
      },
      btnStyle: {
        background: "black",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-800",
      planType: "Pro Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "32px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_49_PRO_PRICE_ID,
      planName: process.env.REACT_APP_STRIPE_MONTHLY_49_PRO_PLAN,
    },
    {
      title: "500 ads/mo",
      allowedDownloadImage: 500,
      plan: "$199.99/mo",
      cardStyle: { background: "white", color: "black" },
      btnStyle: {
        background:
          "linear-gradient(2deg, #5433FF -43.72%, #20BDFF 55.97%, #A5FECB 155.65%)",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-800",
      planType: "Agency Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "26px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_MONTHLY_199_AGENCY_PRICE_ID,
      planName:
        process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID_DISCOUNTED,
    },
  ],
  yearly: [
    {
      title: "10 ads/mo",
      allowedDownloadImage: 10,
      plan: "$99.99/yr",
      priceTitle: "$99.99/year",
      cardStyle: { background: "white", color: "black" },
      btnStyle: {
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
        background: "linear-gradient(90deg, #B43EE9 0%, #4C056C 100%)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-600",
      planType: "Startup Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "26px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_YEARLY_STARTUP_99_PRICE_ID,
      planName: process.env.REACT_APP_PAYPAL_YEARLY_LITE_PLAN_NAME,
    },
    {
      title: "50 ads/mo",
      allowedDownloadImage: 50,
      plan: "$299.99/yr",
      priceTitle: "$299.99/year",
      cardStyle: {
        background: "white",
        color: "black",
        width: "330px",
        padding: "35px",
      },
      btnStyle: {
        background: "black",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-800",
      planType: "Pro Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "32px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_YEARLY_299_PRO_PRICE_ID,
      planName: process.env.REACT_APP_PAYPAL_MONTHLY_PRO_PLAN_NAME,
    },
    {
      title: "500 ads/mo",
      allowedDownloadImage: 500,
      plan: "$799.99/yr",
      priceTitle: "$799.99/year",
      cardStyle: { background: "white", color: "black" },
      btnStyle: {
        background:
          "linear-gradient(2deg, #5433FF -43.72%, #20BDFF 55.97%, #A5FECB 155.65%)",
        boxShadow: "0px 4px 16px 0px rgba(185, 72, 247, 0.30)",
      },
      stylebyclass: "text-size-20 p-8 text-weight-800",
      planType: "Agency Plan",
      planTypeStyle: {
        color: "white",
        textShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        fontFamily: '"Be Vietnam Pro"',
        fontSize: "26px",
        letterSpacing: "6.5px",
      },
      priceId: process.env.REACT_APP_STRIPE_YEARLY_799_AGENCY_PRICE_ID,
      planName: process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_PLAN_NAME,
    },
  ],
};

export const planCardsExtraObj = {
  title: "Pricing that pays for itself.",
  monthly: "Monthly",
  yearly: "Yearly (58% Off!)",
};

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";

const QuestionBox = (props) => {
  const [adCategory, setAdCategory] = useState("");
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("adCategories")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setAdCategory(dataReceived);
        setLoader(true);
      });
    userService.clearQuestionFlowData();
  }, []);

  const setBusinessValue = (adCategory) => {
    setButtonStatus(false);
    setActive(adCategory.data.name);
    setClassvalue("next-btn-enable");
    userService.setKeyValueLocalstorage(
      "adCategoryValue",
      adCategory.data.value
    );
    userService.setKeyValueLocalstorage("categoryName", adCategory.data.name);
    userService.setKeyValueLocalstorage("adCategoryID", adCategory.id);
    userService.setKeyValueLocalstorage(
      "adCategoryQuestion",
      adCategory.data.question
    );
    props.currentPage("business-type");
  };

  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };

  return (
    <>
      <div className="what-do-you-want-an-ad-for-wrapper">
        <b className="what-do-you">What do you want an ad for?</b>
      </div>
      <div className="frame-groups">
        <div className="category-parent">
          <div
            className={`categorys ${active === adCategory[0]?.data?.name && "box-active"
              }`}
            onClick={() => setBusinessValue(adCategory[0])}
          >
            <div className="iconproduct-parent">
              <img
                className="iconproduct"
                alt={adCategory[0]?.data?.name}
                src={adCategory[0]?.data?.imageURL}
              />
              <div className="my-website">{adCategory[0]?.data?.name}</div>
            </div>
          </div>
          <div
            className={`categorys1 ${active === adCategory[1]?.data?.name && "box-active"
              }`}
            onClick={() => setBusinessValue(adCategory[1])}
          >
            <div className="iconproduct-parent">
              <img
                className="application-1-icon"
                alt={adCategory[1]?.data?.name}
                src={adCategory[1]?.data?.imageURL}
              />
              <div className="my-product">{adCategory[1]?.data?.name}</div>
            </div>
          </div>
        </div>
        <div className="category-parent">
          <div
            className={`category2 ${active === adCategory[2]?.data?.name && "box-active"
              }`}
            onClick={() => setBusinessValue(adCategory[2])}
          >
            <div className="iconproduct-parent">
              <img
                className="iconproduct"
                alt={adCategory[2]?.data?.name}
                src={adCategory[2]?.data?.imageURL}
              />
              <div className="my-website">{adCategory[2]?.data?.name}</div>
            </div>
          </div>
          <div
            className={`category3 ${active === adCategory[3]?.data?.name && "box-active"
              }`}
            onClick={() => setBusinessValue(adCategory[3])}
          >
            <div className="iconproduct-parent">
              <img
                className="application-1-icon"
                alt={adCategory[3]?.data?.name}
                src={adCategory[3]?.data?.imageURL}
              />
              <div className="my-product">{adCategory[3]?.data?.name}</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default QuestionBox;

import React from "react";
import welcome_assests from "../images/welcome_assests.svg";
import { useNavigate } from "react-router-dom";
import ButtonGradient from "./common/Button/ButtonGradient";
import StyledButtonWhiteGradientWithIcon from "./common/Button/StyledButtonWhiteGradientWithIcon";
import useWindowSize from "../_helper/ScreenUtil";

const WelcomeScreenWithName = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 767;


  return (
    <div className="row">
      <div className="free-trail-frame-container">
        <div className={`col-12 col-md-12 col-lg-12 col-lg-offset-1 welcome-trail-responsive`}>
          <div className="mt-40 text-center">
            <b className="try-out-the-container">
              <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro"> You are now a <br /><span className="payment-success-adspire-text font-60 text-size-44">PRO Subscriber!</span> </h3>
            </b>
            <p className="text-white text-center text-size-32 font-family-vietnam-pro m-0">You can now download 50 ads each month! <br /><span className="text-weight-600">(And still generate <span className="underline">unlimited ad options</span>!)</span></p>
            <div className="row mt-40 flex content-center">
              <div className="col-sm-1"></div>
              <div className="col-sm-8 flex content-center">
                <StyledButtonWhiteGradientWithIcon
                  btnText="Generate more"
                  btnIconStylebyclass="hideIt"
                  btnStylebyclass={isMobile ? "width-100-percent mb-20 p-0" : "width-80-percent mb-20 p-0"}
                  btnTextStylebyclass="text-size-30 text-white text-weight-600 font-family-vietnam-pro mr-10"
                  btnStyle={{ boxShadow: "unset", height: "60px" }}
                  btnDivStyle={{ boxShadow: "rgba(255, 255, 255, 0.72) 0px 15px 20px -10px inset", width: "100%", borderRadius: "40px", margin: "0px 5px", height: "52px", display: "flex", justifyContent: "center", alignItems: "center" }}
                  onClick={() => navigate("/home")}
                />
              </div>
              <div className="col-sm-1"></div>
            </div>
          </div>
          <img alt="" src={welcome_assests} width={400} />
        </div>
      </div>
    </div>
  );
};

export default WelcomeScreenWithName;

import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import StyledButton1 from "./StyledButton1";

const ConceptInput = (props) => {


  // ******************** Hooks ***************************************
  const navigate = useNavigate();
  const [conceptAdName, setConceptAdName] = useState();
  const [errorMessage, setErrorMessage] = useState("");
  const [showGenerateButton, setShowGenerateButton] = useState(false);


  // ******************** Functions ***************************************
  const handlepostContentBlur = (e) => {
    //if (e.target.value.trim()) {
    setShowGenerateButton(true);
    setConceptAdName(e.target.value);
    userService.setKeyValueLocalstorage("conceptAdInput", e.target.value);
    if (!e.target.value.trim()) {
      setShowGenerateButton(false);
    }
    //}
  };


  const handleSentToIdeasPage = (e) => {
    userService.removeKeyLocalstorage("text");
    userService.setKeyValueLocalstorage("customeGenerateClicked", 'yes');
    navigate("/concept-ad-type");
  };


  return (
    <>
      <form>
        <div className="form-row">
          <div className={props.className}>
            <input
              name="Custom"
              placeholder="Custom"
              type="text"
              onChange={handlepostContentBlur}
              value={conceptAdName}
              className={`custom-input custom-input-radius modal-gradient-is-invalid `}
            />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
        </div>{" "}
      </form>
      {showGenerateButton && <div className="m-top btn-rarius mb-5">
        <div className="col-sm-10 col-sm-offset-1 col-xs-12 flex content-center">
          <div className="w-260">
            <StyledButton1
              btnTheme={"light"}
              type="button"
              name={"Next"}
              data-dismiss="modal"
              btnHeight="64px"
              onClick={handleSentToIdeasPage}
            />
          </div>
        </div>
      </div>}
    </>
  );
};
export default ConceptInput;

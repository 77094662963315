import React, { useState, useEffect } from "react";
import { userService } from "../../../../services/user.service";
import { paymentService } from "../../../../services/payment.service";
import PayPalBtn from "../../../common/PaymentGateway/PaypalBtn";

import {
  PAYPAL_ENVIRONMENT,
  PAYPAL_YEARLY_ULTRA_PLAN_ID,
  PAYPAL_YEARLY_ULTRA_PLAN_NAME, PAYPAL_PAYMENT_STATUS, PAYPAL_YEARLY_ULTRA_PRICE, PLAN_TYPE_ULTRA
} from "../../../../utils/constants";
const PlanYearlyUltra = (props) => {

  const paypalSubscribeUltra = (data, actions) => {
    props.setPaymentProccessStart(true);
    return actions.subscription.create({
      plan_id: PAYPAL_YEARLY_ULTRA_PLAN_ID,
    });
  };


  const paypalOnError = async (err) => {
    props.setPaymentProccessStart(false);
  };

  const paypalOnApproveUltra = async (data, detail) => {
    // call the backend api to store transaction details
    const userId = userService.getUserId();
    const dataToSave = {
      user_id: userId,
      environment: PAYPAL_ENVIRONMENT,
      product_id: PAYPAL_YEARLY_ULTRA_PLAN_NAME,
      subscription_id: data.subscriptionID,
      order_id: data.orderID,
      purchase_date: new Date(),
      status: PAYPAL_PAYMENT_STATUS,
      amount: PAYPAL_YEARLY_ULTRA_PRICE,
      plan: PLAN_TYPE_ULTRA
    };
    
  };
  return (
   <PayPalBtn
        amount="1"
        currency="USD"
        createSubscription={paypalSubscribeUltra}
        onApprove={paypalOnApproveUltra}
        catchError={paypalOnError}
        onError={paypalOnError}
        onCancel={paypalOnError}
      />
  );
};
export default PlanYearlyUltra;
import React from "react";
import perspectivegridflat_1 from "../../../images/landing_page_assests/oct-flor-vector.svg";
import StyledButtonWhite2 from "../../common/Button/StyledButtonWhite2";
import ImageSlider1 from "./ImageSlider1";

const LandingPageAdFlowWithCarouselsSubPage = (props) => {
  return (
    <div className="star-parent">
      <div className="perspective-grid-flat-1-parent" id="free-trial-subscription">
        <img
          className="perspective-grid-flat-1-icon"
          alt=""
          src={perspectivegridflat_1}
        />
      </div>
      {/* <img className="frame-child6" alt="" src={star_7} />
      <img className="frame-child7" alt="" src={star_9} /> */}
      <div className="group-carousel-parent1">
        <div className="carousel-content-parent">
          <div className="heading-and-body-text position-relative z-index-10">
            <div className="generate-incredible-parent">
              <div className="generate-incredible- adspire-welcome carousel-desc carousal-heading-">
                {`Welcome to`}
                {/* <span className="incredible cursor-pointer">incredible,</span> */}
              </div>
              {/* <div className="effective-ads-video carousal-heading">Creative</div>
              <div className="in-seconds carousal-heading">Generator</div> */}
            </div>
            <span className="adspire2 adspire-heading">Adspire®!</span>
            <div className="carousel-desc desc text-center">
              {/* "Generate incredible ads in seconds with our AI! */}
              Generate <b className="incredible-text">incredible</b> ads in <b className="in-seconds-text">seconds</b> with our <b className="ai-text">AI</b><b>!</b>
            </div>

            {/* <StartFreeTrialCarouselBtn></StartFreeTrialCarouselBtn> */}
            <StyledButtonWhite2 />
          </div>
        </div>
        {/* <div className="carousel-gallary-parent- img-slider-margin">
          <SubImageSlider />
        </div> */}
        <div className="carousel-gallary-parent- img-slider-margin">
          <ImageSlider1></ImageSlider1 >
        </div>
      </div>
    </div>
  );
};
export default LandingPageAdFlowWithCarouselsSubPage;


import {
    applyActionCode,
    confirmPasswordReset,
    verifyPasswordResetCode,
  } from "firebase/auth";

  import { auth } from "./Firebase";
import { EXPIRED_INVALID_CODE,EMAIL_VERIFIED,} from "./constants";
  
  export const handleResetPassword = (
    newPassword,
    actionCode,
    continueUrl,
    lang
  ) => {
    // Verify the password reset code is valid.
    return verifyPasswordResetCode(auth, actionCode)
      .then((email) => {
        // Save the new password.
        return confirmPasswordReset(auth, actionCode, newPassword)
          .then((resp) => {
            return {
              message: "PASSWORD_RESET_SUCCESSFULLY",
              status: "ok",
            };
          })
          .catch((error) => {
            return {
              message: "ERROR_OCCURRED_DURING_CONFIRMATION",
              status: "fail",
            };
          });
      })
      .catch((error) => {
        return {
          message: "EXPIRED_INVALID_CODE",
          status: "fail",
        };
      });
  };
  
  export const handleVerifyEmail = (
    actionCode,
    continueUrl,
    lang
  ) => {
    // Try to apply the email verification code.
    return applyActionCode(auth, actionCode)
      .then((resp) => {
        return {
          message: EMAIL_VERIFIED,
          status: "ok",
        };
        
      })
      .catch((error) => {
        // Code is invalid or expired. Ask the user to verify their email address again.
        return {
          message: EXPIRED_INVALID_CODE,
          status: "fail",
        };
      });
  };
  
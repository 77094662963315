import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import line from "../../images/landing_page_assests/line.svg";
import { userService } from "../../services/user.service";
import { getWebsiteTitle } from "../../utils/function";
import { metaDesc } from "../../utils/landingConstants";
import Cookie from "../Cookie";
import Newpopup from "../Newpopup";
import ButtonGradient from "../common/Button/ButtonGradient";
import FooterLanding from "./common/FooterLanding";
import ImageSlider from "./common/ImageSlider";
import LandingPageNewTopSection from "./common/LandingPageNewTopSection";
import MarqueeLight from "./common/MarqueeLight";
import RoasNew_1 from "./common/RoasNew_1";
import SubFooter from "./common/SubFooter";
import SubHeader2NewLandingPage from "./common/SubHeader2NewLandingPage";
import Testimonials from "./common/Testimonials";
import TheWorldsSection from "./common/TheWorldsSection";
import UnleaseImageAds from "./common/UnleaseImageAds";
import WarningUsageWebsite from "./common/WarningUsageWebsite";
import "./css/Landing-page.css";
import { fetchBackgroundTheme, fetchBackground } from "../../utils/Firebase.collection.helper";

const LandingPageAdFlowWithoutCarousels = () => {
  const navigate = useNavigate();
  const [pathname, setNewPathName] = useState("");
  const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setNewPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", metaDesc.LandingPageDescription);
  }, []);

  // New useEffect to handle scroll events
  useEffect(() => {
    const handleScroll = () => {
      // Check the scroll position and update the state
      setIsScrolled(window.scrollY > 50); // You can adjust the scroll threshold as needed
    };

    // Add scroll event listener when the component mounts
    window.addEventListener("scroll", handleScroll);

    // Cleanup: remove event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  const [permanentClosePopup, setPermanentClosePopup] = useState(true);
  const [showExitTabModal, setShowExitTabModal] = useState(false);

  useEffect(() => {
    // Show the popup after a delay
    const timeoutId = setTimeout(() => {
      if (permanentClosePopup) {
        setShowExitTabModal(true);
      }
    }, 7000); // 15 seconds delay

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  // Use useEffect to automatically hide the popup after it's shown
  useEffect(() => {
    if (showExitTabModal) {
      // Automatically hide the popup after 5 seconds
      const timeoutId = setTimeout(() => {
        //setShowExitTabModal(false);
      }, 5000);

      // Clear the timeout when the component is unmounted
      return () => clearTimeout(timeoutId);
    }
  }, [showExitTabModal]);

  const closePopup = () => {
    setShowExitTabModal(false);
    setPermanentClosePopup(false);
  };

  const msg = {
    msg1: "Generate",
    msg2: "",
    msg3: "EFFECTIVE ADS",
    msg4: "in seconds!",
  };

  document.title = getWebsiteTitle() + "AI Ad Generator";

  return (
    <>
      <div className="confirmed-main-landing-page adflow-page">
        <div className="hero-sections-parent">
          <div className="hero-sections new-landing-page-background new-oct-landing-page">
            {isScrolled && <SubHeader2NewLandingPage />}
            {/* <LandingPageAdFlowWithCarouselsSubPage></LandingPageAdFlowWithCarouselsSubPage> */}
            <LandingPageNewTopSection></LandingPageNewTopSection>
          </div>
          <MarqueeLight />
          <TheWorldsSection />
          <div className="nd-sections min-height-100vh new-oct-UnleaseImageAds">
            <img className="line-icon" alt="" src={line} />
            <UnleaseImageAds></UnleaseImageAds>
            <ImageSlider />
          </div>

          <div className="fullwidth mt-40">
            <div className="text-center">
              <ButtonGradient
                title="Generate Ads"
                btnstyle={{
                  textShadow: "5px 5px 20px white",
                }}
                stylebyclass="text-size-30 text-weight-600 text-white"
                btnstylebyclass="m-left-right-auto position-relative z-index-2"
                onClick={() => navigate("/signup")}
              />
              <p className="font-family-sans-serif text-size-16 text-light-gray mt-10">
                Try 100% free for 3 days.{" "}
                <span className="text-weight-600 gradient-head-text">
                  cancel anytime
                </span>
              </p>
            </div>
          </div>

          {pathname != "/v1" && <Testimonials />}
          <RoasNew_1 />
          <SubFooter
            signup={true}
            subscribe={false}
            unleas={false}
            ideas={false}
          ></SubFooter>
          <FooterLanding></FooterLanding>
          <Cookie />
        </div>
      </div>

      {showExitTabModal &&
        userService.getValueFromLocalstorage("remoteConfig")
          .autoAdLeavePopup && <Newpopup closePopup={closePopup} />}
    </>
  );
};

export default LandingPageAdFlowWithoutCarousels;

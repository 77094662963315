import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import addnew from "../../../images/icons/add-new1.svg";
import back from "../../../images/icons/back.svg";
import cross from "../../../images/icons/cross.png";
import download from "../../../images/icons/download-new.svg";
import edit from "../../../images/icons/edit-new.svg";
import { userService } from "../../../services/user.service";
import { getFixedChar, getWebsiteTitle } from "../../../utils/function";
import AdFlowListingButton from "../../common/Button/AdFlowListingButton";
import DownloadunwatermarkedButton from "../../common/Button/DownloadunwatermarkedButton";
import StyledButton1 from "../../common/Button/StyledButton1";
import LoginWaterMarkedModal from "../../common/popup/LoginWaterMarkedModal";

import CheckoutMonthlyUltra from "../../common/StripePayment/CheckoutMonthlyUltra";
import CheckoutYearlyUltra from "../../common/StripePayment/CheckoutYearlyUltra";
import TextFlowDescriptionModal from "../../common/popup/TextFlowDescriptionModal";
import ConceptStep from "../../common/ConceptStep";

const AdFlowListingFree = (props) => {
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  const [generatedImage, setGeneratedImage] = useState(props.copyImages);
  const [isModalOpen, setModalOpen] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn-enable");
  const [loading, setLoader] = useState(false);
  const [downloadClick, setDownloadClick] = useState(false);

  const [creativeImage, setCreativeImage] = useState(false);
  const [overlayImage, setOverlayImage] = useState(false);

  const [remoteImages, setRemoteImages] = useState([]);
  const [mergedImage, setMergedImage] = useState("");

  const navigate = useNavigate();

  const [adCategory, setAdCategory] = useState("");
  const [headline, setHeadline] = useState();
  //const [caption, setCaption] = useState();
  const [description, setDescription] = useState();
  const [calltoaction, setCalltoaction] = useState();

  useEffect(() => {
    setRemoteImages([...remoteImages, ...props.copyImages])
  }, [props.copyImages]);

  const redirectTo = (pagename) => {
    if (pagename === "ad-ideas") {
      userService.setKeyValueLocalstorage("showLocalAdIdeas", "yes");
    }
    navigate("/" + pagename);
  };

  const redirectTosubscibepage = () => {
    window.open("/pricing", "_blank");
  };

  const selectImage = (items) => {
    setActive(items);
    setPopupImage(items);
      if(!userService.isPaidUser()){
        const url = `/signup`;
        window.open(url, "_blank");
    }
  };

  const openQuestionBox = () => {
    const myBtn = document.getElementById("ptextFlowModal");
    myBtn.click();
  };

  const handleDownloadClick = async () => {
    if (userService.isPaidUser()) {
  
      for (let index = 0; index < remoteImages.length; index++) {
        const imageUrl = remoteImages[index];
        //await handleDownload(imageUrl, index);
        await downloadBase64Image(imageUrl, index);
      }
    } else if (
      userService.getValueFromLocalstorage("generatedAdsNumber") == 1
    ) {
      //navigate("/pricing");
      window.open("/pricing", "_blank");
    } else if (userService.getValueFromLocalstorage("isTrialPeriod") == "yes") {
      setModalOpen(true);
    }
  };

  const handleSingleDownloadClick = async () => {
    if (userService.isPaidUser()) {
      await downloadBase64Image(popupImage, "1");
    } else if (
      userService.getValueFromLocalstorage("generatedAdsNumber") == 1
    ) {
      window.open("/pricing", "_blank");
    } else if (userService.getValueFromLocalstorage("isTrialPeriod") == "yes") {
      setModalOpen(true);
    }
  };

  async function downloadBase64Image(base64Data, fileName) {
    // Create an anchor element
    const link = document.createElement("a");
    link.href = base64Data;
    fileName = getFixedChar(
      userService.getValueFromLocalstorage("uploadInput"),
      15
    );
    // Set the download attribute to specify the filename
    link.download = fileName;

    // Simulate a click event to trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  }

  const getFormValueFromAI = async (datas) => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "quick-ad"
    ) {
      props.handleUpload(); // We are sending this function from Quick Ad
    } else {
      props.handleEditUpload();
    }
  };

  const hideTheImage = async (datas) => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "quick-ad"
    ) {
      props.setShowListingPage(false);
    } else {
      props.setShowListingPage(false);
    }
  };

  const handleStoreUpload = () => {
    props.setShowListingPage(false);
    props.handleUpload();
  };

  const BackButtonNew = () => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "quick-ad"
    ) {
      props.setShowListingPage(false);
      navigate("/quick-ad");
    } else if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "concept-ad"
    ) {
      navigate("/concept-ad");
    } else {
      props.setShowListingPage(false);
    }
  };

  document.title = getWebsiteTitle() + "Ad Listing";
  return (
    <React.Fragment>
      {props.copyImages && (
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  <div className="col-sm-12 ml-20 mt-20">
                    <h1 className="top-ranckking-h1">
                      <img
                        onClick={() => BackButtonNew()}
                        alt="backImage"
                        className="mar-right-20 cursor-pointer"
                        src={back}
                      />{" "}
                      Here's your ads!
                    </h1>

                   {/* <p className="text-size-24">Click an ad to edit</p>
                    <div className="clearfix"></div> */}

                    <div className=" mb-20 mt-20  image-listing-container">
                      {props.copyImages &&
                        props.copyImages.map((items, index) => (
                          <div
                            className={`col-sm-3 col-xs-12`}
                            key={`ad-listing-${index}`}
                            title={userService.getValueFromLocalstorage(
                              "uploadInput"
                            )}
                            onClick={() => selectImage(items)}
                            data-toggle="modal"
                            data-target="#imageDetailModal"
                          >
                            <div
                              className={`thumbnailListBG adspire-watermark-container cursor-pointer ${active == items && "box-active"
                                }`}
                            >
                              {!userService.isPaidUser() && (
                                <>
                                  <img
                                    className="img-responsive user-imgDiv pointerEvents"
                                    src={items}
                                    alt={userService.getValueFromLocalstorage(
                                      "uploadInput"
                                    )}
                                  />
                                  <p>
                                    <b>
                                      <span className="watermark-text-adspire-for-images">
                                        ADSPIRE
                                      </span>
                                    </b>
                                  </p>
                                </>
                              )}

                              {userService.isPaidUser() && (
                                <>
                                  <img
                                    data-toggle="modal"
                                    data-target="#myModal"
                                    className="img-responsive user-imgDiv generated-img-arrays"
                                    src={items}
                                    alt=""
                                  />
                                </>
                              )}

                              {/* <div className="clearfix"></div> */}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className=" col-sm-4 col-sm-offset-4 btn-list text-center">
                      <div className=" text-cente btn-radius ">
                        {!userService.isFreeSetUser() &&
                          userService.isPurchaser() && (
                            <AdFlowListingButton
                              handleUpload={props.handleUpload}
                              generatedImage={props.generatedImage}
                              hideTheImage={hideTheImage}
                              handleDownloadClick={handleDownloadClick}
                              pagename="captions"
                            ></AdFlowListingButton>
                          )}

                        {userService.isFreeSetUser() && (
                          <DownloadunwatermarkedButton pagename="captions"></DownloadunwatermarkedButton>
                        )}
                      </div>

                      {userService.isContentAllowed() && (
                        <>
                          {/* image popup */}

                          <div
                            className={`modal fade`}
                            id="imageDetailModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">
                                      <img src={cross} alt="" />
                                    </span>
                                  </button>
                                  <h1 className="top-ranckking-h1 text-left">
                                    Here's your ads!
                                  </h1>
                                </div>
                                <div className="modal-body text-center user-imgDiv">
                                  {!userService.isPaidUser() && (
                                    <p>
                                      <b>
                                        <span className="adspire-logo-for-modal watermark-text-adspire-for-images">
                                          ADSPIRE
                                        </span>
                                      </b>
                                    </p>)}
                                  <img
                                    width={408}
                                    height={408}
                                    src={popupImage}
                                    className="pointerEvents object-fit-cover modal-opened-img"
                                    alt="popupImage"
                                  />
                                </div>
                                {userService.isPaidUser() && (
                                  <div className="modal-footer text-center">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-6 text-center cursor-pointer">
                                        {!userService.isPurchaser() && (
                                          <img
                                            src={edit}
                                            data-dismiss="modal"
                                            alt="Edit your Ad"
                                            onClick={() => redirectTo("store")}
                                          />
                                        )}
                                        {userService.isPurchaser() && (
                                          <img
                                            src={edit}
                                            data-dismiss="modal"
                                            alt="Edit your Ad"
                                            onClick={() => openQuestionBox()}
                                          />
                                        )}
                                      </div>
                                      <div className="col-sm-4 col-xs-6 text-center">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            handleSingleDownloadClick()
                                          }
                                        >
                                          <img src={download} alt="" />
                                        </a>
                                      </div>

                                      <div
                                        className="col-sm-4 col-xs-6 text-center cursor-pointer"
                                        title="New Ad"
                                        data-dismiss="modal"
                                        onClick={() => redirectTo("store")}
                                      >
                                        <img
                                          src={addnew}
                                          data-dismiss="modal"
                                          alt="New Ad"
                                        />
                                      </div>
                                    </div>

                                    {/* <AdButton></AdButton> */}
                                    <div className=" m-top btn-rarius mb-5">
                                      <div className="col-sm-10 col-sm-offset-1 col-xs-12 text-center">
                                        <StyledButton1
                                          btnTheme={"light"}
                                          type="button"
                                          name={"Generate more"}
                                          data-dismiss="modal"
                                          btnHeight="64px"
                                          onClick={handleStoreUpload}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}

                                {!userService.isPaidUser() && (
                                  <div className="modal-footer text-center">
                                    <div className="row">
                                      <div className="col-sm-4 col-xs-6 text-center cursor-pointer">
                                        <img
                                          src={edit}
                                          data-dismiss="modal"
                                          alt="Edit your Ad"
                                          onClick={() =>
                                            redirectTosubscibepage()
                                          }
                                        />
                                      </div>
                                      <div className="col-sm-4 col-xs-6 text-center">
                                        <a
                                          href="#"
                                          onClick={() =>
                                            redirectTosubscibepage()
                                          }
                                        >
                                          <img src={download} alt="" />
                                        </a>
                                      </div>

                                      <div
                                        className="col-sm-4 col-xs-6 text-center  cursor-pointer"
                                        title="New Ad"
                                        data-dismiss="modal"
                                        onClick={() => redirectTosubscibepage()}
                                      >
                                        <img
                                          src={addnew}
                                          data-dismiss="modal"
                                          alt="New Ad"
                                        />
                                      </div>
                                    </div>

                                    {/* <AdButton></AdButton> */}
                                    <div className=" m-top btn-rarius mb-5">
                                      <div className="col-sm-10 col-sm-offset-1 col-xs-12 text-center">
                                        <StyledButton1
                                          btnTheme={"light"}
                                          type="button"
                                          name={"Generate more"}
                                          data-dismiss="modal"
                                          btnHeight="64px"
                                          onClick={redirectTosubscibepage}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>

                          {/* image popup*/}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
      <p id="ploginModal" data-toggle="modal" data-target="#loginModal"></p>
      <LoginWaterMarkedModal></LoginWaterMarkedModal>

      <TextFlowDescriptionModal
        getFormValueFromAI={getFormValueFromAI}
      ></TextFlowDescriptionModal>
      <span
        id="ptextFlowModal"
        data-toggle="modal"
        data-target="#textFlowDescriptionModalId"
      ></span>

      <div
        className={`modal ${isModalOpen ? "show" : ""}`}
        style={{
          display: isModalOpen ? "block" : "none",
          background: "#0000006e",
        }}
      >
        <div
          className="modal-dialog top40 modal-dialog-centered text-open-sans"
          role="document"
        >
          <div className="modal-content modal-gradient-new">
            <div className="modal-header">
              <button
                type="button"
                onClick={() => {
                  setModalOpen();
                }}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="mt-10">
                <div className="text-white font-20-regular text-weight-600">
                  Only paying subscribers can download UNLIMITED ads.
                </div>
                <CheckoutYearlyUltra>Subscribe</CheckoutYearlyUltra>
                <CheckoutMonthlyUltra>Subscribe Monthly</CheckoutMonthlyUltra>
              </div>
            </div>
            <div className="modal-footer text-center"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default AdFlowListingFree;

import React from "react";
import _3rd_section_bg from "../../../images/landing_page_assests/3rd-section-bg.svg";
import grediant1 from "../../../images/landing_page_assests/grediant1.svg";
import sample_gif from "../../../images/varify_account/sample-gif.gif";
import the_worlds_txt from "../../../images/landing_page_assests/the_worlds_txt.svg";
import useWindowSize from "../../../_helper/ScreenUtil";


const TheWorldsSection = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;


  return (
    <div className="the-worlds-backgournd pt-100 pb-100 font-family-vietnam-pro">
      <div className="group-parent">
        <div className="row">
          <div className="col-md-12">
            <div className={isMobile ? "" : `new-roas-text-box-container`}>
              <div className={isMobile ? "col-12 col-md-12 col-lg-12" : `col-12 col-md-12 col-lg-12 flex content-evenly`}>
                <img src={sample_gif} alt="rectangle_bg_purple" className={isMobile ? "width-100-percent" : "width-40-percent gif-the-world mr-30"} />
                <div className="mt-30">
                  <h5 className="text-size-40 mr-30">Trained on <span className="gradient-text-green-blue-lite"><b>12M+</b></span> ads,</h5>
                  <h5 className="text-size-40 mr-30">introducing...</h5>
                  <img src={the_worlds_txt} alt="rectangle_bg_purple" className={isMobile ? "width-100-percent mt-40" : "gif-the-world mr-30 mt-40"} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="grediant-icon1" alt="" src={grediant1} />
    </div>
  );
};
export default TheWorldsSection;
















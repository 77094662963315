import React from "react";
import ImageSlider from "./ImageSlider";
import StartFreeTrialCarouselBtn from "./StartFreeTrialCarouselBtn";
import ImageSlider1 from "./ImageSlider1";
const SubHeaderCarousel = (props) => {
  return (
    <div className="star-parent">
      {/* <img className="frame-child6" alt="" src={star_7} />
      <img className="frame-child7" alt="" src={star_9} /> */}
      <div className="group-carousel-parent1">
        <div className="carousel-content-parent">
          <div className="heading-and-body-text position-relative z-index-10">
            <div className="generate-incredible-parent">
              <div className="generate-incredible carousal-heading">
                {`AI Ad `}
                {/*<span className="incredible cursor-pointer">incredible,</span>*/}
              </div>
              <div className="effective-ads-video carousal-heading">Creative</div>
              <div className="in-seconds carousal-heading">Generator</div>
            </div>
            <div className="carousal-heading carousel-desc">
              Use AI to generate amazing, powerful ads, ad ideas and captions specifically for your business in seconds!
            </div>
            <StartFreeTrialCarouselBtn></StartFreeTrialCarouselBtn>
          </div>
        </div>
        <div className="carousel-gallary-parent">
          <ImageSlider1></ImageSlider1> 
        </div>
      </div>
    </div>
  );
};
export default SubHeaderCarousel;

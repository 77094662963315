import React from 'react';
import { Link } from "react-router-dom";
export default LogoLanding;

function LogoLanding() {

  return (
    <>
      <b className="adspire">
        <Link to={"/"}>Adspire®</Link>
      </b>
    </>
  );
}

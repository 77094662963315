import React from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
const GenerateMoreButton = (props) => {

  // ******************** Hooks ***************************************
  const navigate = useNavigate();

  // ******************** Functions ***************************************
  const redirectTo = (pagename) => {
    if (!userService.isSubscribed() || !userService.checkCredits()) {
      const myBtn = document.getElementById('psubscribeModal');
      myBtn.click()
      navigate("/subscribe");
    } else {
      navigate("/" + pagename);
    }
  };


  return (
    <div className="form-group text-center" title="Generate more ">
      <button type="btn"
        disabled={props.buttonStatus}
        className={`btn btn-primary ${props.classvalue}`} onClick={() => redirectTo(props.pagename)}> Generate more </button>
      <span id="psubscribeModal" data-toggle="modal"
        data-target="#subscribeModal"></span>
    </div>
  );
};
export default GenerateMoreButton;

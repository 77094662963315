export const convertToBase64 = (file) => {
    return new Promise((resolve) => {
        let baseURL = "";
        // Make new FileReader
        let reader = new FileReader();
        // Convert the file to base64 text
        reader.readAsDataURL(file);
        // on reader load somthing...
        reader.onload = () => {
            // Make a fileInfo Object
            baseURL = reader.result;
            resolve(baseURL);
        };
    });
};

export async function imageUrlToBase64(imageUrl) {
    try {
        const response = await fetch(imageUrl);
        const data = await response.json();

        if (data.status === "success") {
            return data.data;
        }
    } catch (error) {
        console.error("Error:", error);
    }
}
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
//import new_add_circle_plus from "../../../images/icons/new_add_circle_plus.svg";
import cross from "../../../images/icons/cross.png";

const ShowErrorModel = ({ showModal, closeModal, message }) => {
  const navigate = useNavigate();
  const redirectTo = () => {
    navigate("/ad-goal");
  };

  const handleCloseModal = () => {
    closeModal(false);
  };

  // To close modal on ESC key
  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.keyCode === 27) handleCloseModal();
    };
    document.addEventListener("keydown", handleKeyPress);
    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <>
      <div
        className={showModal === true ? `modal show` : "modal fade"}
        id="showResponseError"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center">
            <div className="modal-header">
              <button
                onClick={handleCloseModal}
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  <img src={cross} alt="cross" />
                </span>
              </button>
            </div>
            <div className="modal-body ">
              <div className=" text-center m-top">
                <p className="mar-30 text-danger p-5 h4">
                  {message ? message : ""}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ShowErrorModel;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { colors } from "../../../utils/constants";
import {
  getWebsiteTitle,
  isEligibleForFreeAdsAPI,
} from "../../../utils/function";
import ConceptBackButton from "../../common/Button/ConceptBackButton";
import ConceptStep from "../../common/ConceptStep";
import SpinnerLoader from "../../common/SpinnerLoader";

const ConcetpAdType = () => {
  const [conceptAdType, setConceptAdType] = useState("");
  const [active, setActive] = useState(null);
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");

  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("types")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setConceptAdType(dataReceived);
        setLoader(true);
      });

    // Getting prompt value from here
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage("prompt", snapshot.data().prompt);
        userService.setKeyValueLocalstorage(
          "maxIdeas",
          snapshot.data().maxIdeas
        );
        userService.setKeyValueLocalstorage("model", snapshot.data().model);
        userService.setKeyValueLocalstorage(
          "temperature",
          snapshot.data().temperature
        );
        userService.setKeyValueLocalstorage(
          "maxTokens",
          snapshot.data().maxTokens
        );
      });

    // Getting prompt value for image
    db.collection("adIdeas")
      .doc("imageGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage(
          "promptImage",
          snapshot.data().prompt
        );
        userService.setKeyValueLocalstorage("size", snapshot.data().size);
        userService.setKeyValueLocalstorage(
          "maxImages",
          snapshot.data().maxImages
        );
      });
    userService.removeKeyLocalstorage("customeGenerateClicked");
    userService.removeKeyLocalstorage("text");
  }, []);

  document.title = getWebsiteTitle() + "Ad Type";

  const setUserSelectedConceptAdType = (conceptAdTypeObj) => {
    setButtonStatus(false);
    userService.setKeyValueLocalstorage(
      "userSelectedConceptAdTypeObj",
      conceptAdTypeObj
    );
    setClassvalue("generateAd");
    setActive(conceptAdTypeObj.data.name);
    navigate("/product-ad-ideas");
    //if (!isEligibleForFreeAdsAPI()) {
      //navigate("/product-ad-ideas");
   // } else {
     // navigate("/concept-free-ad-ideas");
   // }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className={`col-sm-12 col-xs-12 flex content-center`}>
                  <ConceptStep step="3"></ConceptStep>
                </div>
                <div className="flex content-center align-items-center text-white p-30">
                  <ConceptBackButton
                    className={"mr-20 cursor-pointer"}
                    alt="Back"
                    redirectPage="concept-ad-options"
                  />
                  <h1 className="text-center">What type of ad do you want?</h1>
                </div>
                <div className="clearfix"></div>
                <div className="col-sm-7 col-sm-offset-3">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}

                  {loading && (
                    <div className="row mb-20 mt-20">
                      {conceptAdType &&
                        conceptAdType.map((projectsItems, index) => (
                          <div
                            className={`col-sm-5 col-xs-12`}
                            key={projectsItems.data.name}
                            title={projectsItems.data.name}
                            onClick={() =>
                              setUserSelectedConceptAdType(projectsItems)
                            }
                          >
                            <div
                              className={`thumbnail-category text-size-16 h-60 ${
                                active == projectsItems.data.name &&
                                "box-active"
                              }`}
                              style={{
                                background: colors[index],
                              }}
                            >
                              <div className="select-boxes">
                                <img
                                  data-toggle="modal"
                                  data-target="#myModal"
                                  className="img-thumbnail"
                                  width={60}
                                  alt={projectsItems.data.name}
                                  src={projectsItems.data.imageURL}
                                />
                                <span className="ad-title">
                                  {" "}
                                  {projectsItems.data.name}
                                </span>
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                  )}
                  {loading && (
                    <>
                      {/* NOT REQUIRED, PLS DO NOT OPEN KISHORE <NoSpecificTypeButton></NoSpecificTypeButton>*/}
                      {/* <GenerateAdIdeasButton
                        classvalue={classvalue}
                        buttonStatus={buttonStatus}
                        pagename="ad-ideas"
                      ></GenerateAdIdeasButton> */}
                      {/*<BackButton pagename="description"></BackButton> */}
                    </>
                  )}
                </div>
              </div>

              {/*<div className="form-group text-center">
                <p> <button type="btn" className="btn btn-primary white-border" onClick={() => unsetUserSelectedConceptAdType()}>No specific type</button></p>
              </div>*/}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default ConcetpAdType;

import React, { useState, useEffect } from "react";
import { userService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import { db } from "../utils/Firebase";
import NextButton from "./common/Button/NextButton";
import GenerateCaptionsButton from "./common/Button/GenerateCaptionsButton";
import BackButton from "./common/Button/BackButton";
import Step from "./common/Step";
import { getWebsiteTitle } from "../utils/function";
import GenerateMoreButton from "./common/Button/GenerateMoreButton";
import SpinnerLoader from "./common/SpinnerLoader";
import grid_btn_icon from "../images/icons/grid-btn-icon.svg";
import list_btn_icon from "../images/icons/list_btn_icon.svg";
import { RWebShare } from "react-web-share";
import { saveAs } from "file-saver";
import Subscribe from "./Subscribe";
const MyAdIdeas = (props) => {
  const [myIdeasListing, setMyIdeasListing] = useState(false);
  const [loading, setLoader] = useState(false);
  const [completionText, setCompletionText] = useState("");
  const [completionSubText, setCompletionSubText] = useState("");
  const [latestIndex, setLatestIndex] = useState(0);
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    // create the userRef
    const userRef = db
      .collection("users")
      .doc(userService.getUserId());

    db.collection("userIdeasHistory")
      .where("userRef", "==", userRef)
      .orderBy("createdAt", "desc")
      .limit(8)
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data().completionText,
        }));
        setCompletionText(dataReceived);
        setLoader(true);
      });
  }, []);

  const formatMyIdeasListing = (dataReceived) => {
    const data = dataReceived.split(". ");
    const catArr = [];
    data.forEach((element, index) => {
      if (parseInt(element) > 0) { } else {
        catArr.push({ key: index, name: removeNumberFromString(element) });
      }
    });
    return catArr;
  };

  const removeNumberFromString = (questionText) => {
    return questionText.replace(/[0-9]/g, '')
  };

  const colors = [
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
  ];
  //document.title = getWebsiteTitle() + "My Past Ads";

  return (
    <React.Fragment>
      {!userService.isSubscribed() && (
        <Subscribe></Subscribe>
      )}
      {userService.isSubscribed() && (
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  {/* <Step step="7"></Step> */}
                  <div className="divider-horizontal"></div>

                  <div className="flex content-between">
                    <h2 className="text-left text-size-24 mb-40">My Ideas</h2>
                    <h2 className="text-left text-size-24 mb-40 cursor-pointer">See all</h2>
                  </div>

                  <div className="row mb-20 mt-20 grid-idea-card">
                    {completionText &&
                      completionText.map(
                        (adcategoryItems, index) =>
                          adcategoryItems && formatMyIdeasListing(adcategoryItems.data).length > 0 && (

                            <div key={`ad-ideas-${index}`}
                              className={`margin-tb15-lr-10 w-310 ${formatMyIdeasListing(adcategoryItems.data).length}`}
                            >
                              {formatMyIdeasListing(adcategoryItems.data).length > 0 &&
                                formatMyIdeasListing(adcategoryItems.data).map(
                                  (adcategoryItems, index) =>
                                  (<div key={`ideas-${index}`} className={`some-ad-idea-thumbnailBG ad-idea-height text-size-16`}
                                    style={{
                                      backgroundColor: colors[index],
                                    }}
                                  >
                                    {removeNumberFromString(adcategoryItems.name)}
                                  </div>)
                                )}

                              <div className="clearfix"></div>
                            </div>
                          )
                      )}
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>)}
    </React.Fragment>
  );
};
export default MyAdIdeas;

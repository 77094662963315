import React from "react";
import new_add_circle_plus from "../../../images/icons/add-new.svg";

const AdsCountModalButton = (props) => {
  return (
    <>
      <div className="pull-right  margin-tb15-lr-10" title="New Ad">
        <button type="button" id="newadbtn" className="btn btn-primary generateAd w-156 h-56 ">
         <div className="justifyCenter">
         <span className="gradient-head-text text-size-22 ">{props?.adsCounts}</span>
          <span className="home-modal-btn-text2 "> Ads Left</span>
        
         </div>
          {/* <img src={new_add_circle_plus} alt="" className="" />{" "} */}
       
        </button>
      </div>
    </>
  );
};
export default AdsCountModalButton;

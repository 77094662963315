import React, { useState } from "react";
import { Link } from "react-router-dom";
import signup_img1 from "../../../images/16+_ad_style.svg";
import signup_img2 from "../../../images/generate_in_seconds.svg";
import signup_img3 from "../../../images/unlimited_generation.svg";
import signup_img4 from "../../../images/perfect_for_all.svg";
import NewSubscriberCarousal from "./NewSubscriberCarousal";

const SignupPageText = () => {

  return (
    <>
      {/* <div className="free-trail-frame-child-second-img- image-new-signup-photo-section text-weight-600">
        <div className="item1">
          <div>
            <p>16+  Ad Styles !</p>
            <img alt="_ad_style" src={signup_img2} />
          </div>
        </div>
        <div className="item2">
          <div>
            <p>Generate in Seconds!</p>
            <img alt="generate_in_seconds" src={signup_img1} />
          </div>
        </div>
        <div className="item3">
          <div>
            <p>UNLIMITED Generation!</p>
            <img alt="unlimited_generation" src={signup_img3} />
          </div>
        </div>
        <div className="item4">
          <div>
            <p>Perfect for all business!</p>
            <img alt="perfect_for_all" src={signup_img4} />
          </div>
        </div>
      </div> */}

      <div className="new-subscriber-carousal-container">
        <NewSubscriberCarousal />
      </div>
      <p className="the-disclaimer position-relative z-index-1">By signing up you agree to our <span className="text-underline cursor-pointer"><Link to="/legal/tos" target="_blank" className="th"> Terms & Service</Link></span> and <span className="text-underline cursor-pointer"><Link to="/legal/privacy" target="_blank" className="th"> Privacy Policy</Link>. </span></p>{'\u00A0'}
      <p className="the-disclaimer position-relative z-index-1"> including the way we collect and process your personal data.</p>
    </>
  );
};

export default SignupPageText;

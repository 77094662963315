import React from "react";
import { Link } from "react-router-dom";
import "../../components/landingpage/css/Landing-page.css";
import frame_1261153743_aboutUs_pg from "../../images/aboutUs_page_assests/frame_1261153743_aboutUs_pg.svg";
import frame_1261153744_about_pg from "../../images/aboutUs_page_assests/frame_1261153744_about_pg.svg";
import grediant from "../../images/aboutUs_page_assests/grediant.svg";
import star_1_about_pg from "../../images/aboutUs_page_assests/star_1_about_pg.svg";
import color_bg from "../../images/landing_page_assests/color-bg.svg";
import lines_dots from "../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../images/landing_page_assests/star-1.svg";
import { deduct30DaysFromDate, getWebsiteTitle } from "../../utils/function";
import FooterLanding from "../landingpage/common/FooterLanding";
import HeaderLanding from "../landingpage/common/HeaderLanding";
function Privacy() {
  document.title = getWebsiteTitle() + "Privacy Policy";
  return (
    <React.Fragment>
      <div className="sconfirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}

        <div className="hero-sections-parent">
          <div className="heros-sections">
            <img className="hero-sections-child" alt="" src={star_1} />
            <img className="lines-dots-icon" alt="" src={lines_dots} />
            <img className="line-dots-icon" alt="" src={lines_dots} />
            <img className="dual-color-bg-icon" alt="" src={color_bg} />
            <div className="header">
              <HeaderLanding></HeaderLanding>
            </div>

            <div className="title-page-name">
              <h2 className="gradient-logo-text">Privacy Policy</h2>
            </div>

            <div className="how-section1">
              <div className="row">
                <div className="headingdiv">
                  <p>Last Revised: {deduct30DaysFromDate()}</p>
                  <p>
                    Adspire® ("us", "we", or "our") This page informs you of our
                    policies regarding the collection, use and disclosure of
                    Personal Information we receive from users of the app.
                  </p>
                  <p>
                    We use your Personal Information only for providing and
                    improving the App. By using the App, you agree to the
                    collection and use of information in accordance with this
                    policy.
                  </p>
                  <p>Information Collection And Use</p>
                  <p>
                    While using our App, we may ask you to provide us with
                    certain personally identifiable information that can be used
                    to contact or identify you. Personally identifiable
                    information may include, but is not limited to your name
                    ("Personal Information").
                  </p>
                  <p>Log Data</p>
                  <p>
                    Like many App operators, we collect information that your
                    browser sends whenever you visit our App ("Log Data").
                  </p>
                  <p>
                    This Log Data may include information such as your
                    computer's Internet Protocol ("IP") address, browser type,
                    browser version, the pages of our App that you visit, the
                    time and date of your visit, the time spent on those pages
                    and other statistics.
                  </p>
                  <p>
                    In addition, we may use third party services such as Google
                    Analytics that collect, monitor and analyze this
                  </p>
                  <p>
                    The Log Data section is for businesses that use analytics or
                    tracking services in webApps or apps, like Google Analytics.
                    For the full disclosure section, create your own Privacy
                    Policy.
                  </p>
                  <p>Communications</p>
                  <p>
                    We may use your Personal Information to contact you with
                    newsletters, marketing or promotional materials and other
                    information that ...
                  </p>
                  <p>
                    The Communications section is for businesses that may
                    contact users via email (email newsletters) or other
                    methods. For the full disclosure section, create your own
                    Privacy Policy.
                  </p>
                  <p>Security</p>
                  <p>
                    The security of your Personal Information is important to
                    us, but remember that no method of transmission over the
                    Internet, or method of electronic storage, is 100% secure.
                    While we strive to use commercially acceptable means to
                    protect your Personal Information, we cannot guarantee its
                    absolute security.
                  </p>
                  <p>Changes To This Privacy Policy</p>
                  <p>
                    This Privacy Policy is effective as of March 1, 2018 and
                    will remain in effect except with respect to any changes in
                    its provisions in the future, which will be in effect
                    immediately after being posted on this page.
                  </p>
                  <p>
                    We reserve the right to update or change our Privacy Policy
                    at any time and you should check this Privacy Policy
                    periodically. Your continued use of the Service after we
                    post any modifications to the Privacy Policy on this page
                    will constitute your acknowledgment of the modifications and
                    your consent to abide and be bound by the modified Privacy
                    Policy.
                  </p>
                  <p>
                    If we make any material changes to this Privacy Policy, we
                    will notify you either through the email address you have
                    provided us, or by placing a prominent notice on our webApp.
                  </p>
                  <p>Contact Us</p>
                  <p>
                    If you have any questions about this Privacy Policy, please
                    contact us.{" "}
                  </p>
                </div>
              </div>
            </div>

            <div className="how-section2">
              <div className="about_container">
                <div className="adspire-name">
                  <b className="adspire8">Adspire®</b>
                  <p className="about_container_child">
                    What remarkable ads will you generate? Try it out for{" "}
                    <Link to={"/"}>
                      <span className="free-here-grediant">
                        FREE <span>here.</span>
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <img
                src={frame_1261153744_about_pg}
                alt=""
                className="half-circle1"
              />
              <img
                src={frame_1261153743_aboutUs_pg}
                alt=""
                className="half-circle2"
              />
              <img src={star_1_about_pg} alt="" className="star-1" />
              <img src={grediant} alt="" className="grediant-1" />
            </div>
            <FooterLanding></FooterLanding>
          </div>
        </div>
        {/* <img className="grediant-icon1" alt="" src={grediant1} /> */}
      </div>
    </React.Fragment>
  );
}

export default Privacy;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import PlanYearlyLite from "../components/common/PaymentGateway/PaypalSubscription/PlanYearlyLite";
import PlanYearlyPro from "../components/common/PaymentGateway/PaypalSubscription/PlanYearlyPro";
import PlanYearlyUltra from "../components/common/PaymentGateway/PaypalSubscription/PlanYearlyUltra";
import { userService } from "../services/user.service";
import constants from "../utils/constants";
import PlanDetails from "./common/PaymentGateway/PaypalSubscription/PlanDetails";
import SpinnerLoader from "./common/SpinnerLoader";

import BestSeller from "./common/BestSeller";
import CheckoutYearlyLite from "./common/StripePayment/CheckoutYearlyLite";
import CheckoutYearlyPro from "./common/StripePayment/CheckoutYearlyPro";
import CheckoutYearlyUltra from "./common/StripePayment/CheckoutYearlyUltra";


const PlanYearly = () => {
  const [paymentProccessStart, setPaymentProccessStart] = useState(false);

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      //navigate("/login");
    }
  }, []);

  const redirectTo = () => {
    navigate("/business-type");
  };


  return (
    <>
      {paymentProccessStart && (
        <div>
          <SpinnerLoader></SpinnerLoader>
          <p className="text-center-normal-mar30">
            <b>{constants.PAYMENT_SUCCESS_MSG}</b>
          </p>
        </div>
      )}
      <div className="tabel-parent">
        <table className="tabel-parent-subscribe">
          <thead className="">
            <tr className="">
              <th className=""></th>
              <th className="">
                <div className="subscribe2">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyLite
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyLite>
                  )}
                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div">
                        <PlanYearlyLite
                          setPaymentProccessStart={setPaymentProccessStart}
                        ></PlanYearlyLite>
                      </div>
                      <button className="next">
                        <span className="extracss">Subscribe</span>
                      </button>
                    </>
                  )}
                </div>
              </th>

              <th className="">
                <div className="subscribe3">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyPro
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyPro>
                  )}

                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div">
                        <PlanYearlyPro
                          setPaymentProccessStart={setPaymentProccessStart}
                        ></PlanYearlyPro>
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button>
                    </>
                  )}
                </div>
              </th>
              <th className="">
                <div className="subscribe4">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyUltra
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyUltra>
                  )}

                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div">
                        <PlanYearlyUltra
                          setPaymentProccessStart={setPaymentProccessStart}
                        >
                          {" "}
                        </PlanYearlyUltra>
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button>
                    </>
                  )}
                </div>
              </th>
            </tr>
            <tr>
              <th className="th-parent">
                <div className="get-up-to">
                  <span >Get up to</span>
                  <b className="font-18-regular"> 55% off </b>
                  <span>
                    with a Yearly subscription!
                  </span>
                </div>
              </th>
              <th className="th-parent">
                <div className="lite-parent subscribe-plan-box-yearly">
                  <div className="lite">LITE</div>
                  <div className="only-2499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$15.99/mo</p>
                    <p className="">($199.99/yr)</p>
                  </div>
                </div>
              </th>
              <th className="th-parent">
                <div className="pro-parent subscribe-plan-box-yearly">
                  <div className="pro">PRO</div>
                  <div className="only-2499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$24.99/mo</p>
                    <p className="">($299.99/yr)</p>
                  </div>
                </div>
              </th>
              <th className="th-parent">
                <BestSeller></BestSeller>
                <div className="ultra-parent subscribe-plan-box-yearly">
                  <div className="pro">ULTRA</div>
                  <div className="only-6499mo">
                    <p className="only">
                      <i className="powerful-ai">{`Only `}</i>
                    </p>
                    <p className="mo">$28.99/mo</p>
                    <p className="">($349.99/yr)</p>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <PlanDetails></PlanDetails>
          <tfoot className="">
            <tr>
              <th className=""></th>
              <th className="bottom-subscribe-buttons">
                <div className="subscribe2">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyLite
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyLite>
                  )}

                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      {" "}
                      <div className="paypal-div-bottom">
                        <PlanYearlyLite
                          setPaymentProccessStart={setPaymentProccessStart}
                        >
                          {" "}
                        </PlanYearlyLite>
                      </div>
                      <button className="next">
                        <span className="extracss">Subscribe</span>
                      </button>
                    </>
                  )}
                </div>
              </th>

              <th className="bottom-subscribe-buttons">
                <div className="subscribe3">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyPro
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyPro>
                  )}

                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div-bottom">
                        <PlanYearlyPro
                          setPaymentProccessStart={setPaymentProccessStart}
                        >
                          {" "}
                        </PlanYearlyPro>
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button>
                    </>
                  )}
                </div>
              </th>
              <th className="bottom-subscribe-buttons">
                <div className="subscribe4">
                  {constants.LIVE_PAYMENT.STRIPE && (
                    <CheckoutYearlyUltra
                      setPaymentProccessStart={setPaymentProccessStart}
                    ></CheckoutYearlyUltra>
                  )}

                  {constants.LIVE_PAYMENT.PAYPAL && (
                    <>
                      <div className="paypal-div-bottom">
                        <PlanYearlyUltra
                          setPaymentProccessStart={setPaymentProccessStart}
                        >
                          {" "}
                        </PlanYearlyUltra>
                      </div>
                      <button className="subs-btn">
                        <span className="create-new-ads">Subscribe</span>
                      </button>{" "}
                    </>
                  )}
                </div>
              </th>
            </tr>
          </tfoot>
        </table>

        {/* ***************************For Mobile View************************************** */}
        <div className="subscribe-for-mobile">
          <div className="get-up-to-container">
            <span className="get-up-to-container1">
              <span className="get-up-to">Get up to</span>
              <b className="font-18-regular"> 55% off </b>
              <span className="get-up-to"> with a Yearly subscription!</span>
            </span>
          </div>
          <div>
            <div className="lite-parent mb-30">
              <div className="lite">LITE</div>
              <div className="only-2499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$15.99/mo</p>
                <p className="">($199.99/yr)</p>
              </div>
              <div className="subscribe2">
                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutYearlyLite
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutYearlyLite>
                )}

                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="paypal-div">
                      <PlanYearlyLite
                        setPaymentProccessStart={setPaymentProccessStart}
                      >
                        {" "}
                      </PlanYearlyLite>
                    </div>
                    <button className="next">
                      <span className="extracss">Subscribe</span>
                    </button>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
          <div>
            <div className="pro-parent">
              <div className="pro">PRO</div>
              <div className="only-2499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$24.99/mo</p>
                <p className="">($299.99/yr)</p>
              </div>
              <div className="subscribe3">
                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutYearlyPro
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutYearlyPro>
                )}

                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="paypal-div">
                      <PlanYearlyPro
                        setPaymentProccessStart={setPaymentProccessStart}
                      >
                        {" "}
                      </PlanYearlyPro>
                    </div>
                    <button className="subs-btn">
                      <span className="create-new-ads">Subscribe</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="margin-tb35-lr-0">
            <BestSeller></BestSeller>
            <div className="ultra-parent">
              <div className="pro">ULTRA</div>
              <div className="only-6499mo">
                <p className="only">
                  <i className="powerful-ai">{`Only `}</i>
                </p>
                <p className="mo">$28.99/mo</p>
                <p className="">($249.99/yr)</p>
              </div>
              <div className="subscribe4">
                {constants.LIVE_PAYMENT.STRIPE && (
                  <CheckoutYearlyUltra
                    setPaymentProccessStart={setPaymentProccessStart}
                  ></CheckoutYearlyUltra>
                )}

                {constants.LIVE_PAYMENT.PAYPAL && (
                  <>
                    <div className="paypal-div">
                      <PlanYearlyUltra
                        setPaymentProccessStart={setPaymentProccessStart}
                      ></PlanYearlyUltra>
                    </div>
                    <button className="subs-btn">
                      <span className="create-new-ads">Subscribe</span>
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PlanYearly;

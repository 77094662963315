import getStripe from "../lib/getStripe";
import { userService } from "../services/user.service";
import constants  from "./constants";

export default async function StripeCheckoutRedirect() {
    const stripe = await getStripe();
    let userSelectedPlan=userService.getValueFromLocalstorage("userSelectedPlan");
    if(!userSelectedPlan){
      userSelectedPlan=userService.getValueFromLocalstorage("defaultUserSelectedPlan");
    }
    const clientReferenceId=userService.getValueFromLocalstorage("clientReferenceId");
    userService.removeKeyLocalstorage("userSelectedPlan");
    userService.removeKeyLocalstorage("clientReferenceId");
   // return false;

    const { error } = await stripe.redirectToCheckout({
      lineItems: [
        {
          price: userSelectedPlan,
          quantity: 1,
        },
      ],
      clientReferenceId:clientReferenceId,
      mode: constants.STRIPE_INFO.MODE,
      successUrl: constants.STRIPE_INFO.SUCCESS_URL,
      cancelUrl: constants.STRIPE_INFO.CANCEL_URL,
      customerEmail: userService.getEmailId(),
    });
    console.warn(error.message);
}
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { colors } from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import ConceptBackButton from "../../common/Button/ConceptBackButton";
import ConceptStep from "../../common/ConceptStep";
import SpinnerLoader from "../../common/SpinnerLoader";
import ConceptInputQuestion from "../../common/Button/ConceptInputQuestion";
import ProductCaptionModal from "../../common/popup/ProductCaptionModal";

const ConceptAdOptionsQuestionFlow = (props) => {
  const [conceptAdOptions, setConceptAdOptions] = useState("");
  const [conceptAdOption, setConceptAdOption] = useState(null);
  const [loading, setLoader] = useState(false);
  const [modaltextVariable, setModaltextVariable] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [selectedvalue, setSelectedvalue] = useState("")
  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("quickIdeaGenerator")
      .collection("adGeneration")
      .doc("productTypes")
      .collection("types")
      .doc(userService.getValueFromLocalstorage("conceptAdProductObj").id)
      .collection("options")
      .orderBy("order")

      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
        setConceptAdOptions(dataReceived);
        setLoader(true);
      });
  }, []);

  document.title = getWebsiteTitle() + "Concept Ad Options";

  const setUserSelectedConceptAdOptions = (optionsObj) => {
    setButtonStatus(false);
    userService.setKeyValueLocalstorage("concetAdOptionsObj", optionsObj);
    setClassvalue("generateAd");
    console.log("setUserSelectedConceptAdOptions", optionsObj)
    setConceptAdOption(optionsObj?.data?.name);
  };

  
  const handleChange = (e) => {
    userService.setKeyValueLocalstorage("customeAdOptionInputClicked",'yes');
    userService.setKeyValueLocalstorage("conceptAdInput",e.target.value);
  }

    const getBackPageName = () => {
        return "home";
    }

   const handleCustomInput = (text) => {
    props.GenerateQuestion(text)
 }

 const condition =(props)=>{
  if( userService.getValueFromLocalstorage("questionFlow")!="yes" ){
  setShowModal(prev => !prev);
}else {
  setSelectedvalue(props)
}
}

const handleGoto = () => {
  let input=userService.getValueFromLocalstorage("conceptAdInput"); // here we will priority user input
  if(!input){
    input=userService.getValueFromLocalstorage("conceptAdProductObj").data.name +" "+conceptAdOption;
  }
   props.GenerateQuestion(userService.getValueFromLocalstorage("conceptAdProductObj").data.name +" "+selectedvalue)

}
useEffect(() => {
  if (selectedvalue) {
    handleGoto();
  }
}, [selectedvalue]); 
  return (
    <React.Fragment>
      <div className="container-fluid ">
        <div className="row">
          <div className="">
            <div className="top-contentDIv ">
              <div className="top-ranckking ">
             
              {userService.getValueFromLocalstorage("questionFlow")!="yes" && (
                
                <div className={`col-sm-12 col-xs-12  flex content-center`}>
                <ConceptStep step="2"></ConceptStep>
                </div>
                )}
                <div className="text-white justifyCenter  py-20 col-xs-12  ">
                 <div className="col-xs-12 col-sm-10  col-md-8 col-lg-7   justifyCenter flex">
<div className="flex">
<ConceptBackButton
                    className={"mr-20 cursor-pointer"}
                    alt="Back"
                    redirectPage={getBackPageName()}
                  />

                  <div className="gradiend-title-text1 m-0 ">
                    What{" "}
                    {
                      userService.getValueFromLocalstorage(
                        "conceptAdProductObj"
                      )?.data?.name
                    }{" "}
                    do you sell?
                  </div>
</div>
               
                </div>
                </div>

                <div className={`col-xs-12 mt-20  justifyCenter` }>
                  <div className="col-xs-12  col-sm-5 col-md-4 col-lg-3 bg-prima">
                    <ConceptInputQuestion handleCustomInput={handleCustomInput} className="form-group "></ConceptInputQuestion>
                  </div>
                </div>
                <div className="col-xs-12  ">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (
                <div className=" col-xs-12 justifyCenter mt-20">

                <div className="row     col-md-8 col-lg-6  col-xs-12 col-sm-10   ">
                    
                  
                    {conceptAdOptions &&
                        conceptAdOptions.map((projectsItems, index) => (
                          <div
                            className={`col-sm-6 px-20 col-xs-12`}
                            key={projectsItems.data.name}
                            title={projectsItems.data.name}
                            onClick={() => {
                              setUserSelectedConceptAdOptions(projectsItems);
                              setModaltextVariable(projectsItems.data.name);
                              condition(projectsItems.data.name);
                            }
                            }
                          >
                            <div
                              className={`thumbnail-category text-size-16 h-60 ${conceptAdOption == projectsItems.data.name &&
                                "box-active"
                                }`}
                              style={{
                                background: colors[index],
                              }}
                            >
                              <div className="select-boxes">
                                <span className="ad-title">
                                  {" "}
                                  {projectsItems.data.name}
                                </span>
                              </div>

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))} 
                        </div>
                    </div>
                  )}
                  {loading && (
                    <>
                      {/* NOT REQUIRED, PLS DO NOT OPEN KISHORE <NoSpecificTypeButton></NoSpecificTypeButton>*/}
                      {/* <GenerateAdIdeasButton
                        classvalue={classvalue}
                        buttonStatus={buttonStatus}
                        pagename="ad-ideas"
                      ></GenerateAdIdeasButton> */}
                      {/*<BackButton pagename="description"></BackButton> */}
                    </>
                  )}
                </div>
              </div>
              {/*<div className="form-group text-center">
                <p> <button type="btn" className="btn btn-primary white-border" onClick={() => unsetUserSelectedConceptAdOptions()}>No specific type</button></p>
              </div>*/}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      {showModal &&
        <ProductCaptionModal handleChange ={handleChange} showModal={showModal} setShowModal={setShowModal} handleSubmit={handleGoto} showedText={modaltextVariable} />
      }
    </React.Fragment>
  );
};
export default ConceptAdOptionsQuestionFlow;

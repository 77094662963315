import React, { useState, useEffect } from "react";
import { db } from "../../../utils/Firebase";
import useWindowSize from "../../../_helper/ScreenUtil";
import union_left_arrow from "../../../images/landing_page_assests/union_left_arrow.svg";
import union_center_arrow from "../../../images/landing_page_assests/union_center_arrow.svg";
import union_right_arrow from "../../../images/landing_page_assests/union_right_arrow.svg";
import StyledButton1 from "../../common/Button/StyledButton1";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { fetchBrandImages } from "../../../utils/Firebase.collection.helper";

const BrandedImageSlider = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [brandedImageSlider, setBrandedImageSlider] = useState([]);
  const [inputValue, setInputValue] = useState("");
  const [showGenerateButton, setShowGenerateButton] = useState(false);
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const isIpad = width <= 1024;
  const navigate = useNavigate();

  useEffect(() => {
    let isMounted = true;
    // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        const dataReceivedBrand = await fetchBrandImages();
        if (isMounted) {
          setBrandedImageSlider(dataReceivedBrand);
        }
      } catch (error) {
        console.error("Error fetching brand images:", error);
        // Handle error
      }
    };

    fetchData();

    return () => {
      // Cleanup function
      isMounted = false;
    };
  }, []);
  /*useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      
      try {
        if(isMounted){
          const dataReceivedBrand = await fetchBrandImages();
          setBrandedImageSlider(dataReceivedBrand);
        }
      } catch (error) {
        console.error("Error fetching brand images:", error);
        // Handle error
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);*/

  let currentItem = "";
  if (brandedImageSlider && brandedImageSlider[currentIndex]) {
    currentItem = brandedImageSlider[currentIndex];
  }

  useEffect(() => {
    if (brandedImageSlider && brandedImageSlider.length) {
      const interval = setInterval(() => {
        setCurrentIndex(
          (prevIndex) => (prevIndex + 1) % brandedImageSlider.length
        );
      }, 3000);
      return () => clearInterval(interval);
    }
  }, [brandedImageSlider]);

  const getSelected = (brandedImageId) => {
    currentItem = brandedImageSlider[brandedImageId];
  };

  const handleChnage = (e) => {
    setInputValue(e.target.value);
    if (e.target.value.trim()) {
      setShowGenerateButton(true);
    } else {
      setShowGenerateButton(false);
    }
  };

  const getPageToRedirect = () => {
    navigate(userService.getUserRedirectPage());
  };

  return (
    <div key="slider">
      {currentItem && (
        <div className="col-lg-12" key={currentItem + "_brandImageSlider"}>
          <div className="form-row col-lg-12 mt-20 mb-20" key={"9img"}>
            {isMobile ? null : (
              <div
                className={`form-group ${isMobile ? "width100" : "width-40-percent"
                  } m-left-right-auto position-relative z-index-10`}
              >
                <input
                  name="text"
                  type="text"
                  value={inputValue}
                  onChange={(e) => {
                    handleChnage(e);
                  }}
                  className="text-size-22 font-family-vietnam-pro placeholder-color-light-white outline-none"
                  placeholder={`Input: "${currentItem.data.name}"`}
                />
              </div>
            )}
            {showGenerateButton && (
              <div className="m-top btn-rarius mb-5">
                <div className="col-sm-10 col-sm-offset-1 col-xs-12 flex content-center">
                  <div className="w-260">
                    <StyledButton1
                      btnTheme={"light"}
                      type="button"
                      name={"Generate"}
                      btnHeight="64px"
                      onClick={() => {
                        getPageToRedirect();
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {isMobile ? null : (
            <>
              <img
                key={"1img"}
                className="union_left_arrow"
                src={union_left_arrow}
                alt="union_left_arrow"
              />
              <img
                key={"2img"}
                className="union_center_arrow"
                src={union_center_arrow}
                alt="union_center_arrow"
              />
              <img
                key={"3img"}
                className="union_right_arrow"
                src={union_right_arrow}
                alt="union_right_arrow"
              />
            </>
          )}
          <div className="col-lg-12" style={isIpad ? { marginBottom: "30px" } : { marginTop: "-70px" }}>
            <div className="flex content-center">
              <div>
                {currentItem.data.verticalAd && (
                  <img
                    key={"4img"}
                    className="new-top-section-carousel-img-1"
                    src={currentItem.data.verticalAd}
                    alt={`${currentItem.id} Vertical Ad`}
                    width={200}
                    height={200}
                  />
                )}
              </div>
              <div>
                {currentItem.data.squareAd && (
                  <img
                    key={"5img"}
                    className="new-top-section-carousel-img-2"
                    src={currentItem.data.squareAd}
                    alt={`${currentItem.id} Square Ad`}
                    width={200}
                    height={200}
                  />
                )}
              </div>
              <div>
                {currentItem.data.landscapeAd && (
                  <img
                    key={"6img"}
                    className="new-top-section-carousel-img-3"
                    src={currentItem.data.landscapeAd}
                    alt={`${currentItem.id} Landscape Ad`}
                    width={200}
                    height={200}
                  />
                )}
              </div>
            </div>
          </div>
          <div key={"7img"} className={isMobile ? "" : `col-lg-12 mt-40 mb-20`}>
            <div className="branding-body" key={"8img"}>
              {brandedImageSlider &&
                brandedImageSlider.map((brandedImage, index) => (
                  <React.Fragment key={brandedImage.id}>
                    <img
                      key={brandedImage.id}
                      onClick={() => getSelected(index)}
                      src={brandedImage.data.icon}
                      alt={brandedImage.id}
                      className="cursorpointer"
                      width={70}
                      style={
                        currentItem.id === brandedImage.id
                          ? {
                            background: brandedImage.data.bgColor,
                            borderRadius: "5px",
                          }
                          : {}
                      }
                    />
                  </React.Fragment>
                ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default BrandedImageSlider;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getWebsiteTitle } from "../../utils/function";

import vector1 from "../../images/bottom-arrow.png";
import ellipse_143 from "../../images/landing_page_assests/ellipse-143.svg";
import group_1261152240 from "../../images/landing_page_assests/group-1261152240.svg";
import "./css/Landing-page.css";

import { metaDesc } from "../../utils/landingConstants";
import LandingAdFlow from "./adFlow/LandingAdFlow";
import EffectiveAdsProductFlow from "./common/EffectiveAdsProductFlow";
import FooterLanding from "./common/FooterLanding";
import Marquee2 from "./common/Marquee2";
import RoasNew from "./common/RoasNew";
import SubFooter from "./common/SubFooter";
import SubHeader2NewLandingPage from "./common/SubHeader2NewLandingPage";

const LandingPageAdFlow = () => {
  const [adCategory, setAdCategory] = useState("");
  const [loading, setLoader] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null);
  const [currentPage, setCurrentPage] = useState("prdocutFlowFirstPage");
  const navigate = useNavigate();

  useEffect(() => {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", metaDesc.LandingPageDescription);
  }, []);

  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };
  const getClass = () => {
    if (currentPage == "generate-image") {
      return "ellipse-parent-space-reduce";
    }
  };
  const msg = {
    msg1: "Generate",
    msg2: "",
    msg3: "EFFECTIVE ADS",
    msg4: "in seconds!",
  };

  document.title =
    getWebsiteTitle() +
    "AI Ad Generator";
  return (
    <>


      {/* <span
        id="pIOSMessageModal"
        data-toggle="modal"
        data-target="#iosMessageModalId"
      ></span> */}

      <div className="confirmed-main-landing-page adflow-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
        <div className="hero-sections-parent">
          <div className="hero-sections new-landing-page-background">
            {/* <SubHeader></SubHeader> */}
            <SubHeader2NewLandingPage />
            {currentPage === "prdocutFlowFirstPage" && (
              <>
                <EffectiveAdsProductFlow msg={msg}></EffectiveAdsProductFlow>
                <img
                  className="vector-icon arrow-icon-vactor1"
                  alt=""
                  src={vector1}
                />
              </>
            )}
            <div className="ellipse-box">
              <div
                className={`new-landing-page-ellipse-parent-product-flow ${getClass()}`}
              >
                <img className="frame-child" alt="" src={ellipse_143} />
                <b className="adspire1">Adspire®</b>
                <div
                  className={`what-do-you-want-an-ad-for-productflow ${currentPage}`}
                >
                  <LandingAdFlow currentPage={setCurrentPage}></LandingAdFlow>

                  <div className="component-2">
                    <div className="next6">Start</div>
                  </div>
                </div>
              </div>
              <div className="mockup">
                <img className="mockup-child" alt="" src={group_1261152240} />
              </div>
            </div>
          </div>
          {/* <Marquee></Marquee> */}
          <Marquee2 />
          <RoasNew />
          {/* <Roas></Roas> */}

          <SubFooter unleas={false} ideas={false}></SubFooter>
          <FooterLanding></FooterLanding>
        </div>
      </div>
    </>
  );
};

export default LandingPageAdFlow;

import React, { useEffect } from "react";
import { metaDesc } from "../../utils/landingConstants";
import LandingPageNewTopSection from "../landingpage/common/LandingPageNewTopSection";
import "../landingpage/css/Landing-page.css";




const MobilePage = () => {

  useEffect(() => {
    document.querySelector("meta[name='description']").setAttribute("content", metaDesc.AIADS);
  }, []);



  return (
    <>
      <div className="hero-sections-parent">
        <div className="hero-sections new-landing-page-background new-oct-landing-page text-center">
          {/* <div className="mobile-page-container">
            <div className="perspective-grid-flat-1-parent" id="free-trial-subscription">
              <img
                className="net-grid-sqaures-img"
                alt="square-net"
                src={perspectivegridflat_1}
              />
            </div>
            <div className="mt-30 download-ios-text">
              <p> Download{'\u00A0'}</p>
              <p><span className="text-adspire">ADSPIRE </span>for{'\u00A0'}</p>
              <p className="text-free">FREE! </p>
            </div>
            <a target="_blank" href="https://apps.apple.com/app/apple-store/id1667132875?pt=123467369&ct=MobileWeb&mt=8">
              <button className="download-ios-app-btn">
                <img src={Curved_DownloadOnAppStore} alt="img" width={270} />
              </button></a>

            <div className="carousel-gallary-parent-img-slider-margin">
              <ImageSlider1 />
            </div>
            <div className="bottom-text-mobile-ios-container">
              <p>Generate ads like these</p>
            </div>
          </div> */}
          <a target="_blank" href="https://apps.apple.com/app/apple-store/id1667132875?pt=123467369&ct=MobileWeb&mt=8">
            <LandingPageNewTopSection></LandingPageNewTopSection>
          </a>
        </div>
      </div >
    </>
  );
};

export default MobilePage;


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { getWebsiteTitle } from "../../../utils/function";
import ConceptBackButton from "../../common/Button/ConceptBackButton";
import ConceptStep from "../../common/ConceptStep";
import SpinnerLoader from "../../common/SpinnerLoader";
import ConceptInput from "../../common/Button/ConceptInput";
import {
  fetchAdIdeas,
  updateLocalStorageFromSnapshot,
  fetchAdProductTypes,
} from "../../../utils/Firebase.collection.helper";

const ConceptAd = () => {
  const [conceptAdProduct, setConceptAdProduct] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [loading, setLoader] = useState(false);
  const [activeCircle, setActiveCircle] = useState(0);
  const [conceptAdName, setConceptAdName] = useState(
    userService.getValueFromLocalstorage("conceptAdInput")
  );
  const [errorMessage, setErrorMessage] = useState("");

  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    // Getting prompt value for image
    userService.setUserBackPage("concept-ad");
    if (!userService.isUserFreeAdsStatus()) {
      const pagename = userService.getSubscribePageName();
      navigate("/" + pagename);
    }
    let isMounted = true; // Flag to track whether the component is mounted
    userService.clearConceptFlowData();
    userService.removeKeyLocalstorage("customeGenerateClicked");

    // Getting prompt value from here
    const fetchData = async () => {
     
      try {
        await fetchAdIdeas();
      } catch (error) {
        console.error("Error fetching ad ideas:", error);
        // Handle error
      }

      try {
        if(isMounted){
        const dataReceived = await fetchAdProductTypes();
        setConceptAdProduct(dataReceived);
        setLoader(true);
        await updateLocalStorageFromSnapshot();
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle error
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  const setBusinessValue = (conceptAdProduct) => {
    setButtonStatus(false);
    userService.setKeyValueLocalstorage(
      "conceptAdProductObj",
      conceptAdProduct
    );
    navigate("/concept-ad-options");
  };

  document.title = getWebsiteTitle() + "Concept Ad";

  return (
    <div className="row">
      <div className="top-contentDIv">
        <div className="top-ranckking">
          <div className={`col-sm-12 col-xs-12 flex content-center`}>
            <ConceptStep step="1"></ConceptStep>
          </div>

          {!loading && (
            <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
          )}

          {loading && (
            <>
              <div className="flex content-center align-items-center text-white p-30">
                <ConceptBackButton
                  className={"mr-20 cursor-pointer"}
                  alt="Back"
                  redirectPage="select-ads-type"
                />
                <h1 className="gradiend-title-text m-0">
                  What Product do you sell?
                </h1>
              </div>
              <div className="clearfix"></div>
              <div className={`col-sm-4 col-xs-12 col-sm-offset-4 text-center`}>
                <ConceptInput className="form-group col-sm-10 col-sm-offset-1"></ConceptInput>
                {errorMessage && (
                  <div className="downclass">{errorMessage}</div>
                )}
              </div>
              <div className={`col-sm-10 col-xs-12 col-sm-offset-1 mt-20`}>
                <div className="product-category-container">
                  {conceptAdProduct &&
                    conceptAdProduct.map((conceptAdProductItems, index) => (
                      <div
                        key={index}
                        className={`mt-20 cursor-pointer`}
                        onClick={() => {
                          setBusinessValue(conceptAdProductItems);
                          setActiveCircle(index);
                        }}
                      >
                        <div
                          className={`${
                            activeCircle == index ? "active-circle" : ""
                          }`}
                        >
                          <div
                            className="circle-background-ransparent"
                            style={{
                              backgroundColor:
                                conceptAdProductItems.data.backgroundColor,
                            }}
                          >
                            <img
                              alt="circle-img"
                              width={50}
                              src={conceptAdProductItems.data.iconURL}
                            />
                          </div>
                        </div>
                        <div className="product-category-text">
                          {conceptAdProductItems.data.name}
                        </div>
                      </div>
                    ))}
                </div>
              </div>{" "}
            </>
          )}
        </div>
      </div>
      <div className="clearfix"></div>
    </div>
  );
};
export default ConceptAd;

import React, { useState, useEffect } from "react";
import { userService } from "../services/user.service";
import { useNavigate } from "react-router-dom";
import GenerateCaptionsButton from "./common/Button/GenerateCaptionsButton";
import Step from "./common/Step";
import { getWebsiteTitle } from "../utils/function";
import { RWebShare } from "react-web-share";
import { saveAs } from "file-saver";
import cross from "../images/icons/cross.png";
import download from "../images/icons/download.png";
import share from "../images/icons/share.png";
import add from "../images/icons/add.png";
import LoginWaterMarkedModal from "../components/common/popup/LoginWaterMarkedModal";
import { paymentService } from "../services/payment.service";
import adSpireWatermark from "../images/icons/Adspire_watermark.svg";
import { ImagesTabsSwitch } from "./newhomescreen/ImagesTabsSwitch";

const AdListing = (props) => {
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  const [displayBlock, setDisplayBlock] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn-enable");

  const navigate = useNavigate();

  useEffect(() => {
    if (!userService.checkCredits()) {
      const myBtn = document.getElementById("psubscribeModal");
      myBtn.click();
      navigate("/subscribe");
    }
  }, []);

  const signup = (res, type) => {
    let postData;
    if (type === "facebook" && res.email) {
      postData = {
        name: res.name,
        provider: type,
        email: res.email,
        provider_id: res.id,
        token: res.accessToken,
        provider_pic: res.picture.data.url,
      };
    }

    if (type === "google" && res.profileObj) {
      userService.googlelogin(res);
    }
  };

  const backStylesPage = () => {
    props.setGeneratedImage("");
    navigate("/image-style");
  };

  const redirectTo = (pagename) => {
    if (pagename === "ad-ideas") {
      userService.setKeyValueLocalstorage("showLocalAdIdeas", "yes");
    }
    navigate("/" + pagename);
  };

  const selectImage = (items) => {
    setActive(items);
    setPopupImage(items);
  };

  const openLoginPopup = () => {
    const myBtn = document.getElementById("ploginModal");
    myBtn.click();
  };

  const handleDownloadClick = (url) => {
    // saveAs(url, "img");
    saveAs(url, "Twitter-logo");
  };

  const generateAdIdeasProps = (e) => {
    props.setGeneratedImage(false);
    props.setLoader(true);
    props.generateAdIdeas();
  };

  const colors = ["#4fcfbe", "#ff9e83", "#fc94cf"];
  const responseGoogle = (response) => {
    signup(response, "google");
  };


  document.title = getWebsiteTitle() + "Ad Listing";
  return (
    <React.Fragment>
      <ImagesTabsSwitch />
      {props.generatedImage && (
        <div className="container-fluid">
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  <Step step="7"></Step>


                  <div className="col-sm-10 col-sm-offset-1">
                    <h2 className="text-left gradient-head-text text-size-32 mb-40">
                      Here are some image Ads I made using the idea:
                    </h2>
                    <p>{userService.getValueFromLocalstorage("ideas")}</p>
                    <div className="clearfix"></div>

                    <div className="row mb-20 mt-20">
                      {props.generatedImage &&
                        props.generatedImage.map((items, index) => (
                          <div
                            className={`col-sm-3 col-xs-12`}
                            key={`ad-listing-${index}`}
                            title={userService.getValueFromLocalstorage("ideas")}
                            onClick={() => selectImage(items)}
                            data-toggle="modal"
                            data-target="#imageDetailModal"
                          >
                            <div
                              className={`thumbnailListBG ${active == items && "box-active"
                                }`}
                            >
                              {userService.getValueFromLocalstorage(
                                "isTrialPeriod"
                              ) == "no" && (
                                  <>
                                    <img
                                      className="img-responsive user-imgDiv pointerEvents"
                                      src={items}
                                      alt={userService.getValueFromLocalstorage("ideas")}
                                    />
                                    <img
                                      src={adSpireWatermark}
                                      alt=""
                                      className="watermarkImg-main"
                                    />
                                  </>
                                )}

                              {userService.getValueFromLocalstorage(
                                "isTrialPeriod"
                              ) == "yes" && (
                                  <>
                                    <img
                                      data-toggle="modal"
                                      data-target="#myModal"
                                      className="img-responsive user-imgDiv"
                                      src={items}
                                    />
                                    {/*<img
                                    src={adSpireWatermark}
                                    alt=""
                                    className="watermarkImg"
                                  />*/}
                                  </>
                                )}

                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-sm-offset-3 col-sm-6 text-center">
                      <div className="form-group text-center">
                        {/*!userService.isSubscribed() && (
                          <button
                            type="btn"
                            data-toggle="modal"
                            data-target="#captionModal"
                            className="btn btn-primary generateAd  "
                            onClick={() => openLoginPopup()}
                          >
                            Generate Captions 
                          </button>
                        )*/}

                        {userService.isPurchaser() && (
                          <GenerateCaptionsButton pagename="captions"></GenerateCaptionsButton>
                        )}
                      </div>

                      <div
                        className="form-group text-center pointerclass"
                        onClick={() => backStylesPage()}
                      >
                        Back
                      </div>

                      {userService.isContentAllowed() && (
                        <>
                          {/*CAPTION POPUP START HERE */}
                          <div
                            className="modal fade"
                            id="captionModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="captionModalLabel"
                            aria-hidden="true"
                          >
                            <div className="modal-dialog top40" role="document">
                              <div className="modal-content text-center">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">
                                      <img src={cross} alt="" />
                                    </span>
                                  </button>
                                </div>
                                <div className="modal-body p50">
                                  <div className=" text-center m-top">
                                    <p>
                                      {" "}
                                      You may use these images as inspiration, a
                                      base/part of your ad, or even the final
                                      ad, 100% commercially .
                                    </p>

                                    <button
                                      data-dismiss="modal"
                                      type="button"
                                      className="btn btn-primary generateAd btn-block "
                                      id="greatbtnModal"
                                      onClick={() => redirectTo("captions")}
                                    >
                                      Great!
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          {/*CAPTION POPUP END HERE */}

                          {/* image popup */}

                          <div
                            className={`modal fade`}
                            id="imageDetailModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="contained-modal-title-vcenter"
                            aria-hidden="true"
                          >
                            <div
                              className="modal-dialog modal-dialog-centered"
                              role="document"
                            >
                              <div className="modal-content">
                                <div className="modal-header">
                                  <button
                                    type="button"
                                    className="close"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                  >
                                    <span aria-hidden="true">
                                      <img src={cross} alt="" />
                                    </span>
                                  </button>
                                </div>
                                <div className="modal-body text-center user-imgDiv">
                                  <img
                                    width={408}
                                    height={408}
                                    src={popupImage}
                                    className="pointerEvents"
                                  />
                                </div>
                                <div className="modal-footer text-center">
                                  <div className="row">
                                    <div className="col-sm-6 col-xs-6 text-center">
                                      {userService.getValueFromLocalstorage(
                                        "isTrialPeriod"
                                      ) == "yes" && (
                                          <a
                                            href={`https://adspireai.com/getdownloadimg.php?img=${popupImage}`}
                                          >
                                            {" "}
                                            <button
                                              type="button"
                                              className="btn btn-secondary circlediv"
                                            >
                                              <img
                                                src={download}
                                                alt=""
                                                className="icon-absolute"
                                              />
                                            </button>
                                            <p className="greytext">Download</p>
                                          </a>)}
                                      {userService.getValueFromLocalstorage(
                                        "isTrialPeriod"
                                      ) == "no" && (<>
                                        <button disabled title="Download not available for Trial Period User"
                                          type="button"
                                          className="btn btn-secondary circlediv"
                                        >
                                          <img
                                            src={download}
                                            alt=""
                                            className="icon-absolute"
                                          />
                                        </button>
                                        <p className="greytext" title="Download not available for Trial Period User">X Download</p></>
                                        )}
                                    </div>

                                    {/* <RWebShare
                                      data={{
                                        text: userService.getValueFromLocalstorage(
                                          "ideas"
                                        ),
                                        url: popupImage,
                                        title:
                                          userService.getValueFromLocalstorage(
                                            "input"
                                          ),
                                      }}
                                      >
                                      <div className="col-sm-4 col-xs-4 text-center">
                                        <button
                                          type="button"
                                          className="btn btn-secondary circlediv"
                                        >
                                         <img src={share} alt='' className="icon-absolute"/>
                                        </button>
                                        <p className="greytext">Share</p>
                                      </div>
                                    </RWebShare>*/}

                                    <div
                                      className="col-sm-6 col-xs-6 text-center"
                                      data-toggle="modal"
                                      title="New Ad"
                                      data-target="#exampleModal"
                                      onClick={() => redirectTo("ad-goal")}
                                    >
                                      <button
                                        type="button"
                                        className="btn btn-secondary dashCircle"
                                      >
                                        <img
                                          src={add}
                                          className="icon-absolute"
                                          alt=""
                                        />
                                      </button>
                                      <p className="greytext">New Ad</p>
                                    </div>
                                  </div>

                                  {/* <AdButton></AdButton> */}
                                  <div className=" m-top">
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      <button
                                        type="button"
                                        title="Generate Captions"
                                        className="btn btn-primary white-border btn-block"
                                        data-dismiss="modal"
                                        data-toggle="modal"
                                        data-target="#captionModal"
                                      >
                                        Generate Captions
                                      </button>

                                      {/*<button type="button" className="btn btn-primary generateAd btn-block ">Generate Variations</button>*/}
                                      {/* <GenerateAdIdeasButton></GenerateAdIdeasButton> */}
                                    </div>
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                                    </div>
                                    <div className="col-sm-4 col-xs-4 text-center">
                                      <a
                                        href=""
                                        className="white-textdiv"
                                        data-dismiss="modal"
                                        onClick={() => redirectTo("ad-ideas")}
                                      >
                                        See Ad Ideas
                                      </a>
                                      {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          {/* image popup*/}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
      <p id="ploginModal" data-toggle="modal" data-target="#loginModal"></p>
      <LoginWaterMarkedModal></LoginWaterMarkedModal>
    </React.Fragment>
  );
};
export default AdListing;

const StyledButtonWhite = ({ name, type = "button", disabled = false, onClick, btnTextStyle, btnHeight, btnTheme, ...restProps }) => {


    // ******************** Functions ***************************************
    const handleClick = () => {
        if (onClick) {
            onClick();
        }
    };


    return (
        <>
            {
                btnTheme === "light"
                    ? (<div className="styled-button-1-container"
                        onClick={handleClick}
                    >
                        <div className="free-trail-next-wrapper next-wrapper-btn btn-width light-button wht-bdr3"
                            style={
                                btnHeight ? { height: `${btnHeight}` } : { height: "50px" }
                            }
                            onClick={handleClick}
                        >
                            <button
                                onClick={handleClick}
                                {...restProps}
                                style={{
                                    zIndex: "10"
                                }}
                                className="continue-btn-free-trail cursor-pointer  font-18-regular"
                                type={type}
                                disabled={disabled}
                                data-dismiss="modal"
                            >
                                <span className={
                                    ` ${btnTextStyle} gradient-text`
                                }>
                                    {name ? name : 'Submit'}
                                </span>
                            </button>
                        </div>
                    </div >)
                    :
                    (<div className="styled-button-2-container"
                        onClick={handleClick}
                    >
                        <div className="free-trail-next-wrapper btn-width wht-bdr3"
                            style={
                                btnHeight ? { height: `${btnHeight}` } : { height: "60px" }
                            }
                        >
                            <button

                                className="continue-btn-free-trail cursor-pointer font-18-regular white-button"
                                type={type}
                                data-dismiss="modal"
                                disabled={disabled}
                            >
                                <span className={
                                    ` ${btnTextStyle}`
                                }>
                                    {name ? name : 'Submit'}
                                </span>
                            </button>
                        </div>
                    </div >)
            }
        </>
    )
}


export default StyledButtonWhite
import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";

import API_FIREBASE from "../../../config/apifirebase";
import { APPLICATION_INFO } from "../../../utils/constants";
import SpinnerLoader from "../../common/SpinnerLoader";
import { userService } from './../../../services/user.service';
import StyledButton1 from "../Button/StyledButton1";

const TextDescription = (props) => {
  // Accessing the history instance created by React


  const [loading, setLoader] = useState(false);
  // form validation rules
  const validationSchema = Yup.object().shape({
    headline: Yup.string().required("Please Enter headline"),
    description: Yup.string().required("Please Enter Describe Business"),
    cta: Yup.string().required("Please Enter CTA"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  formOptions.defaultValues = {
    headline: userService.getValueFromLocalstorage("creativeAIheadline"),
    description: userService.getValueFromLocalstorage("creativeAIdescription"),
    cta: userService.getValueFromLocalstorage("creativeAIcta"),
  }

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState } = useForm(formOptions);


  const { errors } = formState;

  // get functions to build form with useForm() hook
  async function onSubmit(data) {

    userService.setKeyValueLocalstorage("creativeAIheadline", data.headline);
    userService.setKeyValueLocalstorage("creativeAIdescription", data.description);
    userService.setKeyValueLocalstorage("creativeAIcta", data.cta);

    props.closeDescriptionModel()
    props.getFormValueFromAI("")

    reset();
  }

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="top-contentDIv text-left">
          <div className="col-sm-12">
            {loading && (
              <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
            )}
            <form onSubmit={handleSubmit(onSubmit)}>

              <div className="form-row">
                <div className="row p-2">
                  <div className="col">
                    <span className="h2 font-weight-bold pad-15">Edit</span>
                  </div>
                </div>
                <div className="form-group col-5 ">
                  <h4 className="h4 text-muted">Headline</h4>
                  <input
                    name="headline"
                    placeholder="Add headline or srvice name"
                    type="text"
                    {...register("headline")}
                    className={`form-control form-input-text-flow ${errors.headline ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.headline?.message}
                  </div>
                </div>
                <div className="divider-border" ></div>
                <div className="form-group col-5">
                  <h4 className="h4 text-muted">Description</h4>
                  <textarea
                    rows="4"
                    cols="50"
                    name="description"
                    placeholder="Description"
                    type="text"
                    {...register("description")}
                    className={`form-control form-input-text-flow ${errors.description ? "is-invalid" : ""
                      }`}
                  />
                  <div className="invalid-feedback">
                    {errors.description?.message}
                  </div>
                </div>
              </div>
              <div className="divider-border" ></div>
              <div className="form-row">
                <div className="form-group col">
                  <h4 className="h4 text-muted">CTA</h4>

                  <input
                    name="cta"
                    placeholder="Add cta "
                    type="text"
                    {...register("cta")}
                    className={`form-control form-input-text-flow ${errors.cta ? "is-invalid" : ""
                      }`}
                  />

                  <div className="invalid-feedback">{errors.cta?.message}</div>
                </div>
              </div>

              {/* <div className="form-row"> 
              <div className="form-group col">
                <h4 className="h4 text-muted">Drop down</h4>
                <select class="select-gradient" aria-label="Default select example">
                  <option class="select-options-gradient" selected>Open this select menu</option>
                  <option class="select-options-gradient" value="1">One</option>
                  <option class="select-options-gradient" value="2">Two</option>
                  <option class="select-options-gradient" value="3">Three</option>
                </select>
              </div>
               </div> */}

              {!loading &&
                <div style={{
                  padding: "5px 0 30px 0"
                }}>

                  <div className="free-trail-next-wrapper next-wrapper-btn btn-width light-button wht-bdr3"
                    style={{ height: "50px" }}
                  >
                    <button
                      className="continue-btn-free-trail cursor-pointer  font-18-regular"
                      type="submit"
                    >
                      <span className="gradient-text">Done
                      </span>
                    </button>
                  </div>
                </div>
              }
            </form>
          </div>{" "}
        </div>
        <div className="clearfix"></div>
      </div>
    </React.Fragment>
  );
};
export default TextDescription;

import { saveAs } from "file-saver";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import { getWebsiteTitle } from "../../../utils/function";

import adSpireWatermark from "../../../images/icons/Adspire_watermark.svg";
import SignInScreen from "../../SignInScreen";

const LandingAdListing = (props) => {
  const [active, setActive] = useState(null);
  const [popupImage, setPopupImage] = useState(null);
  const [displayBlock, setDisplayBlock] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn-enable");

  const navigate = useNavigate();

  const selectImage = (items) => {
    setActive(items);
    if (userService.isContentAllowed()) {
      setPopupImage(items);
    } else {
      openLoginPopup();
    }
  };

  const openLoginPopup = () => {
    const myBtn = document.getElementById("ploginModal");
    myBtn.click();
  };

  const handleDownloadClick = (url) => {
    saveAs(url, "img");
  };

  const generateAdIdeasProps = (e) => {
    props.setGeneratedImage(false);
    props.setLoader(true);
    props.generateAdIdeas();
  };

  useEffect(() => {
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .collection("types")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));
      });

    // Getting prompt value from here
    db.collection("adIdeas")
      .doc("ideasGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage("prompt", snapshot.data().prompt);
        userService.setKeyValueLocalstorage(
          "maxIdeas",
          snapshot.data().maxIdeas
        );
        userService.setKeyValueLocalstorage("model", snapshot.data().model);
        userService.setKeyValueLocalstorage(
          "temperature",
          snapshot.data().temperature
        );
        userService.setKeyValueLocalstorage(
          "maxTokens",
          snapshot.data().maxTokens
        );
      });

    // Getting prompt value for image
    db.collection("adIdeas")
      .doc("imageGenerator")
      .onSnapshot((snapshot) => {
        userService.setKeyValueLocalstorage(
          "promptImage",
          snapshot.data().prompt
        );
        userService.setKeyValueLocalstorage("size", snapshot.data().size);
        userService.setKeyValueLocalstorage(
          "maxImages",
          snapshot.data().maxImages
        );
      });
  }, []);

  const colors = ["#4fcfbe", "#ff9e83", "#fc94cf"];

  document.title = getWebsiteTitle() + "Image Ads";
  return (
    <React.Fragment>
      {props.generatedImage && (
        <div className="container-fluid">
          <div className="text-center">
            {/* <Logo path="/home"></Logo> */}
            <h2 className="gradient-logo-text-adListing text-size-32">
              ADSPIRE
            </h2>
          </div>
          <div className="row">
            <div className="">
              <div className="top-contentDIv">
                <div className="top-ranckking">
                  <div className="col-sm-10 col-xs-10 col-xs-offset-1 col-sm-offset-1">
                    <h2 className="gradient-head-text text-size-32 mb-40">
                      Here are some image Ads I made using the idea:
                    </h2>
                    <p>{userService.getValueFromLocalstorage("ideas")}</p>
                    <div className="clearfix"></div>

                    <div className="row mb-20 mt-20">
                      {props.generatedImage &&
                        props.generatedImage.map((items, index) => (
                          <div
                            className={`col-sm-3 col-xs-12`}
                            key={`gen-ad-img-${index}`}
                            onClick={() => selectImage(items)}
                            data-toggle="modal"
                            data-target="#imageDetailModal"
                          >
                            <div
                              className={`thumbnailListBG mb-20 ${
                                active == items && "box-active"
                              }`}
                            >
                              <img
                                data-toggle="modal"
                                data-target="#myModal"
                                className="img-responsive user-imgDiv pointerEvents"
                                src={items}
                              />
                              {/*} <Watermark text="Adspire" textColor="#000"><img
                                data-toggle="modal"
                                data-target="#myModal"
                                className="img-responsive user-imgDiv pointerEvents"
                                src={items}
                              /></Watermark>*/}
                              <img
                                src={adSpireWatermark}
                                alt=""
                                className="watermarkImg"
                              />
                              <div className="clearfix"></div>
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className="col-sm-offset-0 col-sm-12 text-center">
                      <div className="form-group text-center">
                        {!userService.isContentAllowed() && (
                          <button
                            type="btn"
                            title="Generate Captions"
                            data-toggle="modal"
                            data-target="#captionModal"
                            className="btn btn-primary generateAd  "
                            onClick={() => openLoginPopup()}
                          >
                            Generate Captions
                          </button>
                        )}
                      </div>

                      {!userService.showLogoutBtn() && (
                        <>
                          <div
                            className="form-group text-center text-weight-600"
                            title="Generate more "
                          >
                            <b>
                              Sign in to download your image ads{" "}
                              <span className="underline">unwatermarked:</span>
                            </b>
                          </div>

                          <div className="ad-idea-goole-btn-div">
                            <SignInScreen></SignInScreen>
                          </div>

                          <h2 className="gradient-head-text text-size-32">
                            Here are your amazing ad ideas:
                          </h2>
                          <div className="row mb-20">
                            {colors &&
                              colors.map((color, index) => (
                                <div
                                  className={`col-sm-6 col-xs-12 col-sm-offset-3`}
                                  key={color}
                                >
                                  <div
                                    className={`some-ad-idea-thumbnailBG blur text-size-16`}
                                    style={{
                                      backgroundColor: color,
                                    }}
                                  ></div>
                                  {index == 0 && (
                                    <div className="blur-text">
                                      Sign-in to see<br></br> your ad ideas
                                    </div>
                                  )}

                                  <div className="clearfix"></div>
                                </div>
                              ))}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};
export default LandingAdListing;

import React from 'react'
import Rolling_circle_loader from "../../images/icons/Rolling_circle_loader.svg"

const SpinnerLoader = (props) => {
  return (
    <div className={`text-center ${props.clsname}`}>
      <img
        width={100} className=""
        src={Rolling_circle_loader}
        alt="Loading..."
        title="Loading..."
      />
    </div>
  )
}

export default SpinnerLoader

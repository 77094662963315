import React from "react";
import twitter from "../../../images/icons/twitter.svg";
import ellipse_133 from "../../../images/landing_page_assests/ellipse-133.svg";
import ellipse_134 from "../../../images/landing_page_assests/ellipse-134.svg";
import facebook_1 from "../../../images/landing_page_assests/facebook-1.svg";
import instagram_1 from "../../../images/landing_page_assests/instagram-1.svg";
import line_1 from "../../../images/landing_page_assests/line-1.svg";
import linkedin_1 from "../../../images/landing_page_assests/linkedin-1.svg";
import moveup_1 from "../../../images/landing_page_assests/moveup-1.svg";

export default FooterLanding;

function FooterLanding() {
  const redirectTo = (pageName) => {
    window.location.href = "/" + pageName;
  };
  const getCurrentYear = () => {
    const currentYear = new Date().getFullYear();
    return currentYear;
  };
  const currentYear = getCurrentYear();
  return (
    <>
      <div className="frame-parent32">
        <div className="frame-parent33">
          <div className="adspire-group ">
            <div className="adspire8 ">Adspire®</div>
            <div className="the-worlds-most1 ">{`The world's most powerful AI that inspires & generates ads for your businesses in seconds!`}</div>
         
           
          </div>
          <div className="instance-container position-relative z-index-1">
            <div
              className="next-wrapper5 cursor-pointer"
              onClick={() => redirectTo("free-trial")}
            >
              <div className="small-business">Free Trial</div>
            </div>
            <div
              className="next-wrapper5 cursor-pointer"
              onClick={() => redirectTo("aboutus")}
            >
              <div className="small-business">About Us</div>
            </div>
            <div
              className="next-wrapper5 cursor-pointer"
              onClick={() => redirectTo("legal/tos")}
            >
              <div className="small-business">Terms of Service</div>
            </div>
            <div
              className="next-wrapper5 cursor-pointer"
              onClick={() => redirectTo("legal/privacy")}
            >
              <div className="small-business">Privacy Policy</div>
            </div>
          </div>
        </div>
        <img className="frame-child15" alt="" src={ellipse_133} />
        <img className="frame-child16" alt="" src={ellipse_134} />
        <img className="frame-child17" alt="" src={line_1} />

        <div className="frame-parent34">
          <div className="adspire-inc-all-rights-reserv-wrapper">
            <div className="adspire-inc-all">
              © {currentYear} Trendicator LLC. All rights reserved.
            </div>
          </div>
          <div className="facebook-1-parent">
            <a href="https://www.facebook.com/adspireai" target="_blank">
              <img className="facebook-1-icon" alt="" src={facebook_1} />
            </a>
            <a href="https://www.instagram.com/adspireai" target="_blank">
              <img className="facebook-1-icon" alt="" src={instagram_1} />
            </a>
            <a
              href="https://www.linkedin.com/company/adspireai/"
              target="_blank"
            >
              <img className="facebook-1-icon" alt="" src={linkedin_1} />
            </a>
            <a href="https://www.twitter.com/adspireai" target="_blank">
              <img className="facebook-1-icon" alt="" src={twitter} />
            </a>
          </div>
        </div>
        <div className="frame-wrapper4">
          <div
            className="move-up-1-parent"
            onClick={() => {
              window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
            }}
          >
            <img className="move-up-1-icon" alt="" src={moveup_1} />
            <div className="small-business">Back to top</div>
          </div>
        </div>
      </div>{" "}
    </>
  );
}

// reducers.js
import { UPDATE_DOWNLOAD_COUNT } from '../action/action';
import { userService } from "../../services/user.service";

const initialDownloadLeft = userService.downloadLeftImage()

const downloadLeftReducer = (state = {downloadLeft: initialDownloadLeft },  action) => {
  switch (action.type) {
    case UPDATE_DOWNLOAD_COUNT:
      return {
        ...state,
        downloadLeft: action.payload
      };
    default:
      return state;
  }
};

export default downloadLeftReducer;

import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";

import { getWebsiteTitle } from "../../../utils/function";

const LandingDescriptionBusiness = (props) => {
  
  const [categoryTypeValue, setCategoryTypeValue] = useState(
    userService.getValueFromLocalstorage("categoryTypeValue")
  );
  const [adIdeaforcategoryTypeValue, setAdIdeaforcategoryTypeValue] = useState(
    "Your " + userService.getValueFromLocalstorage("categoryTypeValue")
  );
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");

  const [mainTitle, setMainTitle] = useState(
    "Briefly describe Your " + categoryTypeValue + ":"
  );

  const [brieflyDescribeBusinessText, setBrieflyDescribeBusinessText] =
    useState(userService.getValueFromLocalstorage("input"));


  useEffect(() => {
   // Getting prompt value from here 
   db.collection("adIdeas")
   .doc("ideasGenerator")
   .onSnapshot((snapshot) => {
     userService.setKeyValueLocalstorage("prompt", snapshot.data().prompt);
     userService.setKeyValueLocalstorage("maxIdeas", snapshot.data().maxIdeas);
     userService.setKeyValueLocalstorage("model", snapshot.data().model);
     userService.setKeyValueLocalstorage("temperature", snapshot.data().temperature);
     userService.setKeyValueLocalstorage("maxTokens", snapshot.data().maxTokens);
   });

 // Getting prompt value for image  
 db.collection("adIdeas")
   .doc("imageGenerator")
   .onSnapshot((snapshot) => {
     userService.setKeyValueLocalstorage("promptImage", snapshot.data().prompt);
     userService.setKeyValueLocalstorage("size", snapshot.data().size);
     userService.setKeyValueLocalstorage("maxImages", snapshot.data().maxImages);
   });
  });
  useEffect(() => {
    if (brieflyDescribeBusinessText) {
      setButtonStatus(false)
      setClassvalue("next-btn-enable")
    }
  }, [userService.isLoggedInUser()]);

  const postContent = (e) => {
    setButtonStatus(false)
    setClassvalue("next-btn-enable")
    setBrieflyDescribeBusinessText(e.target.value);
    userService.setKeyValueLocalstorage("input", e.target.value);
  };

  document.title = getWebsiteTitle() + "Description";

  
  const nextTab = async() => {
    props.currentPage("ad-ideas")
    //props.currentPage("ad-type")// ad-ideas
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <form>
                  <div className="top-ranckking">
                  <div className="what-do-you-want-an-ad-for-wrapper">
                  <b className="what-do-you">{mainTitle}</b>
                </div>
                    <div className="col-sm-12 col-sm-offset-0">
                     {/* <h2 className="gradient-head-text text-center text-size-32 mb-40">{mainTitle}</h2>*/}
                     
                
                      <div className="clearfix"></div>
                      <div className="form-group">

                      </div>
                      <div className="row col-sm-8 col-sm-offset-2 mb-40 mr-top-30">
                        <div className="form-group "  >
                          <textarea
                            name="postContent"
                            className="form-control msgbox"
                            placeholder={adIdeaforcategoryTypeValue}
                            rows={6}
                            cols={40}
                            onChange={postContent}
                          >{brieflyDescribeBusinessText}</textarea>
                        </div>
                        <div className="form-group text-center nextbtn-z-index " title="Next" >
                          <button
                            type="btn"
                            disabled={buttonStatus}
                            className={`btn btn-primary ${classvalue}`}
                            onClick={() => nextTab()}
                          >
                            Next
                          </button>
                        </div>
                      </div>


                    </div>
                  </div>

                </form>
              </div>{" "}
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LandingDescriptionBusiness;

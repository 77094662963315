import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import API_FIREBASE from "../config/apifirebase";
import PressIdea from "../images/icons/PressIdea.png";
import { userService } from "../services/user.service";
import constants from "../utils/constants";
import { filterIdeas, getWebsiteTitle } from "../utils/function";
import GenerateMoreButton from "./common/Button/GenerateMoreButton";
import SpinnerLoader from "./common/SpinnerLoader";

const SomeSubAdIdeas = (props) => {
  const [adStyles, setStyles] = useState("");
  const [ideas, setIdeas] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [loading, setLoader] = useState(false);
  const [completionText, setCompletionText] = useState("");
  const [active, setActive] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");
  const [openInputBox, setOpenInputBox] = useState(false);

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
    //  navigate("/login");
    }
   
    
    const textValue=userService.getValueFromLocalstorage("text");
    
    if(userService.getValueFromLocalstorage("text")){
      setLoader(true)
      setCompletionText(filterIdeas(textValue));
      userService.removeKeyLocalstorage("showLocalAdIdeas");
    }
  }, []);

  useEffect(() => {
    //userService.setKeyValueLocalstorage("ideas", ideas);
  }, [ideas]);

  const generateAdIdeas = async () => {
    setLoader(false);
    const input = userService.getValueFromLocalstorage("input");
    const userId = userService.getUserId();
    const typeId = userService.getValueFromLocalstorage("typeIDOfAdValue");
    const type = userService.getValueFromLocalstorage("typeValue");
    const adCategoryId = userService.getValueFromLocalstorage("adCategoryID");
    const prompt = userService.getValueFromLocalstorage("prompt");
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const model = userService.getValueFromLocalstorage("model");
    const temperature = userService.getValueFromLocalstorage("temperature");
    const maxTokens = userService.getValueFromLocalstorage("maxTokens");
    // const maxTokens = userService.getValueFromLocalstorage("maxTokens");

    const objGenerateImage = {
      input,
      userId,
      typeId,
      type,
      adCategoryId,
      prompt,
      maxIdeas,
      model,
      temperature,
      maxTokens,
    };
    let completionTextArr = [];
    API_FIREBASE.post("requestTextCompletionFromOpenAI", objGenerateImage, {
      headers: {
        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
      },
    })
      .then((res) => {
        const text = res.data.completionText;
        setCompletionText(filterIdeas(text));
        userService.setKeyValueLocalstorage(
          "text",
          text
        );
       
        userService.setKeyValueLocalstorage(
          "userIdeaHistoryId",
          res.data.userIdeaHistoryId
        );
        setLoader(true);
        
      }, [])
      .catch((e) => {
        if (e.response.status === 500) {
          //check the library you use to fetch data for how they responds with error statuses

          if (
            constants.APPLICATION_INFO.CREDIT_REACHED_KEY === e.response.data
          ) {
            // used to open the popup
            const myBtn = document.getElementById("psubscribeModal");
            myBtn.click();
          }
          /* setErrorMessage(
            formatErrorMessage(constants.APPLICATION_INFO.CREDIT_REACHED)
          ); */
        }

        setLoader(true);
      });
  };

  const colors = [
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
    "#4fcfbe",
    "#ff9e83",
    "#fc94cf",
    "#8bb6f0",
    "#b596ec",
  ];

  document.title = getWebsiteTitle() + "Ad Ideas";
  const userSelectedIdeas = (ideas) => {
    userService.setKeyValueLocalstorage("ideas", ideas);
    setUserInputValue(ideas);

    setActive(ideas);
    setButtonStatus(false);
    setClassvalue("generateAd");
  };
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };

  const AdduserInsertedIdea = (e) => {
    setUserInputValue(e.target.value);
    userService.setKeyValueLocalstorage("ideas", e.target.value);
  };

  const getText = (userClickedText, realText) => {
    if (userClickedText === realText) {
      return false;
    } else if (
      (userClickedText === realText && !openInputBox) ||
      !openInputBox
    ) {
      return true;
    }
    return false;
  };

  const getInputBtn = (userClickedTextActive, realText) => {
    if (userClickedTextActive === realText) {
      return true;
    }
    return false;
  };

  /*const getBtn=(userClickedTextActive,realText)=>{
    if(userClickedTextActive===realText){
       return true;
    }
    return false;
  }*/
  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                
                <h2 className="gradient-head-text text-center text-size-32 mb-40">
                  Your simple ad Ideas for {userService.getValueFromLocalstorage("input")} 
                </h2>
                {errorMessage && (
                  <p className="error-message text-center">{errorMessage}</p>
                )}

                <div className="clearfix"></div>
                
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (
                    <div className="row mb-20">
                      {completionText &&
                        completionText.slice(1).map(
                          (adcategoryItems, index) =>
                            adcategoryItems && (
                              <div
                                className={`col-sm-12 col-xs-12`}
                                key={adcategoryItems}
                              >
                                <div
                                  className={`some-ad-idea-thumbnailBG text-size-16 ${
                                    active === adcategoryItems &&
                                    "box-active"
                                  }`}
                                  style={{
                                    backgroundColor: colors[index],
                                  }}
                                >
                                  <div className="">
                                    <span className=" text-weight-500">
                                      {getInputBtn(
                                        active,
                                        adcategoryItems
                                      ) && (
                                        <input
                                          className="form-control"
                                          type="text"
                                          onChange={(e) =>
                                            AdduserInsertedIdea(e)
                                          }
                                          value={userInputValue}
                                        ></input>
                                      )}

                                      {getText(active, adcategoryItems) && (
                                        <span
                                          className=" text-weight-500"
                                          onClick={() =>
                                            userSelectedIdeas(
                                              adcategoryItems
                                            )
                                          }
                                        >
                                          {adcategoryItems}
                                        </span>
                                      )}
                                    </span>
                                  </div>

                                  {getInputBtn(active, adcategoryItems) && (
                                    <div className="form-group text-center mtop nobottom-space">
                                      <button
                                        title="Generate Image Ads"
                                        type="button"
                                        className={`btn btn-primary generateAd`}
                                        onClick={() =>
                                          redirectTo(
                                            "image-style"
                                          )
                                        }
                                      >
                                        Generate Image Ads
                                      </button>
                                      {/* <img src={edit_pencil} alt="" className="float-r mt-20" />*/}
                                    </div>
                                  )}

                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            )
                        )}
                      <img
                        src={PressIdea}
                        alt=""
                        width={160}
                        className="tap-to-generate-img"
                      />
                    </div>
                  )}
                  {loading && (
                    <>
                      {!userService.isSubscribed && (
                        <GenerateMoreButton
                          buttonStatus={buttonStatus}
                          classvalue="btn btn-primary generateAd"
                          pagename="image-style"
                        ></GenerateMoreButton>
                      )}

                      {userService.isSubscribed && (
                        <div
                          className="form-group text-center"
                          title="Generate more "
                        >
                          <button
                            type="btn"
                            className={`btn btn-primary next-btn-enable`}
                            onClick={() => generateAdIdeas()}
                          >
                            Generate more{" "}
                          </button>
                        </div>
                      )}

                     
                    </>
                  )}
                
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
     
    </React.Fragment>
  );
};
export default SomeSubAdIdeas;

import React from "react";
import adspire_icon_for_btn from "../../../images/icons/adspire_icon_for_btn.svg";



const StyledButtonWhiteGradientWithIcon = (props) => {

    return (
        <>
            <button className={`${props?.btnStylebyclass} next-wrapper6 try-for-free-btn`} style={props?.btnStyle} onClick={props?.onClick}>
                <div className={`${props?.btnDivStylebyclass} try-for-free-btn-inner-style`} style={props?.btnDivStyle}>
                    <b className={`${props?.btnTextStylebyclass}`} style={props?.btnTextStyle}>
                        {props?.btnText}
                    </b>
                    <img className={`${props?.btnIconStylebyclass}`} src={props?.icon ? props?.icon : adspire_icon_for_btn} alt="icon" width={40} height={40} style={props?.btnIconStyle} />
                </div>
            </button >
        </>
    );
};
export default StyledButtonWhiteGradientWithIcon;



import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import { userService } from "../../../services/user.service";
import { getWebsiteTitle, removeSpecialCharacters } from "../../../utils/function";

const LandingStore = () => {
  document.title = getWebsiteTitle() + "Loading...";
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [loading, setLoading] = useState(false);
  const randomText = [
    "Preparing...",
    "Connecting...",
    "Analyzing...",
    "Reviewing...",
    "Fetching Products...",
    "Saving Images...",
    "Finalizing...",
  ]


  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      navigate("/login");
    }
  }, [!userService.isLoggedInUser()]);


  useEffect(() => {
    const interval = setInterval(() => {
      setLoadingProgress(prevProgress => {
        const randomIncrement = Math.floor(Math.random() * 9) + 1;
        const newProgress = prevProgress + randomIncrement;

        if (newProgress < 99) {
          return newProgress;
        } else {
          clearInterval(interval);
          setLoading(true)
          return 99;
        }
      });
    }, 500); // Adjust the interval as needed

    return () => {
      clearInterval(interval);
    };
  }, []);

  let currentText = randomText[0];
  switch (true) {
    case loadingProgress > 0 && loadingProgress < 18:
      currentText = randomText[0];
      break;
    case loadingProgress >= 18 && loadingProgress < 30:
      currentText = randomText[1];
      break;
    case loadingProgress >= 30 && loadingProgress < 45:
      currentText = randomText[2];
      break;
    case loadingProgress >= 45 && loadingProgress < 54:
      currentText = randomText[3];
      break;
    case loadingProgress >= 54 && loadingProgress < 70:
      currentText = randomText[4];
      break;
    case loadingProgress >= 70 && loadingProgress < 85:
      currentText = randomText[5];
      break;
    case loadingProgress >= 85 && loadingProgress <= 100:
      currentText = randomText[6];
      break;
    default:
      // Handle the case when loadingProgress is outside of the specified ranges
      currentText = "Preparing...";
      break;
  }


  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="top-contentDIv loading-container-new-lading-page">
              <div className="top-ranckking ">
                {/* //////////////// */}
                <div className="loading-screen">
                  <div className="loading-items-container">
                    <div className="circle-wrapper">
                      <div className="circle">
                        <div className="loading-text">{loadingProgress}%</div>
                      </div>
                    </div>
                    <div className="progress-bar-and-content-container">
                      <div className="progress-container">
                        <progress id="file" className="custom-progress" value={loadingProgress} max="100"></progress>
                      </div>
                      <div className="">
                        <span className={`progress-random-text polishing`}>
                          {currentText}
                        </span>
                      </div>
                      {loading && (
                        <div className="row">
                          <div
                            className=" top-ranckking text-center"
                          >
                            <img
                              width={50}
                              className=""
                              src={Rolling_circle_loader}
                              alt="This is taking a bit longer, please wait a few seconds..."
                              title="This is taking a bit longer, please wait a few seconds..."
                            />
                          </div>
                        </div>)}
                    </div>
                  </div>
                </div>
                {/* //////////////// */}
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default LandingStore;

import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { auth } from "../../../utils/Firebase";
import LogoLanding from "../../common/LogoLanding";
import useWindowSize from "../../../_helper/ScreenUtil";

export default HeaderLanding;

function HeaderLanding() {
  const [pathName, setPathName] = useState(window.location.pathname);
  const [loginStatus, setLoginStatus] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize()
  useEffect(() => {
    if (userService.getLoginMethodAndStatus()) {
      setLoginStatus(true);
    }
  }, [userService.getValueFromLocalstorage("userLoginStatus")]);

  const getPageToRedirect = () => {
    navigate(userService.getUserRedirectPage());
  };

  const ulogout = async () => {
    await auth.signOut();
    userService.logout();
    window.location.href = "/";
  };
  const isLaptop = width < 1060
  return (
    <>
      <div className="adspire-parent adspire-parent-on-top">
        <LogoLanding></LogoLanding>
        <div className="frame-parents">
          <div className="instance-parent">
            <div className="next-wrapper" title="Get Started">
              <b className="next">Get Started</b>
            </div>
            <div className="instance-group">
              <button className="next-container" title="Home">
                <h4 className={`head-btn${pathName === "/" ? "-active" : ""}`}>
                  {userService.isLoggedInUser() && (
                    <Link to={"/home"}>Home</Link>
                  )}
                  {!userService.isLoggedInUser() && <Link to={"/"}>Home</Link>}
                </h4>
              </button>
              {!userService.isLoggedInUser() || !isLaptop && (
                <button className="next-container" title="About US">
                  <h4
                    className={`head-btn${pathName === "/aboutus" ? "-active" : ""
                      }`}
                  >
                    <a href={"/aboutus"}>About Us</a>
                  </h4>
                </button>
              )}


              <button className="next-container" title="Pricing">
                <h4
                  className={`head-btn${pathName === "/pricing" ? "-active" : ""
                    }`}
                >
                  <a href={userService.getPricingRedirectPage("pricing")}>
                    Pricing
                  </a>
                </h4>
              </button>
              {/*  <button className="next-container" title="Ai Ads">
                <h4 className={`head-btn${pathName === '/ai-ads' ? '-active' : ''}`}><a href={"/ai-ads"}>Ai Ads</a></h4>
              </button> */}

              {!userService.showLogoutBtn() && (
                <button
                  className="next-container position-relative z-index-1"
                  title="Login"
                >
                  <Link to="/signup" className="head-btn head-font">
                    Login
                  </Link>
                </button>
              )}

              {userService.showLogoutBtn() && (
                <button className="next-container position-relative z-index-1">
                  <h4
                    className="head-btn welcome-logged-in-user position-relative z-index-1"
                    onClick={() => getPageToRedirect()}
                  >
                    Welcome {userService.getUserName()}
                  </h4>
                </button>
              )}

              {userService.showLogoutBtn() && (
                <button
                  onClick={() => ulogout()}
                  className="next-container position-relative z-index-1"
                  title="LogOut"
                >
                  <h4 className="head-btn">LogOut</h4>
                </button>
              )}
            </div>
          </div>

          <button
            title="Try for FREE"
            className="next-wrapper1 position-relative z-index-1"
            onClick={() => getPageToRedirect()}
          >
            <b className="what-do-you">Try for FREE</b>
          </button>
        </div>
      </div>
    </>
  );
}

import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import API_FIREBASE from "../config/apifirebase";
import { userService } from "../services/user.service";
import { db } from "../utils/Firebase";
import constants from "../utils/constants";
import ButtonGradient from "./common/Button/ButtonGradient";
import SpinnerLoader from "./common/SpinnerLoader";

const SubscriptionDetails = () => {
  // ******************** Hooks ***************************************
  const navigate = useNavigate();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isReasonModalOpen, setIsReasonModalOpen] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [actionType, setActionType] = useState("");

  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [loading, setLoader] = useState(false);

  // ******************** Functions ***************************************
  const userAction = (userAction) => {
    if (userAction == "discountOffer") {
      setModalOpen(true);
      setActionType("offer");
    } else if (userAction == "cancelPlan") {
      setModalOpen(true);
      setActionType("cancel");
    } else if (userAction == "deleteUser") {
      setIsConfirmModalOpen(true);
      setActionType("delete");
    }
  };

  const confirmUserAction = (userAction) => {
    if (userAction == "yes" && actionType == "delete") {
    } else if (userAction == "yes" && actionType == "cancel") {
    } else if (userAction == "no") {
      setIsReasonModalOpen(true);
    } else {
      setModalOpen(false);
      setIsReasonModalOpen(false);
      setIsConfirmModalOpen(true);
      setActionType("");
      setModalTitle("");
    }
  };

  const redirectToDiscountPage = () => {
    navigate("/subscriptions-50-off");
  };

  const initFormValues = {
    cancelPlan: "",
  };
  const [formData, setFormData] = useState(initFormValues);

  // form validation rules
  const validationSchema = Yup.object().shape({
    cancelPlan: Yup.string().required(
      "Please type Confirm text to cancel the Plan"
    ),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  formOptions.defaultValues = {
    cancelPlan: "",
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const onSubmit = async (data) => {
    setLoader(true);
    setErrorMessage("");
    if (data.cancelPlan.toUpperCase() != "CONFIRM") {
      setErrorMessage("Please enter CONFIRM to cancel your subscription.");
      setLoader(false);
    } else {
      try {
        setLoader(true);
        setErrorMessage("");
        userService.setKeyValueLocalstorage("cancelPlan", data.cancelPlan);
        await cancelSubscription(data);
        setLoader(false);
      } catch (error) {
      } finally {
        setLoader(false);
      }
    }
  };
  const redirectTo = (page) => {
    navigate("/" + page);
  };
  const cancelSubscription = async () => {
    try {
      const objUserSubscribe = {
        // name: userService.getValueFromLocalstorage("uid"),
        subscriptionId: userService.getValueFromLocalstorage("subscriptionId"),
        environment: process.env.REACT_APP_STATUS,
      };
      // Trial period $19.99 USD with 3 day trial period
      await API_FIREBASE.post(
        "requestStripeCancelSubscriptions",
        objUserSubscribe,
        {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        }
      ).then(async (res) => {
        if (res.data && res.data.canceledSubscription) {
          if (res.data.canceledSubscription.status === "canceled") {
            userService.setKeyValueLocalstorage("purchaser", "no"); // later we would do true
            updateCollection(res.data.canceledSubscription.id);

            setSuccessMessage(
              `Enjoy the rest of your trial until ${userService.getValueFromLocalstorage(
                "ExpiryDate"
              )}`
            );
            return;
          }
        } else {
          setErrorMessage("There is some error, Please try again later!");
          return;
        }
      });
    } catch (err) {
      console.error(err);
      setErrorMessage("There is some error, Please try again later!");
      return;
    }
  };

  const updateCollection = async (subscriptionId) => {
    const collectionRef = db.collection("purchases");
    userService.setKeyValueLocalstorage("purchaser", "no"); // later we would do true
    try {
      const querySnapshot = await collectionRef
        .where("subscriptionId", "==", subscriptionId)
        .get();

      if (querySnapshot.empty) {
        console.log("No matching documents.");
        return;
      }

      querySnapshot.forEach(async (doc) => {
        // Update the document with the new price_id
        await doc.ref.update({
          status: "canceled",
          canceledDate: new Date(),
          cancelSource: "website",
        });
      });
    } catch (error) {
      console.error("Error updating document:", error);
    }
  };

  const closeModal = () => {
    setIsConfirmModalOpen(false);
  };
  const handleCancel = () => {
    closeModal(); // Assuming closeModal is a function passed as a prop to close the modal
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row settings nav-margin-top">
          <span className="span">
            <b className="gradient-head-text text-size-30">
              Settings (Continued)
            </b>
          </span>
          <div className="col-md-12">
            <div className="card-container">
              {!userService.isPurchaser() && (
                <div className="card-body">
                  <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                    Plan
                  </h3>
                  <h4 className="text-white">
                    Your current plan is :
                    <span className="text-weight-600">&nbsp; N/A</span>{" "}
                    <span></span>
                  </h4>
                </div>
              )}
              {userService.isPurchaser() && (
                <div className="card-body">
                  <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                    Plan
                  </h3>

                  {userService.getValueFromLocalstorage("isTrialPeriod") ==
                    "yes" && (
                      <>
                        <h4 className="text-white">
                          Your current plan is :
                          <span className="text-weight-600">
                            &nbsp; Free Trial
                          </span>{" "}
                          <span></span>
                        </h4>

                        {userService.getValueFromLocalstorage("paymentStatus") ===
                          "ACTIVE" && (
                            <p className="text-light-gray-200 font-family-sans-serif">
                              {userService.getValueFromLocalstorage(
                                "planSettingText"
                              )}
                            </p>
                          )}

                        {userService.getValueFromLocalstorage("paymentStatus") ===
                          "canceled" && (
                            <p className="text-light-gray-200 font-family-sans-serif">
                              Expires on &nbsp;
                              {userService.getValueFromLocalstorage("ExpiryDate")}
                            </p>
                          )}
                      </>
                    )}

                  {userService.getValueFromLocalstorage("isTrialPeriod") ==
                    "no" && (
                      <>
                        <h4 className="text-white">
                          Your current plan:{" "}
                          <span className="text-weight-600">
                            {userService.getValueFromLocalstorage("plan")}{" "}
                          </span>{" "}
                          <span>
                            - $
                            {userService.getValueFromLocalstorage("planAmount")}
                          </span>
                        </h4>
                        <p className="text-light-gray-200 font-family-sans-serif">
                          {userService.getValueFromLocalstorage(
                            "paymentStatus"
                          ) === "canceled" && <>Expires on </>}
                          {userService.getValueFromLocalstorage(
                            "paymentStatus"
                          ) === "ACTIVE" && <>Renews on </>}

                          {userService.getValueFromLocalstorage("ExpiryDate")}
                        </p>
                        <button
                          className="text-white bg-none border-none text-size-18 p-0"
                          onClick={() => {
                            redirectTo("pricing");
                          }}
                        >
                          See All Plans
                        </button>
                      </>
                    )}
                </div>
              )}

              <div className="card-body">
                <h3 className="text-white text-weight-600 font-family-vietnam-pro">
                  Danger Zone
                </h3>

                {userService.getValueFromLocalstorage("paymentStatus") ===
                  "ACTIVE" && (
                    <p className="text-light-gray-200 font-family-sans-serif">
                      <div
                        className="underline text-dark-red ml-15 cursor-pointer"
                        onClick={() => {
                          userAction("discountOffer", true);
                        }}
                      >
                        Cancel Plan
                      </div>
                    </p>
                  )}

                {userService.getValueFromLocalstorage("paymentStatus") ===
                  "canceled" && (
                    <>
                      <p className="text-light-bedami-200 font-family-sans-serif font-italic">
                        <div className="underline text-dark ml-15">
                          Plan cancelled on{" "}
                          {userService.getValueFromLocalstorage("CancelDate")}
                        </div>
                      </p>

                      <p className="text-light-green-200 font-family-sans-serif">
                        <div
                          className="underline text-dark ml-15 cursor-pointer"
                          onClick={() => {
                            redirectTo("pricing");
                          }}
                        >
                          Re-activate
                        </div>
                      </p>
                    </>
                  )}

                <p className="text-light-gray-200 font-family-sans-serif">
                  <div
                    className="underline text-dark-red ml-15 cursor-pointer"
                    onClick={() => {
                      userAction("deleteUser", true);
                    }}
                  >
                    Delete Account
                  </div>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`modal ${isModalOpen ? "show" : ""}`}
        style={{
          display: isModalOpen ? "block" : "none",
          background: "#0000006e",
          marginLeft: "12%",
        }}
      >
        <div
          className="modal-dialog modal-dialog-centered top-20-percent text-open-sans"
          role="document"
        >
          <div className="modal-content modal-gradient-new-2">
            <div className="p-15 mr-40">
              <button
                type="button"
                onClick={() => setModalOpen((prev) => !prev)}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="">
                <div className="text-white">
                  {actionType == "offer" && (
                    <>
                      <h1 className="text-weight-600 text-size-40 m-0">
                        How about 50% Off?
                      </h1>
                      <p className="text-size-30">
                        This is the biggest discount we offer, ever!
                      </p>
                    </>
                  )}
                </div>
                <div className="text-center">
                  <ButtonGradient
                    title="Claim 50% Discount"
                    btnstyle={{ background: "white" }}
                    stylebyclass="text-size-20 p-8 text-black"
                    btnstylebyclass="m-left-right-auto mb-20"
                    onClick={() => redirectToDiscountPage()}
                  />
                  <p
                    className={`m-0 text-black text-size-22 cursor-pointer`}
                    onClick={() => confirmUserAction("no")}
                  >
                    {" "}
                    Cancel{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Confirm cancel modal */}
      <div
        className={`modal ${isConfirmModalOpen ? "show" : ""}`}
        style={{
          display: isConfirmModalOpen ? "block" : "none",
          background: "#0000006e",
          marginLeft: "12%",
        }}
      >
        <div
          className="modal-dialog modal-600 modal-dialog-centered top-20-percent text-open-sans"
          role="document"
        >
          <div className="modal-content modal-gradient-new-2">
            <div className="p-15 mr-40">
              <button
                type="button"
                onClick={() => setIsConfirmModalOpen((prev) => !prev)}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10">
                  {loading && (
                    <>
                      <p className="text-size-28">
                        We are processing your cancellation request...
                      </p>
                      <div className="text-center">
                        <SpinnerLoader></SpinnerLoader>
                      </div>
                    </>
                  )}

                  {!loading && successMessage && (
                    <p className="font-22 mt-10 p-60">
                      <b className="text-center text-size-28">
                        {" "}
                        {successMessage}
                      </b>
                      <h5
                        className="text-white text-size-22 text-weight-600 underline cursor-pointer"
                        onClick={() => {
                          redirectTo("pricing");
                        }}
                      >
                        Re-activate
                      </h5>
                    </p>
                  )}

                  {!loading && !successMessage && (
                    <>
                      <div className="text-white">
                        <h1 className="text-weight-600 text-size-40">
                          Are you 100% sure?
                        </h1>
                        <p className="text-size-28">
                          Please type CONFIRM in the box below to cancel your
                          account.
                        </p>

                        {errorMessage && (
                          <p className="errorastrick">{errorMessage}</p>
                        )}
                      </div>
                      <div
                        className={`form-group width-80-percent m-left-right-auto`}
                      >
                        <input
                          name="text"
                          type="text"
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              cancelPlan: e.target.value,
                            })
                          }
                          {...register("cancelPlan")}
                          className="text-size-22 font-family-vietnam-pro placeholder-color-light-white outline-none"
                          style={{
                            background: "#9c9b9b",
                            textTransform: "uppercase",
                          }}
                        />
                      </div>
                      <div className="invalid-feedback">
                        {errors.cancelPlan?.message}
                      </div>

                      <div className="text-center">
                        <ButtonGradient
                          title="Proceed with Cancellation"
                          btnstyle={{ background: "white" }}
                          stylebyclass="text-size-20 p-8 text-black"
                          btnstylebyclass="m-left-right-auto mb-20"
                          disabled={formData.cancelPlan !== "CONFIRM"}
                          type="submit"
                        />
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* Reason Modal */}
      <div
        className={`modal ${isReasonModalOpen ? "show" : ""}`}
        style={{
          display: isReasonModalOpen ? "block" : "none",
          background: "#0000006e",
          marginLeft: "12%",
        }}
      >
        <div
          className="modal-dialog modal-600 modal-dialog-centered top-20-percent text-open-sans"
          role="document"
        >
          <div className="modal-content modal-gradient-new-2">
            <div className="p-15 mr-40">
              <button
                type="button"
                onClick={() => setIsReasonModalOpen((prev) => !prev)}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="mt-10">
                  {!loading && !successMessage && (
                    <>
                      <div className="text-white">
                        <h1 className="text-weight-600 text-size-32">
                          We're sad to see you go!
                        </h1>
                        <p className="text-size-28">
                          Please let us know why you're leaving.
                        </p>
                      </div>
                      <div
                        className={`form-group width-80-percent m-left-right-auto`}
                      >
                        <input
                          name="text"
                          type="text"
                          className="text-size-22 font-family-vietnam-pro placeholder-color-light-white outline-none"
                          style={{
                            background: "#5036a794",
                            textTransform: "uppercase",
                            height: "100px",
                            border: "1px solid gray",
                            boxShadow: "unset",
                          }}
                        />
                      </div>

                      <div className="text-center">
                        <button
                          className="text-white bg-none border-none outline-none text-size-28"
                          onClick={() => {
                            confirmUserAction("");
                          }}
                        >
                          Next
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionDetails;

import React from 'react'
import Rolling_circle_loader from "../../images/icons/Rolling_circle_loader.svg"

const SpinnerLoaderVariations = (props) => {
  return (
    <div className={`text-center loader-center ${props.clsname}`}>

      <img
        width={props.width} className=""
        src={Rolling_circle_loader}
        alt={props.message ? props.title : "Loading..."}
        title={props.message ? props.title : "Loading..."}
      />
    </div>
  )
}

export default SpinnerLoaderVariations

import React, { useEffect, useState } from "react";
// import "react-responsive-carousel/lib/styles/carousel.min.css";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import {
  Autoplay
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import { fetchAdCarousel } from "../../../utils/Firebase.collection.helper";

const NewSubscriberCarousal = () => {
  const [imageSlider, setImageSlider] = useState([]);
  const [mergeImages, setMergeImages] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const slidesPerViewValue = () => {
    return isMobile < 840 && isMobile > 690
      ? 2.5
      : isMobile < 690 && isMobile > 1
        ? 1.2
        : 4;
  };

  useEffect(() => {
    // Merge the titles with their corresponding image URLs
   // const mergedData = imageSlider.map((imageUrl, index) => ({
    //  image: imageUrl,
    //  title: NewSubscriberCarousalItems[index].title,
    //}));
   // setMergeImages(mergedData);
  }, [imageSlider]);

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
         const imageSlider=await fetchAdCarousel(setImageSlider);
         if(isMounted){
          setImageSlider(imageSlider)
      }
      } catch (error) {
        console.error('Error fetching ad categories:', error);
        // Handle error
      }
    };
  
    fetchData();
    return () => {
      isMounted = false;
    };

  }, []);

  return (
    <>
      <Swiper
        centeredSlides={true}
        loop={true}
        grabCursor={false}
        spaceBetween={isMobile < 840 ? 0 : 40}
        slidesPerView={slidesPerViewValue()}
        speed={5000}
        autoplay={{
          delay: 0,
          disableOnInteraction: false,
        }}
        onAutoplayPause={(e) => {
          e?.autoplay?.resume();
        }}
        onAutoplayStop={(e) => {
        }}
        allowTouchMove={true}
        // slidesPerGroupAuto={"auto"}
        // virtual={{
        //   enabled: false
        // }}
        // hashNavigation={true}
        // parallax={true}
        modules={[Autoplay]}
      >
        {imageSlider.map((item, index) => (
          <SwiperSlide key={index}>
            <div>
              <h2 className="text-size-32 font-italic text-white" style={{ height: "110px", display: "flex", flexDirection: " column", justifyContent: "center", marginRight: "60px" }}>
                {item.data.text ? item.data.text : "UNLIMITED Ads!"}
              </h2>
              <div className="icon-container">
                <img
                  className=""
                  src={item.data.image}
                  loading="eager"
                  alt={item.data.text}
                />
                {/* <div className="swiper-lazy-preloader"></div> */}
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default NewSubscriberCarousal;

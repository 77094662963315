import React from "react";
import { useNavigate } from "react-router-dom";
//import new_add_circle_plus from "../../../images/icons/new_add_circle_plus.svg";
// import cross from "../../../images/icons/cross.png";
import new_add_circle_plus from "../../../images/icons/add-new.svg";
import { userService } from "../../../services/user.service";

const AdButtonModal = (props) => {
  const navigate = useNavigate();

  const redirectTo = () => {
    userService.clearDataAfterReferesh()
    const homePage = userService.getUserHomeRedirectPage();
    window.location.href = homePage;
  };

  return (
    <>
      {/* <div className="pull-right margin-tb15-lr-10" title="New Ad">
        <button
          type="button"
          id="newadbtn"
          className="btn btn-primary generateAd w-156 h-56"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <img src={new_add_circle_plus} alt="" className="" />{" "}
          <span className="home-modal-btn-text">New Ad</span>
        </button>
      </div> */}


      <div className="pull-right margin-tb15-lr-10" title="Home">
        <button
          type="button"
          id="newadbtn"
          className="btn btn-primary generateAd autowidth w-56 h-56 homeround"
          data-toggle="modal"
          data-target="#exampleModal"
        >
          <img src={new_add_circle_plus} alt="new_add_ads" />
        </button>
      </div>

      <div
        className="modal fade"
        id="exampleModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center modal-gradient-new">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  X{/* <img src={cross} alt={"close"} /> */}
                </span>
              </button>
            </div>
            <div className="modal-body p50">
              <div className=" text-center m-top">
                <h3 className="mar-30">
                  Are you sure you want to start a new ad?{" "}
                </h3>
                <button
                  type="button"
                  className="btn btn-btn btn-primary generateAd w-160  outer-line margin-left15"
                  data-dismiss="modal"
                >
                  {" "}
                  No{" "}
                </button>
                <button
                  type="button"
                  className="btn btn-primary generateAd w-160 "
                  id="adbtnModal"
                  onClick={redirectTo}
                >
                  {" "}
                  Yes{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AdButtonModal;

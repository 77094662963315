import React from "react";
import { useNavigate } from "react-router-dom";
import SignInScreen from "../../SignInScreen";
const LoginWaterMarkedModal = (props) => {
  const navigate = useNavigate();
  const redirectTo = () => {
    navigate("/subscribe");
  };
  return (
    <>
      <div
        className="modal fade"
        id="loginModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="loginModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center modal-gradient">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body p50">
              <div className=" text-center m-top">
                <p className="mar-30 font20">
                  Login to download your image ads unwatermarked!
                </p>
                <SignInScreen></SignInScreen>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default LoginWaterMarkedModal;

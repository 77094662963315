import ReactPixel from "react-facebook-pixel";
import constants from "../utils/constants";

const sendFbEvents = (eventName, content_name, value) => {
    if (eventName === constants.FREE_TRIAL || eventName === constants.QUICK_FREE_TRIAL || eventName === constants.SUCCESS_PAYMENT) {
        ReactPixel.trackCustom(eventName, {
            content_type: 'Subscription', content_name: content_name, value: value,
            currency: 'USD'
        });
    } else if (eventName === constants.CREATE_ACCOUNT) {
        ReactPixel.trackCustom(eventName, { content_type: 'Signup', content_name: 'Signup' });
    }
}

export const fbEventsService = {
    sendFbEvents
};
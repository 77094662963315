import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import lite_plan from "../../../../images/payment_success_assests/Lite.svg";
import pro_plan from "../../../../images/payment_success_assests/Pro.svg";
import {
  default as free_plan,
  default as ultra_plan,
} from "../../../../images/payment_success_assests/Ultra.svg";
import { fbEventsService } from "../../../../services/fbevents.service";

import {
  userService
} from "../../../../services/user.service";
import { db } from "../../../../utils/Firebase";
import { SUCCESS_PAYMENT } from "../../../../utils/constants";
import {
  formatDate,
  getPlan,
  getWebsiteTitle, getMessage,
  formatDateMMDDYY, checkMonthlyYearly, removeTextTrialMonthly
} from "../../../../utils/function";
import "../PaypalSubscription/PaymentSuccess.css";
import welcome_assests from "../../../../images/welcome_assests.svg";
import StyledButtonWhiteGradientWithIcon from "../../Button/StyledButtonWhiteGradientWithIcon";
import useWindowSize from "../../../../_helper/ScreenUtil";
import new_add_circle_plus from "../../../../images/icons/add-new.svg";
import SpinnerLoader from "../../SpinnerLoader";

const PaymentStripeSuccess = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const [planName, setPlanName] = useState("");
  const [moyr, setMOYr] = useState("");
  const [userAmount, setUserAmount] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [subscription_id, setSubscriptionId] = useState("");
  const [adsPerPlan, setAdsPerPlan] = useState("50 ads/mo");
  const [userPlanName, setUserPlanName] = useState("");
  const [isTrialPeriod, setIsTrialPeriod] = useState(true);
  const [loading, setLoader] = useState(true);

  useEffect(async () => {
    const userId = userService.getUserId();
    // UPDATING USER INFORMATION AFTER MAKING PAYMENT.
    if (userId) {
      db.collection("purchases")
        .where("userId", "==", userId)
        .where("type", "==", "SUBSCRIPTION")
        .orderBy("purchaseDate", "desc")
        .limit(1)
        .onSnapshot((snapshot) => {
          const datas = snapshot.docs.map((doc) => ({
            id: doc.id,
            plan: getPlan(doc.data().priceId),
            product_id: doc.data().productId,
            amount: doc.data().amount,
            order_id: doc.data().orderId,
            purchase_date: formatDate(doc.data().purchaseDate),
            status: doc.data().status,
            expiry_date: doc.data().expiryDate,
            isTrialPeriod: doc.data().isTrialPeriod,
          }));

          if (datas.length > 0) { // Check if data exists
            // will add includes here
            let planName = "";
            setSubscriptionId(datas[0].order_id);
            setIsTrialPeriod(datas[0].isTrialPeriod);
            setUserPlanName(removeTextTrialMonthly(datas[0].plan));
            setMOYr(checkMonthlyYearly(datas[0].plan));
            setUserAmount(datas[0].amount);
            setExpiryDate(formatDateMMDDYY(datas[0].expiry_date));
            setLoader(false);

            if (datas[0].product_id.includes("lite")) {
              setPlanName(lite_plan);
              planName = "Lite";
            } else if (datas[0].product_id.includes("pro")) {
              setPlanName(pro_plan);
              planName = "Pro";
            } else if (
              datas[0].product_id.includes("ultra") ||
              datas[0].product_id.includes("premiumads")
            ) {
              setPlanName(ultra_plan);
              planName = "Ultra";
            } else if (datas[0].product_id.includes("trial")) {
              setPlanName(free_plan);
              planName = "Free";
            }
            setPlanName(free_plan);
            planName = "Free";
            fbEventsService.sendFbEvents(
              SUCCESS_PAYMENT,
              planName,
              datas[0].amount
            );
          }
        });
    }
    /* setTimeout(() => {
       window.location.href = "/home";
     }, 4000);*/

  }, []);

  const redirectTo = () => {
    const homePage = userService.getNewAdPath();
    navigate("/home");
  };

  document.title = getWebsiteTitle() + " Payment Success";
  if (isTrialPeriod) {
    return (
      <div className="row payment-height  flex align-items-center ">
        
        <div className="free-trail-frame-container  ">

          {loading && (
            <div className={`col-xs-12 col-md-12 col-lg-12 welcome-trail-responsive`}>
              <div className="mt-40 text-center">
                <b className="try-out-the-container">
                  <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro" style={{ fontFamily: "Cinzel" }}> We are processing Payment information. </h3>
                </b>
                <b className="try-out-the-container">
                  <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro">Please wait...<SpinnerLoader message="Please wait processing..."></SpinnerLoader></h3>
                </b>
              </div>
            </div>
          )}

          {!loading && (<div className={`col-xs-12 col-md-12 col-lg-12   mt-70    welcome-trail-responsive `}>
            <div className=" text-center  flex align-items-center justifyCenter ">
              <div className="col-xs-11">

              <b className="try-out-the-container">
                <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro"> Welcome to <span className="payment-success-adspire-text font-60 text-size-44">ADSPIRE® !</span> </h3>
              </b>
              <p className="text-white text-center text-size-32 font-family-vietnam-pro m-0">Generate <span className="text-weight-600">unlimited ads</span> &</p>
              <p className="text-white text-center text-size-32 font-family-vietnam-pro m-0">download 5 during your trial.</p>

              {userAmount && (
                <>
                  <p className="text-white text-center text-size-24 font-family-vietnam-pro mt-30">Your <span className="text-weight-600">{userPlanName} ({adsPerPlan})</span> will</p>
                  <p className="text-white text-center text-size-24 font-family-vietnam-pro m-0">start for  <span className="text-weight-600">${userAmount}/{moyr}</span> on {expiryDate}.</p>
                </>
              )}

              <div className="row col-lg-offset-0 mt-40 ">
                <div className="col-sm-12">
                  <StyledButtonWhiteGradientWithIcon
                    btnText="GENERATE ADS"
                    btnIconStylebyclass="hideIt"
                    btnStylebyclass={isMobile ? "width-100-percent mb-20 p-0" : "width-100-percent mb-20 p-0"}
                    btnTextStylebyclass="text-size-30 text-white text-weight-600 font-family-vietnam-pro mr-10"
                    btnStyle={{ boxShadow: "unset", height: "70px" }}
                    btnDivStyle={{ boxShadow: "rgba(255, 255, 255, 0.72) 0px 15px 20px -10px inset", width: "100%", borderRadius: "40px", margin: "0px 5px", height: "60px", display: "flex", justifyContent: "center", alignItems: "center" }}
                    onClick={() => window.location.href = "home"}
                  />
                </div>
              </div>
              </div>
            </div>
            <img alt="" src={welcome_assests} width={400} className="" />
          </div>)}
        </div>
      </div>
    );
  } else {
    return (
      <div  className="row payment-height  align-items-center">
        <div  className="payment-success  payment-height  ">
          <div  className="free-trail-frame-container payment-height  ">
            <div  className="col-sm-12 payment-height  col-xs-12 ">

              {loading && (
                <div  className={`col-12 payment-height  flex align-items-center col-lg-11 col-md-12 col-lg-12 col-lg-offset-1 welcome-trail-responsive`}>
                  <div className="mt-40 text-center">
                    <b className="try-out-the-container">
                      <h3 className="payment-success-welcome-to text-white text-center mar-20 font-60 mb-20 font-family-vietnam-pro"> We are processing Payment information. Please wait... </h3>


                    </b>
                    <SpinnerLoader message="Please wait processing..."></SpinnerLoader></div></div>
              )}

              {!loading && (

                <div style={{
                  marginTop:'130px'
                }} className="  text-center  ">
                  <b className="try-out-the-container ">
                    <span className="payment-success-welcome-to white-text mar-20 font-60">
                      {" "}
                      Welcome to{" "}
                    </span>{" "}
                    <span className="payment-success-adspire-text font-60">
                      Adspire ®{" "}
                      {userService.getValueFromLocalstorage("PaymentInfo").plan}!{" "}
                    </span>
                    <p className="pclass text-center font-21-regular">
                      {" "}
                      You can now start creating your{" "}
                      <span className="text-weight-600">
                        <i>stunning, powerful ads!</i>
                      </span>
                    </p>
                    {/* <div className="alert alert-warning text-center">
              Your Payment Proccess is under process, It may take 10-15 minutes to display in your dashboard. <a href="/payment-history">Click here</a> to check payment information.</div>
*/}
                  </b>
                  <div className="margin-tb15-lr-10  " title="New Ad">
                    <button
                      onClick={() => redirectTo()}
                      type="button"
                      className="btn btn-primary generateAd w-200 h-65"
                    >
                      <img src={new_add_circle_plus} alt="" className="" />{" "}
                      <span className="home-modal-btn-text">New Ad</span>
                    </button>
                  </div>
                  <div className="text-center col-sm-12 mt-50 ">
                    <img alt="" src={planName} />
                  </div>
                </div>
                )}


            </div>
          </div>
        </div>
      </div>

    );
  }
};

export default PaymentStripeSuccess;

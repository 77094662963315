import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import { fetchProductPresetsSiximage } from "../../../utils/Firebase.collection.helper";
// ... (import other images)

const ImageSlider1 = () => {
  const [imageSlider, setImageSlider] = useState([]);
  const [carouselClass, setCarouselClass] = useState("carousel-div");
  const location = useLocation();
  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        
          const imageSlider=await fetchProductPresetsSiximage();
          if(isMounted){
          setImageSlider(imageSlider)
        }
      
      } catch (error) {
        console.error('Error fetching product presets:', error);
        // Handle error
      }
    };

    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (location.pathname === "/ai-ads") {
      setCarouselClass("carousel-div-50")
    }
  }, [location.pathname]);

  // Create a ref to store the button element
  const nextButtonRef = useRef(null);

  // useEffect hook to trigger the button click after component has loaded
  useEffect(() => {
    // Check if the button reference exists
    if (nextButtonRef.current) {
      // Trigger the click event
      nextButtonRef.current.click();
    }
  }, [imageSlider]);

  return (
    <>
      {imageSlider && (
        <Carousel autoPlay={true} transitionTime={500} infiniteLoop={true} showArrows={true} showStatus={false} interval={2000}>
          {imageSlider.map((url, index) => (
            <div className={carouselClass} key={index}>
              <img key={index} src={url} alt={`${index}`} width={80} ref={nextButtonRef} />
            </div>
          ))}
        </Carousel>
      )}
    </>
  );
};

export default ImageSlider1;
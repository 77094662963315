import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import setting_modal from "../images/modal_assests/setting_modal.svg";
import quick_yellow from "../images/modal_assests/Frame_24.svg";
import { userService } from "../services/user.service";
import { getWebsiteTitle } from "../utils/function";

const SelectAdsType = () => {
  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      // navigate("/login");
    }
  }, [!userService.isLoggedInUser()]);

  useEffect(() => {
    if (!userService.isPurchaser()) {
      window.open("/pricing", "_self");
    }
  }, []);

  const redirectTo = (pagename) => {
    if (!userService.isUserFreeAdsStatus()) {
      pagename = userService.getSubscribePageName();
      window.open("/" + pagename, "_blank");
    } else {
      userService.setKeyValueLocalstorage("userSelectedAdTypePage", pagename);
      navigate("/" + pagename);
    }
  };

  document.title = getWebsiteTitle() + "Select Ads Types";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className="clearfix"></div>
                <div className="col-sm-6 col-sm-offset-3">
                  <div className="form-group text-center"></div>
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>

      <div
        className={`modal show overflows-auto`}
        style={{ marginLeft: "15%" }}
      >
        <div
          className="modal-dialog modal-500 modal-350-mobile modal-dialog-centered text-open-sans new-select-ads-type top-20-percent"
          role="document"
        >
          <div className="modal-content modal-gradient-new">
            <div className="modal-header mr-20">
              <button
                type="button"
                onClick={() => redirectTo("quick-ad")}
                className="close"
              >
                <span aria-hidden="true">x</span>
              </button>
            </div>
            <div className="modal-body text-center">
              <div className="mt-4">
                <div className="text-white text-size-32 text-weight-600 pb-3 font-family-vietnam-pro">
                  Choose an ad:
                </div>
                <div className="row popup-product">
                  <div className="col-sm-1"></div>
                  <div className="col-sm-5 cursor-pointer">
                    <div onClick={() => redirectTo("concept-ad")}>
                      <img src={setting_modal} alt="product type" width={130} />
                      <p className="p-title font-18-regular">Custom Ad</p>
                    </div>
                  </div>
                  <div
                    className="col-sm-5 cursor-pointer"
                    onClick={() => redirectTo("quick-ad")}
                  >
                    <div>
                      <img src={quick_yellow} alt="product type" width={130} />
                      <p className="p-title font-18-regular">Quick Ad</p>
                      {/* <p className="sub-title">Ad ideas + concept ads</p> */}
                    </div>
                  </div>
                  <div className="col-sm-1"></div>
                </div>
              </div>
              {/*<div className="flex content-center align-items-center mt-10">
                <div
                  className="upload-btn-control cursor-pointer"
                  onClick={() => redirectTo("store")}
                >
                  <img
                    alt="img-uploader"
                    src={sample_img_uploader}
                    width={30}
                  />
                  <label
                    htmlFor="img-uploader"
                    className="modal-img-uploader mt-5 ml-5 cursor-pointer"
                  >
                    Upload product
                    <input
                      type="file"
                      accept="image/*"
                      id="img-uploader"
                      className="hidden"
                    />
                  </label>

                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div id="overlay" className="overlay" style={{ marginLeft: "13%" }}></div>
    </React.Fragment>
  );
};
export default SelectAdsType;

import { isIOSDevice } from "ios-detector";
import React, { useEffect } from "react";
import { getWebsiteTitle } from "../../utils/function";
import { metaDesc } from "../../utils/landingConstants";
import "./css/Landing-page.css";

import IOSMessageModal from "../common/popup/IOSMessageModal";
import FooterLanding from "./common/FooterLanding";
import SubHeaderSmall from "./common/SubHeaderSmall";
import AdFlowFreeTrial from "./adFlow/AdFlowFreeTrial";

const LandingFreeTrial = () => {
  useEffect(() => {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", metaDesc.AIADS);
  }, []);

  document.title = getWebsiteTitle() + "AI Ads Generator";
  return (
    <>
     
      {/* <span
        id="pIOSMessageModal"
        data-toggle="modal"
        data-target="#iosMessageModalId"
      ></span> */}
      <div className="confirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}
        <div className="hero-sections-parent">
          <div className="hero-sections">
            <SubHeaderSmall></SubHeaderSmall>
            <AdFlowFreeTrial></AdFlowFreeTrial>
          </div>
          <FooterLanding></FooterLanding>
        </div>
      </div>
    </>
  );
};


export default LandingFreeTrial;



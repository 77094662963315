import CookieConsent, { Cookies } from "react-cookie-consent";
import useWindowSize from "../_helper/ScreenUtil";

export default function Cookie() {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const isLaptop = width >= 1024

  return (
    <>
      <CookieConsent
        location="bottom"
        buttonText="Accept"
        declineButtonText="Decline"
        cookieName="accepted"
        style={{
          padding: '10px',
          display: "flex",
          background: 'linear-gradient(125deg, rgba(255, 255, 255, 0.30) -1.58%, rgba(255, 255, 255, 0.00) 105.76%)',
          boxShadow: '4px -4px 16px 0px rgba(255, 255, 255, 0.08) inset, -4px 4px 16px 0px rgba(255, 255, 255, 0.08) inset',
          backdropFilter: 'blur(10px)',
          left: '10px',
          position: 'fixed',
          width: isLaptop ? '20%' : isMobile ? '50%' : '40%',
          minWidth: '250px',
          zIndex: '999',
          fontSize: isMobile ? "16px" : "18px",
          textAlign: 'left',
          bottom: '10px',
          borderRadius: '40px',
          border: '1px solid rgba(255, 255, 255, 0.30)'
        }}
        buttonStyle={{
          background: "linear-gradient(-70.76deg, #ffffff, #ffffff)",
          color: "black",
          fontSize: isMobile ? "14px" : "18px",
          fontFamily: "Be Vietnam Pro",
          marginRight: "70px",
          borderRadius: "20px",
          margin: "unset"
        }}

        buttonWrapperClasses="flex"

        declineButtonStyle={{
          background: "none",
          color: "#bab4b4",
          fontSize: isMobile ? "14px" : "18px",
          fontFamily: "Be Vietnam Pro",
          margin: "unset"
        }}
        expires={150}
        enableDeclineButton
      >
        <span className={`font-family-vietnam-pro ${isMobile ? "text-size-16" : "text-size-28"} text-weight-600`}>We Use Cookies</span><br />
        <span className={`font-family-vietnam-pro ${isMobile ? "text-size-14" : ""}`}>To enhance your experience.</span>
      </CookieConsent>
    </>
  );
}

import React from "react";
import cross from "../../../images/icons/cross.png";
const GeneratedImageAdModal = (props) => {
  return (
    <>
      <div
        className={`modal fade ${props.displayBlock}`}
        id="imageModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="contained-modal-title-vcenter"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">
                  {" "}
                  <img src={cross} alt="" className="" />{" "}
                </span>
              </button>
            </div>
            <div className="modal-body text-center user-imgDiv">
              <img
                data-toggle="modal"
                data-target="#myModal"
                src="https://w7.pngwing.com/pngs/193/660/png-transparent-computer-icons-woman-avatar-avatar-girl-thumbnail.png"
              />
            </div>
            <div className="modal-footer text-center">
              <div className="row">
                <div className="col-sm-4 col-xs-4 text-center">
                  <button
                    type="button"
                    className="btn btn-secondary circlediv"
                    data-dismiss="modal"
                  >
                    <i className="fa fa-download"></i>
                  </button>
                  <p className="greytext">Download</p>
                </div>
                <div className="col-sm-4 col-xs-4 text-center">
                  <button type="button" className="btn btn-secondary circlediv">
                    <i className="fa fa-share"></i>
                  </button>
                  <p className="greytext">Share</p>
                </div>
                <div className="col-sm-4 col-xs-4 text-center" title="New Ad">
                  <button
                    type="button"
                    className="btn btn-secondary dashCircle"
                  >
                    <i className="fa fa-plus"></i>
                  </button>
                  <p className="greytext">New Ad</p>
                </div>
              </div>

              {/* <AdButton></AdButton> */}
              <div className=" m-top">
                <div className="col-sm-4 col-xs-4 text-center">
                  {/* <GenerateAdIdeasButton></GenerateAdIdeasButton> */}
                </div>
                <div className="col-sm-4 col-xs-4 text-center">
                  <button
                    title="Generate Captions"
                    type="button"
                    className="btn btn-primary white-border btn-block"
                  >
                    Generate Captions
                  </button>
                  {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                </div>
                <div className="col-sm-4 col-xs-4 text-center">
                  <a href="" className="white-textdiv">
                    See Ad Ideas
                  </a>
                  {/* <SeeAdIdeasButton></SeeAdIdeasButton> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default GeneratedImageAdModal;

import React from "react";
import { useNavigate } from "react-router-dom";
import TextDescription from "./TextDescription";



const TextFlowDescriptionModal = (props) => {

  const closeDescriptionModel=()=>{
    const myBtn = document.getElementById("btn-close-id");
    myBtn.click();
  }

  const getFormValueFromAI=(props)=>{

    props.getFormValueFromAI("dummy data");
  }

  
 

  return (
    <>
      <div
        className="modal fade"
        id="textFlowDescriptionModalId"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="TextFlowDescriptionModalLabel"
        aria-hidden="true"
        data-backdrop="false"
        style={{ backgroundColor: "rgba(0, 0, 0, 0.5)" }}
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content text-center modal-gradient">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                id="btn-close-id"
              >
                X
              </button>
            </div>
            <div className="modal-body">

              <div className="" >
                <TextDescription closeDescriptionModel={closeDescriptionModel} getFormValueFromAI={props.getFormValueFromAI}></TextDescription>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default TextFlowDescriptionModal;

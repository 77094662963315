import React from 'react';
import ad_goal_gradient from '../../images/icons/ad_goal_gradient-text.svg';
import business_type from '../../images/icons/business-type.svg';
import business_type_gradient from '../../images/icons/business-type-gradi.svg';
import description from '../../images/icons/description.svg';
import description_gradient from '../../images/icons/description-gradient.svg';
import add_type from '../../images/icons/add_type.svg';
import add_type_gradient from '../../images/icons/add_type_gradient.svg';
import add_idea from '../../images/icons/add_idea.svg';
import add_idea_gradient from '../../images/icons/add_idea_gradient.svg';
import image_style from '../../images/icons/image_style.svg';
import image_style_gradient from '../../images/icons/image_style_gradient.svg';
import image_ads from '../../images/icons/image_ads.svg';
import image_ads_gradient from '../../images/icons/image_ads_gradient.svg';
import caption from '../../images/icons/caption.svg';
import { userService } from "../../services/user.service"
import caption_gradient from '../../images/icons/caption_gradient.svg';
import { useNavigate } from "react-router-dom";

const StepText = (props) => {
  const navigate = useNavigate();
  const redirectTo = (pagename) => {
    let isRedirect=false;
    if(pagename==='ad-goal'){
      isRedirect=true;
    }else if(pagename==='business-type' && userService.getValueFromLocalstorage("categoryTypeValue")){
      isRedirect=true;
    } else if(pagename==='description'  && userService.getValueFromLocalstorage("input")){
      isRedirect=true;
    }else  if(pagename==='ad-type' && userService.getValueFromLocalstorage("typeValue")){
      isRedirect=true;
    }else if(pagename==='ad-ideas' && userService.getValueFromLocalstorage("ideas")){
      userService.setKeyValueLocalstorage("showLocalAdIdeas","yes")
      isRedirect=true;
    }else if(pagename==='image-style' && userService.getValueFromLocalstorage("style")){
      isRedirect=true;
    }else if(pagename==='image-ads' && userService.getValueFromLocalstorage("ideas").substring(0,20)){
      isRedirect=true;
    }else if(pagename==='captions' && userService.getValueFromLocalstorage("savingTime")){
      isRedirect=true;
    }
    if(isRedirect){
      navigate("/"+pagename);
    }
  };

  return (
    <>
      <ul className='process-steps mt-110 mb-80'>

        <>
          <li className='pointerclass' onClick={() => redirectTo("ad-goal")}>
            <div className='text-center' title="Ad Goal">
              <img src={ad_goal_gradient} alt="" className='' />
              <h6 className='step-subtext-active'>{userService.getValueFromLocalstorage("categoryName")}</h6>
            </div>
          </li>
          <hr className={
            props.step===2
              ||
              props.step===3
              ||
              props.step===4
              ||
              props.step===5
              ||
              props.step===6
              ||
              props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("business-type")}>
            <div className='text-center' title="Business Type">
              <img src={
                props.step===2
                  ||
                  props.step===3
                  ||
                  props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  business_type_gradient : business_type}
                alt="" className='' />
              <h6 className={
                props.step===2
                  ||
                  props.step===3
                  ||
                  props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("categoryTypeValue")}</h6>
            </div>
          </li>
          <hr className={
            props.step===3
              ||
              props.step===4
              ||
              props.step===5
              ||
              props.step===6
              ||
              props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("description")}>
            <div className='text-center' title="Description">
              <img src={
                props.step===3
                  ||
                  props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  description_gradient : description} alt="" className='' />
              <h6 className={
                props.step===3
                  ||
                  props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("input")}</h6>
            </div>
          </li>
          <hr className={
            props.step===4
              ||
              props.step===5
              ||
              props.step===6
              ||
              props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("ad-type")}>
            <div className='text-center' title="Ad Type">
              <img src={
                props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  add_type_gradient : add_type} alt="" className='' />
              <h6 className={
                props.step===4
                  ||
                  props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("typeValue")}</h6>
            </div>
          </li>
          <hr className={
            props.step===5
              ||
              props.step===6
              ||
              props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("ad-ideas")}>
            <div className='text-center' title="Ad Ideas">
              <img src={
                props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  add_idea_gradient : add_idea} alt="" style={{ width: "53px " }} className='' />
              <h6 className={
                props.step===5
                  ||
                  props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("ideas")}</h6>
            </div>
          </li>
          <hr className={
            props.step===6
              ||
              props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("image-style")}>
            <div className='text-center' title="Image Style">
              <img src={
                props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  image_style_gradient : image_style} alt="" className='' />
              <h6 className={
                props.step===6
                  ||
                  props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("style")}</h6>
            </div>
          </li>
          <hr className={
            props.step===7
              ||
              props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("image-ads")}>
            <div className='text-center'  title="Image Ads">
              <img src={
                props.step===7
                  ||
                  props.step===8
                  ?
                  image_ads_gradient : image_ads} style={{ width: "62px " }} alt="" className='' />
              <h6 className={
                props.step===7
                  ||
                  props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("ideas").substring(0,20)}</h6>
            </div>
          </li>
          <hr className={
            props.step===8
              ?
              "divider-gradient" : "divider"} />
          <li className='pointerclass' onClick={() => redirectTo("captions")}>
            <div className='text-center' title="Captions">
              <img src={
                props.step===8
                  ?
                  caption_gradient : caption} alt="" className='' />
              <h6 className={
                props.step===8
                  ?
                  'step-subtext-active' : "step-subtext"}>{userService.getValueFromLocalstorage("savingTime")}</h6>
            </div>
          </li>
        </>
      </ul>
    </>
  );
}
export default StepText;

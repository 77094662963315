import React from "react";
import { useNavigate } from "react-router-dom";
import new_add_circle_plus from "../../../images/icons/add-new.svg";
import { userService } from "../../../services/user.service";

const RetryErrorModal = (props) => {
const {isRetryModalOpen,isMobile}=props;
  const redirectTo = (action) => {
    if(action==="retry"){
      props.handleRetryModelAction(false);
      props.GenerateQuestion();
    }else{
      props.handleRetryModelAction(false);
    }
  };
  return (
    <>
  

         <div
          className={`modal overflows-auto bg-dark-opacity-10 ${isRetryModalOpen ? "show" : ""
            }`}
          style={{
            display: isRetryModalOpen ? "block" : "none",
            marginLeft: isMobile ? "" : "12%",
          }}
        >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center modal-gradient-new">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() => redirectTo("cancel")}
              >
                <span aria-hidden="true">
                  X
                </span>
              </button>
            </div>
            <div className="modal-body p50">
              <div className=" text-center m-top">
                <h3 className="mar-30">
                There was an error generating your ads. Please retry.
                </h3>
                <button
                  type="button"
                  className="btn btn-btn btn-primary generateAd w-160  outer-line margin-left15"
                  data-dismiss="modal"
                  onClick={() => redirectTo("cancel")}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  className="btn btn-primary generateAd w-160 "
                  id="adbtnModal"
                  onClick={() => redirectTo("retry")}
                >
                 Retry
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default RetryErrorModal;

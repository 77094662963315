import React from "react";
import { useNavigate } from "react-router-dom";
import change_type_emoji from "../../../images/icons/social_icons/change_type_emoji.svg";
import new_add_plus_white_gradient from "../../../images/icons/social_icons/new_add_plus_white_gradient.svg";
import { userService } from "../../../services/user.service";
import StyledButtonWhiteGradientWithIcon from "./StyledButtonWhiteGradientWithIcon";
import useWindowSize from "../../../_helper/ScreenUtil";

const AdFlowListingButton = (props) => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const navigate = useNavigate();
  const generateMore = () => {
    if (
      userService.getValueFromLocalstorage("userSelectedAdTypePage") ===
      "concept-ad"
    ) {
      props.hideTheImage();
      props.handleUpload();
    } else {
      props.handleUpload("generateMore");
    }
  };

  const newActionCase = (actions) => {
    window.location.href = "/" + actions;
  };

  return (
    <div className="col-xs-12">
      <div className=" pb-40 justifyCenter" >
        <StyledButtonWhiteGradientWithIcon
          btnText="Generate more"
          btnIconStylebyclass="hideIt"
          btnStylebyclass={isMobile ? "  p-0 mb-30" : "width-100-percent mb-20 p-0"}
          btnTextStylebyclass="text-size-30 text-white text-weight-600 font-family-vietnam-pro mr-10"
          btnStyle={{ boxShadow: "unset", height: "60px", width:'340px'}}
          btnDivStyle={{ boxShadow: "rgba(255, 255, 255, 0.72) 0px 15px 20px -10px inset", width: "100%", borderRadius: "40px", margin: "0px 5px", height: "52px", display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => generateMore()}
        />
      </div>
      <div className=" text-center flex content-center gap-15" >
        {props.pagename != "store" && (<img src={change_type_emoji} alt="change_type_emoji" className="cursor-pointer" title="Change Type" onClick={() => newActionCase("product-ad-type")} />)}
        <img src={new_add_plus_white_gradient} alt="new_add_plus_white_gradient" className="cursor-pointer" title="New Ad" onClick={() => newActionCase("home")} />
      </div>
    </div>
  );
};
export default AdFlowListingButton;

import { BehaviorSubject } from "rxjs";
import { db } from "../utils/Firebase";

import API_FIREBASE from "../config/apifirebase";
import constants from "../utils/constants"


import {
  formatDate,
  checkTrialPeriod,
  getPlanName,
  getRealPlanName,
  getAmount,
  getPlan,
  isTrialPeriodEnd,
  getPlanDuration,
  isPlanExpired,
  addOneMonthToCurrentDate,
  replaceString,
  formatDateAMPM,
  getUserID,
  formatDateMMDDYY,
} from "../utils/function";
import { userService } from "./user.service";
import { updateUsersCollection } from "../utils/Firebase.collection.helper";
const userSubject = new BehaviorSubject();
// JSON.parse(secureLocalStorage.getItem("user"))
export const paymentService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  getUserAccessInfo,
  getSubscriptionInfoRegistration,
};


async function getSubscriptionInfoRegistration(userDataReceived = "") {
  return new Promise(async (resolve, reject) => {
    const userId = userService.getUserId();

    if (userId) {
      const unsubscribe = db
        .collection("purchases")
        .where("userId", "==", userId)
        .where("type", "==", "SUBSCRIPTION")
        .orderBy("status", "asc")
        .limit(1)
        .onSnapshot((snapshot) => {
          const planDataReceived = snapshot.docs.map((doc) => ({
            id: doc.id,
            product_id: doc.data().productId,
            amount: doc.data().amount,
            plan: getPlan(doc.data().priceId),
            purchase_date: doc.data().purchaseDate,
            expiry_date: doc.data().expiryDate,
            isTrialPeriod: checkTrialPeriod(doc.data()),
            subscriptionId: doc.data().subscriptionId,
            canceledDate: doc.data().canceledDate,
            status: doc.data().status,
            priceId: doc.data().priceId,
          }));

          if (planDataReceived && planDataReceived[0] && planDataReceived[0].product_id) {

            const trialPeriodEnd = isTrialPeriodEnd(
              planDataReceived[0].purchase_date,
              planDataReceived[0].expiry_date
            );

            const plan = getPlan(planDataReceived[0].priceId, trialPeriodEnd);
            userService.setKeyValueLocalstorage("plan", plan);
            userService.setKeyValueLocalstorage("planAmount", planDataReceived[0].amount);
            userService.setKeyValueLocalstorage("product_id", planDataReceived[0].product_id);
            const planInfo = "Your " + userService.getPlanInfoBasedOnPriceId(planDataReceived[0].priceId) +
              " refills on " + addOneMonthToCurrentDate(planDataReceived[0].purchase_date);
            const planSettingText = "Converts to " + plan + " (" + userService.getPlanInfoBasedOnPriceId(planDataReceived[0].priceId) + ") " +
              " for $" + planDataReceived[0].amount + "/" + userService.getMonthlyYearly(plan) + " on " + formatDateMMDDYY(planDataReceived[0].expiry_date);


            // Converts to Pro (50 ads/mo) for $49.99/mo on XX/XX/XXXX

            userService.setKeyValueLocalstorage("planSettingText", planSettingText);
            userService.setKeyValueLocalstorage("planInfoPerDuration", planInfo);

            userService.setKeyValueLocalstorage("isTrialPeriod", trialPeriodEnd === 'no' ? "yes" : "no");

            userService.setKeyValueLocalstorage("subscriptionId", planDataReceived[0].subscriptionId);

            if (userDataReceived) {

              let downloadCount = 0;
              if (planDataReceived[0].status === "ACTIVE" || planDataReceived[0].status === "canceled" || planDataReceived[0].status === "CANCELLED") {
                if (trialPeriodEnd === "yes") {
                  downloadCount = userDataReceived.downloadCount ? userDataReceived.downloadCount : 0;
                } else if (trialPeriodEnd === "no") {
                  downloadCount = userDataReceived.downloadTrialCount ? userDataReceived.downloadTrialCount : 0;
                }
              }
              // PAID USER DOWNLOAD COUNT LIMIT
              userService.setKeyValueLocalstorage("downloadCount", downloadCount);
            }

            if (planDataReceived[0].status === "ACTIVE" && userDataReceived.isConvertedToPaidUser) {
              updatePaidUserStatus(planDataReceived);
              updateUsersCollection({ isConvertedToPaidUser: false }); // update firebase
              resolve("welcome");
            } else if (planDataReceived[0].status === "ACTIVE") {
              updatePaidUserStatus(planDataReceived);
              resolve("home");
            } else if (planDataReceived[0].status === "canceled" || planDataReceived[0].status === "CANCELLED") {
              userService.setKeyValueLocalstorage("paymentStatus", "canceled");
              userService.setKeyValueLocalstorage(
                "CancelDate",
                formatDate(planDataReceived[0].canceledDate)
              );

              if (!isPlanExpired(planDataReceived[0].expiry_date)) {
                updatePaidUserSubStatus()
                resolve("home");
              } else {
                updateNotPaidUserStatus();
                resolve("pricing");
              }
            } else {
              updateNotPaidUserStatus();
              resolve("pricing");
            }
          } else {
            userService.setKeyValueLocalstorage("paymentStatus", "EXPIRED");
            updateNotPaidUserStatus();
            resolve("pricing");
          }
        }
        );
      // Returning unsubscribe function so that snapshot listener can be detached when required
      return unsubscribe;
    } else {
      resolve("pricing");
    }
  });
}

function getUserAccessInfo(pageName) {
  if (userService.getValueFromLocalstorage("product_id").includes("lite")) {
    switch (pageName) {
      case "adIdeas":
        return true;
      default:
        return false;
    }
  }
  if (userService.getValueFromLocalstorage("product_id").includes("pro")) {
    switch (pageName) {
      case "adIdeas":
        return true;
      case "adImages":
        return true;
      default:
        return false;
    }
  }
  if (
    userService.getValueFromLocalstorage("product_id").includes("ultra") ||
    userService.getValueFromLocalstorage("product_id").includes("trial")
  ) {
    return true;
  }
}


const updatePaidUserStatus = (planDataReceived) => {
  userService.setKeyValueLocalstorage("paymentStatus", "ACTIVE"); // later we would do true
  userService.setKeyValueLocalstorage("ExpiryDate", formatDate(planDataReceived[0].expiry_date));
  if (!isPlanExpired(planDataReceived[0].expiry_date)) {
    updatePaidUserSubStatus(); // later we would do true
  }
}

const updateNotPaidUserStatus = () => {
  userService.setKeyValueLocalstorage("purchaser", "no"); // later we would do true
  userService.setKeyValueLocalstorage("generatedAdsNumber", "no");
}

const updatePaidUserSubStatus = () => {
  userService.setKeyValueLocalstorage("purchaser", "yes"); // later we would do true
  userService.setKeyValueLocalstorage("generatedAdsNumber", "paiduser");
}
import React, { useEffect, useState } from "react";
import perspectivegridflat_1 from "../../../images/landing_page_assests/oct-flor-vector.svg";
import star_5 from "../../../images/landing_page_assests/star-5.svg";
import star_7 from "../../../images/landing_page_assests/star-7.svg";
import union_icon_white from "../../../images/landing_page_assests/union-icon-white.svg";
import StyledButtonWhiteGradientWithIcon from "../../common/Button/StyledButtonWhiteGradientWithIcon";
import { useNavigate } from "react-router-dom";
import BrandedImageSlider from "./BrandedImageSlider";
import useWindowSize from "../../../_helper/ScreenUtil";
import { userService } from "../../../services/user.service";
import partners_company_testemonials from "../../../images/landing_page_assests/partners-company-testemonials.svg";
import ButtonGradient from "../../common/Button/ButtonGradient";

const LandingPageNewTopSection = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;
  const checkUserStatusAndRedirect = () => {
    navigate(userService.getUserRedirectPage());
  };

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 500); // Can adjust the scroll threshold as needed
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);


  return (
    <>
      <div className="star-parent">
        <div className="perspective-grid-flat-1-parent" id="free-trial-subscription">
          <img className="perspective-grid-flat-1-icon" alt="" src={perspectivegridflat_1} />
        </div>
        <img className="frame-child6" alt="" src={star_7} />
        <img className="frame-child5" alt="" src={star_5} />
        <img className="new-union cursor-pointer" alt="union_icon_white" src={union_icon_white} onClick={() => {
          window.scrollTo({ top: 99999, left: 0, behavior: "smooth" });
        }} />
        <div className="group-carousel-parent1 mb-30">
          <div className="carousel-content-parent">
            <div className={`${isMobile ? "width-100-percent" : "width50"} m-left-right-auto position-relative z-index-10`}>
              <h3 className={`adspire-heading gradient-head-text text-center ${isMobile ? "text-size-36" : "text-size-60"}`}>ADSPIRE<sup className="font-family-sans-serif adspire-symbol-landing">®</sup></h3>
              {isMobile ?
                <p className={`text-white text-center font-family-vietnam-pro text-weight-600 text-size-24 mt-10 mb-10`}>
                  Generate ads like these, in seconds!
                </p>
                :
                <p className={`text-white text-center font-family-vietnam-pro text-weight-600 text-size-44 mt-10 mb-10`}>
                  Generate ad creatives that actually sell, in seconds.
                </p>}

              <StyledButtonWhiteGradientWithIcon
                btnText="Try for Free"
                btnStyle={{ width: isMobile ? "70%" : "55%", height: isMobile ? "65px" : "75px", marginTop: "20px", marginLeft: "auto", marginRight: "auto" }}
                btnTextStylebyclass={`${isMobile ? "text-size-28" : "text-size-30"} gradient-head-text font-family-vietnam-pro mr-10`}
                onClick={() => checkUserStatusAndRedirect()}
              />

            </div>
          </div>
        </div>
        <BrandedImageSlider />
      </div>
      {isMobile ? <div className="text-center font-family-vietnam-pro text-white text-size-22 mt-10 mb-10">
        <i>Trusted by <span className="text-weight-600">50,000+</span> brands!</i>
      </div>
        :
        <div className="text-center font-family-vietnam-pro text-white text-weight-600 text-size-34 mt-10 mb-10">
          Powering <span className="gradient-head-text text-size-32">50,000+</span> of the world’s best advertisers, from startups to global enterprises.
        </div>}

      {isMobile
        ?
        (isScrolled
          && <div className="landing-bottom-button">
            <ButtonGradient
              title="CONTINUE"
              btnstyle={{ background: "white" }}
              stylebyclass="text-size-20 p-8 text-black text-weight-600"
              btnstylebyclass="width-80-percent m-left-right-auto mb-20"
              type="submit"
              onClick={() => checkUserStatusAndRedirect()}
            />
          </div>)
        :
        <div className="col-md-12 mt-20 mb-50">
          <img className="partners-company-testemonials" src={partners_company_testemonials} alt="rectangle_video_sec_img" width={500} />
        </div>}
    </>
  );
};
export default LandingPageNewTopSection;

import React from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";

const GenerateCaptionsButton = (props) => {

  // ******************** Hooks ***************************************
  const navigate = useNavigate();

  // ******************** Functions ***************************************
  const redirectTo = (pagename) => {
    if (!userService.checkCredits()) {
      const myBtn = document.getElementById('psubscribeModal');
      myBtn.click()
    } else {
      navigate("/" + pagename);
    }
  };


  return (
    <div className="form-group text-center" title="Generate Captions">
      <button type="btn" className="btn btn-primary generateAd " onClick={() => redirectTo(props.pagename)}> Generate Captions </button>
      <span id="psubscribeModal" data-toggle="modal"
        data-target="#subscribeModal">
      </span>
    </div>
  );
};
export default GenerateCaptionsButton;

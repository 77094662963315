import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import FooterLanding from "./landingpage/common/FooterLanding";
import HeaderLanding from "./landingpage/common/HeaderLanding";
import ResetPage from "./landingpage/common/ResetPage";
import { getWebsiteName } from "../utils/function"
import "../components/landingpage/css/new-Landing-page.css";

const ResetPasswordPage = () => {

  document.title =" Free AI Ads using "+getWebsiteName()+" AI Trial";
  return (
    <div className="free-trail-parebt-div">
      <div className="header">
        <HeaderLanding></HeaderLanding>
      </div>
      <ResetPage></ResetPage>
      <FooterLanding></FooterLanding>
    </div>
  );
};

export default ResetPasswordPage;

import React from "react";
import { useNavigate } from "react-router-dom";
import { LITE_SUBSCRIBER_MSG } from "../../../utils/constants";

const SubscribeLiteButtonModal = (props) => {
  const navigate = useNavigate();
  const redirectTo = () => {
     // navigate("/subscribe");
  };
  return (
    <>
     

      <div
        className="modal fade"
        id="subscribeLiteModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="subscribeLiteModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center modal-gradient">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
              
              </button>
            </div>
            <div className="modal-body p46">

              <div className=" text-center m-top" >
                <p className="font20"> {LITE_SUBSCRIBER_MSG}
                
                </p>
                <button onClick={() => redirectTo()}
                  type="button"
                  className="btn btn-btn btn-primary generateAd w-160  outer-line margin-left15"
                  data-dismiss="modal">
                  Got It
                </button>
                
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};
export default SubscribeLiteButtonModal;


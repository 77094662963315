// Import FirebaseAuth and firebase.
import "firebase/compat/auth";
import React, { useEffect, useState } from "react";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { fbEventsService } from "../services/fbevents.service";
import { paymentService } from "../services/payment.service";
import { userService } from "../services/user.service";
import { auth, db } from "../utils/Firebase";
import StripeCheckoutRedirect from "../utils/StripeCheckoutRedirect";
import constants from "../utils/constants";
import { getUserDocInfo } from "../utils/function";
import SpinnerLoader from "./common/SpinnerLoader";

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: "popup",
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    {
      // provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
      //  fullLabel: "Continue with Google"
    },
    /*{
      provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
    }*/
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};
// Configure FirebaseUI END HERE

function SignInScreen(props) {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.
  const [userDataReceived, setUserDataReceived] = useState("");

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged(async (user) => {
      if (!userService.showLogoutBtn() && user && user.uid) {
        props.loginTitle("Login is in process...");
        userService.setKeyValueLocalstorage("userId", user?.uid);
        const userId = user?.uid;
        // THIS FUNCTION RETURNS USER INFORMATION BASED ON EMAIL OR USERID
        const userInformation = await getUserDocInfo(user.email, userId);

        let loginMethod = constants.LOGIN_TYPE_EMAIL;
        if (userService.setKeyValueLocalstorage("signupMethod") == "Email") {
          loginMethod = constants.LOGIN_TYPE_EMAIL;
        }

        /*  if (!user.emailVerified) {
          await user.sendEmailVerification();
          await auth.signOut();
          navigate("/verify-email");
        } else {*/
        // WHEN USER DATA NOT EXIST IN OUR DATABASE, FRESH USER
        if (userInformation == null) {
          const userInfo = {
            loginSource: "Web",
            loginMethod: loginMethod,
            display_name: user?.displayName,
            email: user.email,
            userId: user.uid,
            generatedAdsNumber: 0,
            downloadTrialCount: 0,
            environment: process.env.REACT_APP_STATUS,
          };

          try {
            await db
              .collection("users")
              .doc(userId)
              .set(userInfo, { merge: true });
            fbEventsService.sendFbEvents(
              constants.CREATE_ACCOUNT,
              constants.CREATE_ACCOUNT,
              constants.CREATE_ACCOUNT
            );
            userService.setKeyValueLocalstorage("downloadCount", 0); // for paid user
            userService.googlelogin(user);
            if (userService.getValueFromLocalstorage("affliateCode")) {
              window.location.href = "/verify-account";
            } else {
              window.location.href = userService.getUserRedirectPageAfterSignup();
            }
          } catch (e) {
            console.log(e);
          }
        } else {
          // WHEN USER ALREADY EXIST IN OUR DATABASE, OLD USER
          userService.googlelogin(user);
          setUserDataReceived(userInformation);
        }
        // }
      }
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  // IF USER LOGGED IN CONDITION, WE WILL GET USER INFORMATION AND UPDATE DATA FOR USERS IN LOCAL STORAGE.
  useEffect(async () => {
    const remoteConfig = userService.getValueFromLocalstorage("remoteConfig");

    if (userDataReceived) {
      userService.setKeyValueLocalstorage(
        "userCredits",
        userDataReceived.creditsdownloadCount
      );

      if (userDataReceived.purchaser) {
        userService.setKeyValueLocalstorage("isFirstTimeUser", "no");
        await paymentService
          .getSubscriptionInfoRegistration(userDataReceived)
          .then((response) => {
            window.location.href = "/" + response;
          });
      } else if (userService.getValueFromLocalstorage("affliateCode")) {
        window.location.href = "/verify-account";
      } else if (userDataReceived.errors) {
        /* userService.setKeyValueLocalstorage(
           "verifyErrors",
           userDataReceived.errors +
             "Your payment method failed. Please update payment method to continue using Adspire"
         );*/
        userService.setKeyValueLocalstorage("verifyErrors", "Your payment method failed. Please update payment method to continue using Adspire");
        window.location.href = "/verify-account";
      } else if (
        !userDataReceived.purchaser &&
        userService.getValueFromLocalstorage("userSelectedPlan") != ""
      ) {
        StripeCheckoutRedirect();
      } else if (remoteConfig.stripeCreditCardPage || userDataReceived.downloadCount > 0) {
        userService.setKeyValueLocalstorage("paymentStatus", "EXPIRED");
        window.location.href = "/pricing";
      } else {
        window.location.href = "/verify-account";
      }
    }
  }, [userDataReceived]);

  if (!isSignedIn) {
    return (
      <>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={auth} />
      </>
    );
  }
  return (
    <>
      <SpinnerLoader></SpinnerLoader>
    </>
  );
}
export default SignInScreen;

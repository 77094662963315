import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userService } from "../../../services/user.service";
import { getWebsiteTitle } from "../../../utils/function";

import API_FIREBASE from "../../../config/apifirebase";
import constants from "../../../utils/constants";

import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import LandingAdListing from "../questionsFlow/LandingAdListing";

const LandingGenerateImage = (props) => {
    const [generatedImage, setGeneratedImage] = useState("");
    const [loading, setLoader] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    // Accessing the history instance created by React
    const navigate = useNavigate();
    useEffect(() => {
      generateAdIdeas();
    }, []);
  
   
  
    const generateAdIdeas = async () => {
      
        setLoader(false)
      const input = userService.getValueFromLocalstorage("input");
      const userId = userService.getUserId();
      const styleId = userService.getValueFromLocalstorage("styleId");
      const style = userService.getValueFromLocalstorage("style");
      const idea = userService.getValueFromLocalstorage("ideas");
      const prompt = userService.getValueFromLocalstorage("promptImage");
      const userIdeaHistoryId = userService.getValueFromLocalstorage("userIdeaHistoryId");
      const maxImages = userService.getValueFromLocalstorage("maxImages");
      const size = userService.getValueFromLocalstorage("size");
      const objGenerateImage = {
        input,
        userId,
        styleId,
        style,
        idea,
        prompt,
        userIdeaHistoryId,
        maxImages,
        size,
        landingPage :true
      };
  
      API_FIREBASE.post("requestImageGenerationFromTextFromOpenAI", objGenerateImage, {
        headers: {
          authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
        },
      })
        .then((res) => {
          setLoader(true);
          setGeneratedImage(res.data);
        }, [])
        .catch((e) => {
          setLoader(true);
        });
    };
  
    document.title = getWebsiteTitle() + "Image Ads";
  
    return (
      <>
    
    <LandingAdListing setGeneratedImage={setGeneratedImage} setLoader={setLoader} generateAdIdeas={generateAdIdeas} generatedImage={generatedImage}></LandingAdListing>
      
  
    
        <React.Fragment>
         
  
                    {!errorMessage && !loading  && (
                      <div className="row">
                        <div
                          className=" top-ranckking text-center"
                        >
                          <img
                            width={100} className=""
                            src={Rolling_circle_loader}
                            alt="Generating Image Ads..."
                            title="Generating Image Ads..."
                          />
  
                          <div className="clearfix"></div>
                          <div className=" text-center"><h2 className="gradient-head-text text-center text-size-32 mb-40">Generating Image Ads...</h2></div>
                         
                        </div>
                      </div>)}
        </React.Fragment></>
    );
  };
  export default LandingGenerateImage;

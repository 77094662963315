import React, { useEffect, useState } from "react";
import SpinnerLoader from "../../common/SpinnerLoader";
import {
  LOGIN_MSG, FORGET_PASSWORD_MSG,
  SIGNUP_MSG,
  TRUSTED_BY_SIGNUP_MSG,
} from "../../../utils/constants";
import SignInScreen from "../../SignInScreen";
import StyledButton3 from "../../common/Button/StyledButton3";
import StyledButtonWhite from "../../common/Button/StyledButtonWhite";
import firebase from "firebase/compat/app";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import SignupPageText from "./SignupPageText";
import { userService } from "../../../services/user.service";
import useWindowSize from "../../../_helper/ScreenUtil";
import { checkIsCoupanValid } from "../../../utils/function";


const WelcomeNewSignupLanding = () => {
  //document.title = getWebsiteTitle() + " Signup";
  const [loginTitle, setLoginTitle] = useState("Login to see your ads!"); // Local signed-in state.
  const [errorMessage, setErrorMessage] = useState(""); // Local signed-in state.
  const [loading, setLoader] = useState(false);
  const [pageName, setPageName] = useState("Signup");
  const [pageMessage, setPageMessage] = useState(SIGNUP_MSG);
  const [showPassword, setShowPassword] = useState(false)
  const { width } = useWindowSize();
  const isMobile = width <= 768;



  useEffect(() => {
    if (userService.getValueFromLocalstorage("pageName")) {
      setPageName(userService.getValueFromLocalstorage("pageName"));
      userService.removeKeyLocalstorage("pageName")
    }
  }, [userService.getValueFromLocalstorage("pageName")]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // form validation rules
  const validationSchema = Yup.object().shape({
    email: Yup.string().required("Email is required").email("Email is invalid"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
    promoCode: Yup.string().notRequired().test('coupon-match', 'Coupon does not match', function (value) {
      // Validation logic to check if the coupon matches certain criteria
      if (value) {
        // Example: Check if the coupon is 'SAVE20'
        const isValid = checkIsCoupanValid(value)
        return isValid
      }
      // If the promoCode is not provided, it's considered valid
      return true;
    }),
  });


  const formOptions = { resolver: yupResolver(validationSchema) };
  formOptions.defaultValues = {
    email: userService.getValueFromLocalstorage("signupEmail")
  };


  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors } = formState;


  const onSubmit = async (data) => {
    setErrorMessage("")
    const userEnteredEmail = data.email.toLowerCase().trim();

    if (pageName === "ForgotPassword") {
      setLoader(true)
      await firebase.auth().sendPasswordResetEmail(userEnteredEmail);
      setLoader(false)
      reset();
    } else if (pageName === "Login") {
      setLoader(true)
      try {
        await firebase.auth().signInWithEmailAndPassword(userEnteredEmail, data.password);
        setLoader(false)
        reset();
        // User successfully logged in, you can redirect or perform other actions here
      } catch (error) {
        setErrorMessage("Please enter valid Email/Password")
        setLoader(false)
      }
      userService.setKeyValueLocalstorage("loginMethod", "Email");
      userService.setKeyValueLocalstorage("signupMethod", "Email");
    } else if (pageName === "Signup") {
      try {
        setLoader(true)
        userService.setKeyValueLocalstorage("signupMethod", "Email");
        userService.setKeyValueLocalstorage("signupEmail", data?.email);
        if (data?.promoCode) {
          const isValidCoupan = checkIsCoupanValid(data?.promoCode)
          if (isValidCoupan) {
            userService.setKeyValueLocalstorage("promoCode", data?.promoCode);

          }
        }
        // Create the user account with email and password
        await firebase.auth().createUserWithEmailAndPassword(data?.email, data?.password);
        setLoader(false);
        reset();
      } catch (error) {
        //setLoader(false)
        if (error?.code == "auth/email-already-in-use") {
          //setErrorMessage("This email already exists. Please login using your email account.")
          try {
            await firebase.auth().signInWithEmailAndPassword(data?.email, data?.password);
            setLoader(false)
            reset();
          } catch (error) {
            setErrorMessage("Email account exists. Please login or reset password")
            setLoader(false)
          }
        }
      } finally {
        setLoader(false)
      }
    }
  };

  const CustomPageName = (pageName) => {
    // here we are stasfying the form so that it do not return error 
    setPageName(pageName)
    if (pageName === "Login") {
      setPageMessage(LOGIN_MSG)
      setValue('password', '')
    } else if (pageName === "Signup") {
      setPageMessage(SIGNUP_MSG)
      setValue('password', '')
    } else if (pageName === "ForgotPassword") {
      setPageMessage(FORGET_PASSWORD_MSG)
      setValue('password', '123456', { shouldValidate: false })
    }
  };


  return (
    <div className="group-free-trail-parent">
      <div className="frame-free-trail-parent">
        {/* <div className="new-trail-frame-container-by-2"> */}
        <div className="col-2 col-md-12 col-lg-10 col-lg-offset-1 font-family-sans-serif ">
          <div className="new-trail-frame-wrapper2">
            <div className="free-trail-frame-wrapper">
              {loading && !userService.getUserId() && (<div className="text-center">
                {pageName != 'ForgotPassword' && (<SpinnerLoader></SpinnerLoader>)}
                <p className="text-center-normal-mar30">
                  <b>{pageMessage}</b>
                </p>
              </div>)}
              {errorMessage && (<b className="error-message text-center"> {errorMessage}</b>)}

              <div className="free-trail-frame-parent1 " >
                {pageName === 'ForgotPassword' && (
                  <div className="col-xs-12 justifyCenter">
                    <div className="col-xs-10 col-sm-7 col-md-5 col-lg-7 mt-60 ">
                      <span className="adspire-free-trail-3  fullwidth font-35 text-center">
                        Forgot  Password?
                      </span>
                      <div className="try-3-days-for-free-parent col-xs-12 mt-20">
                        <div className="then-34999year 4">
                          <div className="text-left">
                            <div className="row">
                              <div className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <input
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        {...register("email")}
                                        className={`custom-input custom-input-radius modal-gradient- ${errors.email ? "is-invalid" : ""}`}
                                      />
                                      <div className="invalid-feedback">
                                        {errors.email?.message}
                                      </div>
                                    </div>
                                  </div>
                                  {!loading && (<div className="form-row submit-signup-button">
                                    <StyledButtonWhite
                                      type="submit"
                                      name={"Reset My Password"}
                                    />
                                  </div>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {pageName === 'Login' && (

                  <div className="col-xs-12 justifyCenter mt-60">
                    <div className="col-xs-11 col-sm-7 col-md-5 col-lg-7">

                      <span className="adspire-free-trail-3 fullwidth font-35 text-center">
                        Log-in for powerful AI ads!
                      </span>

                      <div className="try-3-days-for-free-parent col-md-12 col-12 col-lg-8 col-lg-offset-2">
                        <div className="then-34999year 3">
                          <p className="account-login">
                            <span onClick={() => CustomPageName("Signup")}>or Sign-up</span>
                          </p>
                          <div className="text-left mt-20">
                            <div className="row">
                              <div className="width-80-percent m-left-right-auto">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <input
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        {...register("email")}
                                        className={`custom-input custom-input-radius modal-gradient- ${errors.email ? "is-invalid" : ""
                                          }`}
                                      />
                                      <div className="invalid-feedback">
                                        {errors.email?.message}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <div className="password-container">
                                        <input
                                          name="password"
                                          placeholder="Password"
                                          type={showPassword ? "text" : "password"}
                                          {...register("password")}
                                          className={`custom-input custom-input-radius  modal-gradient- ${errors.password ? "is-invalid" : ""
                                            }`} />
                                        <span className="eye-icon" onClick={() => { setShowPassword(prev => !prev) }}>
                                          {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                        </span>
                                      </div>

                                      <div className="invalid-feedback">
                                        {errors.password?.message}
                                      </div>
                                    </div>
                                  </div>
                                  {!loading && (<div className="form-row submit-signup-button">

                                    <StyledButtonWhite
                                      type="submit"
                                      name={"Login"}
                                    />
                                  </div>)}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {pageName === 'Signup' && (

                  <div className="col-md-12 col-lg-12 justifyCenter mt-40">
                    <div className=" col-xs-11 col-sm-10 col-md-7 col-lg-6">
                      <h3 className={`adspire-free-trail-3  ${isMobile ? "text-size-24 mt-30 mb-10 text-weight-600" : "font-35 "}`}>
                        You’re 1 step away from FREE powerful ads!
                      </h3>
                      <p className={` ${isMobile ? "text-size-20 mb-30" : "font-22 mb-20"}`}>{TRUSTED_BY_SIGNUP_MSG}</p>
                      <div className=" t-px-40 try-3-days-for-free-parent ">
                        <div className="then-34999year 2">
                          <div className="container-fluid">
                            <div className={`top-contentDIv text-left ${isMobile ? "width-100-percent" : "width-80-percent"} `}>
                              <div className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group">
                                      <input
                                        name="email"
                                        placeholder="Email"
                                        type="email"
                                        {...register("email")}
                                        className={`custom-input custom-input-radius modal-gradient- ${errors.email ? "is-invalid" : ""
                                          }`}
                                      />
                                      <div className="invalid-feedback">
                                        {errors.email?.message}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="form-row">
                                    <div className="form-group col">
                                      <div className="password-container">
                                        <input
                                          name="password"
                                          placeholder="Password"
                                          type={showPassword ? "text" : "password"}
                                          {...register("password")}
                                          className={`custom-input custom-input-radius  modal-gradient- ${errors.password ? "is-invalid" : ""
                                            }`}
                                        />
                                        <span className="eye-icon" onClick={() => { setShowPassword(prev => !prev) }}>
                                          {showPassword ? <i className="fas fa-eye-slash"></i> : <i className="fas fa-eye"></i>}
                                        </span>
                                      </div>

                                      <div className="invalid-feedback">
                                        {errors.password?.message}
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="form-row">
                                  <div className="form-group col">
                                    <div className="password-container">
                                      <input
                                        name="promoCode"
                                        placeholder="Promo Code"
                                        type="text"
                                        {...register("promoCode")}
                                        className={`custom-input custom-input-radius  modal-gradient- ${errors.promoCode ? "is-invalid" : ""
                                          }`}
                                      />
                                    </div>

                                    <div className="invalid-feedback">
                                      {errors.promoCode?.message}
                                    </div>
                                  </div>
                                        </div>*/}

                                  {!loading && (
                                    <div className="form-row submit-signup-button">
                                      <StyledButton3
                                        btnStyle="p-20"
                                        btnHeight="70px"
                                        btnTextStyle="font-22 text-weight-800 p-2"
                                        type="submit"
                                        name={"Signup & Get 5 FREE Ads!"}
                                      />
                                    </div>
                                  )}
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="try-3-days-for-free-parent new-lading-page-login-with-email-fire">
                  <div className="then-34999year 1">
                    <SignInScreen loginTitle={setLoginTitle}></SignInScreen>

                    {(pageName === 'Signup' || pageName === 'ForgotPassword') && (
                      <p className="account-login">Have an account? <span onClick={() => CustomPageName("Login")}>Login</span></p>)}

                    {(pageName === 'Login') && (
                      <p className="account-login"><span onClick={() => CustomPageName("ForgotPassword")}>Forgot Password?</span></p>)}
                  </div>
                </div>
              </div>
            </div>
            <SignupPageText></SignupPageText>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WelcomeNewSignupLanding;

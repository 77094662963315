import React from "react";
import { userService } from "../../../../services/user.service";
import PayPalBtn from "../../../common/PaymentGateway/PaypalBtn";

import {
  PAYPAL_ENVIRONMENT,
  PAYPAL_PAYMENT_STATUS,
  PAYPAL_YEARLY_LITE_PLAN_ID,
  PAYPAL_YEARLY_LITE_PLAN_NAME,
  PAYPAL_YEARLY_LITE_PRICE, PLAN_TYPE_LITE
} from "../../../../utils/constants";

const PlanYearlyLite = (props) => {

  const paypalSubscribeLite = (data, actions) => {
    props.setPaymentProccessStart(true);
    return actions.subscription.create({
      plan_id: PAYPAL_YEARLY_LITE_PLAN_ID,
    });
  };

  const paypalOnError = async (err) => {
    props.setPaymentProccessStart(false);
  };

  const paypalOnApproveLite = async (data, detail) => {
    // call the backend api to store transaction details
    const userId = userService.getUserId();
    const dataToSave = {
      user_id: userId,
      product_id: PAYPAL_YEARLY_LITE_PLAN_NAME,
      environment: PAYPAL_ENVIRONMENT,
      subscription_id: data.subscriptionID,
      order_id: data.orderID,
      purchase_date: new Date(),
      status: PAYPAL_PAYMENT_STATUS,
      amount: PAYPAL_YEARLY_LITE_PRICE,
      plan: PLAN_TYPE_LITE
    };


  };
  return (
    <PayPalBtn
      amount="1"
      currency="USD"
      createSubscription={paypalSubscribeLite}
      onApprove={paypalOnApproveLite}
      catchError={paypalOnError}
      onError={paypalOnError}
      onCancel={paypalOnError}
    />
  );
};
export default PlanYearlyLite;

import React, { useState, useEffect } from "react";
import { userService } from "../../services/user.service";
import { useNavigate } from "react-router-dom";

import GenerateMoreButton from "../common/Button/GenerateMoreButton";
import {
  getWebsiteTitle,
  filterIdeas,
  ConceptAdIdeasPrompt, showFirst10Words, isEligibleForFreeAdsAPI
} from "../../utils/function";
import API_FIREBASE from "../../config/apifirebase";
import SpinnerLoader from "../common/SpinnerLoader";
import constants from "../../utils/constants";
import edit_pencil from "../../images/icons/edit-pencil-2.svg";
import ConceptStep from "../common/ConceptStep";
import { colors } from "../../utils/constants";


import LoadingScreen from "../landingpage/adFlow/Loading";
import ConceptBackButton from "../common/Button/ConceptBackButton";

const PreviewAdIdeas = (props) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [buttonStatus, setButtonStatus] = useState(false);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [loading, setLoader] = useState(false);
  const [completionText, setCompletionText] = useState("");
  const [active, setActive] = useState(null);
  const [userInputValue, setUserInputValue] = useState("");
  const [openInputBox, setOpenInputBox] = useState(false);

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (userService.getValueFromLocalstorage("text")) {
      setCompletionText(filterIdeas(userService.getValueFromLocalstorage("text")));
      setLoader(true);
    } else {
      generateAdIdeas();
    }
  }, []);


  useEffect(() => {
    userService.redirectToLogin();
  }, []);

  useEffect(() => {
    if (completionText && completionText.length > 0) {
      setTimeout(() => {
        userSelectedIdeas(completionText[0]);
      }, 30);
    }
  }, [completionText]);

  const generateAdIdeas = async () => {
    setLoader(false);
    let typeId = "";
    let type = "";
    let adCategoryId = "";
    const userId = userService.getUserId();
    let input = userService.getValueFromLocalstorage("searchQuery");
    typeId = userService.getValueFromLocalstorage("userSelectedConceptAdTypeObj").id;
    type = userService.getValueFromLocalstorage("userSelectedConceptAdTypeObj").data.name;
    userService.setKeyValueLocalstorage("conceptAdFormattedInput", input);
    userService.setKeyValueLocalstorage("uploadInputLoading", input);
    const maxIdeas = userService.getValueFromLocalstorage("maxIdeas");
    const model = userService.getValueFromLocalstorage("model");
    const temperature = userService.getValueFromLocalstorage("temperature");
    const maxTokens = userService.getValueFromLocalstorage("maxTokens");
    const prompt = ConceptAdIdeasPrompt(maxIdeas, type, input);

    const objGenerateImage = {
      input,
      userId,
      typeId,
      type,
      adCategoryId,
      prompt,
      maxIdeas,
      model,
      temperature,
      maxTokens,
    };

    let completionTextArr = [];
    //let requestTextCompletionFromOpenAI="requestTextCompletionFromOpenAI";
    let requestTextCompletionFromOpenAI = "requestTextCompletionFromOpenAI";

    API_FIREBASE.post(requestTextCompletionFromOpenAI, objGenerateImage, {
      headers: {
        authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
      },
    })
      .then((res) => {
        const text = res.data.completionText;
        const filterIdeasText = filterIdeas(text);
        if (filterIdeasText.length > 0) {
          setCompletionText(filterIdeasText);
          userService.setKeyValueLocalstorage("text", text);

          userService.setKeyValueLocalstorage(
            "userIdeaHistoryId",
            res.data.userIdeaHistoryId
          );
          setLoader(true);
        } else {
          // AGAIN CALLING THE API TO GET THE IDEAS.

          generateAdIdeas();
        }
      }, [])
      .catch((e) => {
        // generateAdIdeas();
        if (e.response.status === 500) {
          // used to open the popup
          //const myBtn = document.getElementById("psubscribeModal");
          // myBtn.click();
        }
        setLoader(true);
      });
  };

  document.title = getWebsiteTitle() + "Ad Ideas";

  const userSelectedIdeas = (ideas) => {
    userService.setKeyValueLocalstorage("ideas", ideas);
    setUserInputValue(ideas);
    setActive(ideas);
    setButtonStatus(false);
    setClassvalue("generateAd");
  };
  const redirectTo = (pagename) => {
    navigate("/" + pagename);
  };

  const AdduserInsertedIdea = (e) => {
    setUserInputValue(e.target.value);
    userService.setKeyValueLocalstorage("ideas", e.target.value);
  };

  const getText = (userClickedText, realText) => {
    if (userClickedText === realText) {
      return false;
    } else if (
      (userClickedText === realText && !openInputBox) ||
      !openInputBox
    ) {
      return true;
    }
    return false;
  };

  const getInputBtn = (userClickedTextActive, realText) => {
    if (userClickedTextActive === realText) {
      return true;
    }
    return false;
  };

  const generateImageAds = () => {
    navigate("/home");
  };

  return (
    <React.Fragment>

      {!loading && (
        <LoadingScreen pagename="adideas"></LoadingScreen>
      )}

      {loading && (<div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking">
                <div className={`col-sm-12 col-xs-12 flex content-center`}>
                  <ConceptStep step="4"></ConceptStep>
                </div>
                <h2 className="gradient-head-text text-center text-size-32">


                  {loading && completionText && (

                    <div className="flex content-center align-items-center text-white p-10">
                      <ConceptBackButton
                        className={"mr-20 cursor-pointer"}
                        alt="Back"
                        redirectPage="concept-ad-options"
                      />
                      <h1 className="text-center">Choose an ad idea</h1>
                    </div>
                  )}
                </h2>
                {errorMessage && (
                  <p className="error-message text-center">{errorMessage}</p>
                )}



                <div className="clearfix"></div>
                <div className="col-sm-6 col-sm-offset-3">
                  {!loading && (
                    <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
                  )}
                  {loading && (
                    <div className="row mb-20">
                      {completionText &&
                        completionText.map((adcategoryItems, index) => (
                          <div
                            className={`col-sm-12 col-xs-12 ${adcategoryItems === "" ? "hideIt" : "nothideIt"
                              }`}
                            key={adcategoryItems}
                          >
                            <div
                              className={` ${active === adcategoryItems
                                ? "box-active-gradient "
                                : "mb-10"
                                }`}
                            >
                              <div
                                className={`some-ad-idea-thumbnail-bg text-size-18`}
                                style={{
                                  background: index == 0 ? "white" : colors[index],
                                }}
                              >
                                <div className="">
                                  <span className=" text-weight-600">
                                    {getInputBtn(active, adcategoryItems) && (
                                      <textarea
                                        className="form-control-edited"
                                        type="text"
                                        onChange={(e) => AdduserInsertedIdea(e)}
                                        value={userInputValue}
                                      ></textarea>
                                    )}

                                    {getText(active, adcategoryItems) && (
                                      <span
                                        className="text-weight-500"
                                        onClick={() =>
                                          userSelectedIdeas(adcategoryItems)
                                        }
                                      >
                                        {showFirst10Words(adcategoryItems, index)}</span>
                                    )}
                                  </span>
                                </div>

                                {getInputBtn(active, adcategoryItems) && (
                                  <>
                                    <div className="justifyCenter mt-10">
                                     <div className="flex ">
                                     <div className="generate-ad-btn-gardient-parent bg-primary ">
                                        <div className="generate-ad-btn-gardient ">
                                          <button
                                            type="button"
                                            title="Generate Image Ads"
                                            className={`cursor-pointer border-none bg-none font-18-regular`}
                                            onClick={() =>
                                              generateImageAds()
                                            }
                                          >
                                            Generate Image Ads
                                          </button>
                                        </div>
                                      </div>
                                      <img
                                        src={edit_pencil}
                                        alt=""
                                        className="float-r ml-20"
                                        width={30}
                                      />
                                     </div>
                                    </div>
                                  </>
                                )}

                                <div className="clearfix"></div>
                              </div>
                            </div>
                          </div>
                        ))}
                      {/* <img
                        src={PressIdea}
                        alt=""
                        width={160}
                        className="tap-to-generate-img"
                      /> */}
                    </div>
                  )}
                  {loading && (
                    <>
                      {!userService.isSubscribed && (
                        <GenerateMoreButton
                          buttonStatus={buttonStatus}
                          classvalue="btn btn-primary generateAd"
                          pagename="concept-image-style"
                        ></GenerateMoreButton>
                      )}

                      {userService.isSubscribed && (
                        <div
                          className="form-group text-center"
                          title="Generate more "
                        >
                          <button
                            type="btn"
                            className={`btn btn-primary btn-background-white-border-curve`}
                            onClick={() => generateAdIdeas()}
                          >
                            <span className="gradient-head-text">
                              Generate more{" "}
                            </span>
                          </button>
                        </div>
                      )}

                      {/* <BackButton pagename="ad-type"></BackButton> */}
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>)}
      <p
        id="psubscribeModal"
        data-toggle="modal"
        data-target="#subscribeModal"
      ></p>
    </React.Fragment>
  );
};
export default PreviewAdIdeas;

import React from "react";
import { useNavigate } from "react-router-dom";
const StartFreeTrialCarouselBtn = (props) => {
  const navigate = useNavigate();
  const redirectTo = (pageName) => {
    navigate(pageName);
    };

  return (
    <button className="next-wrapper1 carousal-btn" onClick={() => redirectTo("/free-trial-subscriptions")}>
      <b
        className="what-do-you "

      >
        Start My Free Trial
      </b>
    </button>);
};
export default StartFreeTrialCarouselBtn;



import React, { useEffect, useState } from "react";
import { userService } from "../../../services/user.service";
import {
  fetchAdProductTypes,
  updateLocalStorageFromSnapshot,
} from "../../../utils/Firebase.collection.helper";
import { getWebsiteTitle } from "../../../utils/function";
import ConceptAdOptionsQuestionFlow from "./ConceptAdOptionsQuestionFlow";

const Category = ({ GenerateQuestion, buttonStatus, updateButtonStatus , setStore}) => {
  const [conceptAdProduct, setConceptAdProduct] = useState("");
  const [loading, setLoader] = useState(false);
  const [activeCircle, setActiveCircle] = useState(0);

  // Accessing the history instance created by React
  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        const dataReceived = await fetchAdProductTypes();
        if(isMounted){
        setConceptAdProduct(dataReceived);
        setLoader(true);
        localStorage.setItem('yourLocalStorageKey', JSON.stringify(dataReceived));
      }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    const storedData = localStorage.getItem('yourLocalStorageKey');
    if (!storedData) {
      fetchData();
    } else {
      setConceptAdProduct(JSON.parse(storedData));
      setLoader(true);
    }
        userService.clearConceptFlowData();
    userService.removeKeyLocalstorage("customeGenerateClicked");
    // Cleanup function to set the flag to false when the component is unmounted
    return () => {
      isMounted = false;
    };
  }, []);

  const setBusinessValue = (conceptAdProduct) => {
    updateButtonStatus(false);
    setStore(false)
    userService.setKeyValueLocalstorage(
      "conceptAdProductObj",
      conceptAdProduct
    );
    userService.setKeyValueLocalstorage("questionFlow", "yes");
  };
  document.title = getWebsiteTitle() + "Concept Ad";
  return (
    <>
      {buttonStatus && (
        <div className="container col-xs-12 justifyCenter">
          <div className={`col-sm-12 col-lg-8 col-md-9 col-xs-12 mt-20`}>
            <div className="row mt-10 ">
              {conceptAdProduct &&
                conceptAdProduct.map((conceptAdProductItems, index) => (
                  <div
                    key={index}
                    className={`  justifyCenter col-lg-3  col-xs-6 col-sm-3 mb-20 cursor-pointer`}
                    onClick={() => {
                      setBusinessValue(conceptAdProductItems);
                    }}
                  >
                    <div>

                    <div
                      className={`${
                        activeCircle === index
                          ? "active-round round-background-transparent"
                          : "round-background-transparent"
                      }`}
                    >
                      <img
                        alt="circle-img"
                        width={64}
                        src={conceptAdProductItems.data.iconURL}
                      />
                    </div>
                    <div className="product-category-text">
                      {conceptAdProductItems.data.name}
                    </div>
                    </div>
                  </div>
                ))}
            </div>
          </div>{" "}
        </div>
      )}

      {!buttonStatus && (
        <ConceptAdOptionsQuestionFlow
          GenerateQuestion={GenerateQuestion}
          isQuestionFlow={true}
          setStore={setStore}
        ></ConceptAdOptionsQuestionFlow>
      )}
    </>
  );
};
export default Category;
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Logo from "./Logo";
import home_bold from "../../images/icons/home_filled.svg";
import payment_light from "../../images/icons/payment.svg";
import payment_bold from "../../images/icons/payment-filled.svg";
import ads_icon from "../../images/icons/new-landing-page/ads.svg";
import subscribe_icon from "../../images/icons/new-landing-page/subscribe.svg";
import { userService } from "../../services/user.service";

const NavbarPrivate = () => {
  const [classActive, setClassActive] = useState("dashboard");
  const [role, setRole] = useState("");

  useEffect(() => {
    let paramName = window.location.pathname.replace(/\//g, "");
    setClassActive(paramName);
    setRole(localStorage.getItem("role"));
  }, []);

  const menuClicked = (menu) => {
    if (menu === "home") {
      userService.removeKeyLocalstorage("ideas");
      window.location.href = "/home";
    } else {
      setClassActive(menu);
    }

  };

  return (
    <div>
      <div className="sidebar-header">
        {userService.checkCredits() && <Logo path="/home"></Logo>}
        {!userService.checkCredits() && <Logo path="/pricing"></Logo>}
      </div>
      <ul className="list-unstyled components">
        <li className="" onClick={() => menuClicked("home")}>
          {userService.checkCredits() && (
            <Link to="/home">
              <div title="Home" className="sidebar-tiles">
                <img
                  src={classActive === "home" ? home_bold : home_bold}
                  alt="Home"
                  className={
                    classActive === "home"
                      ? "sidebar-img-gradient"
                      : "sidebar-img-bgr"
                  }
                />
                <h4 className={classActive === "home" ? "text-bold-600" : ""}>
                  Home
                </h4>
              </div>
            </Link>
          )}

          {!userService.checkCredits() && (
            <Link to="/pricing">
              <div title="Home" className="sidebar-tiles">
                <img
                  src={classActive === "home" ? home_bold : home_bold}
                  alt="Home"
                  className={
                    classActive === "home"
                      ? "sidebar-img-gradient"
                      : "sidebar-img-bgr"
                  }
                />
                <h4 className={classActive === "home" ? "text-bold-600" : ""}>
                  Home
                </h4>
              </div>
            </Link>
          )}
        </li>

        {!userService.checkCredits() && (
          <li className="" onClick={() => menuClicked("my-adlisting")}>
            <Link to="/pricing">
              <div title="My Ads" className="sidebar-tiles">
                <img
                  src={classActive === "my-adlisting" ? ads_icon : ads_icon}
                  alt="My Ads"
                  className={
                    classActive === "my-adlisting"
                      ? "sidebar-img-gradient"
                      : "sidebar-img-bgr"
                  }
                />
                <h4
                  className={
                    classActive === "my-adlisting" ? "text-bold-600" : ""
                  }
                >
                  My Ads
                </h4>
              </div>
            </Link>
          </li>
        )}

        {userService.checkCredits() && (
          <li className="" onClick={() => menuClicked("home")}>
            {/* <Link to="/home"> */}
            <Link to="/home">
              <div title="My Ads" className="sidebar-tiles">
                <img
                  src={classActive === "my-adlisting" ? ads_icon : ads_icon}
                  alt="My Ads"
                  className={
                    classActive === "my-adlisting"
                      ? "sidebar-img-gradient"
                      : "sidebar-img-bgr"
                  }
                />
                <h4
                  className={
                    classActive === "my-adlisting" ? "text-bold-600" : ""
                  }
                >
                  My Ads
                </h4>
              </div>
            </Link>
          </li>
        )}

        <li className="" onClick={() => menuClicked("free-trial-subscription")}>
          <Link to="/pricing">
            <div title="free-trial-subscription" className="sidebar-tiles ">
              <img
                src={
                  classActive === "free-trial-subscription"
                    ? subscribe_icon
                    : subscribe_icon
                }
                alt="free-trial-subscription"
                className={
                  classActive === "free-trial-subscription"
                    ? "sidebar-img-gradient text-light"
                    : "sidebar-img-bgr text-light"
                }
              />
              <h4
                className={
                  classActive === "free-trial-subscription"
                    ? "text-bold-600"
                    : ""
                }
              >
                Subscribe
              </h4>
            </div>
          </Link>
        </li>


        <li className="" onClick={() => menuClicked("payment")}>
          <Link to="/payment-history">
            <div title="Payment" className="sidebar-tiles">
              <img
                src={classActive === "payment" ? payment_bold : payment_light}
                alt="Payment"
                className={
                  classActive === "payment"
                    ? "sidebar-img-gradient"
                    : "sidebar-img-bgr"
                }
              />
              <h4
                className={classActive === "payment" ? "text-bold-600" : ""}
              >
                Payment
              </h4>
            </div>
          </Link>
        </li>

      </ul>
    </div>
  );
};

export default NavbarPrivate;

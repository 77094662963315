import React from "react";
import { userService } from "../../../services/user.service";
import TextFlowDescriptionModal from "../popup/TextFlowDescriptionModal";
import StyledButton1 from "./StyledButton1";

const DownloadunwatermarkedButton = (props) => {

  // ******************** Functions ***************************************
  const redirectTo = (pageName) => {
    if (userService.isPaidUser()) {
      const url = `/${pageName}`;
      window.open(url, "_blank");
    } else {
      const url = `/signup`;
      window.open(url, "_blank");
    }
  };


  return (
    <>
      <div className="form-group text-center">
        <div className="form-group text-center btn-radius-generate-more mt-20" title="Download All">
          <StyledButton1
            btnTheme={"dark"}
            type="button"
            name={"Download All"}
            onClick={() => redirectTo("subscriptions")}
            btnHeight="56px"
          />
        </div>
        <div className="form-group text-center btn-radius-generate-more mt-20" title="Edit">
          <StyledButton1
            btnTheme={"light"}
            type="button"
            name={"Generate More"}
            onClick={() => redirectTo("subscriptions")}
            btnHeight="56px"
          />
        </div>
      </div>
      <TextFlowDescriptionModal getFormValueFromAI={props.getFormValueFromAI} />
      <span
        id="ptextFlowModal"
        data-toggle="modal"
        data-target="#textFlowDescriptionModalId"
      ></span>
    </>
  );
};
export default DownloadunwatermarkedButton;

import React from "react";
import { useNavigate } from "react-router-dom";
import { PRO_SUBSCRIBER_MSG } from "../../../utils/constants";

const SubscribeProButtonModal = (props) => {
  const navigate = useNavigate();
  const redirectTo = () => {
      //navigate("/subscribe");
  };
  return (
    <>
    

      <div
        className="modal fade"
        id="subscribeProModal"
        tabIndex="-1"
        role="dialog"
        aria-labelledby="subscribeModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog top40" role="document">
          <div className="modal-content text-center modal-gradient">
            <div className="modal-header">
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
              
              </button>
            </div>
            <div className="modal-body p50">

              <div className=" text-center m-top" >
                <p className="mar-30 font20"> { PRO_SUBSCRIBER_MSG }
                </p>
                <button
                  type="button"
                  className="btn btn-btn btn-primary generateAd w-160  outer-line margin-left15"
                  data-dismiss="modal">
                  Got It
                </button>
                
              </div>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};
export default SubscribeProButtonModal;


import React from "react";
import { Link } from "react-router-dom";
import "../../components/landingpage/css/Landing-page.css";
import frame_1261153743_aboutUs_pg from "../../images/aboutUs_page_assests/frame_1261153743_aboutUs_pg.svg";
import frame_1261153744_about_pg from "../../images/aboutUs_page_assests/frame_1261153744_about_pg.svg";
import grediant from "../../images/aboutUs_page_assests/grediant.svg";
import star_1_about_pg from "../../images/aboutUs_page_assests/star_1_about_pg.svg";
import color_bg from "../../images/landing_page_assests/color-bg.svg";
import lines_dots from "../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../images/landing_page_assests/star-1.svg";
import { getWebsiteTitle } from "../../utils/function";
import FooterLanding from "../landingpage/common/FooterLanding";
import HeaderLanding from "../landingpage/common/HeaderLanding";
function Riskdisclosure() {

  document.title = getWebsiteTitle() + "Terms Condition";
  return (
    <React.Fragment>
      <div className="sconfirmed-main-landing-page">
        {/* <img className="noise-icon" alt="" src={noise_2x} /> */}

        <div className="hero-sections-parent">
          <div className="heros-sections">
            <img className="hero-sections-child" alt="" src={star_1} />
            <img className="lines-dots-icon" alt="" src={lines_dots} />
            <img className="line-dots-icon" alt="" src={lines_dots} />
            <img className="dual-color-bg-icon" alt="" src={color_bg} />
            <div className="header">
              <HeaderLanding></HeaderLanding>
            </div>

            <div className="title-page-name">
              <h2 className="gradient-logo-text">Terms & Conditions</h2>
            </div>

            <div className="how-section1">
              <div className="row">
                <div className="headingdiv">
                  <p>Our Terms of Service</p>
                  <p>Last Revised: Mar 14, 2023</p>
                  <p>
                    Please read the following terms and conditions carefully
                    before you use services from Adspire. Your use of the
                    products by Adspire. will be subject to these terms and by
                    using the products of Adspire. you agree to be bound by
                    them.
                  </p>
                  <p>
                    1. Introduction Adspire. ("Adspire.", "We", "Us," "Our")
                    develops mobile fitness applications ("Our Products"). Our
                    Apple Applications may be downloaded from the App Store,
                    which is operated and owned by Apple Inc. and other
                    companies within its group ("Apple"). Our Android
                    Applications may be downloaded from Google Play, which is
                    owned and operated by Google Inc. and other companies within
                    its group ("Google"). Our goal is to help people lead a
                    healthy way of life through sports. Our Products offer
                    fitness-related content, features, functionality and other
                    information and services including, without limitation,
                    viral, embeddable or application/device-based features and
                    related technology, also when accessed via the Internet,
                    mobile or other device (collectively, "Our Services"). We
                    develop Our Products for informational purposes only. You
                    must ALWAYS consult your physician or doctor before starting
                    any fitness program. By agreeing to these Terms and
                    Conditions you confirm that you are solely responsible for
                    your state of health.
                  </p>
                  <p>
                    2. Acceptance of Terms and Conditions By using Our Products
                    and Services you accept these Terms and Conditions. If you
                    do not accept these Terms and Conditions, you may not use
                    Our Products and Services. Adspire. reserves the right to
                    amend these Terms and Conditions at any time without prior
                    notice. The up-to-date version of these Terms and Conditions
                    will be available on the official website of Adspire. and
                    any changes will come into effect immediately upon posting.
                    By continuing to use our products you agree to amended terms
                    and conditions.
                  </p>
                  <p>
                    3. Intellectual Property The texts, graphics, designs,
                    logos, button icons, images, data compilations and
                    information ("Content") contained in Our Products and
                    Services are Intellectual Property of Adspire. (or belongs
                    to it) and are protected by copyright laws. All logos and
                    trademarks displayed in Our Products and Services are
                    registered trademarks or trademarks of Adspire. No
                    trademarks may be used without prior written consent of
                    Adspire. except to identify the products or services
                    associated therewith. Our Products and all related software,
                    and all patent rights (including patent applications and
                    disclosures), copyrights, trade secrets, know-how, and any
                    other intellectual property rights therein or relating
                    thereto (including derivative works), are and shall remain
                    the exclusive property of Reed Rock Apps or its licensors.
                  </p>
                  <p>
                    4. Legality of Use By using Our Products, you hereby warrant
                    that: (i) all information contained in any submission by you
                    is either owned by you or rights have been granted to you
                    permitting you to disseminate the information as set forth
                    in these Terms and Conditions; and (ii) the information
                    contained in any submissions by you does not infringe on the
                    intellectual property rights of others, including but not
                    limited to, copyright, trademark, patent, or trade secret
                    rights. You accept full responsibility, assume all risk, and
                    waive and/or release Us from any direct or indirect loss,
                    injury, claim or damage arising from your use of Our
                    Products, whether or not such use was appropriately
                    supervised and regardless of whether you were negligent in
                    such use. By using Our Products, you attest and verify that
                    you are physically and mentally able to use Our Products,
                    and knowingly accept and are fully aware of all risks
                    associated with their use.
                  </p>
                  <p>
                    5. SUBSCRIPTIONS Adspire® Premium Membership subscriptions
                    that grant you access to receive Cryptoscurrency signals.
                  </p>
                  <p>
                    6. Representations and Warranties, Limitation of Liability
                    Adspire. makes no representations regarding: (a) the
                    credentials of anyone using our Services and/or Products as
                    part of any health care or medical plan of care or plan of
                    treatment ("POC"); (b) the suitability of the exercises
                    included in any such POC; (c) the viability, medical
                    soundness or efficacy of any such POC; or (d) the overall
                    health or medical benefits you might derive from using our
                    services and products or POC which incorporates our services
                    and products. The use of any software or hardware offered by
                    Adspire. is no substitute for the consultation by the user
                    of a specialized doctor. Our Services and Products are
                    provided "as is" without any warranties, expressed or
                    implied, including but not limited to the implied warranties
                    of merchantability, used for a particular purpose, or
                    non-infringement. Adspire. makes no representation or
                    warranty that the information contained in our Services
                    and/or Products will be current, timely, complete, accurate
                    or error-free. To the extent that the law does not permit
                    the disclaimer of warranties, all content accessible in our
                    services and products, or any other web site to which our
                    services and products link, is warranted only to the minimum
                    amount legally required. In no event shall Adspire. be
                    liable to any party for any direct, indirect, special or
                    other consequential damages for any use of this in our
                    services and products, or on any linked web site, including,
                    without limitation, any lost profits, business interruption
                    or otherwise. Adspire. shall be liable as currently provided
                    by law, regardless of the statutory basis of such liability
                    (pre-contractual, contractual, tort) only if Adspire. has
                    caused a certain damage willfully or with gross negligence.
                    In the event of slight negligence, Adspire. shall not be
                    liable to other businesses and shall be liable to consumers
                    only for personal damages Adspire. shall not be liable to
                    businesses for follow-on damages, mere pecuniary losses,
                    lost profits or damages resulting from claims of third
                    parties.
                  </p>
                  <p>
                    7. Privacy Policy 8. Maintenance Adspire. is not responsible
                    for any outages or service interruptions that occur from
                    time-to-time when using Our Products, including those due to
                    software, hardware or power failures, or issues at the
                    wireless carrier level. In addition, Adspire. is not
                    responsible for the products and services provided by
                    others, including any Users mobile handsets or wireless data
                    networks. Adspire., in its sole discretion, reserves the
                    right to add or remove operating systems based on commercial
                    factors it deems relevant such as use, adoption and appeal
                    of the operating system.
                  </p>
                  <p>
                    9. Content Created by Users and Rights to such Content The
                    user consents that as a result of the automatic evaluation
                    of the way the user uses Our Products, he/she may be exposed
                    to certain offers and/or marketing messages tailored to such
                    user. The user consents that marketing measures may also be
                    taken in relation to created content, which are marketed by
                    Adspire. The user grants Adspire. the irrevocable, free,
                    non-exclusive and unlimited right to use all content
                    generated, transmitted, saved and published by such user.
                    Accordingly, Adspire. shall have the right to use all
                    content, irrespective of the type of usage. This shall
                    include the right to change and edit such content, unless
                    such changes or edits impair material interests of the user.
                    In this connection, the user waives, to the extent legally
                    permissible, all intellectual property rights. Adspire. does
                    not claim ownership of any content created by users and will
                    not supervise such content.
                  </p>
                  <p>
                    10. Territory The term "Territory" means the United States
                    its territories possessions, North America, the United
                    Kingdom and Australia. Any additional Territories shall be
                    subject to mutual approval.
                  </p>
                  <p>
                    Governing Law These Terms and Conditions, any
                    non-contractual obligations arising in connection with them
                    and litigation between the users and Adspire. shall be
                    subject to the US law only. The state and federal courts in
                    Las Vegas, Nevada, shall have non-exclusive jurisdiction to
                    determine any dispute arising in connection with these Terms
                    and Conditions, including disputes relating to any
                    non-contractual obligations.
                  </p>
                </div>
              </div>
            </div>

            <div className="how-section2">
              <div className="about_container">
                <div className="adspire-name">
                  <b className="adspire8">Adspire®</b>
                  <p className="about_container_child">
                    What remarkable ads will you generate? Try it out for{" "}
                    <Link to={"/"}>
                      <span className="free-here-grediant">
                        FREE <span>here.</span>
                      </span>
                    </Link>
                  </p>
                </div>
              </div>
              <img
                src={frame_1261153744_about_pg}
                alt=""
                className="half-circle1"
              />
              <img
                src={frame_1261153743_aboutUs_pg}
                alt=""
                className="half-circle2"
              />
              <img src={star_1_about_pg} alt="" className="star-1" />
              <img src={grediant} alt="" className="grediant-1" />
            </div>
            <FooterLanding></FooterLanding>
          </div>
        </div>
        {/* <img className="grediant-icon1" alt="" src={grediant1} /> */}
      </div>
    </React.Fragment>
  );
}

export default Riskdisclosure;

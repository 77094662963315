import { yupResolver } from "@hookform/resolvers/yup";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import API_FIREBASE from "../../../config/apifirebase";
import powered_by_stripe from "../../../images/varify_account/powered_by_stripe.svg";
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";
import constants, {
  FREE_TRIAL,
  START_TRIAL,
  INITIATE_CHECKOUT,
  PAYMENT_PROCESS_MSG,
} from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import StyledButton3 from "../../common/Button/StyledButton3";
import SpinnerLoader from "../../common/SpinnerLoader";
import { planCardsObj } from "../../subscribe/SubscribeConstants";
import NewSubscriberCarousal from "./NewSubscriberCarousal";
import { fbEventsService } from "../../../services/fbevents.service";
import { getAffiliatePlanIndex } from "../../../utils/Firebase.collection.helper";

const VerifyAccountPaymentPlan = () => {
  document.title = getWebsiteTitle() + " Verify Account";
  const [errorMessage, setErrorMessage] = useState(""); // Local signed-in state.
  const [successMessage, setSuccessMessage] = useState(""); // Local signed-in state.
  const [loading, setLoader] = useState(false);
  const [pageName, setPageName] = useState("Signup");
  const [pageMessage, setPageMessage] = useState(PAYMENT_PROCESS_MSG);
  const [activeTab, setActiveTab] = useState(2);
  const [activePlanType, setActivePlanType] = useState("monthly");
  const [defaultSelectedPlan, setDefaultSelectedPlan] = useState({});
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };
  const initFormValues = {
    fullName: "",
  };
  const [formData, setFormData] = useState(initFormValues);

  useEffect(async () => {

    // if trialPriceKey is 0, means we have to show monthly pro plan of $49.99
    // if trialPriceKey is 1, means we have to show yearly pro plan of $299.99
    const trialPriceKey = userService.getValueFromLocalstorage("remoteConfig");
    if (trialPriceKey) {
      if (trialPriceKey.trialPrice === 0) {
        setActivePlanType("monthly")
        setDefaultSelectedPlan(planCardsObj.monthly[activeTab - 1]);
      } else if (trialPriceKey.trialPrice === 1) {
        setActivePlanType("yearly")
        setDefaultSelectedPlan(planCardsObj.yearly[activeTab - 1]);
      }
    }

    if (userService.getValueFromLocalstorage("verifyErrors")) {
      setErrorMessage(userService.getValueFromLocalstorage("verifyErrors"));
      userService.removeKeyLocalstorage("verifyErrors");
    }
    const affliatePlan = await getAffiliatePlanIndex();
    if (affliatePlan) {
      setActiveTab(affliatePlan);
    }
    userService.removeKeyLocalstorage("affliateCode");
    // fbEventsService.sendFbEvents(FREE_TRIAL, process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME, PAYPAL_MONTHLY_TRIAL_PRICE);
  }, []);

  useEffect(() => {
    if (userService.getValueFromLocalstorage("pageName")) {
      setPageName(userService.getValueFromLocalstorage("pageName"));
      userService.removeKeyLocalstorage("pageName");
    }
  }, [userService.getValueFromLocalstorage("pageName")]);

  // form validation rules
  const validationSchema = Yup.object().shape({
    fullName: Yup.string().required("Please enter your Full Name"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  formOptions.defaultValues = {
    fullName: "",
  };

  // get functions to build form with useForm() hook
  const { register, handleSubmit, reset, formState, setValue } =
    useForm(formOptions);
  const { errors } = formState;

  const addDataInLog = (paymentMethodMsg, error = false) => {
    const selectedPlan = activePlanType === "monthly" ? planCardsObj.monthly[activeTab - 1] : planCardsObj.yearly[activeTab - 1]; // activeTab start from 1, and index start from 0
    const logData = {
      userId: userService.getUserId(),
      name: userService.getValueFromLocalstorage("fullName"),
      email: userService.getValueFromLocalstorage("signupEmail"),
      paymentMethod: paymentMethodMsg,
      environment: process.env.REACT_APP_STATUS,
      planPrice: selectedPlan.plan,
      planName: selectedPlan.title + " " + selectedPlan.planType,
      page: "VerifyAccountPage",
    };
    const timestamp = new Date();

    db.collection("stripePaymentLogVerifyPage")
      .add({ ...logData, timestamp })
      .then((docRef) => {
        console.log("Document written with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document:", error);
      });
    // IF THERE IS ERROR THEN ADD INFORMATION IN USERS COLLECTIONS
    if (error) {
      const userInfo = {
        errors: paymentMethodMsg,
      };
      db.collection("users")
        .doc(userService.getUserId())
        .set(userInfo, { merge: true });
    }
  };

  const onSubmit = async (data) => {
    setErrorMessage("");
    try {
      setLoader(true);
      userService.setKeyValueLocalstorage("fullName", data.fullName);
      addDataInLog("payment button clicked");
      fbEventsService.sendFbEvents(
        INITIATE_CHECKOUT,
        INITIATE_CHECKOUT,
        INITIATE_CHECKOUT
      );
      await createSubscription(data);
    } catch (error) {
    } finally {
      setLoader(false);
    }
  };

  const stripe = useStripe();
  const elements = useElements();
  const createSubscription = async () => {
    try {
      const cardElement = elements.getElement("card");
      const selectedPlan = activePlanType === "monthly" ? planCardsObj.monthly[activeTab - 1] : planCardsObj.yearly[activeTab - 1];
      // Check if the card element is valid
      if (!cardElement || !cardElement._complete) {
        setErrorMessage("Please enter a valid card information");
        return;
      }

      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });
      console.log("paymentMethod", paymentMethod)

      const objUserSubscribe = {
        userId: userService.getUserId(),
        name: userService.getValueFromLocalstorage("fullName"),
        email: userService.getValueFromLocalstorage("signupEmail"),
        paymentMethod: paymentMethod.paymentMethod.id,
        paymentMethodFunding: paymentMethod.paymentMethod.card.funding,
        priceId: selectedPlan.priceId,
        planName: selectedPlan.title + " " + selectedPlan.planType,
        environment: process.env.REACT_APP_STATUS,
        page: "VerifyAccountPage",
        coupon: userService.getValueFromLocalstorage("promoCode"),
        isTrial: true,
      };
      addDataInLog(paymentMethod.paymentMethod.id + " Credit Funding =>" + paymentMethod.paymentMethod.card.funding);
      if (paymentMethod.paymentMethod.card.funding === "debit" || paymentMethod.paymentMethod.card.funding === "credit") {
        userService.removeKeyLocalstorage("promoCode"); // We are removing promocode once used
        // Trial period $19.99 USD with 3 day trial period
        // requestStripeSubscribeTrialPeriodDynamicPriceCoupon
        // requestStripeSubscribeTrialPeriodDynamicPrice
        await API_FIREBASE.post(
          "requestStripeSubscribeTrialPeriodDynamicPriceCoupon",
          objUserSubscribe,
          {
            headers: {
              authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
            },
          }
        ).then(async (res) => {
          if (res.data.clientSecret) {
            const confirm = await stripe.confirmCardPayment(
              res.data.clientSecret
            );
            //if (confirm.error) return alert("Payment unsuccessful!");
            if (confirm.error) {
              setLoader(false);
              addDataInLog("Payment unsuccessfull");
              setErrorMessage("Payment unsuccessfull");
              return;
            }
            fbEventsService.sendFbEvents(START_TRIAL, START_TRIAL, START_TRIAL);
            addDataInLog("Payment Successfull");
            window.location.href = "/success";
            setSuccessMessage("Payment Successful! Subscription active.");
            return;
          } else if (res.data.latestInvoiceId) {
            // on trial case, it will fall here. so add data in settings
            userService.setKeyValueLocalstorage("userSettings", {
              downloadImageCountLimit:
                constants.APPLICATION_INFO.DOWNLOAD_IMAGE_TRIAL_USER_COUNT,
            });
            addDataInLog("Payment Successfull");
            window.location.href = "/success";
            setSuccessMessage("Payment Successful! Subscription active.");
            return;
          } else if (res.data.message) {
            addDataInLog(res.data.message, true);
            setErrorMessage(res.data.message);
            return;
          }
        });
      } else {
        addDataInLog("Card funding issue" + paymentMethod.paymentMethod.card.funding);
        setErrorMessage("Pre-paid cards are not allowed. Please input a valid credit card.");
      }
    } catch (err) {
      console.error(err);
      addDataInLog("Payment failed!");
      setErrorMessage("Payment failed!");
      return;
    }
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#fff", // Text color
        fontFamily: "Arial, sans-serif",
        height: "100%",
        fontSize: "18px",
        "::placeholder": {
          color: "#aab7c4", // Placeholder color
        },
      },
      // invalid: {
      //   color: '#eb1c26', // Invalid text color
      // },
    },
  };

  return (
    <div className="group-free-trail-parent">
      <div className="frame-free-trail-parent">
        <div className="new-trail-frame-wrapper2 font-family-sans-serif">
          <div className="free-trail-frame-wrapper">
            {loading && (
              <div className="text-center">
                <SpinnerLoader></SpinnerLoader>
                <p className="text-center-normal-mar30">
                  <b>{pageMessage}</b>
                </p>
              </div>
            )}
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-12  col-lg-12">
                  <div className="col-md-4  col-lg-4  show-in-desktop-only">
                    {/* <ImageWithoutThumb /> */}
                    {/* <img src={sample_gif} alt="sample-gif" style={{ width: "65%", borderRadius: "20px", marginTop: "130px" }} />
                    <p className="text-size-30 mt-10 font-italic">Pay only if</p>
                    <p className="text-size-30 mt-10 font-italic">you love it!</p>*/}
                  </div>
                  <div className="col-xs-12 justifyCenter">


                    <div className="col-md-5  col-lg-4 mt-40 mt-80-mobile">
                      <h3 className="adspire-free-trail-3 font-35">
                        Verify Account
                      </h3>



                      <p className="text-size-24 mt-10 ">
                        3 days free, then {defaultSelectedPlan.priceTitle}
                      </p>

                      <p className="text-size-24 mt-10 ">
                        You will <span className="underline">not</span> be charged
                        for your free trial.
                      </p>

                      {userService.getValueFromLocalstorage("remoteConfig").isShowTrialPrice && (<p className="font-family-sans-serif text-size-16 text-light mt-10">3 days free, then $49.99/mo.<span className="text-weight-600 gradient-text-green-blue-lite"> Cancel Anytime. </span></p>)}

                      {/*<p className="font-15-italic mt-10">{TRUSTED_BY_MSG}</p>*/}

                      {errorMessage && (
                        <p className="font-22 mt-10">
                          <b className="error-message text-center">
                            {" "}
                            {errorMessage}
                          </b>
                        </p>
                      )}
                      {successMessage && (
                        <p className="font-22 mt-10">
                          <b className="text-center"> {successMessage}</b>
                        </p>
                      )}
                      <div className="try-3-days-for-free-parent mt-20 px-20">
                        <div className="then-34999year">
                          <div className="container-fluid">
                            <div className="top-contentDIv text-left">
                              <div className="">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="form-row">
                                    <div className="form-group col-5">
                                      <input
                                        name="fullName"
                                        placeholder="Card Holder Full Name"
                                        type="text"
                                        {...register("fullName")}
                                        className={`text-size-18 border-none custom-input-radius modal-gradient- ${errors.fullName ? "is-invalid" : ""
                                          }`}
                                      />
                                      <div className="invalid-feedback">
                                        {errors.fullName?.message}
                                      </div>
                                    </div>
                                  </div>

                                  <div className="form-row">
                                    <div className="form-group col">
                                      <div className="form-group-input verify-account-card-input">
                                        <CardElement
                                          options={cardElementOptions}
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {!loading && (
                                    <div className="form-row submit-signup-button">
                                      <StyledButton3
                                        btnHeight="70px"
                                        btnTextStyle="font-22 text-weight-800"
                                        type="submit"
                                        name={"Complete Registration"}
                                      />
                                    </div>
                                  )}
                                  <div className="flex content-between align-items-center">
                                    <p className="text-light-gray text-weight-600">
                                      <i
                                        className="fa fa-lock"
                                        aria-hidden="true"
                                      ></i>{" "}
                                      Encrypted, safe & secure
                                    </p>
                                    <img
                                      src={powered_by_stripe}
                                      alt="powered-by-stripe"
                                    />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {userService.getValueFromLocalstorage("remoteConfig")
                        .verifyAccountTierSlider && (
                          <div className="tabs-styling">
                            <div className="tab-content">
                              {activeTab === 1 && (
                                <div className="content-style">
                                  3 days free, then $19.99/mo.{" "}
                                  <span>Cancel Anytime.</span>
                                </div>
                              )}
                              {activeTab === 2 && (
                                <div className="content-style">
                                  3 days free, then $49.99/mo.{" "}
                                  <span>Cancel Anytime.</span>
                                </div>
                              )}
                              {activeTab === 3 && (
                                <div className="content-style">
                                  3 days free, then $199.99/mo.{" "}
                                  <span>Cancel Anytime.</span>
                                </div>
                              )}
                            </div>
                            <div className="tab-header">
                              <div
                                className={`tab-item ${activeTab === 1 ? "active" : ""
                                  }`}
                                onClick={() => handleTabClick(1)}
                              >
                                10 Ads <div className="bottom-sizing">/mo </div>
                              </div>
                              <div
                                className={`tab-item ${activeTab === 2 ? "active" : ""
                                  }`}
                                onClick={() => handleTabClick(2)}
                              >
                                50 Ads <div className="bottom-sizing">/mo </div>
                              </div>
                              <div
                                className={`tab-item ${activeTab === 3 ? "active" : ""
                                  }`}
                                onClick={() => handleTabClick(3)}
                              >
                                500 Ads <div className="bottom-sizing">/mo </div>
                              </div>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-4 col-lg-4 show-in-mobile-only">
                    {/* <ImageWithoutThumb /> */}
                    {/*<img src={sample_gif} alt="sample-gif" style={{ width: "80%", borderRadius: "20px", marginTop: "35px" }} />*/}
                  </div>
                  <div className="col-md-4 col-lg-4">
                    {/* <ImageSalesWithoutThumb />*/}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-subscriber-carousal-container">
            <NewSubscriberCarousal />
          </div>
          {/*<p className="the-disclaimer position-relative z-index-1">By signing up you agree to our <span className="text-underline cursor-pointer"><Link to="/legal/tos" target="_blank" className="th"> Terms & Service</Link></span> and <span className="text-underline cursor-pointer"><Link to="/legal/privacy" target="_blank" className="th"> Privacy Policy</Link>. </span></p>{'\u00A0'}
          <p className="the-disclaimer position-relative z-index-1"> including the way we collect and process your personal data.</p>*/}
        </div>
      </div>
    </div>
  );
};
export default VerifyAccountPaymentPlan;

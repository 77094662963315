import React from 'react';
import { useNavigate } from "react-router-dom";
import back from "../../../images/ad_goal_assets/back.svg";

const ConceptBackButton = ({ alt, className, redirectPage }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    if (window.location.href.includes('home') === true) {
      window.location.reload();
    } else if(redirectPage) {
      navigate("/" + redirectPage);
    }
  };

  return (
    <img
      src={back}
      alt={alt}
      className={className}
      onClick={handleClick}
    />
  );
};

export default ConceptBackButton;

import React from "react";
import _00113000x3000_4_2x from "../../../images/landing_page_assests/00113000x3000-4@2x.png";
import _00113000x3000_41_2x from "../../../images/landing_page_assests/00113000x3000-41@2x.png";
import _00113000x3000_42_2x from "../../../images/landing_page_assests/00113000x3000-42@2x.png";
import _00113000x3000_43_2x from "../../../images/landing_page_assests/00113000x3000-43@2x.png";
import _3rd_section_bg from "../../../images/landing_page_assests/3rd-section-bg.svg";
import grediant1 from "../../../images/landing_page_assests/grediant1.svg";
import rectangle_bg_purple from "../../../images/newRoas/rectangle_bg_purple.svg";
import rectangle_bg_blue from "../../../images/newRoas/rectangle_bg_blue.svg";
import useWindowSize from "../../../_helper/ScreenUtil";


const RoasNew_1 = () => {
  const { width } = useWindowSize();
  const isMobile = width <= 767;
  const isIpad = width <= 1024;

  return (
    <div className="roas new-roas-backgournd" style={{ maxHeight: isIpad ? "" : "900px" }}>
      <div className="group-parent">
        <div className="adspire-your-roas-to-82x-and-parent">
          <div className="adspire-your-roas-container ">
            <span className="unlimited-caption-txt-container">
              <span className="adspire-your-new-roas">Adspire<sup>®</sup> your</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">ROAS</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">to</span>
              <span>{` `}</span>
              <span className="x">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">and </span>
              <span className="adspire-your-new-roas cursor-pointer">Beyond!</span>
            </span>
          </div>
          <div className="x-wrapper">
            <div className="image-ads">8.2X</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className={isMobile ? "" : `new-roas-text-box-container`}>
              <div className={isMobile ? "col-12 col-md-12 col-lg-12" : isIpad ? "col-12 col-md-12 col-lg-12" : `col-12 col-md-12 col-lg-12 flex content-evenly`}>

                <div className="new-roas-content">
                  <div className={isMobile ? "mb-negative-80" : isIpad ? "mb-negative-80" : ""}>
                    <img src={rectangle_bg_purple} alt="rectangle_bg_purple" className={isMobile ? "width-100-percent" : ""} />
                    <div className="roas-box-text-container">
                      <pre className={isMobile ? `text-size-22` : isIpad ? "text-size-40" : ""}>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          Adspire® user reach
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          <span className="text-white text-size-50 text-weight-600">8.2X</span> higher ROAS on
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          average using their
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          generated AI ads!
                        </p>
                      </pre>
                    </div>
                  </div>
                </div>
                <div className="new-roas-content">
                  <div className={isMobile ? "mb-negative-80" : isIpad ? "mb-negative-80" : ""}>
                    <img src={rectangle_bg_blue} alt="rectangle_bg_purple" className={isMobile ? "width-100-percent" : ""} />
                    <div className="roas-box-text-container">
                      <pre className={isMobile ? `text-size-22` : isIpad ? "text-size-40" : ""}>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          <span className="text-white text-size-50 text-weight-600">96%</span>
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          of Adspire® users increase
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          their CTR significantly in
                        </p>
                        <p className="text-light-gray-200 font-family-vietnam-pro m-0">
                          their 1st month!
                        </p>
                      </pre>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <img className="grediant-icon1" alt="" src={grediant1} />
    </div>
  );
};
export default RoasNew_1;
















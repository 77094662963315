import secureLocalStorage from "react-secure-storage";
import { BehaviorSubject } from "rxjs";
import { auth } from "../utils/Firebase";

import constants, {
  priceTestingDownloadIds,
  priceLiveDownloadIds,
} from "../utils/constants";
import { updateUsersCollection } from "../utils/Firebase.collection.helper";
import { UPDATE_DOWNLOAD_COUNT } from "../redux/action/action";
const userSubject = new BehaviorSubject();
const downloadCount = 0;

export const userService = {
  user: userSubject.asObservable(),
  get userValue() {
    return userSubject.value;
  },
  login,
  logout,
  isLoggedInUser,
  getUserInfo,
  setIsLoggedInUser,
  setKeyValueLocalstorage,
  getValueFromLocalstorage,
  removeKeyLocalstorage,
  checkCredits,
  isSubscribed,
  clearQuestionFlowData,
  isContentAllowed,
  //subscribeStatus,
  googlelogin,
  isOldUser,
  isNewUser,
  getUserId,
  isRealLoggedInUser,
  getUserName,
  getLoginMethodAndStatus,
  showLogoutBtn,
  getEmailId,
  isPurchaser,
  isFreeSetUser,
  isUserFreeAdsStatus,
  getSubscribePageName,
  clearSavedData,
  clearConceptFlowData,
  getHomePage,
  getNewAdPath,
  isPaidUser,
  getUserRedirectPage,
  getUserHomeRedirectPage,
  getPricingRedirectPage,
  checkLoggedInUserAndRedirect,
  getUserRedirectPageAfterSignup,
  isTrialPeriodForPaidUser,
  fullAccesslUser,
  downloadLeftImage,
  downloadImageCountDeduct,
  getUserBackPage,
  setUserBackPage,
  getDownloadCount,
  getPlanInfoBasedOnPriceId,
  clearDataAfterReferesh,
  getMonthlyYearly,
  redirectToLogin
};

function isContentAllowed() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes

  if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("socialLoginStatus") === "yes"
  ) {
    return true;
  } else if (
    secureLocalStorage.getItem("loginMethod") ===
    constants.LOGIN_TYPE_ANNONYMOUS
  ) {
    return false;
  } else if (
    (secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_GOOGLE ||
      secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_FACEBOOK ||
      secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_APPLE) &&
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("socialLoginStatus") === "yes"
  ) {
    return true;
  }
  return false;
}

function isSubscribed() {
  if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    userService.getValueFromLocalstorage("isTrialPeriod") === "yes"
  ) {
    return true;
  }
  return false;
}

function isPurchaser() {
  if (secureLocalStorage.getItem("purchaser") === "yes") {
    return true;
  } /* else if (parseInt(secureLocalStorage.getItem("userCredits")) > 0) {
    return true;
  }  */
  return false;
}

function isFreeSetUser() {
  if (secureLocalStorage.getItem("generatedAdsNumber") !== "paiduser") {
    return true;
  }
  return false;
}

function isPaidUser() {
  if (secureLocalStorage.getItem("purchaser") === "yes") {
    return true;
  }
  return false;
}

// HERE WE CHECK EITHER USER IS TRAIL PERIOD OR TRIAL PERIOD OVER FOR PAID USER
function isTrialPeriodForPaidUser() {
  if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    secureLocalStorage.getItem("isTrialPeriod") === "yes"
  ) {
    return true;
  } else if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    secureLocalStorage.getItem("isTrialPeriod") === "no"
  ) {
    return false;
  }
  return true;
}

function fullAccesslUser() {
  if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    secureLocalStorage.getItem("isTrialPeriod") === "no"
  ) {
    return "yes";
  } else if (
    secureLocalStorage.getItem("purchaser") === "yes" &&
    secureLocalStorage.getItem("isTrialPeriod") === "yes" &&
    downloadLeftImage() > 0
  ) {
    return "trialUser";
  }
  return "no";
}



// THERE IS TWO CASE HERE, 1 FOR TRIAL USER, TRAIL USER CAN ONLY DOWNLOAD 5ADS
// PAID USER CAN DOWNLOAD MONTHLY ASSIGNED ADS BASED ON PLAN
function downloadLeftImage() {
  const downloadCount = userService.getValueFromLocalstorage("downloadCount");
  if (downloadCount > 0) {
    return downloadCount;
  }
  return 0;
}

// THERE IS TWO CASE HERE, 1 FOR TRIAL USER, TRAIL USER CAN ONLY DOWNLOAD 5ADS
// PAID USER CAN DOWNLOAD MONTHLY ASSIGNED ADS BASED ON PLAN
function getPlanInfoBasedOnPriceId(priceId) {
  const priceDownloadInfo = {
    ...priceTestingDownloadIds,
    ...priceLiveDownloadIds,
  };
  return priceDownloadInfo[priceId];
}



function getMonthlyYearly(text) {
  // Check if text is undefined
  if (text === undefined) {
    return null;
  }
  // Create a regular expression pattern to match "monthly" or "yearly" (case-insensitive)
  const pattern = /\b(monthly|yearly)\b/i;

  // Test if the pattern matches the text
  const match = text.match(pattern);

  // If there's a match, return "mo" for monthly, "yr" for yearly, else return null
  if (match) {
    return match[0].toLowerCase() === 'monthly' ? 'mo' : 'yr';
  } else {
    return null;
  }
}

// Deduct the image download count for Trial user / Paid User
function downloadImageCountDeduct() {
  let downloadCount = 0;
  downloadCount = userService.getValueFromLocalstorage("downloadCount");
  downloadCount = parseInt(downloadCount - 1);
  userService.setKeyValueLocalstorage("downloadCount", downloadCount);
  if (isTrialPeriodForPaidUser()) {
    updateUsersCollection({ downloadTrialCount: downloadCount }); // update firebase
    return downloadCount;
  } else if (!isTrialPeriodForPaidUser()) {
    // To deduct the count, we need to get data from Firebase collection the substract it for
    // Better result.
    updateUsersCollection({ downloadCount: downloadCount }); // update firebase
    return downloadCount;
  }
}

export const updateDownloadCountRedux = (downloadCount) => ({
  type: UPDATE_DOWNLOAD_COUNT,
  payload: downloadCount,
});

function getDownloadCount() {
  return userService.getValueFromLocalstorage("downloadCount");
}

function isUserFreeAdsStatus() {
  if (secureLocalStorage.getItem("generatedAdsNumber") === "paiduser") {
    return true;
  } else if (secureLocalStorage.getItem("generatedAdsNumber") === 1) {
    return false;
  }
  return true;
}

function getSubscribePageName() {
  return "subscriptions";
}

function getHomePage() {
  return "home";
}

function getNewAdPath() {
  return "home";
}

function isNewUser() {
  if (
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("socialLoginStatus") === "yes"
  ) {
    return false;
  } else if (
    secureLocalStorage.getItem("userLoginStatus") === "no" ||
    secureLocalStorage.getItem("isFirstTimeUser") === "yes"
  ) {
    return true;
  }
  return true;
}

function isOldUser() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  if (secureLocalStorage.getItem("userLoginStatus") === "no") {
    return false;
  } else if (
    secureLocalStorage.getItem("isFirstTimeUser") === "no" ||
    isSubscribed()
  ) {
    return true;
  }
  return false;
}

function login(userInfo) {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  userSubject.next(userInfo);
  secureLocalStorage.setItem("user", JSON.stringify(userInfo));
  secureLocalStorage.setItem("userId", userInfo?.userId);
  secureLocalStorage.setItem("loginMethod", constants.LOGIN_TYPE_ANNONYMOUS);
  secureLocalStorage.setItem("userLoginStatus", "yes");
  return userInfo;
}

function googlelogin(userInfo) {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  userSubject.next(userInfo);
  secureLocalStorage.setItem("loginGoogleInfo", JSON.stringify(userInfo));
  secureLocalStorage.setItem("name", userInfo.displayName);
  secureLocalStorage.setItem("email", userInfo.email);
  secureLocalStorage.setItem("providerPic", "userInfo");
  secureLocalStorage.setItem("loginMethod", constants.LOGIN_TYPE_GOOGLE);
  secureLocalStorage.setItem("socialLoginStatus", "yes");
  secureLocalStorage.setItem("userLoginStatus", "yes");
}

function getEmailId() {
  return secureLocalStorage.getItem("email")
    ? secureLocalStorage.getItem("email")
    : "";
}

function showLogoutBtn() {
  if (
    (secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_GOOGLE ||
      secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_FACEBOOK ||
      secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_APPLE) &&
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("socialLoginStatus") === "yes"
  ) {
    return true;
  } else {
    return false;
  }
}


function clearDataAfterReferesh() {
  userService.removeKeyLocalstorage("ideas");
}

function clearQuestionFlowData() {
  userService.removeKeyLocalstorage("isSubscribed");
  userService.removeKeyLocalstorage("typeValue");
  userService.removeKeyLocalstorage("adCategoryQuestion");
  userService.removeKeyLocalstorage("userIdeaHistoryId");
  userService.removeKeyLocalstorage("style");
  userService.removeKeyLocalstorage("adCategoryValue");
  userService.removeKeyLocalstorage("typeIDOfAdValue");
  userService.removeKeyLocalstorage("styleId");
  userService.removeKeyLocalstorage("ideas");
  userService.removeKeyLocalstorage("captions");
  userService.removeKeyLocalstorage("categoryName");
  userService.removeKeyLocalstorage("adCategoryID");
  userService.removeKeyLocalstorage("input");
  userService.removeKeyLocalstorage("categoryTypeValue");
  userService.removeKeyLocalstorage("savingTime");
  userService.removeKeyLocalstorage("text");
  userService.removeKeyLocalstorage("userCredits");
  userService.removeKeyLocalstorage("overLayImageBase64");
}

function clearConceptFlowData() {
  userService.removeKeyLocalstorage("ConceptAdProduct");
}

async function clearSavedData() {
  clearQuestionFlowData();
  userService.removeKeyLocalstorage("realPlan");
  userService.removeKeyLocalstorage("adListingPageBackButton");
  userService.removeKeyLocalstorage("planAmount");
  userService.removeKeyLocalstorage("isFirstTimeUser");
  userService.removeKeyLocalstorage("generatedAdsNumber");
  userService.removeKeyLocalstorage("question");
  userService.removeKeyLocalstorage("uploadInputLoading");
  userService.removeKeyLocalstorage("paymentStatus");
  userService.removeKeyLocalstorage("darkColor");
  userService.removeKeyLocalstorage("planDuration");
  userService.removeKeyLocalstorage("reviewName");
  userService.removeKeyLocalstorage("ExpiryDate");
  userService.removeKeyLocalstorage("conceptAdInput");
  userService.removeKeyLocalstorage("redirectPage");
  userService.removeKeyLocalstorage("loginGoogleInfo");
  userService.removeKeyLocalstorage("isTrialPeriod");
  userService.removeKeyLocalstorage("user");
  userService.removeKeyLocalstorage("product_id");
  userService.removeKeyLocalstorage("name");
  userService.removeKeyLocalstorage("plan");
  userService.removeKeyLocalstorage("purchaser");
  userService.removeKeyLocalstorage("quickFreeTrial");
  userService.removeKeyLocalstorage("userSelectedPlan");
  userService.removeKeyLocalstorage("loginMethod");
  userService.removeKeyLocalstorage("email");
  userService.removeKeyLocalstorage("storeImages");
  userService.removeKeyLocalstorage("generatedAdsNumber");
  userService.removeKeyLocalstorage("creativeAIheadline");
  userService.removeKeyLocalstorage("creativeAIdescription");
  userService.removeKeyLocalstorage("creativeAIcta");
  userService.removeKeyLocalstorage("bulletpoints");
  userService.removeKeyLocalstorage("userId");
  userService.removeKeyLocalstorage("uploadInput");
  userService.removeKeyLocalstorage("uploadId");
  userService.removeKeyLocalstorage("imageName");
  userService.removeKeyLocalstorage("quickAdResponse");
  userService.removeKeyLocalstorage("userSelectedAdTypePage");
  userService.removeKeyLocalstorage("providerPic");
  userService.removeKeyLocalstorage("promptImage");
  userService.removeKeyLocalstorage("reviewMessage");
  userService.removeKeyLocalstorage("model");
  userService.removeKeyLocalstorage("customeAdOptionInputClicked");
  userService.removeKeyLocalstorage("conceptAdFormattedInput");
  userService.removeKeyLocalstorage("userSelectedConceptAdTypeObj");
  userService.removeKeyLocalstorage("imageHeight");
  userService.removeKeyLocalstorage("fullName");
  userService.removeKeyLocalstorage("bulletpoints");
  userService.removeKeyLocalstorage("lightColor");
  userService.removeKeyLocalstorage("planSettingText");
  userService.removeKeyLocalstorage("subscriptionId");
  userService.removeKeyLocalstorage("planInfoPerDuration");
  userService.removeKeyLocalstorage("isTrialPeriod");
  userService.removeKeyLocalstorage("CancelDate");
  userService.removeKeyLocalstorage("downloadCount");
  userService.removeKeyLocalstorage("ctaPreview");
  userService.removeKeyLocalstorage("userLoginStatus");
  userService.removeKeyLocalstorage("defaultUserSelectedPlan");
  userService.removeKeyLocalstorage("searchQuery");
}

function setIsLoggedInUser() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  secureLocalStorage.setItem("userLoginStatus", "yes");
  return true;
}

function getUserInfo() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  return secureLocalStorage.getItem("userId");
}

function getUserId() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  if (
    secureLocalStorage.getItem("userId") === undefined ||
    !secureLocalStorage.getItem("userId")
  ) {
    return false;
  } else {
    return secureLocalStorage.getItem("userId");
  }
}

function isLoggedInUser() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  if (secureLocalStorage.getItem("userId")) {
    return true;
  }
  return false;
}

function getUserName() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  if (
    secureLocalStorage.getItem("name") &&
    secureLocalStorage.getItem("userId")
  ) {
    return secureLocalStorage.getItem("name");
  } else {
    return "";
  }
}

function isRealLoggedInUser() {
  if (
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("socialLoginStatus") === "yes" &&
    secureLocalStorage.getItem("user") &&
    secureLocalStorage.getItem("userId") &&
    (secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_GOOGLE ||
      secureLocalStorage.getItem("loginMethod") ===
      constants.LOGIN_TYPE_FACEBOOK ||
      secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_APPLE)
  ) {
    return true;
  } else if (
    secureLocalStorage.getItem("userLoginStatus") === "yes" &&
    secureLocalStorage.getItem("user") &&
    secureLocalStorage.getItem("userId") &&
    secureLocalStorage.getItem("purchaser") === "yes"
  ) {
    return true;
  }
  return false;
}

async function logout() {
  secureLocalStorage.setItem("userLoginStatus", "no");
  secureLocalStorage.setItem("socialLoginStatus", "no");
  await clearSavedData();
  await auth.signOut();
  userSubject.next(null);
  return true;
}

function checkCredits() {
  if (secureLocalStorage.getItem("purchaser") === "yes") {
    return true;
  }
  return false;
  /* if (secureLocalStorage.getItem("purchaser") === "yes") {
    return true;
  } else if (parseInt(secureLocalStorage.getItem("userCredits")) > 0) {
    return true;
  } else if (
    parseInt(secureLocalStorage.getItem("userCredits")) > 0 &&
    (secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_GOOGLE ||
      secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_FACEBOOK ||
      secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_APPLE)
  ) {
    return true;
  } 
    return false;*/
}

function setKeyValueLocalstorage(k, v) {
  secureLocalStorage.setItem(k, v);
}

function getLoginMethodAndStatus() {
  if (
    secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_GOOGLE ||
    secureLocalStorage.getItem("loginMethod") ===
    constants.LOGIN_TYPE_FACEBOOK ||
    secureLocalStorage.getItem("loginMethod") === constants.LOGIN_TYPE_APPLE
  ) {
    return true;
  }
  return false;
}

function getValueFromLocalstorage(k) {
  return secureLocalStorage.getItem(k) ? secureLocalStorage.getItem(k) : "";
}

function removeKeyLocalstorage(k) {
  secureLocalStorage.removeItem(k);
}

function getPricingRedirectPage(pageName = "") {
  if (!userService.isLoggedInUser()) {
    return "/signup";
  } else {
    return "/" + pageName;
  }
}

function getUserRedirectPage(pageName = "") {
  if (!userService.isLoggedInUser()) {
    return "/signup";
  } else if (pageName === "tryforfree" && userService.isPaidUser()) {
    return "/pricing";
  } else if (userService.isLoggedInUser() && userService.isPaidUser()) {
    return "/home";
  } else {
    return "/pricing";
  }
}

function getUserHomeRedirectPage() {
  if (userService.isPurchaser()) {
    return "/home";
  } else {
    return "/pricing";
  }
}

function getUserBackPage() {
  return userService.getValueFromLocalstorage("adListingPageBackButton");
}
function setUserBackPage(pagename) {
  return userService.setKeyValueLocalstorage(
    "adListingPageBackButton",
    pagename
  );
}

function checkLoggedInUserAndRedirect() {
  const userId = userService.getUserId();

  if (
    !userId &&
    (window.location.pathname === "/signup" ||
      window.location.pathname === "/login")
  ) {
  } else if (
    userId &&
    (window.location.pathname === "/signup" ||
      window.location.pathname === "/login")
  ) {
    return getUserRedirectPage();
  }
}

function getUserRedirectPageAfterSignup(pageName = "") {
  const isLoggedIn = userService.isLoggedInUser();
  const isPaid = userService.isPaidUser();
  const remoteConfig = userService.getValueFromLocalstorage("remoteConfig");

  if (!isLoggedIn) {
    return "/signup";
  }

  if (pageName === "tryforfree" && isPaid) {
    return "/pricing";
  }

  if (isLoggedIn && isPaid) {
    return "/home";
  }

  if (remoteConfig.stripeCreditCardPage) {
    return "/pricing";
  }

  return "/verify-account";
}

function redirectToLogin() {
  // publish user to subscribers and store in local storage to stay logged in between page refreshes
  if (!secureLocalStorage.getItem("userId")) {
    logout();
    window.location.href = "/signup";
  }
}
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';

let firebaseConfig = {
  apiKey:             process.env.REACT_APP_FB_API_KEY,
  authDomain:         process.env.REACT_APP_FB_AUTH_DOMAIN,
  projectId:          process.env.REACT_APP_FB_PROJECT_ID,
  storageBucket:      process.env.REACT_APP_FB_STORAGE_BUCKET,
  messagingSenderId:  process.env.REACT_APP_FB_MESSAGING_SENDER_ID,
  appId:              process.env.REACT_APP_FB_APP_ID,
  measurementId:      process.env.REACT_APP_FB_MEASUREMENT_ID,
};

// Use this to initialize the firebase App
const firebaseApp = firebase.initializeApp(firebaseConfig);

// Use these for db & auth
export const db = firebaseApp.firestore();
export const auth = firebase.auth();
export const admin = firebase;
import React from "react";
import group_1000000975 from "../../../images/landing_page_assests/group-1000000975.svg";
import vector_4 from "../../../images/landing_page_assests/vector-4.svg";

import grediant1 from "../../../images/landing_page_assests/grediant1.svg";
const Roas = (props) => {
  return (
    <div className="roas">
      <div className="rd-section-bg" />
      <div className="group-parent">
        <div className="adspire-your-roas-to-82x-and-parent">
          <div className="adspire-your-roas-container">
            <span className="unlimited-caption-txt-container">
              <span className="adspire-your">Adspire® your</span>
              <span>{` `}</span>
              <span className="roas1">ROAS</span>
              <span>{` `}</span>
              <span className="adspire-your">to</span>
              <span>{` `}</span>
              <span className="x">8.2X</span>
              <span>{` `}</span>
              <span className="adspire-your">and </span>
              <span className="adspire-your-beyond cursor-pointer">Beyond!</span>
            </span>
          </div>
          <div className="x-wrapper">
            <div className="image-ads">8.2X</div>
          </div>
        </div>
        <div className="group-container">
          <div className="vector-parent">
            <img className="group-child" alt="" src={vector_4} />
            <div className="frame-wrapper1">
              <div className="frame-child5" />
            </div>
          </div>
          <div className="adspire-user-reach-container">
            <p className="adspire-user-reach">{`Adspire® users reach `}</p>
            <p className="x-higher-roas-on-average-using">
              <span className="span">
                <b className="x3">8.2X</b>
              </span>
              <span className="higher-roas-on-average-using-t">
                <span className="span">{` `}</span>
                <span>
                  higher ROAS on average using their AI-generated ads!
                </span>
              </span>
            </p>
          </div>
          <img className="group-icon" alt="" src={group_1000000975} />
          <div className="of-adspire-users-container">
            <p className="x-higher-roas-on-average-using">
              <b className="b">{`96% `}</b>
            </p>
            <p className="of-adspire-users-increase-thei">
              <span>
                <span className="higher-roas-on-average-using-t">{`of Adspire® users increase their CTR`} <span className="underline text-weight-600">significantly</span> {`in their `}</span>
                <b className="st-month">1st month!</b>
              </span>
            </p>
          </div>
        </div>
      </div>
      <img className="grediant-icon1" alt="" src={grediant1} />
    </div>
  );
};
export default Roas;
















import React, { useEffect, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useLocation } from "react-router-dom";
import useWindowSize from "../../../_helper/ScreenUtil";
import { fetchSalesCollection } from "../../../utils/Firebase.collection.helper";

// ... (import other images);
const ImageSlider = () => {
  const [imageSlider, setImageSlider] = useState([]);
  const [carouselClass, setCarouselClass] = useState("carousel-div");
  const location = useLocation();
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  useEffect(() => {
    let isMounted = true; // Flag to track whether the component is mounted
    const fetchData = async () => {
      try {
        const dataReceivedBrand = await fetchSalesCollection();

        if (isMounted) {
          setImageSlider(dataReceivedBrand[0]?.data.adImageURLs);
        }
      } catch (error) {
        console.error("Error fetching sales collection:", error);
        // Handle error
      }
    };
    fetchData();
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    if (location.pathname == "/ai-ads") {
      setCarouselClass("carousel-div-50");
    }
  }, [location.pathname]);

  // Create a ref to store the button element
  const nextButtonRef = useRef(null);

  // useEffect hook to trigger the button click after component has loaded
  useEffect(() => {
    // Check if the button reference exists
    if (nextButtonRef.current) {
      // Trigger the click event
      nextButtonRef.current.click();
    }
  }, [imageSlider]);

  return (
    <div className="carousel-zig-zag thumb-hide">
      {imageSlider && (
        <Carousel
          infiniteLoop={true}
          selectedItem={0}
          interval={2000}
          showThumbs={false}
          autoPlay={false}
          centerMode
          centerSlidePercentage={30}
        >
          {imageSlider?.map((url, index) => (
            <div className={carouselClass} key={index}>
              <img
                key={index}
                src={url}
                alt={`${index}`}
                width={80}
                style={{
                  margin: "20px",
                  transform: `rotate(${index % 2 === 0 ? 10 : -10}deg)`,
                }}
              />
            </div>
          ))}
        </Carousel>
      )}
    </div>
  );
};

export default ImageSlider;

import { isIOSDevice } from "ios-detector";
import React, { useEffect } from "react";
import { getWebsiteTitle } from "../../utils/function";
import { metaDesc } from "../../utils/landingConstants";

import "./css/Landing-page.css";


import IOSMessageModal from "../common/popup/IOSMessageModal";
import FooterLanding from "./common/FooterLanding";
import SubHeaderSmall from "./common/SubHeaderSmall";

import SubHeaderCarousel from "./common/SubHeaderCarousel";
import SubHeaderLandingVideoFlow from "./common/SubHeaderLandingVideoFlow";
import StripeForm from "../payment/StripeForm";



const LandingPageStripe = () => {

  // ************************* Other methods *****************************************
  document.title = getWebsiteTitle() + "AI Ads Generator";



  // ************************* First time redering *****************************************
  useEffect(() => {
    document.querySelector("meta[name='description']").setAttribute("content", metaDesc.AIADS);
  }, []);



  return (
    <>
      
      
              <StripeForm></StripeForm>
           
    </>
  );
};

export default LandingPageStripe;

import React from 'react'
import Formgroup from './formgroup'
import StyledButton1 from '../common/Button/StyledButton1'

const Modal = ({ formData, handleAdType, ShowPopup, handleChange, errorMessage, modalAddOnClass }) => {

    return (
        < div  className='col-xs-12  justifyCenter'>
            <div
         
                className={formData.length > 2 ? " modal-dialog justifyCenter col-xs-12 modal-dialog-centered text-open-sans  " : "modal-dialog top10 modal-dialog-centered text-open-sans"}
                role="document"
            >
                <div className="modal-content  modal-gradient-new font-family-vietnam-pro quick-ad-flow-modal ">
                    <div className={formData.length > 2 ? "quest-modal col-xs-12 form-group " : "modal-body text-center  form-group "}>
                       <div   >
   <Formgroup fields={formData} handleChange={handleChange} />
                        {formData.length > 2 ? (
                            <StyledButton1
                                onClick={handleAdType}
                                name={"Generate Ads"}
                                btnTheme={"light"}
                                btnHeight={"45px"}
                            />
                        ) : formData?.some((field) => !field.value) ? (
                            ""
                        ) : (
                            <StyledButton1 onClick={ShowPopup} name={"Next"} btnTheme={"light"} btnHeight={"45px"} />
                        )}
                       </div>
                     

                    </div>
                </div>
            </div>
            </div>
    )
}

export default Modal

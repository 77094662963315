import { userService } from "../services/user.service";
import { db } from "../utils/Firebase";
import {
  PAYPAL_MONTHLY_LITE_PLAN_NAME,
  PAYPAL_MONTHLY_LITE_PRICE,
  PAYPAL_MONTHLY_PRO_PLAN_NAME,
  PAYPAL_MONTHLY_PRO_PRICE,
  PAYPAL_MONTHLY_TRIAL_PLAN_NAME,
  PAYPAL_MONTHLY_TRIAL_PRICE,
  PAYPAL_MONTHLY_ULTRA_PLAN_NAME,
  PAYPAL_MONTHLY_ULTRA_PRICE,
  PAYPAL_YEARLY_LITE_PLAN_NAME,
  PAYPAL_YEARLY_LITE_PRICE,
  PAYPAL_YEARLY_PRO_PLAN_NAME,
  PAYPAL_YEARLY_PRO_PRICE,
  PAYPAL_YEARLY_TRIAL_PLAN_NAME,
  PAYPAL_YEARLY_TRIAL_PRICE,
  PAYPAL_YEARLY_ULTRA_PLAN_NAME,
  PAYPAL_YEARLY_ULTRA_PRICE,
  PLAN_MONTH,
  PLAN_TYPE_FREE_TRIAL,
  PLAN_TYPE_LITE,
  PLAN_TYPE_PRO,
  PLAN_TYPE_ULTRA,
  PLAN_YEAR,
  priceLiveIds,
  priceTestingIds, priceTestingDownloadIds, priceLiveDownloadIds, VALId_COUPAN_LIST
} from "./constants";

export const APPLICATION_INFO = {
  ADSPIRE_COLLECTION_API: process.env.REACT_APP_API_URL,
  ADSPIRE_API_HEADER_KEY: process.env.REACT_APP_API_HTTP_AUTH_KEY,
  APP_NAME: "Adspire",
  APP_TITLE_SEPERATOR_NAME: " - ",
};
/**
 * Separate number with comma
 *
 * @param {number} number
 *
 * @returns {string}
 */
export const numberWithCommas = (number) => {
  if (number === undefined || number === null) return 0;

  return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};
export const getWebsiteTitle = () => {
  return (
    APPLICATION_INFO.APP_NAME + "" + APPLICATION_INFO.APP_TITLE_SEPERATOR_NAME
  );
};

export const getWebsiteName = () => {
  return APPLICATION_INFO.APP_NAME;
};

export const showFirst10Words = (text, index) => {
  if (index > 0) {
    // Split the text into an array of words
    const words = text.split(/\s+/);
    // Take the first 10 words
    const first10Words = words.slice(0, 10);
    // Join the words back into a string
    return first10Words.join(" ") + "...";
  } else {
    return text;
  }
};

export const sortCategory = (categoryType) => {
  return categoryType.sort((a, b) => {
    const nameA = a.name.toLowerCase(); // ignore upper and lowercase
    const nameB = b.name.toLowerCase(); // ignore upper and lowercase
    const orderType = localStorage.getItem("orderType");
    if (orderType === "desc") {
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
    } else {
      if (nameA > nameB) {
        return 1;
      }
      if (nameA < nameB) {
        return -1;
      }
    }

    // names must be equal
    return 0;
  });
};

export const formatErrorMessage = (errorMessage) => {
  return errorMessage;
};

export const filterIdeas = (inputString) => {
  const completionTextArr = inputString
    .split(/\d+\./)
    .slice(1)
    .map((str) => str.trim());
  return completionTextArr;
};

export const toFixed = (num) => {
  return num.toFixed();
};

export const formatDate = (date) => {
  if (date) {
    return new Date(date.seconds * 1000).toLocaleString();
  } else {
    return "N/A";
  }
};

export const isTrialPeriodEnd = (purchaseDate, expiryDate = "") => {
  if (getDateDifference(purchaseDate, expiryDate) > 4) {
    return "yes";
  } else {
    return "no";
  }
};

export const getAmountInfo = (data) => {
  if (getDateDifference(data.purchaseDate, data.expiryDate) > 4) {
    return "$" + data.amount;
  } else {
    return getPlanName(data.productId, data.purchaseDate, data.expiryDate);
  }
};

export const getPlanName = (productId, purchaseDate, expiryDate) => {
  if (productId === PAYPAL_YEARLY_LITE_PLAN_NAME) {
    return PLAN_TYPE_LITE + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_PRO_PLAN_NAME) {
    return PLAN_TYPE_PRO + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_ULTRA_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_TRIAL_PLAN_NAME) {
    if (isTrialPeriodEnd(purchaseDate, expiryDate) === "yes") {
      return PLAN_TYPE_ULTRA + PLAN_YEAR;
    } else {
      return PLAN_TYPE_FREE_TRIAL + PLAN_YEAR;
    }
  } else if (productId === PAYPAL_MONTHLY_TRIAL_PLAN_NAME) {
    if (isTrialPeriodEnd(purchaseDate, expiryDate) === "yes") {
      return PLAN_TYPE_ULTRA + PLAN_MONTH;
    } else {
      return PLAN_TYPE_FREE_TRIAL + PLAN_MONTH;
    }
  } else if (productId === PAYPAL_MONTHLY_LITE_PLAN_NAME) {
    return PLAN_TYPE_LITE + PLAN_MONTH;
  } else if (productId === PAYPAL_MONTHLY_PRO_PLAN_NAME) {
    return PLAN_TYPE_PRO + PLAN_MONTH;
  } else if (productId === PAYPAL_MONTHLY_ULTRA_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_MONTH;
  } else if (productId === "ai.adspire.app.agency.1month") {
    return "Agency 1 month Plan";
  }
};

export const getAmount = (amount) => {
  if (amount > 0) {
    return parseFloat(amount).toFixed(2);
  }
  return "0.00";
};

export const getPlan = (stripePriceId, trialPeriodEnd = "no") => {
  let data = { ...priceTestingIds, ...priceLiveIds };
  if (trialPeriodEnd === "yes") {
    return data[stripePriceId]?.replace("Trial", "");
  } else if (trialPeriodEnd) {
    return data[stripePriceId]?.replace("Trial", "");
  }
  return data[stripePriceId] || "Price type not found";
};

export const getPlans = (stripePriceId, planDataReceived) => {
  let data = { ...priceTestingIds, ...priceLiveIds };
  const trialPeriodEnd = isTrialPeriodEnd(
    planDataReceived.purchaseDate,
    planDataReceived.expiryDate
  );
  if (trialPeriodEnd === "yes") {
    return data[stripePriceId]?.replace("Trial", "");
  }
  return data[stripePriceId] || "Price type not found";
};


export const getAdsPerPlan = (stripePriceId, trialPeriodEnd = "no") => {
  let data = { ...priceTestingDownloadIds, ...priceLiveDownloadIds };
  return data[stripePriceId];
}

export function replaceString(inputString) {
  // Replace all occurrences of "abc" with "xyz"
  var replacedString = inputString.replace(/Trial/g, "").replace("  ", " ");
  return replacedString;
}

export const getRealPlanName = (productId, purchaseDate, expiryDate) => {
  if (productId === PAYPAL_YEARLY_LITE_PLAN_NAME) {
    return PLAN_TYPE_LITE + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_PRO_PLAN_NAME) {
    return PLAN_TYPE_PRO + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_ULTRA_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_YEAR;
  } else if (productId === PAYPAL_YEARLY_TRIAL_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_YEAR;
  } else if (productId === PAYPAL_MONTHLY_TRIAL_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_MONTH;
  } else if (productId === PAYPAL_MONTHLY_LITE_PLAN_NAME) {
    return PLAN_TYPE_LITE + PLAN_MONTH;
  } else if (productId === PAYPAL_MONTHLY_PRO_PLAN_NAME) {
    return PLAN_TYPE_PRO + PLAN_MONTH;
  } else if (productId === PAYPAL_MONTHLY_ULTRA_PLAN_NAME) {
    return PLAN_TYPE_ULTRA + PLAN_MONTH;
  }
};

export const getPlanDuration = (productId, purchaseDate, expiryDate) => {
  if (productId === PAYPAL_YEARLY_LITE_PLAN_NAME) {
    return "yearly";
  } else if (productId === PAYPAL_YEARLY_PRO_PLAN_NAME) {
    return "yearly";
  } else if (productId === PAYPAL_YEARLY_ULTRA_PLAN_NAME) {
    return "yearly";
  } else if (productId === PAYPAL_YEARLY_TRIAL_PLAN_NAME) {
    return "yearly";
  } else if (productId === PAYPAL_MONTHLY_TRIAL_PLAN_NAME) {
    return "monthly";
  } else if (productId === PAYPAL_MONTHLY_LITE_PLAN_NAME) {
    return "monthly";
  } else if (productId === PAYPAL_MONTHLY_PRO_PLAN_NAME) {
    return "monthly";
  } else if (productId === PAYPAL_MONTHLY_ULTRA_PLAN_NAME) {
    return "monthly";
  }
};

export const getPlanAmount = (productId, purchaseDate, expiryDate = "") => {
  if (productId === PAYPAL_YEARLY_LITE_PLAN_NAME) {
    return PAYPAL_YEARLY_LITE_PRICE;
  } else if (productId === PAYPAL_YEARLY_PRO_PLAN_NAME) {
    return PAYPAL_YEARLY_PRO_PRICE;
  } else if (productId === PAYPAL_YEARLY_ULTRA_PLAN_NAME) {
    return PAYPAL_YEARLY_ULTRA_PRICE;
  } else if (productId === PAYPAL_YEARLY_TRIAL_PLAN_NAME) {
    if (getDateDifference(purchaseDate, expiryDate) > 3) {
      // 3 days is trial period so comparing with 3 only
      return PAYPAL_YEARLY_TRIAL_PRICE;
    } else {
      return "0.00";
    }
  } else if (productId === PAYPAL_MONTHLY_TRIAL_PLAN_NAME) {
    if (getDateDifference(purchaseDate, expiryDate) > 3) {
      // 3 days is trial period so comparing with 3 only
      return PAYPAL_MONTHLY_TRIAL_PRICE;
    } else {
      return "0.00";
    }
  } else if (productId === PAYPAL_MONTHLY_LITE_PLAN_NAME) {
    return PAYPAL_MONTHLY_LITE_PRICE;
  } else if (productId === PAYPAL_MONTHLY_PRO_PLAN_NAME) {
    return PAYPAL_MONTHLY_PRO_PRICE;
  } else if (productId === PAYPAL_MONTHLY_ULTRA_PLAN_NAME) {
    return PAYPAL_MONTHLY_ULTRA_PRICE;
  } else if (productId === PAYPAL_MONTHLY_ULTRA_PLAN_NAME) {
    return PAYPAL_MONTHLY_ULTRA_PRICE;
  } else if (productId === "ai.adspire.app.agency.1month") {
    return 199.99;
  }
};

export function checkTrialPeriod(data) {
  if (getDateDifference(data.purchaseDate, data.expiryDate) > 3) {
    return false;
  } else {
    return true;
  }
}

export function getDateDifference(purchaseDate, expiryDate) {
  const difference = Math.abs(
    expiryDate?.seconds * 1000 - purchaseDate?.seconds * 1000
  );

  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day
  const days = Math.floor(difference / millisecondsPerDay);

  return days;
}

/**
 * Function to get the document ID using email ID
 * @param {string} email
 * @return {string} - returns a documentid
 */
export async function getDocumentIdByEmail(email, userId) {
  try {
    let querySnapshot = "";
    if (userId) {
      querySnapshot = await db
        .collection("users")
        .where("userId", "==", userId)
        .get();
    } else {
      querySnapshot = await db
        .collection("users")
        .where("email", "==", email)
        .get();
    }

    if (querySnapshot.empty) {
      return null;
    }

    // Assuming there's only one document with the given email
    return querySnapshot.docs[0].data();

    // const documentId = documentSnapshot.id;

    //return documentSnapshot;
  } catch (error) {
    return null;
  }
}

/**
 * Function to get the document ID using email ID
 * @param {string} email
 * @param {string} userId
 * @return {string} - returns a document ID
 */
export async function getUserDocInfo(email, userId) {
  try {
    const document = getDocumentIdByEmail(email, userId);

    if (document) {
      return document;
    } else {
      return null;
    }
  } catch (error) {
    return null;
  }
}

export function getRandomNumber(max, min) {
  return Math.floor(Math.random() * (max - min) + min);
}
export function removeSpecialCharacters(inputString) {
  // Define a regular expression pattern to match special characters
  const pattern = /[^a-zA-Z0-9\s]/g;

  // Use the pattern to replace special characters with an empty string
  const cleanedString = inputString?.replace(pattern, "");

  return cleanedString;
}

export function correctImageUrl(url) {
  // Check if the URL is null or empty
  if (!url) {
    return null; // You can choose to return null or handle this case differently
  }

  // Check if the URL starts with "http://" or "https://"
  if (!/^https?:\/\//i.test(url)) {
    // If not, add "https://" to the URL
    url = "https://" + url;
  }

  // Check for any additional URL validation or corrections here
  // For example, you can validate the domain or check for invalid characters

  return url.replace("////", "//");
}

export const upperCase = (stringText) => {
  return stringText.toUpperCase();
};

export const removeQuotes = (stringWithQuotes) => {
  return stringWithQuotes.replace(/^"|"$/g, "");
};

export const getFixedChar = (stringText, num) => {
  return stringText.replace(/\s+/g, "").slice(0, num).toLowerCase();
};

/*export const QuickAdFlowUserPrompt = (userProductName) => {
  return `Choose between the following ad types: Simple, Abstract, Funny, Emotional, Professional, Outrageous. Then, generate 1 concise, engaging and effective image ad
  idea for a ${userProductName}, with the main focus on the ${userProductName}, without any caption, tagline or text. (Only 1 sentence)
  Then generate the following texts to put on that image ad for that ad idea for a ${userProductName}:
  - A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA
  (Call-To-Action) text to put on the image ad (Maximum of 2 words and 10 characters).
  - 3 really captivating and effective bullet points promoting the perks of a ${userProductName} for an image ad (MAXIMUM of 2-3 words and 10 characters per bullet point)
  (IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${userProductName}, and that each text follows the MAXIMUM
  specified word and character count)`
}*/

export const QuickAdFlowUserPrompt = (userProductName) => {
  return `Generate 1 concise, engaging and effective image ad idea for a ${userProductName}, with the main focus on the ${userProductName}, without any caption, tagline or text. (Only 1 sentence)
Then generate the following texts to put on that image ad for that ad idea for a ${userProductName}:
- A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA (Call-To-Action) text to put on the image ad (Maximum of 2 words and 10 characters).
- A VERY natural, positive, easy, relate-able testimonial review message (10 words maximum, 65 characters maximum) and a name for the review
- A question selling the ad (7 words maximum, 40 characters maximum)
- 2 CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
- 3 really captivating and effective bullet points promoting the perks of the ${userProductName} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${userProductName}, and that each text follows the MAXIMUM specified word count and character count exactly)

EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
Image Ad Idea: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting.
Headline: Elevate Your Style
Description: Timeless Elegance, Unleashed!
CTA: Shop Now
Review Message: Absolutely love this jacket! It feels and looks so awesome!
Review Name: Ryan L.
Question: Want to look like a rock star?
Color 1 (Dark): #000052
Color 2 (Light): #ffed61
Bullet Points:
• Iconic Style
• Unmatched Quality
• Premium Leather)`;
};

export const ConceptAdIdeasPrompt = (maxIdeas, ad_type, input) => {
  return `Generate ${maxIdeas} very creative, effective ${ad_type} image ad ideas for a ${input}, with the main focus on the ${input}, without any caption, tagline or text. (Only 1 sentence. Example: A graceful hand reaching for a radiant blue luxury purse, bathed in soft sunlight.) (List the ad ideas starting from the absolute best) IMPORTANT: DO NOT use ANY of the following words in the ad ideas: "child, children, kid, kill, blood, suicide`;
};

export const NewHomeScreenPrompt = (searchQuery) => {
  return `Generate up to 5 questions to ask a user to specify their ${searchQuery} better so that an AI text-to-image generator understands their ${searchQuery} specific to how it actually looks. For each question, provide a short, concise example answer. (Start with the 3 most important questions) IMPORTANT: The maximum character count
   for each question should be 30 characters (but each question should be as concise as possible), and the maximum character count for each Example Answer should be 50 characters. DO NOT go over this limit! And each Example Answer should start with i.e. Also, keep in mind that only the answers to these questions will be sent to the image generator, 
   so no "yes or no" questions. Format Example: (for a leather jacket)

  Question 1: What is the main color?
  Example Answer: i.e. brown
  Question 2: What type of material?
  Example Answer: i.e. denim
  Question 3: Describe the pattern.
  Example Answer: i.e. stripes
  Question 4: Any unique features?
  Example Answer: i.e. embroidery
  Question 5: What is the closure type?
  Example Answer: i.e. zipper
  IMPORTANT: Provide your response exactly in the given format. Do not include anything else or leave any blank responses.  `;
};
// REMEMBER NOT TO ADD ANY COMMA AFTER ARRAY ELSE IT WILL NOT RETURN CORRECT DATA. 
// FACED THIS PROBLEM KISHORE
export const PreviewImagePrompt = (searchQuery) => {
  return `Generate 4 of each of the following in a VERY creative, enticing, and effective way that will be used as ad copy to promote ${searchQuery} for the image ad "{generated image ad idea}":
- For Facebook Ads, Primary Texts (Up to 125 characters, but may be truncated after around 90 characters in some placements)
- For Facebook Ads, 4 Headlines (Up to 40 characters)
- For Instagram Ads, 4 Captions (with emojis, Up to 125 characters)

FORMAT: (follow this EXACTLY)
{
  "FacebookAds": {
    "PrimaryTexts": [
      "Upgrade your driving game with our latest model.",
      "Unleash the power of the future with our sleek car.",
      "Experience limitless potential behind the wheel.",
      "Unmatched performance for the ultimate driving experience."
    ],
    "Headlines": [
      "Unleash Your Potential",
      "Upgrade Your Drive",
      "Futuristic Performance",
      "Limitless Possibilities"
    ]
  },
  "InstagramAds": {
    "Captions": [
      "Experience the future of driving with our sleek car. 🚀 #unleashyourpotential",
      "Ready to elevate your driving game? Our car has you covered. 🏎️ #ultimateexperience",
      "Unmatched performance, futuristic design. This is the car of your dreams. 💫 #powerunleashed",
      "Step into the future with our cutting-edge car. 🚗 #aheadofthecurve"
    ]
  }
}`;
};

export const ImageGeneratePrompt = (combinedValues) => {
  return `Generate 1 concise, engaging and effective image ad idea for a ${combinedValues}, with the main focus on the ${combinedValues}, without any caption, tagline or text. (Only 1 sentence)
Then generate the following texts to put on that image ad for that ad idea for a ${combinedValues}:
- A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA (Call-To-Action) text to put on the image ad (Maximum of 2 words and 10 characters).
- A VERY natural, positive, easy, relate-able testimonial review message (10 words maximum, 65 characters maximum) and a name for the review
- A question selling the ad (7 words maximum, 40 characters maximum)
- 2 CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
- 3 really captivating and effective bullet points promoting the perks of the ${combinedValues} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${combinedValues}, and that each text follows the MAXIMUM specified word count and character count exactly)

EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
Image Ad Idea: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting.
Headline: Elevate Your Style
Description: Timeless Elegance, Unleashed!
CTA: Shop Now
Review Message: Absolutely love this jacket! It feels and looks so awesome!
Review Name: Ryan L.
Question: Want to look like a rock star?
Color 1 (Dark): #000052
Color 2 (Light): #ffed61
Bullet Points:
• Iconic Style
• Unmatched Quality
• Premium Leather)`;
};

export const ConceptAdImagePrompt = (ad_type, input, selected_ad_idea) => {
  return `Generate ONLY the following ad texts in a ${ad_type} theme for the ad ${selected_ad_idea} for a ${input}:
- A really captivating and effective headline (MAX 3 words, 12 characters MAX), description (4-6 words, 25 characters MAX) and CTA text to put on the image ad (call-to-action button text, max 2 words, 10 characters MAX).
- A VERY natural, positive, easy, relate-able testimonial review message (10 words maximum, 65 characters maximum) and a name for the review
- A question selling the ad (7 words maximum, 40 characters maximum)
- 2 CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
- 3 really captivating and effective bullet points promoting the perks of the ${input} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${input}, and that each text follows the MAXIMUM specified word count and character count exactly)

EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
Headline: Elevate Your Style
Description: Timeless Elegance, Unleashed!
CTA: Shop Now
Review Message: Absolutely love this jacket! It feels and looks so awesome!
Review Name: Ryan L.
Question: Want to look like a rock star?
Color 1 (Dark): #000052
Color 2 (Light): #ffed61
Bullet Points:
• Iconic Style
• Unmatched Quality
• Premium Leather`;
};
/*export const ConceptAdImagePrompt = (ad_type, input, selected_ad_idea) => {
  return `Generate ONLY the following ad texts in a ${ad_type} theme for the ad ${selected_ad_idea} for a ${input}:
- A really captivating and effective headline (MAX 3 words, 12 characters MAX), description (4-6 words, 25 characters MAX) and CTA text to put on the image ad (call-to-action button text, max 2 words, 10 characters MAX).
- 3 really captivating and effective bullet points promoting a ${input} for the image ad (MAXIMUM of 3-4 words and 22 characters per bullet point) 

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a {input}, and that each text follows the MAXIMUM specified word and character count)`
}*/

export const StoreImagePrompt = (searchQuery) => {
  return `Generate the following in a very creative, effective way for a ${searchQuery} image ad:
  - A creative, effective, engaging background idea for an image ad for ${searchQuery} (Maximum of 1-2 sentences, AND start the background idea with: “placed in a background of” or “set on a…” or something like that)
  - A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA (Call-To-Action) text to put on the image ad (Maximum of 2 words and 12 characters).
  - A VERY natural, positive, easy, relate-able testimonial review message (50 characters MAXIMUM)
  - A name for the review
  - A question selling the ad (7 words maximum, 40 characters maximum)
  - 3 pairs of CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
  - 3 really captivating and effective bullet points promoting the perks of the ${searchQuery} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)
  
  (IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${searchQuery}, and that each text follows the MAXIMUM specified word count and character count exactly)
  
  EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
  Background Idea: Placed in a background of rugged urban streets illuminated by vibrant neon lights.
  Headline: Elevate Your Style
  Description: Timeless Elegance, Unleashed!
  CTA: Shop Now
  Review Message: "I LOVE this jacket! It feels and looks so awesome!"
  Review Name: Ryan L.
  Question: Want to look like a rock star?
  Color 1 (Light): #ffed61
  Color 2 (Light): #fff1d1
  Color 3 (Light): #ff997d
  Color 1 (Dark): #000052
  Color 2 (Dark): #201c2e
  Color 3 (Dark): #000f1c
  Bullet Points:
  • Iconic Style
  • Unmatched Quality
  • Premium Leather`;
};

export const ThemeImagePrompt = (searchQuery, selectedTheme, selectedBackgroundName) => {
  return `For a ${searchQuery}, generate the following ad texts in a very creative, effective, ${selectedTheme} theme related to a ${selectedBackgroundName} background: 
- A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA (Call-To-Action) text to put on the image ad (Maximum of 2 words and 12 characters). 
- A VERY natural, positive, easy, relate-able testimonial review message (50 characters MAXIMUM)
- A name for the review
- A question selling the ad (7 words maximum, 40 characters maximum)
- 3 pairs of CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
- 3 really captivating and effective bullet points promoting the perks of the {input} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a {input}, and that each text follows the MAXIMUM specified word count and character count exactly)

EXAMPlE: (FOLLOW THIS EXAMPLE FORMAT EXACTLY!!)
Headline: Elevate Your Style
Description: Timeless Elegance, Unleashed!
CTA: Shop Now
Review Message: I LOVE this jacket! It feels and looks so awesome!
Review Name: Ryan L.
Question: Want to look like a rock star?
Light Color 1: #ffed61
Light Color 2: #fff1d1
Light Color 3: #ff997d
Dark Color 1: #000052
Dark Color 2: #201c2e
Dark Color 3: #000f1c
Bullet Points:
• Iconic Style
• Unmatched Quality
• Premium Leather
`;
};



export const StoreBackgroundImagePrompt = (userProductName) => {
  return `Generate the following a very creative, effective way for a ${userProductName} Background image ad:
- A creative, effective, engaging background idea for an image ad for ${userProductName} (Maximum of 1 sentences, AND start the background idea with: “placed in a background of” or “set on a…” or something like that)
EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
Background Idea: Placed in a background of rugged urban streets illuminated by vibrant neon lights.`;
};

export const StoreAdFlowUserPrompt = (userProductName) => {
  return `Generate the following a very creative, effective way for a ${userProductName} image ad:
- A creative, effective, engaging background idea for an image ad for ${userProductName} (Maximum of 1-2 sentences, AND start the background idea with: “placed in a background of” or “set on a…” or something like that)
- A really captivating and effective headline (MAXIMUM of 3 words and 12 characters), description (MAXIMUM of 4-6 words and 25 characters) and a CTA (Call-To-Action) text to put on the image ad (Maximum of 2 words and 12 characters).
- A VERY natural, positive, easy, relate-able testimonial review message (50 characters MAXIMUM) and a name for the review
- A question selling the ad (7 words maximum, 40 characters maximum)
- 2 CONTRASTING theme colors for the ads in hex code (1st color has to be dark, 2nd color has to be light. Both colors should NOT be even close to the same hue - they should be contrasting! So if the dark color is dark pink, the light color cant be light pink, but it can be light blue. And at least ONE color should be at least a little colorful (i.e. dark gray, and light yellow))
- 3 really captivating and effective bullet points promoting the perks of the ${userProductName} (Each bullet point should be a MAXIMUM of 4 words and 12 characters)

(IMPORTANT: Make sure that each text sounds very natural and enticing for viewers of the image ad to buy a ${userProductName}, and that each text follows the MAXIMUM specified word count and character count exactly)

EXAMPLE: (FOLLOW THIS EXAMPLE CLOSELY)
Background Idea: Placed in a background of rugged urban streets illuminated by vibrant neon lights.
Headline: "Elevate Your Style"
Description: "Timeless Elegance, Unleashed!"
CTA: "Shop Now"
Review Message: Absolutely love this jacket! It feels and looks so awesome!
Review Name: Ryan L.
Question: Want to look like a rock star?
Color 1 (Dark): #000052
Color 2 (Light): #ffed61
Bullet Points:
• Iconic Style
• Unmatched Quality`;
};

export const updateNewSignupUserStatus = async () => {
  const userInfo = {
    generatedAdsNumber: 1,
  };
  try {
    await db
      .collection("users")
      .doc(userService.getUserId())
      .set(userInfo, { merge: true });
    userService.setKeyValueLocalstorage("generatedAdsNumber", 1);
  } catch (e) {
    console.log(e);
  }
};

export const FreetypePrompt = () => {
  return `What is the best creative but effective image ad idea type for $(adCategory) about $(input)? Please choose from the following ad types: Simple, Abstract, Funny, Professional, Emotional, Outrageous (Do not provide an explanation, respond with just the image ad idea type, for example: "Professional")`;
};

export const FreeIdeaPrompt = (maxIdeas, type, adCategoryValue, input) => {
  return `Generate ${maxIdeas} concise ${type} image ad idea for ${adCategoryValue} about ${input}, without any caption, tagline or text in the ad idea. (Only 1 sentence. Format example: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting) (Only 1 sentence. Format example: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting)`;
};

export const FreeRowIdeaPrompt = () => {
  return `Generate $(maxIdeas) concise $(type) image ad idea for  about $(input), without any caption, tagline or text in the ad idea. (Only 1 sentence. Format example: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting) (Only 1 sentence. Format example: A confident individual wearing a sleek leather jacket, standing against a backdrop of a moody urban setting)`;
};

export const FreeTypeValue = () => {
  const types = ["Simple", "Abstract"];
  return types[0];
};

export const setFreeAdsCount = (count) => {
  userService.setKeyValueLocalstorage("freeAdsCount", count);
};

export const getFreeAdsCount = () => {
  if (userService.getValueFromLocalstorage("freeAdsCount") > 0) {
    return userService.getValueFromLocalstorage("freeAdsCount");
  }
  return 0;
};

export const isEligibleForFreeAdsAPI = () => {
  if (userService.isPurchaser()) {
    return false;
  } else if (getFreeAdsCount() < 5 && !userService.isLoggedInUser()) {
    return true;
  }
  return false;
};

export function isPlanExpired(expirationDate) {
  // ADDING 1 DAY MORE EXPIRY DATE INTENTIONALLY, SINCE IT WILL HELP USER TO MAKE PAYMENT
  const oneDayInMilliseconds = 24 * 60 * 60; // 1 day in milliseconds
  // Parse the expiration date string to a Date object
  const expiration = new Date((expirationDate?.seconds + oneDayInMilliseconds) * 1000);
  // Get the current date
  const currentDate = new Date();

  // Compare the two dates
  return currentDate > expiration;
}

export function addOneMonthToCurrentDate(date) {
  // Get the current date
  let currentDate = new Date(date?.seconds * 1000);

  // Add 1 to the month
  currentDate.setMonth(currentDate.getMonth() + 1);
  return getFormattedDate(currentDate.getTime());
}

export function getFormattedDate(currentDates) {
  // Get the current date
  let currentDate = new Date(currentDates);

  // Extract year, month, and day components
  let year = currentDate.getFullYear();
  let month = (currentDate.getMonth() + 1).toString().padStart(2, "0"); // Months are zero-based
  let day = currentDate.getDate().toString().padStart(2, "0");

  // Create the formatted date string
  let formattedDate = `${month}-${day}-${year % 100}`;

  return formattedDate;
}

export function formatDateAMPM(inputDate1) {
  const inputDate = new Date(inputDate1.seconds * 1000);
  // Check if inputDate is a valid Date object
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    return "Invalid Date";
  }

  // Extract date components
  var month = String(inputDate.getMonth() + 1).padStart(2, "0");
  var day = String(inputDate.getDate()).padStart(2, "0");
  var year = inputDate.getFullYear();

  // Extract time components
  var hours = String(inputDate.getHours() % 12 || 12).padStart(2, "0");
  var minutes = String(inputDate.getMinutes()).padStart(2, "0");
  var ampm = inputDate.getHours() < 12 ? "am" : "pm";

  // Construct the formatted date string
  var formattedDate =
    month + "-" + day + "-" + year + ", " + hours + ":" + minutes + " " + ampm;

  return formattedDate;
}

export function formatDateMMDDYY(inputDate1) {
  const inputDate = new Date(inputDate1?.seconds * 1000);
  // Check if inputDate is a valid Date object
  if (!(inputDate instanceof Date) || isNaN(inputDate)) {
    return "Invalid Date";
  }

  // Extract date components
  var month = String(inputDate.getMonth() + 1).padStart(2, "0");
  var day = String(inputDate.getDate()).padStart(2, "0");
  var year = inputDate.getFullYear();

  // Extract time components
  var hours = String(inputDate.getHours() % 12 || 12).padStart(2, "0");
  var minutes = String(inputDate.getMinutes()).padStart(2, "0");
  var ampm = inputDate.getHours() < 12 ? "am" : "pm";

  // Construct the formatted date string
  var formattedDate =
    month + "-" + day + "-" + year;

  return formattedDate;
}

export function truncateString(str, charLength = 60) {
  try {
    // Check if the string length is greater than the specified character length
    if (str && str.length > charLength) {
      // Truncate the string to the specified character length and add '...'
      return str.slice(0, charLength) + "...";
    } else {
      // If the string length is within the specified character length, return the original string
      return str;
    }
  } catch (error) {
    // If an error occurs during string truncation, handle it gracefully
    console.error("Error occurred while truncating string:", error.message);
    // Return the original string
    return str;
  }
}

export const deduct30DaysFromDate = () => {
  const currentDate = new Date();
  const resultDate = new Date(currentDate);
  resultDate.setDate(resultDate.getDate() - 60);
  return resultDate.toDateString();
};



export const containsAffiliate = (str) => {
  // Define the prefix to check for
  const prefix = "/affiliate/";

  // Check if the string starts with the prefix
  if (str.startsWith(prefix)) {
    // If it does, return the substring after the prefix
    return str.substring(prefix.length);
  } else {
    // If it doesn't, return null or an empty string, depending on your requirements
    return null;
  }
};


export const checkMonthlyYearly = (text) => {
  // Check if the text contains "monthly" or "yearly"
  if (/\bmonthly\b/i.test(text)) {
    return "mo";
  } else if (/\byearly\b/i.test(text)) {
    return "yr";
  }
  // Return null if neither "monthly" nor "yearly" is found
  return "mo";
}


export const removeTextTrialMonthly = (text) => {
  // Define regular expression to match "Trial Monthly" or "Trial Yearly"
  const regex = /\bTrial\s+(Monthly|Yearly)\b/gi;
  // Replace matched substrings with an empty string
  return text.replace(regex, "").trim();
}

export const loadImage = (blob) => {
  return new Promise((resolve, reject) => {
    const image = new Image();
    image.onload = () => resolve(image);
    image.onerror = (error) => reject(error);
    image.src = URL.createObjectURL(blob);
  });
};

export const mergeImages = async (
  imagesBase64,
  i,
  copyItems,
  base64OverLayImage,
  totalImageCount,
  userAction = "",
  variations = false
) => {
  try {
    if (!imagesBase64) return;

    const remoteImage1 = "data:image/png;base64," + imagesBase64;
    const remoteImageBlob = await fetch(remoteImage1).then((response) =>
      response.blob()
    );

    //const base64Image = svg64(base64OverLayImage);
    const base64Image = "data:image/png;base64," + base64OverLayImage;
    const base64Blob = await fetch(base64Image).then((response) =>
      response.blob()
    );

    const canvas = document.createElement("canvas");
    const context = canvas.getContext("2d");
    const remoteImage = await loadImage(remoteImageBlob);
    canvas.width = remoteImage.width;
    canvas.height = remoteImage.height;
    context.drawImage(remoteImage, 0, 0);

    const base64ImageObj = await loadImage(base64Blob);
    context.drawImage(base64ImageObj, 0, 0);

    const mergedImageDataUrl = canvas.toDataURL();
    return mergedImageDataUrl;

    /*if (variations) {
      copyImages.splice(i, 1, mergedImageDataUrl);
      setCopyImages(copyImages);
      setIsVariationCompleted(true);
      userService.setKeyValueLocalstorage("variationImage", i);
    } else {
      copyItems.push(mergedImageDataUrl);
      if (i === totalImageCount - 1) {
        setShowListingPage(true);
        setLoader(false);
        setCopyImages(
          userAction === "edit" ? copyItems : [...copyImages, ...copyItems]
        );
      }
    }*/
  } catch (error) {
    console.error("Error in mergeImages:", error);
  }
};

export function removeUndefined(array) {
  return array.filter(element => element !== undefined);
}

export function checkIsCoupanValid(coupan) {
  const iSCoupanValid = VALId_COUPAN_LIST.filter(c => c === coupan)
  if(iSCoupanValid[0]){
    return true
  }
  return false
}
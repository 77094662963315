import React from "react";
import _00113000x3000_4_2x from "../../../images/landing_page_assests/00113000x3000-4@2x.png";
import _00113000x3000_41_2x from "../../../images/landing_page_assests/00113000x3000-41@2x.png";
import _00113000x3000_42_2x from "../../../images/landing_page_assests/00113000x3000-42@2x.png";
import _00113000x3000_43_2x from "../../../images/landing_page_assests/00113000x3000-43@2x.png";
import _3rd_section_bg from "../../../images/landing_page_assests/3rd-section-bg.svg";
import grediant1 from "../../../images/landing_page_assests/grediant1.svg";
import newRoasArrowIcon1 from "../../../images/newRoas/icon1.svg";
import newRoasArrowIcon2 from "../../../images/newRoas/icon2.svg";


const RoasNew = (props) => {
  return (
    <div className="roas new-roas-backgournd new-roas-pb-200">
      <div className="rd-section-bg overlay-image " />
      <div className="group-parent">
        <div className="adspire-your-roas-to-82x-and-parent">
          <div className="adspire-your-roas-container ">
            <span className="unlimited-caption-txt-container">
              <span className="adspire-your-new-roas">Adspire® your</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">ROAS</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">to</span>
              <span>{` `}</span>
              <span className="x">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
              <span>{` `}</span>
              <span className="adspire-your-new-roas">and </span>
              <span className="adspire-your-new-roas cursor-pointer">Beyond!</span>
            </span>
          </div>
          <div className="x-wrapper">
            <div className="image-ads">8.2X</div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="new-roas-text-box-container">
              <div className="new-roas-text-box-1">
                <div className="new-roas-icon-container">
                  <div className="new-roas-icons-bg">
                    <img className="new-roas-icon-1" src={newRoasArrowIcon1} alt="icon" />
                  </div>
                </div>
                <div className="new-roas-content ">
                  <div className="adspire-user-reach-container-new-roas">
                    <p className="text-black">{`Adspire® users reach `}</p>
                    <p className="x-higher-roas-on-average-using">
                      <span className="span-">
                        <b className="x3 x3-new-roas text-black">8.2X</b>
                      </span>
                      <span className="higher-roas-on-average-using-t">
                        <span className="span">{` `}</span>
                        <span className="text-black">
                          higher ROAS <br></br>on average from users using<br></br>Adspire®'s generative AI Ads!
                        </span>
                      </span>
                    </p>
                    <div className="new-roas-content-line">
                      <div className="progress-container-new-roas-down">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="new-roas-text-box-2">
                <div className="new-roas-icon-container">
                  <div className="new-roas-icons-bg">
                    <img className="new-roas-icon-1" src={newRoasArrowIcon2} alt="icon" />
                  </div>
                </div>
                <div className="new-roas-content">
                  <div className="adspire-user-reach-container-new-roas">
                    <p className="adspire-user-reach text-black x3 x3-new-roas">{`96% `}</p>
                    <p className="x-higher-roas-on-average-using">
                      <span className="higher-roas-on-average-using-t">
                        <span className="text-black">
                          of users improve their CTR<br></br>
                          significantly in their 1st month!
                        </span>
                      </span>
                    </p>
                    <div className="new-roas-content-line">
                      <div className="progress-container-new-roas-down new-roas-progress-width">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="vector-parent">
            <img className="group-child" alt="" src={vector_4} />
            <div className="frame-wrapper1">
              <div className="frame-child5" />
            </div>
          </div> */}
          {/* <img className="group-icon" alt="" src={group_1000000975} /> */}
          {/* <div className="of-adspire-users-container">
            <p className="x-higher-roas-on-average-using">
              <b className="b">{`96% `}</b>
            </p>
            <p className="of-adspire-users-increase-thei">
              <span>
                <span className="higher-roas-on-average-using-t">{`of Adspire® users increase their CTR`} <span className="underline text-weight-600">significantly</span> {`in their `}</span>
                <b className="st-month">1st month!</b>
              </span>
            </p>
          </div> */}
        </div>
      </div>
      <img className="grediant-icon1" alt="" src={grediant1} />
    </div>
  );
};
export default RoasNew;
















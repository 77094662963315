import React from "react";
import star_2 from "../../../images/landing_page_assests/star-2.svg";
const Marquee = (props) => {
  return (
    <div className="marquee-wrapper line-new-landing-page">
      <div className="container">
        <div className="marquee-block">
          <div className="marquee-inner to-left">
            <span>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>

              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>

              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
            </span>
            <span className="second-items-marquee">
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
              <div className="marquee-item">
                <img className="line-child-new-lading" alt="" src={star_2} />
                <b className="adspire2">Adspire®</b>
              </div>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Marquee;

const app_status = process.env.REACT_APP_STATUS; // Replace with your actual app_status value

let APPLICATION_INFO;

if (app_status === "production") {
  APPLICATION_INFO = {
    DOWNLOAD_IMAGE_TRIAL_USER_COUNT: 5,
    ADSPIRE_COLLECTION_API: process.env.REACT_APP_API_URL,
    ADSPIRE_API_HEADER_KEY: process.env.REACT_APP_API_HTTP_AUTH_KEY,
    HTTPS: "https://",
    MAX_IMAGE: 8,
    UPLOAD_GENERATE_MAX_IMAGE: 16,
    NEW_MAX_IMAGE: 5,
    ADFLOW_ACTIVE_TAB: 3,
    IS_INSPECTING_DISABLE: true,
    SIZE: "256x256",
    STORE_FEATURE_API_URL: "https://app.scrapingbee.com/api/v1",
    APP_STATUS: "LIVE",
    ADSPIRE_WEBSITE_BASE_64_CONVERSION_URL:
      "https://adspireai.com/base64.php?img=",
    WEBSITE_DOWNLOAD_IMG: "https://adspireai.com/storeimg/downloadimg.php?img=",
    STRIPE_API_CREATE_CUSTOMER_AND_SUBSCRIBE:
      "http://adspireai.com:3002/api_subscribe",
  };
} else if (app_status === "development") {
  APPLICATION_INFO = {
    DOWNLOAD_IMAGE_TRIAL_USER_COUNT: 5,
    ADSPIRE_COLLECTION_API: process.env.REACT_APP_API_URL,
    ADSPIRE_API_HEADER_KEY: process.env.REACT_APP_API_HTTP_AUTH_KEY,
    HTTPS: "https://",
    MAX_IMAGE: 8,
    UPLOAD_GENERATE_MAX_IMAGE: 16,
    NEW_MAX_IMAGE: 5,
    IS_INSPECTING_DISABLE: false,
    ADFLOW_ACTIVE_TAB: 3,
    SIZE: "256x256",
    STORE_FEATURE_API_URL: "https://app.scrapingbee.com/api/v1",
    APP_STATUS: "LIVE",
    WEBSITE_DOWNLOAD_IMG:
      "http://testing.adspireai.com/storeimg/downloadimg.php?img=",
    ADSPIRE_WEBSITE_BASE_64_CONVERSION_URL:
      "http://testing.adspireai.com/base64.php?img=",
    STRIPE_API_CREATE_CUSTOMER_AND_SUBSCRIBE:
      "http://testing.adspireai.com:3002/api_subscribe",
  };
} else {
  APPLICATION_INFO = {
    DOWNLOAD_IMAGE_TRIAL_USER_COUNT: 5,
    ADSPIRE_COLLECTION_API: process.env.REACT_APP_API_URL,
    ADSPIRE_API_HEADER_KEY: process.env.REACT_APP_API_HTTP_AUTH_KEY,
    HTTPS: "https://",
    MAX_IMAGE: 8,
    UPLOAD_GENERATE_MAX_IMAGE: 16,
    NEW_MAX_IMAGE: 5,
    ADFLOW_ACTIVE_TAB: 3,
    IS_INSPECTING_DISABLE: false,
    SIZE: "256x256",
    STORE_FEATURE_API_URL: "https://app.scrapingbee.com/api/v1",
    APP_STATUS: "DEBUG",
    WEBSITE_DOWNLOAD_IMG:
      "http://testing.adspireai.com/storeimg/downloadimg.php?img=",
    ADSPIRE_WEBSITE_BASE_64_CONVERSION_URL:
      "http://testing.adspireai.com/base64.php?img=",
    STRIPE_API_CREATE_CUSTOMER_AND_SUBSCRIBE:
      "http://localhost:3002/api_subscribe",
  };
}

let STRIPE_INFO;

if (app_status === "production") {
  STRIPE_INFO = {
    MODE: "subscription",
    SUCCESS_URL: `https://adspireai.com/success`,
    CANCEL_URL: `https://adspireai.com/`,
  };
} else if (app_status === "development") {
  STRIPE_INFO = {
    MODE: "subscription",
    SUCCESS_URL: `http://testing.adspireai.com/success`,
    CANCEL_URL: `http://testing.adspireai.com/`,
  };
} else {
  STRIPE_INFO = {
    MODE: "subscription",
    SUCCESS_URL: `http://localhost:3000/success`,
    CANCEL_URL: `http://localhost:3000/`,
  };
}

export const PASSWORD_RESET_SUCCESSFULLY = "Your password reset successfully.";
export const ERROR_OCCURRED_DURING_CONFIRMATION =
  "There is some error ocurred, Please try again later!";
export const EXPIRED_INVALID_CODE = "Your code expired.";
export const EMAIL_VERIFIED = "Your email verified successfully.";
export const PASSWORD_RESET_MSG = "Your password reset is in process...";

export const ITEMS_PER_PAGE = 20;
export const SORT_OPTIONS = {
  ASC: "asc",
  DESC: "desc",
};

export const VALId_COUPAN_LIST = ['HappyWeekends']
export const APPLICATION_ERROR_MESSAGE = {
  CREDIT_REACHED:
    "User does not have enough credits to execute this action or isn't subcribed to the correct tier",
  CREDIT_REACHED_KEY:
    "Something happened while trying to get and save a Text Completion. Error => Error: User does not have enough credits to execute this action or isn't subcribed to the correct tier",
};
export const URLS = {
  AD_GOAL: "ad-goal",
  BUSINESS_TYPE: "business-type",
  DESCRIPTION: "description",
  AD_TYPE: "ad-type",
  AD_IDEAS: "ad-ideas",
  IMAGE_STYLE: "image-style",
  IMAGE_ADS: "image-ads",
  CAPTIONS: "captions",
};
export const LOGIN_TYPE_ANNONYMOUS = "Anonymous";
export const LOGIN_TYPE_GOOGLE = "Google";
export const LOGIN_TYPE_EMAIL = "Email";
export const LOGIN_TYPE_FACEBOOK = "Facebook";
export const LOGIN_TYPE_APPLE = "Apple";

export const PLAN_YEAR = " Yearly";
export const PLAN_MONTH = " Monthly";

export const PLAN_TYPE_LITE = "Lite";
export const PLAN_TYPE_PRO = "Pro";
export const PLAN_TYPE_ULTRA = "Ultra";
export const PLAN_TYPE_FREE_TRIAL = "Free Trial";

export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID;

export const PAYPAL_PAYMENT_STATUS = "APPROVED";
export const PAYPAL_API_CLIENT_ID = process.env.REACT_APP_PAYPAL_API_CLIENT_ID;

export const PAYPAL_YEARLY_LITE_PLAN_ID =
  process.env.REACT_APP_PAYPAL_YEARLY_LITE_PLAN_ID;
export const PAYPAL_YEARLY_LITE_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_YEARLY_LITE_PLAN_NAME;
export const PAYPAL_YEARLY_LITE_PRICE = 191.99;

export const PAYPAL_YEARLY_PRO_PLAN_ID =
  process.env.REACT_APP_PAYPAL_YEARLY_PRO_PLAN_ID;
export const PAYPAL_YEARLY_PRO_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_YEARLY_PRO_PLAN_NAME;
export const PAYPAL_YEARLY_PRO_PRICE = 299.99;

export const PAYPAL_YEARLY_ULTRA_PLAN_ID =
  process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_PLAN_ID;
export const PAYPAL_YEARLY_ULTRA_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_PLAN_NAME;
export const PAYPAL_YEARLY_ULTRA_PRICE = 49.99;

export const PAYPAL_MONTHLY_LITE_PLAN_ID =
  process.env.REACT_APP_PAYPAL_MONTHLY_LITE_PLAN_ID;
export const PAYPAL_MONTHLY_LITE_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_MONTHLY_LITE_PLAN_NAME;
export const PAYPAL_MONTHLY_LITE_PRICE = 19.99;

export const PAYPAL_MONTHLY_PRO_PLAN_ID =
  process.env.REACT_APP_PAYPAL_MONTHLY_PRO_PLAN_ID;
export const PAYPAL_MONTHLY_PRO_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_MONTHLY_PRO_PLAN_NAME;
export const PAYPAL_MONTHLY_PRO_PRICE = 19.99;

export const PAYPAL_MONTHLY_ULTRA_PLAN_ID =
  process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_PLAN_ID;
export const PAYPAL_MONTHLY_ULTRA_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_PLAN_NAME;
export const PAYPAL_MONTHLY_ULTRA_PRICE = 19.99;

export const PAYPAL_YEARLY_TRIAL_PLAN_ID =
  process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_TRIAL_PLAN_ID;
export const PAYPAL_YEARLY_TRIAL_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_YEARLY_ULTRA_TRIAL_PLAN_NAME;
export const PAYPAL_YEARLY_TRIAL_PRICE = 99.99;

export const PAYPAL_MONTHLY_TRIAL_PLAN_NAME =
  process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME;
export const PAYPAL_MONTHLY_TRIAL_PRICE = 19.99;

export const PAYPAL_ENVIRONMENT = process.env.REACT_APP_PAYPAL_ENVIRONMENT;

export const errorMessage =
  "User does not have enough credits to execute this action or isn't subcribed to the correct tier";

export const PRO_SUBSCRIBER_MSG =
  "Become an Ultra subscriber to generate unlimited captions, ad variations & more!";
export const LITE_SUBSCRIBER_MSG =
  "Become a Pro+ subscriber to generate unlimited image ads & more!";

export const PAYMENT_SUCCESS_MSG = "Payment is in Process please wait...";
export const LOGIN_MSG = "Processing please wait...";
export const SIGNUP_MSG = "Processing please wait...";
export const PAYMENT_PROCESS_MSG =
  "Transaction in progress, please be patient...";
export const FORGET_PASSWORD_MSG =
  "Password reset email sent. Check your inbox/spam or junk folder";

export const FREE_TRIAL = "Free Trial";
export const START_TRIAL = "Start Trial";
export const INITIATE_CHECKOUT = "Initiate Checkout";
export const QUICK_FREE_TRIAL = "Quick Free Trial";
export const CREATE_ACCOUNT = "Create Account";
export const SUCCESS_PAYMENT = "Successfull Payment";
export const MONTHLY_TRIAL_PRICE = 19.99;
export const YEARLY_TRIAL_PRICE = 99.99;

export const MONTHLY_DISCOUNTED_PRICE = 9.99;
export const YEARLY_DISCOUNTED_PRICE = 49.99;

export const DEFAULT_USER_YEARLY_PAYMENT_PLAN =
  process.env.REACT_APP_STRIPE_YEARLY_ULTRA_TRIAL_PRICE_ID;
export const DEFAULT_USER_MONTHLY_PAYMENT_PLAN =
  process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID;

export const TRUSTED_BY_MSG = "Trusted by 50,000+ advertisers and brands!";
export const TRUSTED_BY_SIGNUP_MSG = "Trusted by 50,000+ advertisers & brands!";

export const TESTIMONIAL_VIDEO =
  "https://www.youtube-nocookie.com/embed/7h8vckZGirk?si=ne3HpQ7YaQ_unIp7";

export const LIVE_PAYMENT = {
  STRIPE: true,
  PAYPAL: false,
};

export const priceTestingIds = {
  price_1N8e5MKvvJjpTJHaM2eLhWhb: "YEARLY LITE Plan",
  price_1N8e7AKvvJjpTJHaO2J8evwa: "Yearly PRO Plan",
  price_1N8e9VKvvJjpTJHa1GdebMJS: "Yearly ULTRA Plan",
  price_1N8canKvvJjpTJHa5fLMpaw2: "Monthly LITE Plan",
  price_1N8eBUKvvJjpTJHaNVXAfmQG: "Monthly PRO Plan",
  price_1N8eCNKvvJjpTJHatO2ZRVPO: "Monthly ULTRA Plan",
  price_1O53R0KvvJjpTJHaloJFuerc: "Yearly ULTRA Plan",
  price_1O52mWKvvJjpTJHairmJCHGf: "Monthly ULTRA Plan",
  price_1OV4sqKvvJjpTJHaOdZCjo6T: "Monthly ULTRA Discounted",
  price_1OV4tjKvvJjpTJHavApntF4Z: "Yearly ULTRA Discounted",
  price_1OPnleKvvJjpTJHaw77gZGC5: "Monthly ULTRA Trial Plan",
  price_1OV4taKvvJjpTJHar2hk5tNl: "Yearly ULTRA Plan",
  price_1OV4sHKvvJjpTJHaOJrqgK9H: "Monthly ULTRA Plan",

  price_1OZzFvKvvJjpTJHaAyGojKtF: "Startup Monthly Plan", // startup plan yearly 19
  price_1OZyUxKvvJjpTJHanOEMnF3H: "Pro Monthly Plan",
  price_1OZymxKvvJjpTJHacwYT0QBm: "Agency Monthly Plan",

  price_1OZzMfKvvJjpTJHaRSmtOxDI: "Startup Yearly Plan", // startup plan yearly 99
  price_1OZyfTKvvJjpTJHakILf2ZkX: "Pro Yearly Plan",
  price_1OZyr5KvvJjpTJHa5io8XjYM: "Agency Yearly Plan",

  price_1OaibIKvvJjpTJHaAZfsTfic: "Startup Trial Monthly Plan",
  price_1OaigKKvvJjpTJHaiTS1K6pU: "Pro Trial Monthly Plan",
  price_1OaifJKvvJjpTJHahOJhO6XE: "Agency Trial Monthly Plan",
};
// Live price ids
export const priceLiveIds = {
  price_1Oaid6KvvJjpTJHagnuu9mjT: "Startup Trial Monthly Plan",
  price_1OaigjKvvJjpTJHanPxARzd8: "Pro Trial Monthly Plan",
  price_1OaieIKvvJjpTJHaas2ia46M: "Agency Trial Monthly Plan",

  price_1NAWpUKvvJjpTJHa1ZXUGe0A: "Yearly LITE Plan",
  price_1NAWqoKvvJjpTJHapTCshmgA: "Yearly PRO_PRICE",
  price_1NAWsCKvvJjpTJHahjxLxoFN: "Yearly ULTRA Plan",
  price_1NAWjqKvvJjpTJHa9FRx6vop: "Monthly LITE Plan",
  price_1NAWmVKvvJjpTJHaHTqbOS6V: "Monthly PRO Plan",
  price_1NAWo8KvvJjpTJHagsZOqiMR: "Monthly ULTRA Plan",
  price_1O53SoKvvJjpTJHaVx7K1bfM: "Yearly ULTRA Trial Plan",
  price_1O53UnKvvJjpTJHaLEF9YLb0: "Monthly ULTRA Trial Plan",
  price_1OV4sqKvvJjpTJHaOdZCjo6T: "Monthly ULTRA Plan Discounted",
  price_1OV4tjKvvJjpTJHavApntF4Z: "Yearly ULTRA Plan Discounted ",
  price_1NAWuUKvvJjpTJHaCJFJtSRI: "Yearly ULTRA Trial Plan",
  price_1NCLR9KvvJjpTJHaoQCfy2WE: "Monthly ULTRA Trial Plan",
  price_1OPnleKvvJjpTJHaw77gZGC5: "Monthly ULTRA Trial Plan",
  price_1OZzHRKvvJjpTJHa17B5tvrt: "Startup Monthly Plan",
  price_1OZycUKvvJjpTJHarxTMXHBg: "Pro Monthly Plan",
  price_1OZyn5KvvJjpTJHafXg0uTjD: "Agency Monthly Plan",
  price_1OZzJHKvvJjpTJHa8VIAze3t: "Startup Yearly Plan",
  price_1OZyfvKvvJjpTJHaXzZYTrbR: "Pro Yearly Plan",
  price_1OZypeKvvJjpTJHadcn5yg4V: "Agency Yearly Plan",
};

export const priceTestingDownloadIds = {
  price_1N8e5MKvvJjpTJHaM2eLhWhb: "10 ads/mo",
  price_1N8e7AKvvJjpTJHaO2J8evwa: "50 ads/mo",
  price_1N8e9VKvvJjpTJHa1GdebMJS: "500 ads/mo",
  price_1N8canKvvJjpTJHa5fLMpaw2: "10 ads/mo",
  price_1N8eBUKvvJjpTJHaNVXAfmQG: "50 ads/mo",
  price_1N8eCNKvvJjpTJHatO2ZRVPO: "500 ads/mo",
  price_1O53R0KvvJjpTJHaloJFuerc: "500 ads/mo",
  price_1O52mWKvvJjpTJHairmJCHGf: "500 ads/mo",
  price_1OV4sqKvvJjpTJHaOdZCjo6T: "500 ads/mo",
  price_1OV4tjKvvJjpTJHavApntF4Z: "500 ads/mo",
  price_1OPnleKvvJjpTJHaw77gZGC5: "500 ads/mo",
  price_1OV4taKvvJjpTJHar2hk5tNl: "500 ads/mo",
  price_1OV4sHKvvJjpTJHaOJrqgK9H: "500 ads/mo",
  price_1OZzFvKvvJjpTJHaAyGojKtF: "10 ads/mo", // startup plan yearly 19
  price_1OZyUxKvvJjpTJHanOEMnF3H: "50 ads/mo",
  price_1OZymxKvvJjpTJHacwYT0QBm: "500 ads/mo",
  price_1OZzMfKvvJjpTJHaRSmtOxDI: "10 ads/mo", // startup plan yearly 99
  price_1OZyfTKvvJjpTJHakILf2ZkX: "50 ads/mo",
  price_1OZyr5KvvJjpTJHa5io8XjYM: "500 ads/mo",
  price_1OaibIKvvJjpTJHaAZfsTfic: "10 ads/mo",
  price_1OaigKKvvJjpTJHaiTS1K6pU: "50 ads/mo",
  price_1OaifJKvvJjpTJHahOJhO6XE: "500 ads/mo",
};
// Live price ids
export const priceLiveDownloadIds = {
  price_1Oaid6KvvJjpTJHagnuu9mjT: "10 ads/mo",
  price_1OaigjKvvJjpTJHanPxARzd8: "50 ads/mo",
  price_1OaieIKvvJjpTJHaas2ia46M: "500 ads/mo",
  price_1NAWpUKvvJjpTJHa1ZXUGe0A: "10 ads/mo",
  price_1NAWqoKvvJjpTJHapTCshmgA: "50 ads/mo",
  price_1NAWsCKvvJjpTJHahjxLxoFN: "500 ads/mo",
  price_1NAWjqKvvJjpTJHa9FRx6vop: "10 ads/mo",
  price_1NAWmVKvvJjpTJHaHTqbOS6V: "50 ads/mo",
  price_1NAWo8KvvJjpTJHagsZOqiMR: "500 ads/mo",
  price_1O53SoKvvJjpTJHaVx7K1bfM: "500 ads/mo",
  price_1O53UnKvvJjpTJHaLEF9YLb0: "500 ads/mo",
  price_1OV4sqKvvJjpTJHaOdZCjo6T: "500 ads/mo",
  price_1OV4tjKvvJjpTJHavApntF4Z: "500 ads/mo",
  price_1NAWuUKvvJjpTJHaCJFJtSRI: "500 ads/mo",
  price_1NCLR9KvvJjpTJHaoQCfy2WE: "500 ads/mo",
  price_1OPnleKvvJjpTJHaw77gZGC5: "500 ads/mo",
  price_1OZzHRKvvJjpTJHa17B5tvrt: "10 ads/mo",
  price_1OZycUKvvJjpTJHarxTMXHBg: "50 ads/mo",
  price_1OZyn5KvvJjpTJHafXg0uTjD: "500 ads/mo",
  price_1OZzJHKvvJjpTJHa8VIAze3t: "10 ads/mo",
  price_1OZyfvKvvJjpTJHaXzZYTrbR: "50 ads/mo",
  price_1OZypeKvvJjpTJHadcn5yg4V: "500 ads/mo",
};

// export const headlineFontsArray = ["Zilla Slab Highlight", "Yeseva_One", "Ultra", "TiltPrism", "Secular_One", "Righteous", "Paytone_One", "Fugaz_One", "DM_Serif_Display", "Caprasimo"];
export const headlineFontsArray = [
  "Caprasimo",
  "DM_Serif_Display",
  "Fugaz_One",
  "Paytone_One",
  "Righteous",
  "Secular_One",
  "TiltPrism",
  "Ultra",
  "Yeseva_One",
  "Zilla Slab Highlight",
  "AbrilFatface",
  "Anton",
  "DMSerifDisplay",
  "FugazOne",
  "Righteous",
  "SecularOne",
  "TiltPrism",
  "Ultra",
  "YesevaOne",
  "CherrySwash",
  "FasterOne",
  "LondrinaSolid",
  "MedievalSharp",
  "Merriweather Italic",
  "MrsSheppards",
  "Nosifer",
  "Pacifico",
  "ProtestStrike",
  "Ramaraja",
  "RampartOne",
  "Rowdies",
  "RubikBrokenFax",
  "RubikBubbles",
  "RubikDoodleShadow",
  "Sen",
  "Stick",
  "TitanOne",
  "Yellowtail"
]

export const basicFontsArray = [
  "Cinzel",
  "Cormorant_Garamond",
  "DM_Sans",
  "Exo_2",
  "Urbanist",
  "Teko",
  "Sen",
  "Roboto",
  "Questrial",
  "PT_Sans_Narrow",
  "Protest_Revolution",
  "Poppins",
  "Playfair_Display",
  "Oswald",
  "Open_Sans",
  "Montserrat",
  "Merriweather",
  "Maven_Pro",
  "Mate_SC",
  "Lato",
  "Helvetica-Font",
  "Freehand",
  "Fredoka",
  "Fraunces",
  "Figtree",
  "Cinzel-Regular",
  "Cinzel-Medium",
  "Cinzel-SemiBold",
  "Cinzel-Bold",
  "Cinzel-ExtraBold",
  "CormorantGaramond-Regular",
  "CormorantGaramond-Medium",
  "CormorantGaramond-SemiBold",
  "CormorantGaramond-Bold",
  "CormorantGaramond-Italic",
  "CormorantGaramond-MediumItalic",
  "CormorantGaramond-SemiBoldItalic",
  "CormorantGaramond-BoldItalic",
  "Exo2-Light",
  "Exo2-Regular",
  "Exo2-Medium",
  "Exo2-SemiBold",
  "Exo2-Bold",
  "Exo2-ExtraBold",
  "Exo2-LightItalic",
  "Exo2-Italic",
  "Exo2-MediumItalic",
  "Exo2-SemiBoldItalic",
  "Exo2-BoldItalic",
  "Exo2-ExtraBoldItalic",
  "Figtree-Regular",
  "Figtree-Medium",
  "Figtree-SemiBold",
  "Figtree-Bold",
  "Figtree-ExtraBold",
  "Figtree-Italic",
  "Figtree-MediumItalic",
  "Figtree-SemiBoldItalic",
  "Figtree-BoldItalic",
  "Figtree-ExtraBoldItalic",
  "Frendoka-Regular",
  "Frendoka-Medium",
  "Frendoka-SemiBold",
  "Frendoka-Bold",
  "Freehand-Regular",
  "Helvetica-Light",
  "Helvetica-Regular",
  "Helvetica-Bold",
  "Helvetica-Compressed",
  "Lato-Light",
  "Lato-Regular",
  "Lato-Black",
  "Lato-Bold",
  "Lato-LightItalic",
  "Lato-Italic",
  "Lato-BlackItalic",
  "Lato-BoldItalic",
  "MateSC-Regular",
  'MavenPro-Regular',
  'MavenPro-Medium',
  'MavenPro-SemiBold',
  'MavenPro-Bold',
  'MavenPro-ExtraBold',
  'Montserrat-Light',
  'Montserrat-Regular',
  'Montserrat-Medium',
  'Montserrat-SemiBold',
  'Montserrat-Bold',
  'Montserrat-ExtraBold',
  'Montserrat-LightItalic',
  'Montserrat-Italic',
  'Montserrat-MediumItalic',
  'Montserrat-SemiBoldItalic',
  'Montserrat-BoldItalic',
  'Montserrat-ExtraBoldItalic',
  'OpenSans-Light',
  'OpenSans-Regular',
  'OpenSans-Medium',
  'OpenSans-SemiBold',
  'OpenSans-Bold',
  'OpenSans-ExtraBold',
  'OpenSans-LightItalic',
  'OpenSans-Italic',
  'OpenSans-MediumItalic',
  'OpenSans-SemiBoldItalic',
  'OpenSans-BoldItalic',
  'OpenSans-ExtraBoldItalic',
  'Oswald-Light',
  'Oswald-Regular',
  'Oswald-Medium',
  'Oswald-SemiBold',
  'Oswald-Bold',
  'PlayFair Display-Regular',
  'PlayFair Display-Medium',
  'PlayFair Display-SemiBold',
  'PlayFair Display-Bold',
  'PlayFair Display-ExtraBold',
  'PlayFair Display-Italic',
  'PlayFair Display-MediumItalic',
  'PlayFair Display-SemiBoldItalic',
  'PlayFair Display-BoldItalic',
  'PlayFair Display-ExtraBoldItalic',
  'Poppins-Light',
  'Poppins-Regular',
  'Poppins-Medium',
  'Poppins-SemiBold',
  'Poppins-Bold',
  'Poppins-ExtraBold',
  'Poppins-LightItalic',
  'Poppins-Italic',
  'Poppins-MediumItalic',
  'Poppins-SemiBoldItalic',
  'Poppins-BoldItalic',
  'Poppins-ExtraBoldItalic',
  'ProtestRevolution-Regular',
  'PTSansNarrow-Regular',
  'PTSansNarrow-Bold',
  'Questrial-Regular',
  'Roboto-Light',
  'Roboto-Regular',
  'Roboto-Medium',
  'Roboto-Bold',
  'Roboto-LightItalic',
  'Roboto-Italic',
  'Roboto-MediumItalic',
  'Roboto-BoldItalic',
  'Teko-light',
  'Teko-Regular',
  'Teko-Medium',
  'Teko-SemiBold',
  'Teko-Bold',
  'Times-New-Roman-Regular',
  'Times-New-Roman-Bold',
  'Times-New-Roman-Italic',
  'Times-New-Roman-Bold-Italic',
  'Urbanist-Light',
  'Urbanist-Regular',
  'Urbanist-Medium',
  'Urbanist-SemiBold',
  'Urbanist-Bold',
  'Urbanist-ExtraBold',
  'Urbanist-LightItalic',
  'Urbanist-Italic',
  'Urbanist-MediumItalic',
  'Urbanist-SemiBoldItalic',
  'Urbanist-BoldItalic',
  'Urbanist-ExtraBoldItalic',
];

export const colors = [
  "linear-gradient(180deg, #FAD057 -52.06%, #EF986E 99.79%)",
  "linear-gradient(180deg, #B0ECD3 -6.57%, #0D959A 99.95%)",
  "linear-gradient(0deg, #9796F0 0%, #DCBCF9 60.36%, #FBC7D4 135.71%)",
  "linear-gradient(0deg, #F68DB1 0.21%, #FF9472 100%)",
  "linear-gradient(0deg, #EDE574 0%, #DCE0A3 84.29%, #E1F5C4 133.57%)",
  "linear-gradient(180deg, #FAD057 -52.06%, #EF986E 99.79%)",
  "linear-gradient(180deg, #B0ECD3 -6.57%, #0D959A 99.95%)",
  "linear-gradient(0deg, #9796F0 0%, #DCBCF9 60.36%, #FBC7D4 135.71%)",
  "linear-gradient(180deg, #FAD057 -52.06%, #EF986E 99.79%)",
  "linear-gradient(180deg, #B0ECD3 -6.57%, #0D959A 99.95%)",
  "linear-gradient(0deg, #9796F0 0%, #DCBCF9 60.36%, #FBC7D4 135.71%)",
  "linear-gradient(0deg, #F68DB1 0.21%, #FF9472 100%)",
  "linear-gradient(0deg, #EDE574 0%, #DCE0A3 84.29%, #E1F5C4 133.57%)",
  "linear-gradient(180deg, #FAD057 -52.06%, #EF986E 99.79%)",
  "linear-gradient(180deg, #B0ECD3 -6.57%, #0D959A 99.95%)",
  "linear-gradient(0deg, #9796F0 0%, #DCBCF9 60.36%, #FBC7D4 135.71%)",
  "linear-gradient(0deg, #F68DB1 0.21%, #FF9472 100%)",
  "linear-gradient(0deg, #EDE574 0%, #DCE0A3 84.29%, #E1F5C4 133.57%)",
];


export const DISCOUNT_CODES = {
  DISC_10: "10%",
  DISC_20: "20%",
  DISC_30: "30%",
  DISC_40: "40%",
};

export default {
  APPLICATION_INFO,
  STRIPE_INFO,

  PASSWORD_RESET_SUCCESSFULLY,
  ERROR_OCCURRED_DURING_CONFIRMATION,
  EXPIRED_INVALID_CODE,
  EMAIL_VERIFIED,
  PASSWORD_RESET_MSG,

  ITEMS_PER_PAGE,
  SORT_OPTIONS,
  APPLICATION_ERROR_MESSAGE,
  URLS,
  LOGIN_TYPE_ANNONYMOUS,
  LOGIN_TYPE_GOOGLE,
  LOGIN_TYPE_EMAIL,
  LOGIN_TYPE_FACEBOOK,
  LOGIN_TYPE_APPLE,
  PLAN_YEAR,
  PLAN_MONTH,
  PLAN_TYPE_LITE,
  PLAN_TYPE_PRO,
  PLAN_TYPE_ULTRA,
  PLAN_TYPE_FREE_TRIAL,
  GOOGLE_CLIENT_ID,
  PAYPAL_PAYMENT_STATUS,
  PAYPAL_API_CLIENT_ID,

  PAYPAL_YEARLY_LITE_PLAN_ID,
  PAYPAL_YEARLY_LITE_PLAN_NAME,
  PAYPAL_YEARLY_LITE_PRICE,

  PAYPAL_YEARLY_PRO_PLAN_ID,
  PAYPAL_YEARLY_PRO_PLAN_NAME,
  PAYPAL_YEARLY_PRO_PRICE,

  PAYPAL_YEARLY_ULTRA_PLAN_ID,
  PAYPAL_YEARLY_ULTRA_PLAN_NAME,
  PAYPAL_YEARLY_ULTRA_PRICE,

  PAYPAL_MONTHLY_LITE_PLAN_ID,
  PAYPAL_MONTHLY_LITE_PLAN_NAME,
  PAYPAL_MONTHLY_LITE_PRICE,
  PAYPAL_MONTHLY_PRO_PLAN_ID,
  PAYPAL_MONTHLY_PRO_PLAN_NAME,
  PAYPAL_MONTHLY_PRO_PRICE,

  PAYPAL_MONTHLY_ULTRA_PLAN_ID,
  PAYPAL_MONTHLY_ULTRA_PLAN_NAME,
  PAYPAL_MONTHLY_ULTRA_PRICE,

  PAYPAL_YEARLY_TRIAL_PLAN_ID,
  PAYPAL_YEARLY_TRIAL_PLAN_NAME,
  PAYPAL_YEARLY_TRIAL_PRICE,

  PAYPAL_MONTHLY_TRIAL_PLAN_NAME,
  PAYPAL_MONTHLY_TRIAL_PRICE,
  PAYPAL_ENVIRONMENT,
  errorMessage,
  PRO_SUBSCRIBER_MSG,
  LITE_SUBSCRIBER_MSG,
  PAYMENT_SUCCESS_MSG,
  LOGIN_MSG,
  SIGNUP_MSG,
  FORGET_PASSWORD_MSG,

  FREE_TRIAL,
  QUICK_FREE_TRIAL,
  CREATE_ACCOUNT,
  INITIATE_CHECKOUT,
  SUCCESS_PAYMENT,
  MONTHLY_TRIAL_PRICE,
  YEARLY_TRIAL_PRICE,
  LIVE_PAYMENT,
  colors,
  DISCOUNT_CODES,
};

import React from 'react';

const BestSeller = (props) => {
    const getBestSellerCss = () => {
        
        if (window.location.pathname == "/") {
            return "create-new-ads";
        }
        return "best-seller-div";
      };

  return (
    <div className={`div ${getBestSellerCss()}`}>
                  <div className="create-new-ads ">Best Seller</div>
                </div>
  )
}

export default BestSeller

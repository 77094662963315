import React from "react";
import useWindowSize from "../../../_helper/ScreenUtil";

const UnleaseImageAds = (props) => {
  const { width } = useWindowSize();
  const isMobile = width <= 768;

  return (
    <>
      <div className="nd-headig position-relative z-index-1">
        <div className="generate-unbelievable-effect-container">
          <div className="unlimited-captions-txt-container">
            <p className="x-higher-roas-on-average-using">
              Revolutionize your
              {/*<span className="incredible cursor-pointer"> of AI Ads to produce </span>*/}
            </p>
            <p className="cursor-pointer">marketing with </p>
            <div className="mb-40">
              <div className="image-ads-wrapper">
                <div className="image-ads">AI ads!</div>
              </div>
              <p className={`font-family-vietnam-pro text-center ${isMobile ? "text-size-24 m-30 line-h-40" : "text-size-28"}`}>(Sales from ads generated by Adspire<sup>®</sup>&nbsp;)</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default UnleaseImageAds;

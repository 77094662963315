import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { convertToBase64 } from "../../../_helper/convertToBase64";
import API_FIREBASE from "../../../config/apifirebase";
import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import circleCheck from "../../../images/icons/circle-check.svg";
import { userService } from "../../../services/user.service";
import constants from "../../../utils/constants";
import { getWebsiteTitle } from "../../../utils/function";
import ShowErrorModel from "../../common/popup/ShowErrorModel";

const UploadProduct = () => {
  const [adCategory, setAdCategory] = useState("");
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [active, setActive] = useState(null);
  const [loading, setLoader] = useState(false);
  const [userCredit, setUserCredit] = useState(0);
  const [selectedFile, setSelectedFile] = useState(null);
  const [generatedImage, setGeneratedImage] = useState('');
  const [errorMessage, setErrorMessage] = useState("")
  const [showModal, setShowModel] = useState(false)

  // Accessing the history instance created by React
  const navigate = useNavigate();
  useEffect(() => {
    if (!userService.isLoggedInUser()) {
      navigate("/login");
    }
  }, [!userService.isLoggedInUser()]);


  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    const acceptedTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/webp'];
    if (acceptedTypes.includes(file.type)) {
      setSelectedFile(file);
      setErrorMessage('');
      setLoader(true)
      await handleUpload(file)
    } else {
      setSelectedFile(null);
      setShowModel(true)
      setErrorMessage('Only JPEG, JPG, PNG, and WEBP images are allowed!');
    }
  };


  const handleUpload = async (selectedFile) => {
    if (selectedFile) {
      setLoader(true)
      setShowModel(false)
      const resBase64 = await convertToBase64(selectedFile);
      const pureBase64 = resBase64.substring(resBase64.indexOf(',') + 1);

      if (userService.checkCredits()) {
        const userId = userService.getUserId();
        const objGenerateImage = {
          userId,
          formattedPrompt: 'Generate a creative, effective, engaging background idea for an image ad for ' + userService.getValueFromLocalstorage("uploadInput") + ' (It should just be a background without any person in it, 1-2 sentences max) Then generate a really captivating and effective headline (MAX 4 words, 18 characters MAX), description (4-12 words, 55 characters MAX) and CTA (call-to-action button text, max 2 words, 10 characters MAX) text to put on the image ad.',
          imageData: pureBase64
        };

        API_FIREBASE.post("requestBackgroundReplacement", objGenerateImage, {
          headers: {
            authkey: constants.APPLICATION_INFO.ADSPIRE_API_HEADER_KEY,
          },
        })
          .then((res) => {
            setLoader(false);
            userService.setKeyValueLocalstorage("uploadedImageResponse", res.data);
            navigate("/adflow-listing");
          }, [])
          .catch((e) => {
            setErrorMessage("You do not have enough credits for this action.")
            setLoader(false);
            setSelectedFile(null)
            setShowModel(true)
          }).finally(() => {
            setLoader(false);
          });
      }
    } else {
      console.log("No file selected.");
    }
  };

  document.title = getWebsiteTitle() + "Upload Product";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="">
            <div className="top-contentDIv">
              <div className="top-ranckking upload-product">
                {userService.showLogoutBtn() && (
                  <h1 className="text-center">Upload your product photo:</h1>
                )}


                {loading && (
                  <div className="row">
                    <div
                      className=" top-ranckking text-center"
                    >
                      <img
                        width={100}
                        className=""
                        src={Rolling_circle_loader}
                        alt="Generating Image Ads..."
                        title="Generating Image Ads..."
                      />

                      <div className="clearfix"></div>
                      <p>{generatedImage}</p>
                      <div className=" text-center"><h2 className="gradient-head-text text-center text-size-32 mb-40">Generating Image Ads...

                        <br></br>
                        Please wait..<br></br>
                        This can take up to 1 minute<br></br>
                      </h2></div>

                    </div>
                  </div>)}

                <div className="clearfix"></div>
                <div className="col-sm-6 col-sm-offset-3">
                  <div className="img-upload">
                    {!loading && (<div className="text-center inp-upload cursor-pointer">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleFileChange}
                        id="file"
                      />
                    </div>)}

                    {selectedFile && (
                      <div>
                        <p>Selected File: {selectedFile.name}</p>
                        <img
                          src={URL.createObjectURL(selectedFile)}
                          alt="Selected"
                        />
                      </div>
                    )}
                    {!loading && (<p> Upload your product photo</p>)}
                  </div>

                  {userService.showLogoutBtn() && (
                    <>
                      <p> Example:</p>

                      <div className="row mb-20 mt-20">
                        {userService.getValueFromLocalstorage("productData") &&
                          userService
                            .getValueFromLocalstorage("productData")
                            .map((projectsItems, index) => (
                              <div
                                className="col-sm-4 col-xs-12 mt-20 "
                                key={projectsItems.data.input}
                                title={projectsItems.data.input}
                              >
                                <div
                                  className={`text-size-16 ${active == projectsItems.data.input &&
                                    "box-active"
                                    }`}
                                >
                                  <div className="btn-text-left">
                                    <img
                                      data-toggle="modal"
                                      data-target="#myModal"
                                      className="img-thumbnail img-radius pointerEvents"
                                      alt={projectsItems.data.input}
                                      src={projectsItems.data.adImageUrl}
                                    />

                                    {active == projectsItems.data.input ? (
                                      <img
                                        data-toggle="modal"
                                        data-target="#myModal"
                                        alt={projectsItems.data.input}
                                        width={20}
                                        src={circleCheck}
                                        className="w-20 float-r pointerEvents"
                                      />
                                    ) : (
                                      ""
                                    )}
                                  </div>

                                  <div className="clearfix"></div>
                                </div>
                              </div>
                            ))}
                      </div>
                    </>
                  )}

                </div>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </div>
      </div>
      <ShowErrorModel message={errorMessage} showModal={showModal} closeModal={setShowModel} />
    </React.Fragment>
  );
};
export default UploadProduct;

import React, { useEffect, useState } from "react";
import Rolling_circle_loader from "../../../images/icons/Rolling_circle_loader.svg";
import { userService } from "../../../services/user.service";
import { getWebsiteTitle, removeSpecialCharacters } from "../../../utils/function";
import LoadingSecond from "./LoadingSecond";

const LoadingScreen = (props) => {
  document.title = getWebsiteTitle() + "Preparing...";
  const [loadingProgress, setLoadingProgress] = useState(0);
  const [secondLoading, setSecondLoading] = useState(false);

  const [loading, setLoading] = useState(false);
  let randomText = [
    "Preparing...",
    "Analyzing Input...",
    "Researching " + userService.getValueFromLocalstorage("uploadInputLoading") + " Ads...",
    "Brainstorming... ",
  ]

  // useEffect(() => {
  //   let pageName = "";
  //   let intervalTime = 500;
  //   let random = 9;

  //   if (props.pagename === "adideas") {
  //     pageName = "adideas";
  //     intervalTime = 500;
  //     random = 25;
  //   } else {
  //     pageName = userService.getValueFromLocalstorage("userSelectedAdTypePage");
  //   }

  //   if (pageName === 'quick-ad' || pageName === 'concept-ad') {
  //     intervalTime = 1200;
  //     random = 15;
  //   }


  //   const circle = document.querySelector(".circle");
  //   const interval = setInterval(() => {
  //     setLoadingProgress(prevProgress => {
  //       const randomIncrement = Math.floor(Math.random() * random) + 1;
  //       const newProgress = prevProgress + randomIncrement;

  //       // Update the conic-gradient with smooth transition
  //       const gradient = `conic-gradient(#26ff71, #58fcca, #6dffb9, #6dff93, #26ff71, #26ff71 ${3.6 * newProgress}deg, #666666d9 0%)`;
  //       circle.style.background = gradient;

  //       if (newProgress < 99) {
  //         return newProgress;
  //       } else {
  //         clearInterval(interval);
  //         setSecondLoading(true)
  //         setLoading(true)
  //         return 99;
  //       }
  //     });
  //   }, intervalTime); // Adjust the interval as needed

  //   return () => {
  //     clearInterval(interval);
  //   };
  // }, []);




  useEffect(() => {
    let pageName = "";
    let intervalTime = 500;
    let random = 9;

    if (props.pagename === "adideas") {
      pageName = "adideas";
      intervalTime = 500;
      random = 25;
    } else {
      pageName = userService.getValueFromLocalstorage("userSelectedAdTypePage");
    }

    if (pageName === 'quick-ad' || pageName === 'concept-ad') {
      intervalTime = 1200;
      random = 15;
    }


    // ********************** Loader timing logic and percentage increment ********************************************
    let number = document.getElementById("number");
    let count = 0;
    let intervalId = setInterval(() => {
      if (count == 99) {
       
        setSecondLoading(true)
        setLoading(true)
      } else {
        count += 1;
        number.innerHTML = count + "%";
      }
      setLoadingProgress(count);
    }, 130);
    return () => {
      clearInterval(intervalId);
    };
  }, []);



  let currentText = randomText[0];
  switch (true) {
    case loadingProgress > 0 && loadingProgress < 30:
      currentText = randomText[0];
      break;
    case loadingProgress >= 30 && loadingProgress < 45:
      currentText = randomText[1];
      break;
    case loadingProgress >= 45 && loadingProgress < 77:
      currentText = randomText[2];
      break;
    case loadingProgress >= 75:
      currentText = randomText[3];
      break;
    default:
      // Handle the case when loadingProgress is outside of the specified ranges
      currentText = "Preparing...";
      break;
  }



  return (
    <React.Fragment>
      {!secondLoading &&

        (<div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="top-contentDIv col-xs-12  loading-container-new-lading-page">
                <div className="top-ranckking  ">
                  {/* //////////////// */}
                  <div className="loading-screen">
                    <div className="loading-items-container2">
                      {/* <div className="circle-wrapper">
                      <div className="circle">
                        <div className="loading-text">{loadingProgress}%</div>
                      </div>
                    </div> */}

                      <div className="skill">
                        <div className="outers">
                          <div className="inners">
                            <div id="number"></div>
                          </div>
                        </div>
                        <div className="svg-outer-div">
                          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" width="300px" height="300px">
                            <defs>
                              <linearGradient id="GradientColor">
                                <stop offset="0%" stop-color="#26FF71" />
                                <stop offset="68%" stop-color="#6DFFB9" />
                                <stop offset="100%" stop-color="#26FF71" />
                              </linearGradient>
                            </defs>
                            <circle cx="150" cy="150" r="140" stroke-linecap="round" />
                          </svg>
                        </div>
                      </div>

                      <div className="progress-bar-and-content-container">
                        {/* <div className="progress-container">
                          <progress id="file" className="custom-progress-one" value={loadingProgress} max="100"></progress>
                        </div> */}
                        <div className="">
                          <span className={`progress-random-text-one ${removeSpecialCharacters(currentText).toLowerCase()}`}>
                            {currentText}</span>
                        </div>
                        {loading && (
                          <div className="row">
                            <div
                              className="top-ranckking text-center"
                            >
                              <img
                                width={50}
                                className=""
                                src={Rolling_circle_loader}
                                alt="Generating Ads..."
                                title="Generating Ads..."
                              />
                            </div>
                          </div>)}
                      </div>
                    </div>
                  </div>
                  {/* //////////////// */}
                </div>
              </div>
              <div className="clearfix"></div>
            </div>
          </div>
        </div>)
      }
      {secondLoading && (<LoadingSecond></LoadingSecond>)}
    </React.Fragment>
  );
};
export default LoadingScreen;

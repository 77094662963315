import { userService } from "../services/user.service";
import { db } from "./Firebase"; // Make sure to import your Firebase configuration
export const fetchAdProductTypes = async () => {
  try {
    const snapshot = await db
      .collection("adIdeas")
      .doc("quickIdeaGenerator")
      .collection("adGeneration")
      .doc("productTypes")
      .collection("types")
      .orderBy("order")
      .get();

    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return dataReceived;
  } catch (error) {
    console.error("Error getting ad product types:", error);
    throw error;
  }
};

export const updateLocalStorageFromSnapshot = async () => {
  try {
    const snapshot = await db.collection("adIdeas").doc("ideasGenerator").get();

    if (snapshot.exists) {
      const data = snapshot.data();
      userService.setKeyValueLocalstorage("prompt", data.prompt);
      userService.setKeyValueLocalstorage("maxIdeas", data.maxIdeas);
      userService.setKeyValueLocalstorage("model", data.model);
      userService.setKeyValueLocalstorage("temperature", data.temperature);
      userService.setKeyValueLocalstorage("maxTokens", data.maxTokens);
    }
  } catch (error) {
    console.error("Error getting document:", error);
    throw error;
  }
};

export const fetchBrandImages = async () => {
  try {
    const snapshot = await db
      .collection("brandImages")
      .orderBy("order", "asc")
      .get();
    const dataReceivedBrand = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    return dataReceivedBrand;
  } catch (error) {
    console.error("Error getting brand images:", error);
    throw error;
  }
};

export const fetchProductPresets = async () => {
  try {
    const snapshot = await db.collection("productPresets").get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    // Filter the data array to only keep objects with id "ConceptAds"
    const allDataArray = dataReceived
      .filter((item) => item.id === "ConceptAds")
      .flat();
    return allDataArray[0]?.data.adImageURLs || [];
  } catch (error) {
    console.error("Error getting product presets:", error);
    throw error;
  }
};

export const fetchSalesCollection = async () => {
  try {
    const snapshot = await db
      .collection("salesCollection")
      .get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    // Filter the data array to only keep objects with id "introSalesImages"
    return dataReceived
      .filter((item) => item.id === "introSalesImages")
      .flat();
  } catch (error) {
    console.error("Error getting brand images:", error);
    throw error;
  }
};

export const fetchSalesCollectiona_DELETE = async (setImageSlider) => {
  try {
    const snapshot = await db.collection("salesCollection").get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    // Filter the data array to only keep objects with id "introSalesImages"
    const allDataArray = dataReceived
      .filter((item) => item.id === "introSalesImages")
      .flat();
    setImageSlider(allDataArray[0]?.data.adImageURLs);
  } catch (error) {
    console.error("Error getting sales collection:", error);
    throw error;
  }
};

export const fetchAdCategories = async (setAdCategory, setLoader) => {
  let unsubscribe; // Declare a variable to hold the unsubscribe function

  try {
    const snapshot = await db
      .collection("adIdeas")
      .doc("ideasGenerator")
      .collection("adCategories")
      .orderBy("order")
      .onSnapshot((querySnapshot) => {
        const dataReceived = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setAdCategory(dataReceived);
        setLoader(true);
      });

    // Store the unsubscribe function
    unsubscribe = () => snapshot();

    return snapshot; // You may want to return the snapshot for potential further use
  } catch (error) {
    console.error("Error getting ad categories:", error);
    throw error;
  } finally {
    // Make sure to call the unsubscribe function in the cleanup
    if (unsubscribe) {
      unsubscribe();
    }
  }
};


export const fetchProductPresetsSiximage = async () => {
  try {
    const snapshot = await db.collection("productPresets").get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    // Filter the data array to only keep objects with id "ConceptAds"
    const allDataArray = dataReceived
      .filter((item) => item.id === "ConceptAds")
      .flat();
    return allDataArray[0]?.data.adImageURLs.slice(0, 6) || [];
  } catch (error) {
    console.error("Error getting product presets:", error);
    throw error;
  }
};

export const fetchAdIdeas = async () => {
  try {
    const snapshot = await db.collection("adIdeas").doc("imageGenerator").get();

    if (snapshot.exists) {
      const data = snapshot.data();
      userService.setKeyValueLocalstorage("promptImage", data.prompt);
      userService.setKeyValueLocalstorage("size", data.size);
      userService.setKeyValueLocalstorage("maxImages", data.maxImages);
    }
  } catch (error) {
    console.error("Error getting ad ideas:", error);
    throw error;
  }
};

export const fetchStyles = async (setStyles, setLoader) => {
  try {
    const snapshot = await db
      .collection("adIdeas")
      .doc("imageGenerator")
      .collection("styles")
      .orderBy("order")
      .get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    setStyles(dataReceived);
    setLoader(true);
  } catch (error) {
    console.error("Error getting product presets:", error);
    throw error;
  }
};

export const fetchAdCarousel = async (setImageSlider) => {
  try {
    const snapshot = await db.collection("adCarousel1").get();
    return snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
  } catch (error) {
    console.error("Error getting product presets:", error);
    throw error;
  }
};

export const fetchEmail = async () => {
  try {
    const snapshot = await db.collection("stripePaymentLogVerifyPage").get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    const filteredData = dataReceived.filter(
      (item) => !item.data.email.includes("yopmail.com")
    );

    // Extract only email IDs
    const emailIds = filteredData.map((item) => item.data.email);
  } catch (error) {
    console.error("Error getting product presets:", error);
    throw error;
  }
};

export const updateUsersCollection = (downloadObjCount) => {
  // Get a reference to the document in the collection using the user ID
  const userId = userService.getUserId();
  const docRef = db.collection("users").doc(userId);
  // Update the document with the new data
  docRef
    .update(downloadObjCount)
    .then(function () {
      // console.log("Document successfully updated!" , downloadObjCount);
    })
    .catch(function (error) {
      console.error("Error updating document: ", error);
    });
};

export const fetchRemoteConfig = async () => {
  try {
    const snapshot = await db
      .collection("websiteConfig")
      .get();
    const dataReceived = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    userService.setKeyValueLocalstorage("remoteConfig", dataReceived[0]['data']);
  } catch (error) {
    console.error("Error getting remote Config", error);
    throw error;
  }
};

export const fetchBackgroundTheme = async () => {
  try {
    const snapshot = await db
      .collection("adIdeas").doc("backgroundImageGenerator").collection("themes").orderBy("order", "asc")
      .get();
    const themes = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return themes;
  } catch (error) {
    console.error("Error getting remote Config", error);
    throw error;
  }
};

export const fetchBackground = async (ids) => {
  try {
    const snapshot = await db
      .collection("adIdeas").doc("backgroundImageGenerator").collection("themes").doc(ids).collection("backgrounds").orderBy("order", "asc")
      .get();
    const themes = snapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));

    return themes;
  } catch (error) {
    console.error("Error getting remote Config", error);
    throw error;
  }
};




export const getAffiliatePlanIndex = async () => {
  try {
    const paramKey = userService.getValueFromLocalstorage("affliateCode");
    const snapshot = await db.collection("affliatesPlans").where("paramKey", "==", paramKey).get();

    if (!snapshot.empty) {
      const data = snapshot.docs[0].data(); // Accessing the first document directly
      return data.planIndex || 2; // Use planIndex if it exists, otherwise default to 2
    } else {
      return 2; // Return default value if no matching document found
    }
  } catch (error) {
    return 2; // Return default value if no matching document found
    // throw error;
  }
};


export const addAffliateLog = (affliateCode) => {
  const logData = {
    userId: userService.getUserId(),
    name: userService.getValueFromLocalstorage("fullName"),
    email: userService.getValueFromLocalstorage("signupEmail"),
    affliateCode: affliateCode,
    environment: process.env.REACT_APP_STATUS,
    createdAt: new Date(),
  };

  try {
    db.collection("affliateLog").add(logData)
      .then((docRef) => {
        console.log("Document written with ID:", docRef.id);
      })
      .catch((error) => {
        console.error("Error adding document:", error);
      });
  } catch (error) {
    console.error("Error adding document:", error);
  }
};


import React from 'react';
import Modal from 'react-modal';
import freetrial from "../images/free-trial.png";
import freead from "../images/freead.png";

Modal.setAppElement('#root'); // Set the root element for accessibility

const Newpopup = (props) => {

  const cls = () => {
    props.closePopup();
  }


  return (
    <>
      <div className={`modal show show-in-desktop-only`} style={{ marginTop: "0%", background: "#0000007d" }}>
        <div className="modal-dialog modal-xl modal-dialog-centered text-open-sans" role="document">
          <div className="modal-gradient-new ">
            <div className="modal-header d-flex justify-content-between">
              <div></div>
              <h5 className="modal-title hedertitle" >WHY COMPLETE REGISTRATION NOW?</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={() => cls()}>
                <span aria-hidden="true" className="text-black">x</span>
              </button>
            </div>
            <div className="modal-body p-0">
              <div className="container-fluid">
                <div className="row">
                  <div className=''>
                    <div className="col-md-6">
                      <div className="popupcard">
                        <div className="popupcard-content">
                          <h2>3-day 100% free trial!</h2>
                        </div>
                        <div className="card-link">
                          <a href="#">
                            <img src={freetrial} alt="Link Image" width={285} />
                          </a>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 ml-auto"><div className="popupcard pdbtm">
                      <div className="popupcard-content pdbtm">
                        <h2>First 5 ads are free!</h2>
                      </div>
                      <div className="card-link ">
                        <a href="#" >
                          <img src={freead} alt="Link Image" className="adimg" width={100} />
                        </a>
                      </div>
                    </div></div>
                    <div className="col-md-6">
                      <div className="popupcard bgimg">
                        <div className="card-content">
                          <h2>Get better ROI, or get a refund.</h2>
                        </div>
                        <div className="card-link pmlnt">
                          <a href="#" className="btn btn-primary buttonpri bg-btn-lite-green-lite-orange">
                            Yes, we are that confident in our tech.
                          </a>
                        </div>
                      </div></div>
                    <div className="col-md-6 ml-auto"><div className="popupcard bgimg1">
                      <div className="col-md-6">
                        <h2 className='text-size-44 text-weight-600 text-left m-5 text-white'>Pay</h2>
                        <h2 className='text-size-44 text-weight-600 text-left m-5 text-white'>only</h2>
                        <h2 className='text-size-44 text-weight-600 text-left m-5 text-white'>if you</h2>
                        <h2 className='text-size-44 text-weight-600 text-left m-5 text-white'>love it.</h2>
                      </div>
                      <div className="tcenter">
                        <div className="card-link ">
                          <a href="#" className="btn btn-primary buttonpsec">
                            Upgrade to Paid
                          </a>
                        </div>
                        <div className="marginor">or</div>
                        <div className="card-link">
                          <a href="#" className="btn btn-primary buttonpri">
                            <span className="gradient-text">Cancel Account</span>
                          </a>
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Newpopup;

import React from "react";
import StyledButton1 from "../Button/StyledButton1";
import { useState } from "react";


const ProductCaptionModal = (props) => {
  const [errorMessage, setErrorMessage] = useState();

  return (
    <>
      {props?.showModal ? (
        <div className={`modal show overflows-auto bg-dark-opacity-10`}>
          <div
            className={`modal-dialog modal-450 modal-350-mobile modal-dialog-centered text-open-sans top-20-percent animate-top-to-bottom`}
            role="document"
          >
            <div className="modal-content modal-gradient-new quick-ad-flow-modal">
              <div className="modal-body text-center form-group">
                <div className="">
                  <button
                    type="button"
                    onClick={() => props?.setShowModal(false)}
                    className="close"
                  >
                    <span aria-hidden="true">x</span>
                  </button>
                </div>
                <div className="text-left font-20-regular text-weight-600 ">
                  What {props?.showedText} do you sell?
                </div>
                <div>
                  {errorMessage && (
                    <div
                      className="text-left error-message"
                      style={{
                        color: "#e8e8d9",
                        marginBottom: "10px",
                        fontSize: "16px",
                      }}
                    >
                      {errorMessage}
                    </div>
                  )}
                  <input
                    type="text"
                    value={props?.userProductName}
                    className="user-product-name"
                    onChange={props?.handleChange}
                    placeholder="Ex: brown leather jacket"
                  ></input>
                </div>
                <div>
                  <StyledButton1
                    onClick={props?.handleSubmit}
                    name={"Next"}
                    btnTheme={"light"}
                    btnHeight={"45px"}
                  />
                </div>
                <div className="mt-30">
                  <p className="cursor-pointer" onClick={props?.handleSubmit}>Skip</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null
      }
    </>
  );
};
export default ProductCaptionModal;

import { headlineFontsArray, basicFontsArray } from "./constants";

export const base64RequiredImage = [0, 11, 12, 3, 1, 14];

export const getStyleMaps = async (i, lightColor, darkColor) => {

  const headlineFont = getHeadlineFont(i);

  const descriptionFont = getBasicFont(i);

  const perksFont = getBasicFont(i + 1);
  const questionFont = getBasicFont(i + 2);
  const ctaFont = getBothFont(i + 3);

  const reviewNameFont = getHeadlineFont(i + 1);

  let styleMaps = {
    captionType: "",
    captionAlignment: "center",
    canvasColor: darkColor,
    headlineFontColor: lightColor,
    headlineBorderWidth: 2,
    headlineBorderColor: lightColor ? darkColor : lightColor,
    ctaShadowColor: lightColor,
    ctaButtonColor: darkColor,
    ctaFontColor: lightColor,
    ctaButtonRadius: 40,
    questionFontColor: lightColor,
    questionBorderWidth: 2,
    questionBorderColor: darkColor,
    perksFontColor: darkColor,
    perksColor: lightColor,
    perksShadowColor: darkColor,
    descriptionFontSize: 34,
    descriptionFontColor: "#ffffff",
    descriptionFontWeight: 500,
    headlineFont,
    descriptionFont,
    perksFont,
    questionFont,
    ctaFont,
    reviewNameFont,
    imageShadowColor: lightColor,
    canvasColorSecondary: lightColor,
    headlineFontSize: 34, // default added by kishore
    reviewNameFontColor: lightColor,
    //ctaFontSize;
    reviewMessageFontColor: lightColor,
  };



  switch (i) {
    // NEW LAYOUT
    case 0:  // 10 number changed to 0
      styleMaps.captionType = "bottomRightImageWithInfo";
      styleMaps.canvasColor = darkColor;
      styleMaps.descriptionFontSize = 48; //make it like headline
      styleMaps.imageShadowColor = lightColor;
      styleMaps.descriptionFontColor = "#fff";//darkColor;
      styleMaps.canvasColorSecondary = lightColor;
      styleMaps.headlineBorderWidth = 0;
      break;
    case 1:  // 15 to 1 changed
      styleMaps.captionType = "stacksWithImage";
      styleMaps.canvasColor = darkColor;
      styleMaps.canvasColorSecondary = lightColor;
      styleMaps.ctaButtonColor = lightColor;
      styleMaps.ctaFontColor = darkColor;
      styleMaps.headlineFontColor = darkColor;
      styleMaps.headlineBorderWidth = 0;
      styleMaps.descriptionFontColor = darkColor;
      break;
    case 2:  // 5 to 2 
      styleMaps.captionType = "leftRect";
      styleMaps.captionAlignment = "left";
      styleMaps.canvasColor = lightColor;
      styleMaps.headlineFontColor = darkColor;
      styleMaps.ctaShadowColor = darkColor;
      styleMaps.headlineBorderWidth = 0;
      break;
    case 3: // 13 to 3
      styleMaps.captionType = "rectangleImageCTAHeadline";
      styleMaps.canvasColor = darkColor;
      styleMaps.headlineBorderWidth = 0;
      styleMaps.imageShadowColor = lightColor;
      break;
    case 4:
      styleMaps.captionType = "bottomRect";
      styleMaps.descriptionFontSize = 48;
      styleMaps.descriptionFontWeight = 900;
      styleMaps.descriptionFontColor = darkColor;
      styleMaps.canvasColor = lightColor;
      styleMaps.ctaButtonColor = darkColor;
      styleMaps.ctaShadowColor = darkColor;
      break;
    case 5:
      styleMaps.captionAlignment = "center";
      styleMaps.captionType = "perks";
      styleMaps.perksShadowColor = darkColor;
      styleMaps.perksColor = lightColor;
      break;
    case 6:
      styleMaps.captionType = "bottomInfo";
      styleMaps.captionAlignment = "center";
      styleMaps.headlineFontColor = "#0B0B0B"; // black
      styleMaps.descriptionFontColor = "#0B0B0B"; // black
      styleMaps.ctaButtonColor = darkColor;
      styleMaps.ctaFontColor = "#FFFFFF"; // white
      styleMaps.canvasColor = lightColor;
      styleMaps.headlineFontSize = 30;
      styleMaps.headlineBorderWidth = 0;
      break;
    case 7:
      styleMaps.captionType = "sale";
      styleMaps.captionAlignment = "center";
      styleMaps.canvasColor = lightColor;
      break;
    case 8:
      styleMaps.captionType = "review";
      styleMaps.captionAlignment = "center";
      styleMaps.canvasColor = darkColor;
      styleMaps.reviewMessageFontColor = lightColor;
      styleMaps.reviewNameFontColor = lightColor;
      break;
    case 9:
      styleMaps.captionType = "question";
      //styleMaps.captionAlignment = "center";
      styleMaps.headlineBorderWidth = 0;
      styleMaps.ctaFontSize = 36;
      break;
    case 10:
      styleMaps.captionAlignment = "center";
      styleMaps.captionType = "info";
      styleMaps.descriptionFontSize = 48;
      styleMaps.descriptionFontWeight = 900;
      styleMaps.descriptionFontColor = lightColor;
      break;
    case 11:
      styleMaps.captionType = "centerImageWithDescCTA";
      styleMaps.headlineBorderWidth = 0;
      styleMaps.canvasColor = darkColor;
      styleMaps.descriptionFontSize = 48; //make it like headline
      styleMaps.imageShadowColor = lightColor;
      styleMaps.descriptionFontColor = darkColor;
      styleMaps.canvasColorSecondary = lightColor;
      break;
    case 12:
      styleMaps.captionType = "centerImageCTAWithTopHeadline";
      styleMaps.canvasColor = lightColor;
      styleMaps.canvasColorSecondary = darkColor;
      styleMaps.imageShadowColor = lightColor;
      styleMaps.headlineBorderWidth = 0;
      styleMaps.headlineFontSize = 34;
      styleMaps.headlineFontColor = darkColor;
      break;
    case 13:
      styleMaps.captionAlignment = "left";
      styleMaps.captionType = "info";
      //styleMaps.descriptionFontSize = 48;
      //styleMaps.descriptionFontWeight = 900;
      // styleMaps.descriptionFontColor = lightColor;
      styleMaps.headlineFontSize = 48;
      styleMaps.headlineBorderWidth = 0;
      break;
    case 14:
      styleMaps.captionType = "questionWithCircularImage";
      styleMaps.canvasColor = lightColor;
      styleMaps.questionFontColor = darkColor;
      styleMaps.imageShadowColor = darkColor;
      break;
    case 15:
      styleMaps.captionAlignment = "center";
      styleMaps.captionType = "simple";
      styleMaps.headlineFontSize = 46;
      styleMaps.headlineBorderWidth = 0;
      break;
    default:
      break;
  }

  return styleMaps;
};

const getHeadlineFont = (i) => {
  // Generate a random index within the range of the array length
  const randomIndex = Math.floor(Math.random() * headlineFontsArray.length);
  // Return the font at the randomly generated index
  return headlineFontsArray[randomIndex];
}
const getBasicFont = (i) => {
  // Generate a random index within the range of the array length
  const randomIndex = Math.floor(Math.random() * basicFontsArray.length);
  // Return the font at the randomly generated index
  return basicFontsArray[randomIndex];
}

const getBothFont = (i) => {
  const mergedFontsArray = [...headlineFontsArray, ...basicFontsArray];
  // Generate a random index within the range of the array length
  const randomIndex = Math.floor(Math.random() * mergedFontsArray.length);
  // Return the font at the randomly generated index
  return mergedFontsArray[randomIndex];
}

import React, { useEffect, useState } from "react";
import { getRandomNumber } from "../../../utils/function";
import StartFreeTrialBtn from "./StartFreeTrialBtn";
import StyledButton1 from "../../common/Button/StyledButton1";
import { useNavigate } from "react-router-dom";

const SubHeaderLandingVideoFlow = () => {
  const navigate = useNavigate();
  const [video, setVideo] = useState("p54D4H6VKdk");
  useEffect(() => {
    const youtubeVideo = ['p54D4H6VKdk', '9ZdomhAEi2Y', 'H9iOfdGGBiY', 'IhWwaKkgl8k'];
    const i = getRandomNumber(3, 0);
    if (i => 0 && i < 4) {
      setVideo("https://www.youtube-nocookie.com/embed/" + youtubeVideo[i] + "?controls=0&rel=0");
    } else {
      setVideo("https://www.youtube-nocookie.com/embed/" + youtubeVideo[0] + "?controls=0&rel=0");
    }
  }, []);

  const handleSubmitGenerateQuickAds = () => {
    navigate("/signup");
  };

  return (
    <div className="star-parent">
      <div className="group-carousel-parent1">
        <div className="carousel-content-parent">
          <div className="heading-and-body-text position-relative z-index-1 m-20-mobile">
            <div className="generate-incredible-parent">
              <div className="effective-ads-video carousal-heading">
                ADSPIRE
              </div>
              <div className="text-left margin-tb35-lr-0 font-family-sans-serif text-size-24">
                The world's first & only true AI Ad Creatives generator!
              </div>
            </div>
            <div className="width50 width-full-mobile">
              <StyledButton1
                name={"Generate"}
                btnTheme={"light"}
                btnHeight={"45px"}
                onClick={handleSubmitGenerateQuickAds}
              />
            </div>
          </div>
        </div>
        <div className="carousel-gallary-parent">
          <iframe
            width="90%"
            height="350"
            src={video}
            title="Adspire"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
};
export default SubHeaderLandingVideoFlow;

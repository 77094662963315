import { userService } from "../../../services/user.service";
import StripeCheckoutRedirect from "../../../utils/StripeCheckoutRedirect";
import StyledButton1 from "../Button/StyledButton1";
import { useNavigate } from "react-router-dom";
import StyledButton4 from "../Button/StyledButton4";


export default function CheckoutFreetrialQuickMonthlyUltraDiscounted(props) {
  const navigate = useNavigate();

  async function handleCheckout() {

    if (props.componentName ==="StyledButtonMonth") {
      userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID_DISCOUNTED);
    } else if (props.componentName ==="StyledButtonYear") {
      userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_YEARLY_ULTRA_TRIAL_PRICE_ID_DISCOUNTED);
    }
    // userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_MONTHLY_ULTRA_TRIAL_PRICE_ID);
    userService.setKeyValueLocalstorage("clientReferenceId", process.env.REACT_APP_PAYPAL_MONTHLY_ULTRA_TRIAL_PLAN_NAME + "||" + userService.getUserId());

    if (!userService.showLogoutBtn()) {
      navigate("/signup");
    } else {
      StripeCheckoutRedirect();
    }
  }
  return (
    <>
      {props.componentName ==="StyledButton1" && (<StyledButton1 type={props.buttonType} name={props.buttonName} onClick={handleCheckout} />)}

      {props.componentName ==="StyledButtonMonth" && (<StyledButton4 btnTheme={"light"} name={props.buttonName} onClick={handleCheckout} />)}

      {props.componentName ==="StyledButtonYear" && (<StyledButton4 btnHeight={props.btnHeight} btnTextStyle={props.btnTextStyle} amount={props.buttonName} onClick={handleCheckout} />)}
    </>
  )
}
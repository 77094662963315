import { isIOSDevice } from "ios-detector";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getWebsiteTitle } from "../../utils/function";
import { metaDesc } from "../../utils/landingConstants";
import Cookie from "../Cookie";
import IOSMessageModal from "../common/popup/IOSMessageModal";
import FooterLanding from "./common/FooterLanding";
import LandingPageAdFlowWithCarouselsSubPage from "./common/LandingPageAdFlowWithCarouselsSubPage";
import MarqueeLight from "./common/MarqueeLight";
import RoasNew from "./common/RoasNew";
import SubFooter from "./common/SubFooter";
import SubHeader2NewLandingPage from "./common/SubHeader2NewLandingPage";
import Testimonials from "./common/Testimonials";
import "./css/Landing-page.css";
import ImageSlider from "./common/ImageSlider";
import UnleaseImageAds from "./common/UnleaseImageAds";
import line from "../../images/landing_page_assests/line.svg";
import ButtonGradient from "../common/Button/ButtonGradient";

const LandingPageAdFlow = () => {
  const navigate = useNavigate();
  const [pathname, setNewPathName] = useState("");
  const location = useLocation();
  useEffect(() => {
    setNewPathName(location.pathname);
  }, [location]);

  useEffect(() => {
    document
      .querySelector("meta[name='description']")
      .setAttribute("content", metaDesc.LandingPageDescription);
  }, []);

  const msg = {
    msg1: "Generate",
    msg2: "",
    msg3: "EFFECTIVE ADS",
    msg4: "in seconds!",
  };

  document.title =
    getWebsiteTitle() +
    "AI Ad Generator";
  return (
    <>
    
      <div className="confirmed-main-landing-page adflow-page">
        <div className="hero-sections-parent ">
          <div className="hero-sections new-landing-page-background/ new-oct-landing-page">
            <SubHeader2NewLandingPage />
            <LandingPageAdFlowWithCarouselsSubPage></LandingPageAdFlowWithCarouselsSubPage>
          </div>
          <MarqueeLight />

          <div className="nd-sections min-height-100vh new-oct-UnleaseImageAds">
            <img className="line-icon" alt="" src={line} />
            <UnleaseImageAds></UnleaseImageAds>
            <ImageSlider />
          </div>


          <div className="fullwidth">
            <div className="text-center">
              <ButtonGradient
                title="Generate Ads"
                stylebyclass="text-size-24 text-weight-600"
                btnstylebyclass="m-left-right-auto"
                onClick={() => navigate("/signup")}
              />
              <p className="font-family-sans-serif text-size-16 text-light-gray mt-10">Try 100% free for 3 days. <span className="text-weight-600 text-sky-blue">cancel anytime</span></p>
            </div>
          </div>

          {pathname != '/v1' && (<Testimonials />)}
          <RoasNew />
          <SubFooter signup={false} subscribe={true} unleas={false} ideas={false}></SubFooter>
          <FooterLanding></FooterLanding>
          <Cookie />
        </div>
      </div>
    </>
  );
};

export default LandingPageAdFlow;

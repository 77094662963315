import React, { useState, useEffect } from "react";
import FooterLanding from "./FooterLanding";
import HeaderLanding from "./HeaderLanding";
import WelcomeNewSignupLanding from "./WelcomeNewSignupLanding";
import ellipse134_orange from "../../../images/landing_page_assests/ellipse134_orange.svg";
import lines_dots from "../../../images/landing_page_assests/lines-dots.svg";
import star_1 from "../../../images/landing_page_assests/star-1.svg";
import { getWebsiteName } from "../../../utils/function";
import "../css/new-Landing-page.css";
import Newpopup from "../../Newpopup";
import { userService } from "../../../services/user.service";
import { useLocation } from "react-router-dom";


const WelcomeNewSignup = () => {
  const [showExitTabModal, setShowExitTabModal] = useState(false);
  const [permanentClosePopup, setPermanentClosePopup] = useState(true);
  useEffect(() => {
    // Show the popup after a delay
    const timeoutId = setTimeout(() => {
      if (permanentClosePopup) {
        setShowExitTabModal(true);
      }
    }, 15000); // 15 seconds delay

    // Clear the timeout when the component is unmounted
    return () => clearTimeout(timeoutId);
  }, []);

  // Use useEffect to automatically hide the popup after it's shown
  useEffect(() => {
    if (showExitTabModal) {
      // Automatically hide the popup after 5 seconds
      const timeoutId = setTimeout(() => {
        //showExitTabModal(false);
      }, 5000);

      // Clear the timeout when the component is unmounted
      return () => clearTimeout(timeoutId);
    }
  }, [showExitTabModal]);
  const closePopup = () => {
    setShowExitTabModal(false);
    setPermanentClosePopup(false);
  }

  document.title = " Free AI Ads using " + getWebsiteName() + " AI Trial";
  return (
    <div className="confirmed-main-landing-page">
      <div className="hero-sections-parent">
        <div className="hero-sections">
          <div className="free-trail-parebt-div">
            <img className="hero-sections-child" alt="" src={star_1} />
            <img className="lines-dots-icon" alt="" src={lines_dots} />
            <img className="line-dots-icon" alt="" src={lines_dots} />
            <img className="color-bg-orange-icon" alt="" src={ellipse134_orange} />
            <div className="header">
              <HeaderLanding></HeaderLanding>
            </div>
            <WelcomeNewSignupLanding></WelcomeNewSignupLanding>
            <FooterLanding></FooterLanding>
          </div>
        </div>
      </div>
      {showExitTabModal && userService.getValueFromLocalstorage("remoteConfig").autoAdLeavePopup && (
        <Newpopup closePopup={closePopup} />)
      }
    </div>
  );
};

export default WelcomeNewSignup;

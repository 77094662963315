import React from "react";
import stars_5_testemonials from "../../../images/landing_page_assests/stars-5-testemonials.svg";
import time_testemonials from "../../../images/landing_page_assests/time_check_1.svg";
import affordable_testemonials from "../../../images/landing_page_assests/funding_1.svg";
import effective_testemonials from "../../../images/landing_page_assests/creativity_1.svg";
import ad_variations_testemonials from "../../../images/landing_page_assests/gallery_1.svg";
import { useNavigate } from "react-router-dom";
import ButtonGradient from "../../common/Button/ButtonGradient";
import { TESTIMONIAL_VIDEO, TRUSTED_BY_MSG } from "../../../utils/constants";
import useWindowSize from "../../../_helper/ScreenUtil";



const Testimonials = () => {
  const navigate = useNavigate();
  const { width } = useWindowSize();
  const isMobile = width <= 768;



  const bottomData = [
    {
      text: "SAVE TIME",
      subtext: "Get ads done in seconds, not days.",
      colors: "linear-gradient(0deg, #9796F0 0%, #DCBCF9 60.36%, #FBC7D4 135.71%)",
      icon: time_testemonials
    },
    {
      text: "EFFECTIVE ADS",
      subtext: "Our AI is trained on 1,000,000+ effective ads and counting!",
      colors: "linear-gradient(0deg, #EDE574 0%, #DCE0A3 84.29%, #E1F5C4 133.57%)",
      icon: effective_testemonials
    },
    {
      text: "AFFORDABLE",
      subtext: "No more $5K+/month agencies. Plans start at just $20/mo.",
      colors: "linear-gradient(0deg, #00C9FF 0%, #53E7C7 66.74%, #92FE9D 135.28%)",
      icon: affordable_testemonials
    },
    {
      text: "AD VARIATIONS",
      subtext: "10+ completely unique ads each time you generate!",
      colors: "linear-gradient(0deg, #F68DB1 0.21%, #FF9472 100%)",
      icon: ad_variations_testemonials
    }
  ];


  return (
    <div className="roww fullwidth mt-30 mb-40">
      <div className="container-testemonials">
        <div className="row">
          <div className="col-md-12">
            <div className="col-md-12 col-lg-12 col-12">
              <div className={`${isMobile ? "" : "width50"} m-left-right-auto`}>
                <h1 className={`font-family-vietnam-pro text-weight-600 ${isMobile ? "text-size-40" : "text-size-80"}`}>WHY <span className="gradient-head-text">ADSPIRE®</span>?</h1>
                <p className={`font-family-vietnam-pro ${isMobile ? "text-size-22 text-center mb-20" : "text-size-28 text-left"}`}>Let our artificial intelligence generate stunning, data-backed creatives in seconds that convert, so you can save time and money to scale.</p>
              </div>
            </div>
            <div className={isMobile ? "" : "col-md-12"}>
              <div className="testimonials-bottom-items">
                {bottomData &&
                  bottomData?.map((testData, index) => {
                    return (
                      <div className={isMobile ? "text-align-last-center" : "testimonials-bottom-items-inner-container"} key={index}>
                        <div className="outer-circle-div-testemonials" style={{ background: testData?.colors }}>
                          <img className="" src={testData?.icon} alt={testData?.text} width={60} />
                        </div>
                        <div className="text-left ml-20">
                          <h4 className="font-family-vietnam-pro text-size-30 text-weight-600"><i>{testData?.text}</i></h4>
                          <p className="font-family-sans-serif text-light-gray-200 text-size-20">{testData?.subtext}</p>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
            <div className="col-md-12 mt-60 mb-40 position-relative z-index-10">
              <div className="text-center">
                <ButtonGradient
                  title="Generate Ads"
                  btnstyle={{
                    textShadow: "5px 5px 20px white"
                  }}
                  stylebyclass="text-size-30 text-weight-600 text-white"
                  btnstylebyclass="m-left-right-auto"
                  onClick={() => navigate("/signup")}
                />
                <p className="font-family-sans-serif text-size-16 text-light-gray mt-10">Try 100% free for 3 days. <span className="text-weight-600 gradient-head-text">cancel anytime</span></p>
              </div>
            </div>

            <div className="unlimited-caption-txt-container">
              <p className={`adspire-your-new-roas mt-60 mb-50 text-testimonials`}>How it Works</p>
            </div>

            <div className="col-md-12">
              <iframe
                className="youtube-video position-relative z-index-2"
                width="70%"
                height="500"
                style={{ borderRadius: "25px" }}
                src={TESTIMONIAL_VIDEO}
                title="Adspire"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe>
            </div>
            <div className="col-md-12">
              <img src={stars_5_testemonials} alt="rectangle_video_sec_img" width={300} />
            </div>
            <div className="col-md-4 col-md-offset-4 mt-10">
              <p className="font-family-vietnam-pro text-size-30 text-weight-600">{TRUSTED_BY_MSG}</p>
            </div>

          </div>
        </div>
      </div>
    </div >
  );
};
export default Testimonials;
















import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getWebsiteTitle } from "../../../utils/function"
import { userService } from "../../../services/user.service";
import { db } from "../../../utils/Firebase";

import circleCheck from "../../../images/icons/circle-check.svg";
import SpinnerLoader from "../../../components/common/SpinnerLoader";

const LandingImageStyle = (props) => {
  const [adStyles, setStyles] = useState("");
  const [active, setActive] = useState(null)
  const [buttonStatus, setButtonStatus] = useState(true);
  const [classvalue, setClassvalue] = useState("next-btn");
  const [loading, setLoader] = useState(false);
  // Accessing the history instance created by React
  const navigate = useNavigate();

  useEffect(() => {
    db.collection("adIdeas")
      .doc("imageGenerator")
      .collection("styles")
      .orderBy("order")
      .onSnapshot((snapshot) => {
        const dataReceived = snapshot.docs.map((doc) => ({
          id: doc.id,
          data: doc.data(),
        }));

        setStyles(dataReceived);
        setLoader(true)
      });
  }, []);

  const setStyleValue = (StyleData) => {
    userService.setKeyValueLocalstorage("style", StyleData.data.name);
    userService.setKeyValueLocalstorage("styleId", StyleData.id);
    setButtonStatus(false)
    setClassvalue("generateAdGradient")
    setActive(StyleData.data.name)
    // redirectTo();
  };

  document.title = getWebsiteTitle() + "Image Style";

  return (
    <React.Fragment>


      {/*<h2 className="gradient-head-text text-center text-size-32 mb-40">
                  What style do you want your image ad in?
                </h2>*/}
      <div className="what-do-you-want-an-ad-for-wrapper">
        <b className="what-do-you"> What style do you want your image ad in?</b>
      </div>
      <div className="clearfix"></div>
      <div className="col-sm-6">
        {!loading && (
          <SpinnerLoader message="Generating Ideas..."></SpinnerLoader>
        )}
        {loading && (<div className="row mb-20 mt-20">
          {adStyles &&
            adStyles.map((projectsItems) => (
              <div
                className={`col-sm-6 col-xs-12`}
                key={projectsItems.data.backgroundColor + "trnp"}
                onClick={() => setStyleValue(projectsItems)}
              >
                <div
                  title={projectsItems.data.name}
                  className={`thumbnailBG text-size-16 ${active === projectsItems.data.name && 'box-active'}`}
                  style={{
                    backgroundColor: projectsItems.data.backgroundColor,
                  }}
                >
                  <div className="select-boxes-for-img btn-text-left">
                    <img
                      data-toggle="modal"
                      data-target="#myModal"
                      className="img-thumbnail"
                      width={60}
                      alt={projectsItems.data.name}
                      src={projectsItems.data.imageURL}
                    />
                    <span className="ad-title">
                      {" "}
                      {projectsItems.data.name}
                    </span>

                    {active === projectsItems.data.name ?
                      < img
                        data-toggle="modal"
                        data-target="#myModal"
                        alt={projectsItems.data.name}
                        width={20}
                        src={circleCheck}
                        className="w-20 float-r"
                      />
                      :
                      ""}
                  </div>

                  <div className="clearfix"></div>
                </div>
              </div>
            ))}
        </div>)}
        {loading && (
          <div className="form-group text-center" title="Generate Image Ads">
            <button type="btn"
              disabled={buttonStatus}
              className={`btn btn-primary ${classvalue}`}
              onClick={() => props.currentPage("generate-image")}>
              Generate Image Ads    </button>
          </div>

        )}
      </div>


    </React.Fragment>
  );
};
export default LandingImageStyle;

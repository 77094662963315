import React from "react";
import { getWebsiteTitle } from "../../utils/function";
import ConceptBackButton from "../common/Button/ConceptBackButton";
import NewSubscribe from "./NewSubscribe";


const SubscribeContainer = () => {
  document.title = getWebsiteTitle() + " Subscribe Plans";

  return (
    <React.Fragment>
      <div className="container-fluid">
        <div className="row">
          <div className="top-contentDIv">
            <div className="new-subscribe-page">
              <div className="flex align-items-center text-white">
                <ConceptBackButton
                  className={"mr-20 cursor-pointer subscribe-backbtn"}
                  alt="Back"
                  redirectPage="select-ads-type"
                />
                <h2 className="mb-20 free-trail-welcome-to">Subscribe</h2>
              </div>
              <div className="divider-border-2"></div>
              <NewSubscribe />
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};
export default SubscribeContainer;

import { userService } from "../../../services/user.service";
import StripeCheckoutRedirect from "../../../utils/StripeCheckoutRedirect";

export default function CheckoutMonthlyPro() {
  async function handleCheckout() {
    userService.setKeyValueLocalstorage("userSelectedPlan", process.env.REACT_APP_STRIPE_MONTHLY_PRO_PRICE_ID);
    userService.setKeyValueLocalstorage("clientReferenceId", process.env.REACT_APP_PAYPAL_MONTHLY_PRO_PLAN_NAME + "||" + userService.getUserId());

    if (!userService.showLogoutBtn()) {
      const myBtn = document.getElementById("ploginModal");
      myBtn.click();
    } else {
      StripeCheckoutRedirect();
    }
  }

  return <>
    <p className="p0" id="ploginModal" data-toggle="modal" data-target="#loginModal">
    </p><button className="subs-btn" onClick={handleCheckout}>
      <span className="create-new-ads">Subscribe</span>
    </button></>;
}